
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import Payment from './Payment';
import styles from './Invoice.module.scss';
import { Checkbox, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { ExpandLess, ExpandMore, InfoOutlined, PaidOutlined, TuneOutlined } from '@mui/icons-material';
import { toast } from "react-toastify";
import { useReactToPrint } from 'react-to-print';
import { Button, Modal } from '../../Components/Components';
import CloseButton from '../../Components/Modal/CloseButton/CloseButton';
import { shortDate, daysPassedOrInFuture } from '../../Utils/timeHandler';
import { Truncate } from '../../Utils/Validation';
import Tip from '../Tip/Tip';

const CustomerInvoices = (props: {
    isOpenCustomerInvoicesModal;
    setIsOpenCustomerInvoicesModal: CallableFunction;
    isPassedInvoice
    appointmentDetails?
    isSuccess: CallableFunction;
    isSuccessFullPayment?
}) => {

    const [isLoading, setIsLoading] = useState<any>(false)
    const [chargesList, setChargesList] = useState<any>([])
    const [billingAddress, setBillingAddress] = useState<any>("")
    const [addedDate, setAddedDate] = useState<any>("")
    const [dueDate, setDueDate] = useState<any>("")
    const [selectedCharge, setSelectedCharge] = useState<any>([])
    const [remainingTotal, setRemainingTotal] = useState(0)
    const [paymentItemList, setPaymentItemList] = useState<any>([])
    const [totRemain, setTotRemain] = useState<any>(0)
    const [totPaid, setTotPaid] = useState<any>(0)
    const [totTax, setTotTax] = useState(0) as any
    const [selectAllCharges, setSelectAllCharges] = useState(false)
    const [isOpenPaymentsModal, setIsOpenPaymentsModal] = useState(false)
    const [payCustomerID, setPayCustomerID] = useState(0)

    const [isTipModalOpen, setIsTipModalOpen] = useState(false);
    const [isTipEnabled, setIsTipEnabled] = useState(false);

    // const [groupdChares, setGroupdChares] = useState<any>([])


    const GetInvoiceDetails = async (id, type) => {
        let para:any = {};
        if(type === "inv"){
            para = {
                numInvoiceID: id
            }
        }else{
            para = {
                numAppointmentID: id
            }
        }
        console.log("sent params to fetch-", para)
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetInvoiceDetails`;
      
        try {
          const response = await axios.get(apiUrl, {
            params: para,
          });
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
    };

    const getTipServiceDetails = async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}Tip/GetTipServiceItemIdByName`;
      
        try {
          const response = await axios.get(apiUrl, {
            params: {
              serviceName: "Tip"
            },
          });
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      };

      const getLocationWiseTipDetailsByLocationID = async (LocID) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}Tip/GetLocationWiseTipDetailsByLocationID`;
      
        try {
          const response = await axios.get(apiUrl, {
            params: {
                LocationID: LocID
            },
          });
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      };

    useEffect(() => {
        if (props.isOpenCustomerInvoicesModal === true) {
            if (props.isPassedInvoice === true) {
                    fetchInvoiceData(props.appointmentDetails.numInvoiceID, "inv");
                
            } else {
                    fetchInvoiceData(props.appointmentDetails.numAppointmentID, "app");

            }
        }
    }, [props.isOpenCustomerInvoicesModal]);


    useEffect(()=>{
        if (props.isOpenCustomerInvoicesModal === true) {
        getTipServiceDetails()
        .then((data) => {
          console.log("TipDetail", data)
          if(data === "Service item not found."){

            setIsTipEnabled(false)

          }else{
  
            getLocationWiseTipDetailsByLocationID(props?.appointmentDetails?.LocationID)
            .then((data) => {
              if(props.appointmentDetails?.Employee?.EmployeeID !== props.appointmentDetails?.Employee?.DealerID){
                if (Array.isArray(data) && data.length === 0) {
                    setIsTipEnabled(false)
                } else {
                  if(data?.[0]?.BatherTipPerc === 0 && data?.[0]?.GroomerTipPerc === 0){
                    setIsTipEnabled(false)
                  }else{
                    setIsTipEnabled(true)
                  }
                }
              }else{
                setIsTipEnabled(true)
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
                }
    })
    .catch((error) => {
    console.log("error", error);
    });

}
    },[props.isOpenCustomerInvoicesModal])

    const fetchInvoiceData = (id , type) => {
        setIsLoading(true)
        if(id !== null && id !== undefined){
            GetInvoiceDetails(id , type)
            .then((data) => {
                console.log("res - inv list", data);


                // const groupedAppointments = data.reduce((acc, appointment) => {
                //     const { numAppointmentID } = appointment;
                    
                //     if (!acc[numAppointmentID]) {
                //       acc[numAppointmentID] = [];
                //     }
                    
                //     acc[numAppointmentID].push(appointment);
                    
                //     return acc;
                //   }, {});

                //   console.log("groupd", groupedAppointments)
                //   setGroupdChares(groupedAppointments)

                setChargesList(data)
                setTotPaid(data[0].totalPaid)
                setTotTax(data[0].taxTotal)
                let billingAddress1 = (data[0].varBillToAddress1 !== null)? data[0].varBillToAddress1 : "";
                let varBillToAddress2 = (data[0].varBillToAddress2 !== null)? data[0].varBillToAddress2 : "";
                let varBillToCity = (data[0].varBillToCity !== null)? data[0].varBillToCity : "";
                let varBillToState = (data[0].varBillToState !== null)? data[0].varBillToState : "";
                let varBillToZip = (data[0].varBillToZip !== null)? data[0].varBillToZip : "";
                setBillingAddress(billingAddress1 +" "+ varBillToAddress2 +" "+ varBillToCity +", "+ varBillToState +", "+varBillToZip)
                setAddedDate(data[0].dtInvoiceCreatedDate)
                setDueDate(data[0].dtInvoiceDueDate)
                setPayCustomerID(data[0].numCustomerID)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log("error", error);
            });
        }else{
            console.log("cancelled")
        }

    }

    useEffect(()=>{
        setSelectAllCharges(true)
        const selectedCh:any = [];
        chargesList.map((item, index)=>{
            if(item.remaining > 0){
                selectedCh?.push(index)   
            }          
        })
        setSelectedCharge(selectedCh)
    },[chargesList])

    useEffect(()=>{
        let amt = 0
        if(selectedCharge.length > 0){
            selectedCharge.map((index)=>{
                amt = amt + parseFloat(chargesList[index].remaining)
            })
            setRemainingTotal(amt)
        }else{
            let amt = 0
            chargesList.map((charge)=>{
                amt = amt + parseFloat(charge.remaining)
            })
            setRemainingTotal(amt) 
        }
    },[selectedCharge])

    useEffect(()=>{
        if(chargesList.length > 0) {
            let amt = 0
            let totRemainAmt = 0;
            chargesList.map((charge)=>{
                amt = amt + parseFloat(charge.remaining)
                totRemainAmt = totRemainAmt + parseFloat(charge.remaining)
            })
            setRemainingTotal(amt)
            setTotRemain(totRemainAmt)
        }
    },[chargesList])
    
    const makePayment = () =>{
        if(selectedCharge.length > 0){
            let selectedArr:any = [];
            selectedCharge.map((item, index)=>{
                selectedArr.push(
                    {
                        numChargeID: chargesList[item].numChargeID,
                        remaining: chargesList[item].remaining,
                        varTitle: chargesList[0].numMainInvoiceID,
                        numInvoiceID: chargesList[0].numInvoiceID,
                        type: "Tvp",
                        numOrderID: chargesList[item].numOrderID,
                        remTotal: totRemain
                    }
                )
            })
            setPaymentItemList(selectedArr)
            setIsOpenPaymentsModal(true)
        }else{
            toast.info("Please select orders to proceed to payments");
        }
    }

    const handlePayment = (index) => {
        setPaymentItemList([{
            numChargeID: chargesList[index].numChargeID,
            varTitle: chargesList[index].varServiceProductDescription,
            remaining: chargesList[index].remaining,
            type: "Order",
            numInvoiceID: chargesList[0].numInvoiceID,
            numOrderID: chargesList[index].numOrderID,
            remTotal: totRemain
        }])
        setIsOpenPaymentsModal(true)
    } 

    const selectItem = (index) => {
        const selectedCh = [...selectedCharge];
        const isIn = selectedCh.includes(index);
        if(!isIn){
            selectedCh?.push(index)
            setSelectedCharge(selectedCh)
        }else{
            var carIndex = selectedCh.indexOf(index);
            const afterRemove  = [...selectedCharge];
            afterRemove.splice(carIndex,1)
            setSelectedCharge(afterRemove)  
        }
    }

    const printInvoice = () => {
        setSelectedCharge([])
        setSelectAllCharges(!selectAllCharges)
        handlePrint()
    }

    const componentRef = useRef() as any;
    const componentRefReceipt = useRef() as any;
    const componentRefSimplified = useRef() as any;

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const handlePrintReceipt = useReactToPrint({
        content: () => componentRefReceipt.current,
    })

    const handlePrintReceiptSimplified = useReactToPrint({
        content: () => componentRefSimplified.current,
    })

    useEffect(()=>{
        if(selectAllCharges === true){
            setSelectedCharge([])
            const selectedCh:any = [];
            chargesList.map((item, index) => {
                if(item.remaining > 0){
                    selectedCh?.push(index)   
                }    
            })
            setSelectedCharge(selectedCh)

        }else{
            setSelectedCharge([])
        }

    }, [selectAllCharges])



    const handleAddTip = () => {
        setIsTipModalOpen(true);
      };

    const groomerName = "John Doe"; 
    const batherName = "Jane Smith"; 
    const globalGroomerPercentage = 75; 
    const globalBatherPercentage = 25; 
    const [groomerTip, setGroomerTip] = useState(0);
    const [batherTip, setBatherTip] = useState(0);

    const handleCloseModal = () => {
        setIsTipModalOpen(false);
    };

    const handleSaveTip = (groomerTip, batherTip) => {
        setGroomerTip(groomerTip);
        setBatherTip(batherTip);
        fetchInvoiceData(props.appointmentDetails.numAppointmentID , "app")
        setSelectedCharge([])
        setSelectAllCharges(false)
    };
  

    return (
        <div>

                    <Modal open={props.isOpenCustomerInvoicesModal} >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%' }}>
                            <div style={{ margin: '-15px -15px 0 -15px',display: "flex", justifyContent: 'space-between', backgroundColor: '#f3f4f6', padding: '1rem 1rem 1rem 25px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
                                <div>
                                    <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
                                        <div style={{ color: "#002867", fontSize: "24px", fontWeight: "600" }}>View Invoice</div>
                                    </div>
                                </div>
                                <div style={{ color: "#F9FAFB", borderRadius: "8px" }}>
                                    <div style={{ height: "40px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                        <CloseButton onClick={() => props.setIsOpenCustomerInvoicesModal(false)} />
                                    </div>
                                </div>
                            </div>

                            {isTipModalOpen && (
                                <Tip
                                groomerName={groomerName}
                                batherName={batherName}
                                onClose={handleCloseModal}
                                onSave={handleSaveTip}
                                globalGroomerPercentage={globalGroomerPercentage}
                                globalBatherPercentage={globalBatherPercentage}
                                totalAmount={parseFloat(totPaid)+ parseFloat(totRemain)}
                                isOpenTipModal={isTipModalOpen}
                                setIsOpenTipModal={()=>setIsTipModalOpen(!isTipModalOpen)}
                                appDetails={props.appointmentDetails}
                                />
                            )}

                            <div style={{ padding: '0 10px' }}>
                                <div>
                                        <div style={{ display: 'flex', justifyContent:'space-between', paddingBottom: '25px' }}>
                                            <div>
                                            <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Invoice No: </span>{(chargesList[0] !== undefined)? chargesList[0].numMainInvoiceID : "--"}</p>
                                                <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Customer:</span> {(chargesList[0] !== undefined)? chargesList[0].varSalutationName : ""}{(chargesList[0] !== undefined)? chargesList[0].varCustomerFirstName : ""} {(chargesList[0] !== undefined)? chargesList[0].varCustomerLastName : ""}</p>
                                                <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Billing Address: </span>{ billingAddress}</p>
                                            </div>
                                            <div>
                                                <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Date:</span> {shortDate(addedDate)+" ("+daysPassedOrInFuture(addedDate)+")"}</p>
                                                <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Date Due:</span> {shortDate(dueDate)+" ("+daysPassedOrInFuture(dueDate)+")"}</p>
                                            </div>
                                        </div>
                                        {
                                        /* <div style={{ marginRight: '16px' }}>
                                            <table className={styles.invTable} style={{ width: '100%', paddingRight: '14px' }}>
                                                <tr>
                                                <th style={{ whiteSpace: 'nowrap' }}><div style={{display:"flex", flexDirection:"column", margin:"0", alignItems:"center"}}><Checkbox checked={selectAllCharges} onChange={()=>{setSelectAllCharges(!selectAllCharges)}}/></div></th>
                                                    <th style={{ whiteSpace: 'nowrap' }}>Order ID</th>
                                                    <th style={{ width: '100%', textAlign: 'left' }}>Description</th>
                                                    <th style={{ whiteSpace: 'nowrap' }}>Unit Chrg</th>
                                                    <th style={{ whiteSpace: 'nowrap' }}>Qty.</th>
                                                    <th style={{ whiteSpace: 'nowrap' }}>Charge</th>     
                                                    <th style={{ whiteSpace: 'nowrap' }}>Adjustments</th>
                                                    <th style={{ whiteSpace: 'nowrap' }}>Tax</th>
                                                    <th style={{ whiteSpace: 'nowrap' }}>Payments</th>  
                                                    <th style={{ whiteSpace: 'nowrap' }}>Balance</th> 
                                                    <th style={{ whiteSpace: 'nowrap' }}><p style={{ width: '65px' }}><strong>Action</strong></p></th>                          
                                                </tr>
                                                <tr style={{ visibility: 'collapse' }}>
                                                    <th style={{ whiteSpace: 'nowrap' }}></th>
                                                    <th></th>                                              
                                                    <th style={{ width: '100%', textAlign: 'left' }}></th>
                                                    <th></th>     
                                                    <th></th>
                                                    <th></th>  
                                                    <th></th> 
                                                    <th><Button color='info' variant='outlined' text='PAY'></Button></th>               
                                                </tr>
                                            </table>
                                        </div>

                                        <div style={{  overflowY: 'scroll', minHeight:"100px", maxHeight:"400px" }}>
                                            <table className={styles.invTable} style={{ width: '100%' }}>  
                                                <tr style={{ visibility: 'collapse' }}>
                                                <th style={{ whiteSpace: 'nowrap' }}>Select</th>
                                                    <th style={{ whiteSpace: 'nowrap' }}>Order ID</th>
                                                    <th style={{ width: '100%', textAlign: 'left' }}>Description</th>
                                                    <th style={{ whiteSpace: 'nowrap' }}>Unit Chrg</th>
                                                    <th style={{ whiteSpace: 'nowrap' }}>Qty.</th>
                                                    <th style={{ whiteSpace: 'nowrap' }}>Charge</th>   
                                                    <th style={{ whiteSpace: 'nowrap' }}>Adjustments</th>
                                                    <th>Tax</th>  
                                                    <th>Payments</th>  
                                                    <th>Balance</th>  
                                                    <th><Button color='info' variant='outlined' text='PAY'></Button></th>                               
                                                </tr>                                       
                                                {
                                                    chargesList.map((charge, index) => {

                                                        const selectedChargeItem = [...selectedCharge];
                                                        const isIn = selectedChargeItem.includes(index);

                                                        return <>
                                                            <tr className={ styles.mainRow } style={{ borderBottom: '2px solid #dbdbdb' }}>
                                                                <td>                                                                
                                                                    <Checkbox disabled={(parseFloat(charge.remaining) > 0)? false : true} checked={isIn} onChange={()=>{selectItem(index)}}></Checkbox>                                                                                                                      
                                                                </td>
                                                                <td>
                                                                    {
                                                                        (charge.Adjustments.length > 0 || (charge.Payments.length > 0)) ?
                                                                            <IconButton 
                                                                                onClick={() => {
                                                                                    let collapseCharges = [...chargesList];
                                                                                    collapseCharges[index].collapsed = !collapseCharges[index].collapsed
                                                                                    setChargesList(collapseCharges)
                                                                                }} 
                                                                                style={{ padding: '0 2px 2px 1px' }}
                                                                            >
                                                                                {
                                                                                    (chargesList[index].collapsed) ?
                                                                                        <ExpandLess style={{ fontSize: '15px' }}></ExpandLess>
                                                                                    : 
                                                                                        <ExpandMore style={{ fontSize: '15px' }}></ExpandMore>
                                                                                        
                                                                                }
                                                                                
                                                                            </IconButton>
                                                                        : null
                                                                    }
                                                                    
                                                                    <strong>{charge.numOrderID}</strong>
                                                                </td>
                                                                <td style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>                                                                
                                                                    <div style={{display:"flex", gap:"4px", alignItems:"center"}}>
                                                                        <div style={(charge.varServiceOrProduct === "S")? {color:"#5178bb", fontWeight:"600"} : {color:"#88bb51", fontWeight:"600"}}>({charge.varServiceOrProduct})</div>
                                                                        <p>{charge.varServiceOrProductName} {(charge.numPetID === -1)? null : (charge.numPetID === null)? null : <span>Pet: (<span>{charge.varPetName}</span>)</span>}{(charge.numAppointmentID !== null && charge.numAppointmentID !== 0)? <><span style={{fontWeight:"500"}}>{" App:"+charge.numAppointmentID}</span></> : null }</p>
                                                                    </div>
                                                                    <Tooltip 
                                                                            title={
                                                                                <>
                                                                                    <p style={{ fontSize: '14px', color: '#fff', fontWeight: '600' }}>{charge.varServiceOrProduct} - Description</p>
                                                                                    <p style={{ fontSize: '12px', color: '#fff' }}>{charge.varServiceProductDescription}</p>
                                                                                </>                                                                                                                                                                                                                                                                   
                                                                            }
                                                                        >
                                                                            <InfoOutlined style={{ fontSize: "17px", color: "#979797" }} />
                                                                    </Tooltip>                                                                                                                   
                                                                </td>
                                                                <td >
                                                                    ${charge.decChargeAmount}
                                                                </td>
                                                                <td style={{textAlign:"center"}} >
                                                                    {charge.numQuantity}
                                                                </td>
                                                                <td>
                                                                    ${charge.decChargeAmt}
                                                                </td>
                                                                <td>
                                                                    (${charge.totalAdjustments})
                                                                    
                                                                </td>
                                                                <td>
                                                                    ${charge.decTaxAmt}
                                                                </td>
                                                                <td>
                                                                    (${charge.totalPayments})
                                                                
                                                                </td>
                                                                <td>
                                                                    ${parseFloat(charge.remaining).toFixed(2)}
                                                                </td>
                                                                <td>
                                                                    <Button disabled={(parseFloat(charge.remaining) > 0)? false : true} color='info' variant='outlined' text='PAY' onClick={()=>{handlePayment(index)}}></Button>
                                                                </td>
                                                            </tr>                                                                                                                                                          
                                                            {
                                                                (charge.Adjustments.length > 0) ?
                                                                    charge.Adjustments.map((adj, adjIndex) => {
                                                                        return <tr  style={{ backgroundColor: '#f7dddd73' }}className={ (chargesList[index].collapsed) ? undefined : styles.rowhidden }>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td><div style={{display:"flex", alignItems:"center", paddingLeft: '15px', gap:"5px"}}><TuneOutlined style={{color:"orange", fontSize:"18px"}}/><p style={{ fontSize:'12px', margin:'0' }}>{adj.varPromotionDescription}</p></div></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td><p style={{ paddingLeft: '15px', margin:'0' }}>${adj.decAdjustmentAmount}</p></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                        </tr>                                                                                                                                                   
                                                                    })
                                                                : null
                                                            }    

                                                            {
                                                                (charge.Payments.length > 0) ?
                                                                    charge.Payments.map((pay, adjIndex) => {
                                                                        return <tr style={{ backgroundColor: '#ddf7ec73' }} className={ (chargesList[index].collapsed) ? undefined : styles.rowhidden }>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td><div style={{display:"flex", alignItems:"center", paddingLeft: '15px', gap:"5px"}}><PaidOutlined style={{color:"green", fontSize:"18px"}}/><p style={{ fontSize:'12px', margin:'0' }}>{pay.varPmtReasonCodeDescription1}</p></div></td>
                                                                            <td></td>                                                                        
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td><p style={{ paddingLeft: '15px', margin:'0' }}>${pay.decPaymentAmount}</p></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                        </tr>                                                                                                                                                   
                                                                    })
                                                                : null
                                                            }                                                                                                          
                                                        </>                                                 
                                                    })
                                                }
                                            </table>  
                                        </div>

                                        <div style={{ marginRight: '16px' }}>
                                            <table className={styles.invTable} style={{ width: '100%' }}>  
                                                <tr style={{ visibility: 'collapse' }}>                                         
                                                    <th colSpan={3} style={{ width: '100%'}}>TOTAL</th>                                             
                                                    <th>Total</th>
                                                    <th>Adjustments</th>
                                                    <th>Balance</th>    
                                                </tr>            
                                                <tr>                                         
                                                    <th colSpan={5} style={{ width: '100%', fontSize: '25px', textAlign: 'left' }}>Total</th>                                             
                                                    <th style={{ textAlign: 'right', fontSize: '25px' }}>${parseFloat(remainingTotal.toString()).toFixed(2)}</th>   
                                                </tr>                                                                                 
                                            </table>  
                                        </div> */}

                                        <div style={{display:"flex", gap:"5px", alignItems:"center", width:"100%", borderBottom:"1px solid #d7d7d7", padding:"10px"}}>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                                <Checkbox checked={selectAllCharges} onChange={()=>{setSelectAllCharges(!selectAllCharges)}}/>      
                                            </div>
                                            <p style={{margin:"0", color:"gray"}}>Select all</p>
                                        </div>


                                        <div style={{height:"250px", overflowY:"scroll"}}>

                                            {
                                                 chargesList.map((charge, index) => {

                                                    const selectedChargeItem = [...selectedCharge];
                                                    const isIn = selectedChargeItem.includes(index);

                                                        return (
                                                            <div style={{display:"flex", justifyContent:"space-between", background:"#FFFFFF", marginTop:"10px", border:"1px solid #d7d7d7", padding:"10px", borderRadius:"8px" }}>
                                                            <div style={{display:"flex"}}>
                                                                <div style={{display:"flex", alignItems:"center"}}>
                                                                    <Checkbox disabled={(parseFloat(charge.remaining) > 0)? false : true} checked={isIn} onChange={()=>{selectItem(index)}}></Checkbox>                                                                                           
                                                                </div>
                                                                <div>
                                                                    <div style={{display:"flex", flexDirection:"column", borderBottom:"1px solid #fffafa"}}>
                                                                        <p style={{margin:"0", fontWeight:"500"}}>Order ID: <span style={{color:"#000000"}}>{charge?.numOrderID}</span></p>
                                                                        
                                                                        <div style={{display:"flex", alignItems:"center", gap:"10px"}}>                                                                
                                                                            <div style={{display:"flex", gap:"4px", alignItems:"center"}}>
                                                                                <div style={(charge.varServiceOrProduct === "S")? {color:"#5178bb", fontWeight:"600"} : {color:"#88bb51", fontWeight:"600"}}>({charge.varServiceOrProduct})</div>
                                                                                <p style={{margin:"0"}}>{Truncate(charge.varServiceOrProductName, 30)} {(charge.numPetID === -1)? null : (charge.numPetID === null)? null : <span> | Pet: (<span>{charge.varPetName}</span>)</span>}{(charge.numAppointmentID !== null && charge.numAppointmentID !== 0)? <><span style={{fontWeight:"500"}}>{" App:"+charge.numAppointmentID}</span></> : null }</p>
                                                                            </div>
                                                                            <Tooltip 
                                                                                    title={
                                                                                        <>
                                                                                            <p style={{ fontSize: '14px', color: '#fff', fontWeight: '600' }}>{charge.varServiceOrProduct} - Description</p>
                                                                                            <p style={{ fontSize: '12px', color: '#fff' }}>{charge.varServiceProductDescription}</p>
                                                                                        </>                                                                                                                                                                                                                                                                   
                                                                                    }
                                                                                >
                                                                                    <InfoOutlined style={{ fontSize: "17px", color: "#979797" }} />
                                                                            </Tooltip>                                                                                                                   
                                                                        </div>

                                                                    </div>
            
                                                                    <div style={{display:"flex", gap:"10px", marginTop:"10px", background:"#f1f1f1", borderRadius:"8px", padding:"10px", width:"max-content"}}>
                                                                        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                                                                            <span style={{color:"#000000"}}>${charge.decChargeAmount}</span>
                                                                            <p style={{color:"#585858", margin:"0"}}>Unit Charge</p>
                                                                        </div>
            
                                                                        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                                                                            <span style={{color:"#000000"}}>{charge.numQuantity}</span>
                                                                            <p style={{color:"#585858", margin:"0"}}>Qty</p>
                                                                        </div>
            
                                                                        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                                                                            <span style={{color:"#000000"}}>${charge.decChargeAmt}</span>
                                                                            <p style={{color:"#585858", margin:"0"}}>Charge</p>
                                                                        </div>
            
                                                                        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                                                                            <span style={{color:"#000000"}}>(${charge.totalAdjustments})</span>
                                                                            <p style={{color:"#585858", margin:"0"}}>Adjustments</p>
                                                                        </div>
            
                                                                        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                                                                            <span style={{color:"#000000"}}> ${charge.decTaxAmt}</span>
                                                                            <p style={{color:"#585858", margin:"0"}}>Tax</p>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{display:"flex", marginTop:"10px"}}>
                                                                        <div style={{background:"#eefff5", padding:"10px", borderTopLeftRadius:"8px", borderBottomLeftRadius:"8px"}}>
                                                                        <p style={{color:"black", margin:"0"}}>Payments: <span style={{color:"#000000"}}> ${charge.totalPayments}</span></p>
                                                                        </div>
                                                                        <div style={{background:"#ffeeee", padding:"10px", borderTopRightRadius:"8px", borderBottomRightRadius:"8px"}}>
                                                                        <p style={{color:"black", margin:"0"}}>Remaining: <span style={{color:"#000000"}}> ${parseFloat(charge.remaining).toFixed(2)}</span></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
               
                                                            </div>
                                                            <div style={{display:"flex", alignItems:"center"}}>
                                                                <Button disabled={(parseFloat(charge.remaining) > 0)? false : true} color='info' variant='outlined' text='PAY' onClick={()=>{handlePayment(index)}}></Button>
                                                            </div>
                                                        </div>
                                                        )
                                                 })
                                            }


                                        </div>


                                        
                                        
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '0 20px 10px 0', gap:"10px" }}>  
                            <Button color='default' variant='outlined' onClick={() => printInvoice()}>Print Invoice</Button>
                            <Button color='default' variant='outlined' onClick={() => handlePrintReceiptSimplified()}>Print Receipt</Button> 
                            <Button color='default' variant='outlined' onClick={() => handlePrintReceipt()}>Print Detailed Receipt</Button> 
                            {
                                (props.isPassedInvoice !== true && isTipEnabled === true)? 
                                    <Button color='success' variant='contained' disabled={false} onClick={handleAddTip}>Add Tip</Button> 
                                    : null  
                            }                             
                                <Button color='primary' variant='contained' disabled={false} onClick={() => makePayment()}>Make Payment</Button>
                                <Payment customerID={payCustomerID} data={paymentItemList} isOpenPaymentsModal={isOpenPaymentsModal} setIsOpenPaymentsModal={(value) => setIsOpenPaymentsModal(value)} clearSelected={(value) => {
                                    if(value === true){
                                        setSelectedCharge([])
                                    }
                                }}
                                onSuccess={(value)=> {
                                    if(value === true){
                                        fetchInvoiceData(props.appointmentDetails.InvoiceID , "inv")
                                        setSelectedCharge([])
                                        setSelectAllCharges(false)
                                        props.isSuccess(true)
                                    }
                                }}

                                isSuccessFullPayment={(e)=>{
                                    if(e === true){
                                        props.isSuccessFullPayment(true)
                                    }

                                }}
                                appointment={props.appointmentDetails}
                                passedByInv={props.isPassedInvoice}
                                 />
                            </div>
                        </div>
                    </Modal>


            <div  style={{ display: 'none' }}>
            <div  id="printInvoice" ref={componentRef}>
                <div style={{ margin: '40px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '25px' }}>
                        <img style={{ width: '200px' }} src={`${process.env.REACT_APP_MEDIA_URL}invoice-mockup/images/pettech-logo.png`}></img>
                        <p style={{ fontSize: '40px', fontWeight: '600', color: '#002867' }}>INVOICE</p>
                    </div>
                    
                    <div style={{ display: 'flex', justifyContent:'space-between', paddingBottom: '25px' }}>
                        <div>                    
                        <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Invoice No: </span>{(chargesList[0] !== undefined)? chargesList[0].numMainInvoiceID : "--"}</p>
                            <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Customer:</span> {(chargesList[0] !== undefined)? chargesList[0].varSalutationName : null}{(chargesList[0] !== undefined)? chargesList[0].varCustomerFirstName : null} {(chargesList[0] !== undefined)? chargesList[0].varCustomerLastName : null}</p>
                            <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Billing Address: </span>{ billingAddress}</p>
                        </div>
                        <div>
                            <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Date:</span> {shortDate(addedDate)}</p>
                            <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Date Due:</span> {shortDate(dueDate)}</p>
                        </div>
                    </div>

                    <div>
                        <table className={styles.invTablePrint} style={{ width: '100%' }}>  
                            <tr>
                                <th style={{ whiteSpace: 'nowrap', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Order ID</th>
                                <th style={{ width: '100%', textAlign: 'left', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Description</th>
                                <th style={{ width: '100%', textAlign: 'left', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Unit Chrg</th>
                                <th style={{ width: '100%', textAlign: 'left', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Qty.</th>
                                <th style={{ textAlign: 'center', padding: '5px', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Charge</th>    
                                <th style={{ textAlign: 'center', padding: '5px', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Adjustments</th>
                                <th style={{ textAlign: 'center', padding: '5px', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Tax</th> 
                                <th style={{ textAlign: 'center', padding: '5px', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Payments</th>  
                                <th style={{ textAlign: 'center', padding: '5px', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Balance</th>                       
                            </tr>                                       
                            {
                                chargesList.map((charge, index) => {
                                    return <>
                                        <tr>
                                            <td style={{ textAlign: 'center' }}>                                         
                                                <strong>{charge.numOrderID}</strong>
                                            </td>
                                            <td>                                                                        
                                                <p style={{ margin:'0' }}>
                                                    <span style={(charge.varServiceOrProduct === "S")? {color:"#5178bb", fontWeight:"600"} : {color:"#88bb51", fontWeight:"600"}}>({charge.varServiceOrProduct}) &nbsp;</span>
                                                    {charge.varServiceProductDescription}  {(charge.numPetID === -1)? null : (charge.numPetID === null)? null : <span>Pet: (<span>{charge.varPetName}</span>)</span>}
                                                </p>                                                                                                                
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                ${charge.decChargeAmount}
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {charge.numQuantity}
                                            </td>

                                            <td style={{ textAlign: 'right' }}>
                                                ${charge.decChargeAmt}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                (${charge.totalAdjustments})
                                                
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                ${charge.decTaxAmt}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                (${charge.totalPayments})
                                            
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                ${parseFloat(charge.remaining).toFixed(2)}
                                            </td>
                                        </tr>                                                                                                                                                          
                                        {
                                            (charge.Adjustments.length > 0) ?
                                                charge.Adjustments.map((adj, adjIndex) => {
                                                    return <tr>                                                 
                                                        <td></td>
                                                        <td><p style={{ margin:'0' }}>{adj.varPromotionDescription}</p></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><p style={{ margin:'0', textAlign: 'right' }}>${adj.decAdjustmentAmount}</p></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>                                                                                                                                                   
                                                })
                                            : null
                                        }                                                                                                
                                    </>                                                 
                                })
                            }
                        </table>  
                    </div>

                    <div style={{ marginTop: '15px' }}>
                        <table style={{ width: '100%' }}>  
                            <tr style={{ backgroundColor: '#ddebf8' }}>                                         
                                <th colSpan={5} style={{ width: '100%', fontSize: '20px', textAlign: 'left', color: '#002867' }}>Total</th>                                         
                                <th style={{ textAlign: 'right', fontSize: '20px', color: '#002867' }}>${parseFloat((totPaid + totRemain).toString()).toFixed(2)}</th>   
                            </tr>                                                                                                                
                        </table>  
                    </div>  

                    
                    <div style={{ backgroundColor: '#f3f4f6', marginTop: '30px', borderRadius: '15px' }}>
                        <p style={{ fontSize: '12px', fontWeight: '600', width: '100%', padding: '5px', textAlign: 'center' }}>Thank you for your Business</p>
                    </div>      
                    <p style={{ textAlign: 'center', borderRadius: '15px', padding: '5px', backgroundColor: '#f3f4f6', fontSize: '10px' }}>Pet Tech, 39, Berry Hill Road, Syosett 11791&nbsp;&nbsp;|&nbsp;&nbsp;New York&nbsp;&nbsp;|&nbsp;&nbsp;(456) 789-0000&nbsp;&nbsp;|&nbsp;&nbsp;info@pettech.com</p>           
                </div> 
            </div>  

            <div  id="printReceipt" ref={componentRefReceipt}>
                <div style={{ margin: '40px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '25px' }}>
                        <img style={{ width: '200px' }} src={`${process.env.REACT_APP_MEDIA_URL}invoice-mockup/images/pettech-logo.png`}></img>
                        <p style={{ fontSize: '40px', fontWeight: '600', color: '#002867' }}>RECEIPT</p>
                    </div>
                    
                    <div style={{ display: 'flex', justifyContent:'space-between', paddingBottom: '25px' }}>
                        <div>                    
                        <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Invoice No: </span>{(chargesList[0] !== undefined)? chargesList[0].numMainInvoiceID : "--"}</p>
                            <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Customer:</span> {(chargesList[0] !== undefined)? chargesList[0].varSalutationName : null}{(chargesList[0] !== undefined)? chargesList[0].varCustomerFirstName : null} {(chargesList[0] !== undefined)? chargesList[0].varCustomerLastName : null}</p>
                            <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Billing Address: </span>{ billingAddress}</p>
                        </div>
                        <div>
                            <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Date:</span> {shortDate(addedDate)}</p>
                            <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Date Due:</span> {shortDate(dueDate)}</p>
                        </div>
                    </div>

                    <div>
                        <table className={styles.invTablePrint} style={{ width: '100%' }}>  
                            <tr>
                                <th style={{ whiteSpace: 'nowrap', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Order ID</th>
                                <th style={{ width: '100%', textAlign: 'left', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Description</th>
                                                                <th style={{ width: '100%', textAlign: 'left', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Unit Chrg</th>
                                <th style={{ width: '100%', textAlign: 'left', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Qty.</th>
                                <th style={{ textAlign: 'center', padding: '5px', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Charge</th>   
                                <th style={{ textAlign: 'center', padding: '5px', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Adjustments</th>
                                <th style={{ textAlign: 'center', padding: '5px', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Tax</th>  
                                <th style={{ textAlign: 'center', padding: '5px', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Payments</th>  
                                <th style={{ textAlign: 'center', padding: '5px', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Balance</th>                       
                            </tr>                                       
                            {
                                chargesList.map((charge, index) => {
                                    return <>
                                        <tr>
                                            <td style={{ textAlign: 'center' }}>                                         
                                                <strong>{charge.numOrderID}</strong>
                                            </td>
                                            <td>                                                                        
                                                <p style={{ margin:'0' }}>
                                                    <span style={(charge.varServiceOrProduct === "S")? {color:"#5178bb", fontWeight:"600"} : {color:"#88bb51", fontWeight:"600"}}>({charge.varServiceOrProduct}) &nbsp;</span>
                                                    {charge.varServiceProductDescription} {(charge.numPetID === -1)? null : (charge.numPetID === null)? null : <span>Pet: (<span>{charge.varPetName}</span>)</span>}
                                                </p>                                                                                                                
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                ${charge.decChargeAmount}
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {charge.numQuantity}
                                            </td>

                                            <td style={{ textAlign: 'right' }}>
                                                ${charge.decChargeAmt}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                (${charge.totalAdjustments})
                                                
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                ${charge.decTaxAmt}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                (${charge.totalPayments})
                                            
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                ${parseFloat(charge.remaining).toFixed(2)}
                                            </td>
                                        </tr>                                                                                                                                                          
                                        {
                                            (charge.Adjustments.length > 0) ?
                                                charge.Adjustments.map((adj, adjIndex) => {
                                                    return <tr style={{ backgroundColor: '#fbf0f0' }}>                                                 
                                                        <td></td>
                                                        <td><p style={{ margin:'0', marginLeft:"10px" }}>{adj.varPromotionDescription}</p></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><p style={{ margin:'0', textAlign: 'right' }}>${adj.decAdjustmentAmount}</p></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>                                                                                                                                                   
                                                })
                                            : null
                                        }    

                                        {
                                            (charge.Payments.length > 0) ?
                                                charge.Payments.map((pay, adjIndex) => {
                                                    return <tr style={{ backgroundColor: '#f0fbf6' }}>                                                    
                                                        <td></td>
                                                        <td><p style={{ margin:'0', marginLeft:"10px" }}>{pay.varPmtReasonCodeDescription1}</p></td>
                                                        <td></td>                                                                        
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><p style={{ textAlign: 'right', margin:'0' }}>${pay.decPaymentAmount}</p></td>
                                                        <td></td>
                                                    </tr>                                                                                                                                                   
                                                })
                                            : null
                                        }                                                                                                          
                                    </>                                                 
                                })
                            }
                        </table>  
                    </div>

                    <div style={{ marginTop: '15px' }}>
                        <table style={{ width: '100%' }}> 
                        <tr style={{ backgroundColor: '#ddebf8' }}>                                         
                                <th colSpan={5} style={{ width: '100%', fontSize: '14px', textAlign: 'left', color: '#002867' }}>Tax Amount</th>                                             
                                <th style={{ textAlign: 'right', fontSize: '14px', color: '#002867' }}>${parseFloat(totTax.toString()).toFixed(2)}</th>   
                            </tr>                               
                            <tr style={{ backgroundColor: '#ddebf8' }}>                                         
                                <th colSpan={5} style={{ width: '100%', fontSize: '15px', textAlign: 'left', color: '#002867' }}>Total Amount</th>                                             
                                <th style={{ textAlign: 'right', fontSize: '15px', color: '#002867' }}>${parseFloat((totPaid + totRemain).toString()).toFixed(2)}</th>   
                            </tr>            
                            <tr style={{ backgroundColor: '#ddebf8' }}>                                         
                                <th colSpan={5} style={{ width: '100%', fontSize: '14px', textAlign: 'left', color: '#002867' }}>Paid Amount</th>                                             
                                <th style={{ textAlign: 'right', fontSize: '14px', color: '#002867' }}>${parseFloat(totPaid.toString()).toFixed(2)}</th>   
                            </tr>        
                            <tr style={{ backgroundColor: '#ddebf8' }}>                                         
                                <th colSpan={5} style={{ width: '100%', fontSize: '15px', textAlign: 'left', color: '#002867' }}>Balance Amount</th>                                             
                                <th style={{ textAlign: 'right', fontSize: '15px', color: '#002867' }}>${parseFloat(totRemain.toString()).toFixed(2)}</th>   
                            </tr>                                                                          
                        </table>  
                    </div>   

                    
                    <div style={{ backgroundColor: '#f3f4f6', marginTop: '30px', borderRadius: '15px' }}>
                        <p style={{ fontSize: '12px', fontWeight: '600', width: '100%', padding: '5px', textAlign: 'center' }}>Thank you for your Business</p>
                    </div>      
                    <p style={{ textAlign: 'center', borderRadius: '15px', padding: '5px', backgroundColor: '#f3f4f6', fontSize: '10px' }}>Pet Tech, 39, Berry Hill Road, Syosett 11791&nbsp;&nbsp;|&nbsp;&nbsp;New York&nbsp;&nbsp;|&nbsp;&nbsp;(456) 789-0000&nbsp;&nbsp;|&nbsp;&nbsp;info@pettech.com</p>           
                </div> 
            </div>

            <div  id="printReceipt" ref={componentRefSimplified}>
                <div style={{ margin: '40px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '25px' }}>
                        <img style={{ width: '200px' }} src={`${process.env.REACT_APP_MEDIA_URL}invoice-mockup/images/pettech-logo.png`}></img>
                        <p style={{ fontSize: '40px', fontWeight: '600', color: '#002867' }}>RECEIPT</p>
                    </div>
                    
                    <div style={{ display: 'flex', justifyContent:'space-between', paddingBottom: '25px' }}>
                        <div>                    
                        <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Invoice No: </span>{(chargesList[0] !== undefined)? chargesList[0].numMainInvoiceID : "--"}</p>
                            <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Customer:</span> {(chargesList[0] !== undefined)? chargesList[0].varSalutationName : null}{(chargesList[0] !== undefined)? chargesList[0].varCustomerFirstName : null} {(chargesList[0] !== undefined)? chargesList[0].varCustomerLastName : null}</p>
                            <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Billing Address: </span>{ billingAddress}</p>
                        </div>
                        <div>
                            <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Date:</span> {shortDate(addedDate)}</p>
                            <p style={{ fontSize: '16px' }}><span style={{ fontWeight: '600' }}>Date Due:</span> {shortDate(dueDate)}</p>
                        </div>
                    </div>

                    <div>
                        <table className={styles.invTablePrint} style={{ width: '100%' }}>  
                            <tr>
                                <th style={{ whiteSpace: 'nowrap', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Order ID</th>
                                <th style={{ width: '100%', textAlign: 'left', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Description</th>
                                                                <th style={{ width: '100%', textAlign: 'left', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Unit Chrg</th>
                                <th style={{ width: '100%', textAlign: 'left', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Qty.</th>
                                <th style={{ textAlign: 'center', padding: '5px', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Charge</th>   
                                <th style={{ textAlign: 'center', padding: '5px', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Adjustments</th>
                                <th style={{ textAlign: 'center', padding: '5px', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Tax</th>  
                                <th style={{ textAlign: 'center', padding: '5px', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Payments</th>  
                                <th style={{ textAlign: 'center', padding: '5px', fontSize: '12px', backgroundColor: '#ddebf8', color: '#002867' }}>Balance</th>                       
                            </tr>                                       
                            {
                                chargesList.map((charge, index) => {
                                    return <>
                                        <tr>
                                            <td style={{ textAlign: 'center' }}>                                         
                                                <strong>{charge.numOrderID}</strong>
                                            </td>
                                            <td>                                                                        
                                                <p style={{ margin:'0' }}>
                                                    <span style={(charge.varServiceOrProduct === "S")? {color:"#5178bb", fontWeight:"600"} : {color:"#88bb51", fontWeight:"600"}}>({charge.varServiceOrProduct}) &nbsp;</span>
                                                    {charge.varServiceProductDescription} {(charge.numPetID === -1)? null : (charge.numPetID === null)? null : <span>Pet: (<span>{charge.varPetName}</span>)</span>}
                                                </p>                                                                                                                
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                ${charge.decChargeAmount}
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {charge.numQuantity}
                                            </td>

                                            <td style={{ textAlign: 'right' }}>
                                                ${charge.decChargeAmt}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                (${charge.totalAdjustments})
                                                
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                ${charge.decTaxAmt}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                (${charge.totalPayments})
                                            
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                ${parseFloat(charge.remaining).toFixed(2)}
                                            </td>
                                        </tr>                                                                                                                                                                                                                                                                   
                                    </>                                                 
                                })
                            }
                        </table>  
                    </div>

                    <div style={{ marginTop: '15px' }}>
                        <table style={{ width: '100%' }}>
                        <tr style={{ backgroundColor: '#ddebf8' }}>                                         
                                <th colSpan={5} style={{ width: '100%', fontSize: '14px', textAlign: 'left', color: '#002867' }}>Tax Amount</th>                                             
                                <th style={{ textAlign: 'right', fontSize: '14px', color: '#002867' }}>${parseFloat(totTax.toString()).toFixed(2)}</th>   
                            </tr>                                 
                            <tr style={{ backgroundColor: '#ddebf8' }}>                                         
                                <th colSpan={5} style={{ width: '100%', fontSize: '15px', textAlign: 'left', color: '#002867' }}>Total Amount</th>                                             
                                <th style={{ textAlign: 'right', fontSize: '15px', color: '#002867' }}>${parseFloat((totPaid + totRemain).toString()).toFixed(2)}</th>   
                            </tr>           
                            <tr style={{ backgroundColor: '#ddebf8' }}>                                         
                                <th colSpan={5} style={{ width: '100%', fontSize: '14px', textAlign: 'left', color: '#002867' }}>Paid Amount</th>                                             
                                <th style={{ textAlign: 'right', fontSize: '14px', color: '#002867' }}>${parseFloat(totPaid.toString()).toFixed(2)}</th>   
                            </tr>        
                            <tr style={{ backgroundColor: '#ddebf8' }}>                                         
                                <th colSpan={5} style={{ width: '100%', fontSize: '15px', textAlign: 'left', color: '#002867' }}>Balance Amount</th>                                             
                                <th style={{ textAlign: 'right', fontSize: '15px', color: '#002867' }}>${parseFloat(totRemain.toString()).toFixed(2)}</th>   
                            </tr>                                                                          
                        </table>  
                    </div>  

                    
                    <div style={{ backgroundColor: '#f3f4f6', marginTop: '30px', borderRadius: '15px' }}>
                        <p style={{ fontSize: '12px', fontWeight: '600', width: '100%', padding: '5px', textAlign: 'center' }}>Thank you for your Business</p>
                    </div>      
                    <p style={{ textAlign: 'center', borderRadius: '15px', padding: '5px', backgroundColor: '#f3f4f6', fontSize: '10px' }}>Pet Tech, 39, Berry Hill Road, Syosett 11791&nbsp;&nbsp;|&nbsp;&nbsp;New York&nbsp;&nbsp;|&nbsp;&nbsp;(456) 789-0000&nbsp;&nbsp;|&nbsp;&nbsp;info@pettech.com</p>           
                </div> 
            </div>  
             </div>
        </div>
    );
}

export default CustomerInvoices;