import CryptoJS from 'crypto-js';

// Secret Key for encryption/decryption
const SECRET_KEY = 'esypetasdfghjkl';

// Encrypt function
export const encrypt = (id) => {
  const ciphertext = CryptoJS.AES.encrypt(id, SECRET_KEY).toString();
  // Convert to Base64 and make it URL-safe by replacing characters
  const urlSafeCiphertext = ciphertext.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  return urlSafeCiphertext;
};

// Decrypt function
export const decrypt = (ciphertext) => {
  // Convert from URL-safe to standard Base64 encoding
  const base64Ciphertext = ciphertext.replace(/-/g, '+').replace(/_/g, '/');
  const bytes = CryptoJS.AES.decrypt(base64Ciphertext, SECRET_KEY);
  const originalId = bytes.toString(CryptoJS.enc.Utf8);
  return originalId;
};

