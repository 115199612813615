import styled from "@emotion/styled";
import { Chip as MaterialChip } from "@mui/material";

import "./Chip.sass";

export const Chip = (props: {
  label?: string;
  variant?: "filled" | "outlined";
  size?: "medium" | "small";
  shape?: "round" | "square";
  avatar?; //avatar
  color?; // "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
  icon?; // icon;
  onClick?: CallableFunction | any; // function
  onDelete?; // function
  deleteIcon?; // icon;
  className?; // custom CSS
  customStyle?:
    | "Success"
    | "GreenOutlined"
    | "LightGreenOutlined"
    | "GreenFilled"
    | "RedOutlined"
    | "Primary"
    | "BlueOutlined"
    | "BlueFilled"
    | "YellowOutlined"
    | "Error"; // custom themes
  customBorder?; // custom CSS Border
  customBorderRadius?; // custom CSS BorderRadius
  customBackgroundColor?; // custom CSS Color
  fontColor?; // custom CSS Background
  sx?; // MUI `sx` prop is a shortcut for defining custom style that has access to the theme.
  status?;
  fontWeight?;
  fontSize?;
}) => {
  return (
    <MaterialChip
      label={props.label}
      variant={props.variant === undefined ? `filled` : props.variant}
      size={props.size === undefined ? `medium` : props.size}
      avatar={props.avatar}
      color={props.color === undefined ? "default" : props.color}
      icon={props.icon}
      onDelete={props.onDelete}
      deleteIcon={props.deleteIcon}
      className={props.customStyle}
      onClick={props.onClick}
      sx={props.sx}
      style={{
        border: `${props.customBorder}`,
        borderRadius: `${
          props.shape === "square" ? `4px` : `${props.customBorderRadius}`
        }`,
        backgroundColor: `${props.customBackgroundColor}`,
        color: `${props.fontColor}`, fontWeight: `${props.fontWeight}`, fontSize: `${props.fontSize}`, whiteSpace:"normal"
      }}
    />
  );
};
