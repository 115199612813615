import { Route, Routes } from "react-router-dom"
import "./App.scss"
import { useJsApiLoader } from '@react-google-maps/api';
import * as signalR from '@microsoft/signalr';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DefaultLayout from "./Pages/Layout/DefaultLayout"
import Unauthorized from "./Pages/Layout/Unauthorized"
import PersistLogin from "./Hooks/PersistLogin"
import RequireAuth from "./Hooks/RequireAuth"
import TabLayout from "./Pages/Layout/TabLayout/TabLayout"
import TabDashboard from "./Pages/Dashboard/Dashboard"
import NotFound from "./Pages/NotFound/NotFound"
import Login from "./Pages/Login/Login"
import { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import Profile from "./Pages/Profile/Profile"
import Register from "./Pages/Register/Register"
import Pets from "./Pages/Pets/Pets"
import Bookings from "./Pages/Bookings/Bookings"
import Payments from "./Pages/Payments/Payments"
import Gallery from "./Pages/Bookings/Gallery/Gallery";
import ActivateAccount from "./Pages/ActivateAccount/ActivateAccount";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import { Box, Modal, Typography } from "@mui/material";
import { CloseButton } from "./Components";
import { ImgComparisonSlider } from "@img-comparison-slider/react";
import ResetSecurity from "./Pages/ResetSecurity/ResetSecurity";


function App() {
    const navigate = useNavigate()
    const location = useLocation() as any

    const [loggedInUser, setLoggedInUser] = useState<any>(null)
    const [resMade, setResMade] = useState<any>(false)
    const [triggerFetch, setTriggerFetch] = useState(false);
    const [receivedData, setReceivedData] = useState<any>(null)

    const handleLoginSuccess = (userData) => {
        console.log(userData)
        setLoggedInUser(userData)
        const from = location.state?.from?.pathname || `${""}`
        navigate(from, { replace: true })
    }

    const [selectedNotification, setSelectedNotification] = useState<any>(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
      if (loggedInUser !== null) {
          console.log("Ready to connect to SignalR");

          if (loggedInUser?.customer?.numCustomerID !== undefined) {
              console.log("In-Progress SignalR", loggedInUser?.customer?.numCustomerID);

              const connection = new signalR.HubConnectionBuilder()
                  .withUrl(`https://q5g5jwpb-7138.asse.devtunnels.ms/notifications?customerId=${loggedInUser?.customer?.numCustomerID}`)
                  .withAutomaticReconnect()
                  .build();

              connection.start()
                  .then(() => console.log('Connected to SignalR hub'))
                  .catch((err) => console.error('Error connecting to SignalR hub:', err));

              connection.on("ReceiveNotification", (beforeImage, afterImage) => {
                  console.log('Notification received:', beforeImage, afterImage);
                  
                  setTriggerFetch(true);
                  
                  toast.info('You got a new image suggestion!', {
                      onClick: () => handleToastClick({ beforeImage, afterImage })
                  });
              });

              return () => {
                  connection.stop();
              };
          }
      }
  }, [loggedInUser]);

  useEffect(() => {
    if (loggedInUser !== null) {
        console.log("Ready to connect to SignalR2");

        if (loggedInUser?.customer?.numCustomerID !== undefined) {
            console.log("In-Progress SignalR2", loggedInUser?.customer?.numCustomerID);

            const connection = new signalR.HubConnectionBuilder()
                .withUrl(`https://q5g5jwpb-7138.asse.devtunnels.ms/commonNotifications?recipientId=${loggedInUser?.customer?.numCustomerID}`)
                .withAutomaticReconnect()
                .build();

            connection.start()
                .then(() => console.log('Connected to SignalR hub2'))
                .catch((err) => console.error('Error connecting to SignalR hub2:', err));

            connection.on("ReceiveCommonNotification", (notificationId, notificationMessage, subject) => {
                console.log('Notification received:', notificationId, notificationMessage, subject);
                setTriggerFetch(true);
                
                toast.success(subject, {
                  onClick: () =>  setReceivedData(notificationId)
              });
            });

            return () => {
                connection.stop();
            };
        }
    }
}, [loggedInUser]);


  useEffect(() => {
    if (triggerFetch === true) {
        setTriggerFetch(false);
    }
}, [triggerFetch]);

    const handleToastClick = (notification) => {
        setSelectedNotification(notification);
        setModalOpen(true); 
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const GOOGLE_MAP_KEY: any = process.env.REACT_APP_GMAP_API_KEY
const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries: ['places']
});

    return (
        <>
        <Routes>
            <Route path="/" element={<DefaultLayout />}>
            <Route path="activate-account/:userId" element={<ActivateAccount />} />
            <Route path="reset-password/:userId" element={<ResetPassword />} />
            <Route path="reset-security-questions-password/:userId" element={<ResetSecurity />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="login" element={<Login onLoginSuccess={handleLoginSuccess} />} />
                <Route path="register" element={<Register />} />
                <Route path="unauthorized" element={<Unauthorized />} />
                <Route element={<PersistLogin />}>
                    <Route element={<RequireAuth allowedRoles={[123]} />}>
                        <Route path="tablet" element={<TabLayout receivedData={receivedData} onFetchNotifications={triggerFetch} loginData={loggedInUser} />}>
                            <Route path="/tablet" element={<TabDashboard loginData={loggedInUser} />} />
                        </Route>
                        <Route path="/" element={<TabLayout receivedData={receivedData}  onFetchNotifications={triggerFetch} loginData={loggedInUser} />}>
                            <Route path="/" element={<TabDashboard loginData={loggedInUser} />} />
                        </Route>
                        <Route path="/" element={<TabLayout receivedData={receivedData} onFetchNotifications={triggerFetch} loginData={loggedInUser} />}>
                            <Route path="/profile" element={<Profile loginData={loggedInUser} />} />
                        </Route>
                        <Route path="/" element={<TabLayout receivedData={receivedData}  onFetchNotifications={triggerFetch} loginData={loggedInUser} />}>
                            <Route path="/pets" element={<Pets loginData={loggedInUser} />} />
                        </Route>
                        <Route path="/" element={<TabLayout receivedData={receivedData}  onFetchNotifications={triggerFetch} loginData={loggedInUser} isReservationMade={(e)=>{
                            if(e === true){
                                setResMade(true)
                            }
                        }} />}>
                            <Route path="/bookings" element={<Bookings loginData={loggedInUser} isRefresh={resMade} setRefreshSuccess={(e)=>{if(e === true){setResMade(false)}}}/>} />
                        </Route>
                        <Route path="/" element={<TabLayout receivedData={receivedData}  onFetchNotifications={triggerFetch} loginData={loggedInUser} />}>
                            <Route path="/payments" element={<Payments loginData={loggedInUser} />} />
                        </Route>
                        <Route path="/" element={<TabLayout receivedData={receivedData}  onFetchNotifications={triggerFetch} loginData={loggedInUser} />}>
                            <Route path="/gallery" element={<Gallery loginData={loggedInUser} />} />
                        </Route>
                    </Route>
                    <Route path="/">
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
        
        <Modal
  open={modalOpen}
  onClose={handleClose}
  aria-labelledby="modal-title"
  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
>
  <Box
    sx={{
      backgroundColor: 'white',
      padding: { xs: 2, sm: 3 },
      width: { xs: '90%', sm: '600px' },
      borderRadius: 2,
      boxShadow: 24,
      position: 'relative',
    }}
  >
    {/* Header section */}
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          id="modal-title"
          variant="h6"
          component="h2"
          sx={{ fontWeight: '600', fontSize: { xs: '16px', sm: '18px' } }}
        >
          Employee Suggestion Cut
        </Typography>
      </Box>
      <CloseButton onClick={handleClose} />
    </Box>

    {/* Description section */}
    <Typography
      variant="body2"
      sx={{ color: 'gray', marginTop: 1 }}
    >
      Here’s a preview of the groom cut we recommend for your pet. Let us know if you like it!
    </Typography>

    {/* Image comparison section */}
    <Box sx={{ marginTop: 2 }}>
      {selectedNotification && (
        <ImgComparisonSlider
          style={{
            width: '100%',
            height: 'auto',
            maxHeight: '500px',
          }}
        >
          <img
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            alt="404"
            slot="first"
            src={selectedNotification?.beforeImage}
          />
          <img
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            alt="404"
            slot="second"
            src={selectedNotification?.afterImage}
          />
        </ImgComparisonSlider>
      )}
    </Box>
  </Box>
</Modal>


        </>
  
    )
}

export default App
