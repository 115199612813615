import { MenuItem, TextField } from '@mui/material';
import styles from './Input.module.scss';
import {getStr} from "../../Utils/Validation";

export interface AppInputItemValue {
    value: string;
    text: any;
}

export const Input = (props: {
    label?;
    required?;
    className?;
    inputClassName?;
    items?: AppInputItemValue[];
    onChange?: CallableFunction;
    disabled?: boolean;
    value?;
    variant?;
    size?;
    itemSize?;
    outline?: boolean;
    type?: 'color' | 'date' | 'email' | 'file' | 'month' | 'text' | 'password' | 'number';
    inputProps?;
    ref?;
    backgroundColor?;
}) => {
    return (
        <div className={`${styles.appInput} ${props.className}`} style={{width:"100%"}}>
            <TextField
                InputProps={ {
                    classes: {
                        underline: styles.underline,
                        notchedOutline: (props.outline) ? styles.outline : styles.outlineNone
                    },
                    style: { fontSize: (props.itemSize == undefined) ? `medium` : `${props.itemSize}px`, backgroundColor: (props.backgroundColor != undefined)? "#F9FAFB" : undefined},              
                }}
                inputProps={props.inputProps}
                value={props.onChange ? getStr(props.value) : props.value}
                type={props.type}
                label={ (props.label != undefined) ? (
                    <div className={styles.textWrap}>
                        <div className={styles.label}>{props.label}</div>
                    </div>) : null
                }
                select={!!props.items}
                fullWidth
                size={props.size}
                disabled={props.disabled}                
                variant={(props.variant != undefined) ? props.variant : "standard"}
                className={props.inputClassName}
                onChange={(e) => {
                    props.onChange?.(e.target.value);
                }}
                ref={props.ref}
            >
                {props.items?.map((item, i) => {
                    return (
                        <MenuItem key={i} value={item.value} style={{fontSize: (props.itemSize == undefined) ? `medium` : `${props.itemSize}px`}} >
                            {item.text}
                        </MenuItem>
                    );
                })}
            </TextField>
        </div>
    );
}