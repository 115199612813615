import axios from '../Apis/axios'
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth() as any;

    const refresh = async () => {
        const response = await axios.post('/Auth', JSON.stringify({ username: 'admn', password: 'admin@pettech' }), {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        })

        setAuth(prev => {
            console.log(JSON.stringify(prev))
            console.log(response?.data?.token)

            return { ...prev, roles: [123, 456, 789], accessToken: response?.data?.token, loggedIn: true}
        })
        return response?.data?.token
    }
    return refresh;
}

export default useRefreshToken;