import React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

const FloatingButton = ({ onClick, onLoading }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      style={{
        position: 'fixed',
        bottom: '100px',
        right: '20px',
        borderRadius: '50%',
        width: '80px', 
        height: '80px', 
        padding: '0', 
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      }}
      onClick={onClick}
      disabled={onLoading}
    >
      <AddIcon style={{ margin: '0', fontSize:'30px' }} />
    </Button>
  );
};

export default FloatingButton;