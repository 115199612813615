import { useNavigate, useParams } from 'react-router-dom';
import { decrypt } from '../../Utils/encrypt';
import { useEffect, useState } from 'react';
import { axiosPrivate } from '../../Apis/axios';
import { toast, ToastContainer } from 'react-toastify';
import { Checkbox } from '@mui/material';
import { Button } from '../../Components';
import { Card, CardContent, CardMedia, Checkbox as MUICheckbox, Button as MUIButton, TextField, Typography, Box } from '@mui/material';
import Swal from 'sweetalert2';

function ActivateAccount(props) {

    const { userId } = useParams()
    const [questions, setQuestions] = useState([]) as any
    const [answers, setAnswers] = useState({}) as any
    const [selectedQuestions, setSelectedQuestions] = useState([]) as any
    const [userIDDec, setUserIDDec] = useState("") as any
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false) as any;

    async function getAllQuestions() {
      setIsLoading(true)
        const result = await axiosPrivate.get(`UserQuestion/GetAll`)
        return result
    }

    async function validateUserID(id) {
      const result = await axiosPrivate.get(`Auth/CheckUserActivation/${id}`)
      return result
  }
   
    useEffect(() => {
        if(userId !== null && userId !== ""){
             const decrypted = decrypt(userId);
             setUserIDDec(decrypted)
             validateUserID(decrypted)
             .then((response) => {
                 console.log(response)
                 if(response.status === 200){
                    if(response?.data?.success === false){
                      showRegistrationErrorAlert(response?.data?.message)
                    }else{
                      getAllQuestions()
                      .then((response) => {
                          setQuestions(response?.data?.userQuestion)
                          setIsLoading(false)
                      })
                      .catch((err) => {
                          console.log(err)
                          toast.error("Something went wrong!")
                          setIsLoading(false)
                      }) 
                     }
                 }
             })
             .catch((err) => {
                 console.log(err)
                 showRegistrationErrorAlert("Unauthorized Access")
             }) 
        }else{
            console.log("not ok")
        }
    }, [])

    const showRegistrationErrorAlert = (msg) => {
      Swal.fire({
        title: 'Error!',
        html: `
          <div style="font-size: 16px; margin-top: 10px;">
            ${msg}
          </div>
        `,
        iconHtml: `<div style="display: flex; justify-content: center;">
                     <img src="https://cdn-icons-png.flaticon.com/512/753/753345.png" alt="success-icon" style="width: 80px; height: 80px;" />
                   </div>`,
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText: 'OK',
        customClass: {
          icon: 'no-border',
        }
      }).then((result) => {
        if (result.isConfirmed || result.dismiss) {
          console.log("SweetAlert was closed.");
          navigate("/login");
        }
      });
    };


    const handleCheckBox = (questionID, value) => {
        const getValue = value.target.checked;
        const tempSelectedQuestions = [...selectedQuestions];
    
        if (getValue && selectedQuestions.length < 3) {
          tempSelectedQuestions.push(questionID);
        } else {
          if (tempSelectedQuestions.includes(questionID)) {
            const index = tempSelectedQuestions.indexOf(questionID);
            if (index !== -1) {
              tempSelectedQuestions.splice(index, 1);
              const tempAnswers = { ...answers };
              delete tempAnswers[questionID]; 
              setAnswers(tempAnswers);
            }
          }
        }
    
        setSelectedQuestions(tempSelectedQuestions);
      };
    
      // Handle answer change
      const handleAnswerChange = (questionID, event) => {
        const tempAnswers = { ...answers };
        tempAnswers[questionID] = event.target.value;
        setAnswers(tempAnswers);
      };
      
      const handleSubmit = () => {
        if(userIDDec === ""){
            toast.error("Invalid user ID")
        }else{
            let payload = [] as any

            Object.keys(answers)?.map((ans) => {
                payload.push({
                    id: 0,
                    numUserID: userIDDec,
                    numQuestionID: ans,
                    varAnswer: answers[ans],
                })
            })
            console.log(payload)
            if(payload.length < 3){
                toast.warning("Select 3 Questions and Answer")
                setIsLoading(false)
            }else{
              setIsLoading(true)
              postAnswers(payload)
            }
        }

      }

      async function postAnswers(payloadData) {
        await axiosPrivate
            .post(`UserQuestion/PopulateSecurityAnsweres`, JSON.stringify(payloadData), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log(result?.data)
                    updateUserStatus(payloadData)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setIsLoading(false)
            })
    }

    async function updateUserStatus(payloadData) {
    await axiosPrivate
        .put(`Auth/ActivateUser?numUserID=${payloadData[0]?.numUserID}`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
        .then((result) => {
            if (result.status === 200) {
                console.log(result?.data)
                showRegistrationSuccessAlert()
            }
        })
        .catch((err) => {
            console.log(err)
            toast.error("Something went wrong!")
            setIsLoading(false)
        })
    }

    const showRegistrationSuccessAlert = () => {
      Swal.fire({
        title: 'Account activation successful!',
        html: `
          <div style="font-size: 16px; margin-top: 10px;">
            Your account is all set! You can now log in and start using our pet grooming app to take care of your furry friends. Welcome aboard!
          </div>
        `,
        iconHtml: `<div style="display: flex; justify-content: center;">
                     <img src="https://cdn-icons-png.flaticon.com/512/190/190411.png" alt="success-icon" style="width: 80px; height: 80px;" />
                   </div>`,
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText: 'OK',
        customClass: {
          icon: 'no-border',
        }
      }).then((result) => {
        if (result.isConfirmed || result.dismiss) {
          console.log("SweetAlert was closed.");
          setIsLoading(false)
          navigate("/login");
        }
      });
    };


    return (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '100vh' }}
      >
          <ToastContainer />
        <Card style={{ maxWidth: '500px', width: '100%', padding: '20px' }}>
          
          {/* Card Media for the Logo */}
          <CardMedia
            component="img"
            image="https://test.esypet.net/static/media/esypet-logo.5f7c03070095ad942f20.png"
            alt="EsyPet Logo"
            style={{ height: '100px', objectFit: 'contain' }}
          />

          {/* Card Content */}
          
          <CardContent>

          {
          (isLoading)?
          <p>Loading...</p> :
          <>

            {
                (questions.length > 0)?
              <Typography variant="h6" align="center" gutterBottom>
                Select 3 Questions and Answer
              </Typography> : null
            }
  
            {/* Questions List */}
            <Box component="ul" sx={{ padding: 0, listStyleType: 'none' }}>
              {questions.map((q) => (
                <Box key={q?.numUserQuestionID} sx={{ marginBottom: '10px' }}>
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      checked={selectedQuestions.includes(q?.numUserQuestionID)}
                      onChange={(e) => handleCheckBox(q?.numUserQuestionID, e)}
                    />
                    <Typography>{q?.varQuestion}</Typography>
                  </Box>
                  {selectedQuestions.includes(q?.numUserQuestionID) ? (
                    <TextField
                      value={answers[q?.numUserQuestionID] || ''} 
                      onChange={(e) => handleAnswerChange(q?.numUserQuestionID, e)}
                      placeholder="Enter Answer"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                    />
                  ) : null}
                </Box>
              ))}
            </Box>
          </>
          }

            {
              (questions.length > 0)?
              <>
            {/* Verify Button */}
              <Box mt={3} display="flex" justifyContent="center">
                <Button variant="contained" color="primary" onClick={() => {handleSubmit()}} disabled={isLoading}>
                Verify My Account
              </Button>
            </Box>
              </> :null
            }
  

          </CardContent>
        </Card>
      </Box>
    
    );
}

export default ActivateAccount;