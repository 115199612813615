import axios from "../Apis/axios";
import useAuth from "./useAuth";

const useLogout = () => {
    const { setAuth } = useAuth() as any

    const logout = async () => {
        setAuth({ username: undefined, password: undefined, roles: undefined, accessToken: undefined, loggedIn: true })
        // try{
        //     const response = await axios('/logout', {
        //         withCredentials: true
        //     })
        // }
        // catch (err){
        //     console.error(err)
        // }
    }

    return logout;
}

export default useLogout;