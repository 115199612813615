import { FactCheckOutlined, HourglassBottom, InsertInvitationOutlined, PaidOutlined, ReceiptOutlined } from '@mui/icons-material';
import { useState } from 'react';
import axios from "../../Apis/axios";
import { useEffect } from "react";
import { formatCash } from '../../Utils/Validation';
import InvTable from './InvTable';
import styles from './Invoice.module.scss';


function CustomerInvoices(props:{
    data?;
    type;
    index?;
    mode?: boolean;
}) {

  const [invCount, setInvCount] = useState<any>(0)
  const [invSettled, setInvSettled] = useState<any>(0)
  const [invPending, setInvPending] = useState<any>(0)
  const [totPaid, setTotPaid] = useState<any>(0)
  const [totRemain, setTotRemain] = useState<any>(0)


  const [filterText, setFilterText] = useState('');
  const [data, setData] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleFilterTextChange = (text) => {
    setFilterText(text);
    setPage(0); // Reset the page when the filter changes
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset the page when the number of rows per page changes
  };



    const getCustomerInvoiceList = async (cusID) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetInvoicesListByCustomerID`;
      
        try {
          const response = await axios.get(apiUrl, {
            params: {
              numCustomerID: cusID,
            },
          });
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
    };
    useEffect(()=>{
      fetchCustomerInvoices()
    },[props.data.numCustomerID])


    const fetchCustomerInvoices = () => {
        if(props.data !== undefined){
          if(props.data.numCustomerID !== null && props.data.numCustomerID !== undefined && props.data.numCustomerID !== ""){
              getCustomerInvoiceList(props.data.numCustomerID)
              .then((data) => {
                  console.log("res - Customer all Invoices", data);
                  if(data !== undefined && data.length > 0){
                      setInvCount(data.length)
                      setInvPending(data[0].CountPaymentNotSettled)
                      setInvSettled(data[0].CountPaymentSettled)
                      setTotPaid(data[0].TotalCustomerPayments)
                      setTotRemain(data[0].TotalCustomerRemainingBalance)
                      setData(data)
                  }
              })
              .catch((error) => {
                  console.log("error", error);
              });
          }
      }
    }

    const handleSuccess = (data) => {
      if(data === true){
        fetchCustomerInvoices()
      }
    };

    const cardStyle:any = {
        border: '1px solid #e5e5e5',
        borderRadius: '8px',
        padding: '10px',
        boxShadow: '2px 2px 16px rgba(0, 40, 103, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
      };
      
      const textContainerStyle:any = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      };
      
    return (
        <div>
            {/* <div
              style={{
                display: 'flex',
                gap: '20px',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >

          
            </div> */}

<div className={styles.widget_container}>
<div style={cardStyle}>
                <ReceiptOutlined style={{ marginBottom: '10px', color:"#1976d2" }} />
                <div style={textContainerStyle}>
                  <p style={{ fontWeight: '600', color: 'black', fontSize: '20px', margin: '0' }}>{invCount}</p>
                  <p style={{ fontWeight: '600', color: 'black', margin: '0' }}>Total</p>
                </div>
              </div>
              <div style={cardStyle}>
                <FactCheckOutlined style={{ marginBottom: '10px', color:"#1976d2" }} />
                <div style={textContainerStyle}>
                  <p style={{ fontWeight: '600', color: 'black', fontSize: '20px', margin: '0' }}>{invSettled}</p>
                  <p style={{ fontWeight: '600', color: 'black', margin: '0' }}>Completed</p>
                </div>
              </div>
              <div style={cardStyle}>
                <InsertInvitationOutlined style={{ marginBottom: '10px', color:"orange" }} />
                <div style={textContainerStyle}>
                  <p style={{ fontWeight: '600', color: 'orange', fontSize: '20px', margin: '0' }}>{invPending}</p>
                  <p style={{ fontWeight: '600', color: 'black', margin: '0' }}>Invoices</p>
                </div>
              </div>
              <div style={cardStyle}>
                <PaidOutlined style={{ marginBottom: '10px', color:"#20bd5a" }} />
                <div style={textContainerStyle}>
                  <p style={{ fontWeight: '600', color: '#20bd5a', fontSize: '20px', margin: '0' }}>${formatCash(totPaid)}</p>
                  <p style={{ fontWeight: '600', color: 'black', margin: '0' }}>Paid Amt.</p>
                </div>
              </div>
              <div style={cardStyle}>
                <HourglassBottom style={{ marginBottom: '10px', color:"#ff5252" }} />
                <div style={textContainerStyle}>
                  <p style={{ fontWeight: '600', color: '#ff5252', fontSize: '20px', margin: '0' }}>${formatCash(totRemain)}</p>
                  <p style={{ fontWeight: '600', color: 'black', margin: '0' }}>Remain Bal.</p>
                </div>
              </div>
</div>

            <div style={{marginTop:"15px"}}>
              {
                (data.length > 0)?
                  <InvTable
                  data={data}
                  filterText={filterText}
                  onFilterTextChange={handleFilterTextChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sendDataToParent={handleSuccess}
                  customerID={props.data.numCustomerID}
                  isSuccess={(e)=>{
                    if(e === true){
                      console.log("hitttt")
                       fetchCustomerInvoices()
                    }
                  }}
                />
                :
                <p style={{color:"gray"}}>No invoices available for the selected customer</p>
              }

            </div>

        </div>
    );
}

export default CustomerInvoices;