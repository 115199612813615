import React, { useEffect, useState } from 'react';
import { axiosPrivate } from '../../Apis/axios';
import { Avatar } from '../../Components';
import { shortDate } from '../../Utils/timeHandler';

function Ownership(props:{
    data?
    index?
    isLoading?
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [preOwners, setPreOwners] = useState([]) as any;
    async function getOwnerList() {
        if(props.data.customerPet[props.index].numPetID != null){
            setIsLoading(true)
            const result = await axiosPrivate.get(`Pet/GetPreviousOwnersByID/${props.data.customerPet[props.index].numPetID}`) 
            return result;
        }
    }

    useEffect(()=>{
        getOwnerList()
        .then(response => {
            setPreOwners(response?.data?.petPreviousOwner)
            console.log(response?.data?.petPreviousOwner)
        })
        .catch(err => {            
            console.log(err)
            console.log("Something went wrong!", err);  
        })
    },[])

    return (
        <div>
            <p style={{ fontWeight: "600", fontSize: "16px", color:"#192252" }}>Current Owner</p>
            <div style={{background:"#FAFAFA", borderRadius:"8px", padding:"10px"}}>
                <div style={{display:"flex", gap:"10px", alignItems:"center"}}>
                    <Avatar
                        img={`${process.env.REACT_APP_MEDIA_URL}directories/customers/` + props.data?.varCustomerImagePath}
                        isVip={false}
                        className={"medium"}
                        labelType={"largeLabel"}
                    />
                    <div>
                        <p style={{margin:"0"}}>{props.data?.varCustomerFirstName +" "+props.data?.varCustomerLastName}</p>
                        <p style={{fontSize:"14px", color:"gray"}}>{props.data?.customerAddress[0]?.varCustomerCity +" "+props.data?.customerAddress[0]?.varCustomerState}</p>
                    </div>
                </div>
                <div style={{display:"flex", justifyContent:"space-between", marginTop:"10px", borderTop:"1px solid #ebebeb", paddingTop:"10px"}}>
                    <p style={{margin:"0", fontWeight:"500"}}>{props.data?.varCustomerMobilePhone}</p>
                    <p style={{margin:"0", fontWeight:"500"}}>{props.data?.varCustomerHomePhone}</p>
                </div>
            </div>

            <p style={{ fontWeight: "600", fontSize: "16px", color:"#192252" }}>Previous Owners</p>
            {
                (preOwners.length > 0)?
                preOwners.map((item, index)=>{
                    return(
                        <div style={{background:"#FAFAFA", borderRadius:"8px", padding:"10px"}}>
                        <div style={{display:"flex", gap:"10px", alignItems:"center"}}>
                            <Avatar
                                img={`${process.env.REACT_APP_MEDIA_URL}directories/customers/` + "ss"}
                                isVip={false}
                                className={"small"}
                                labelType={"largeLabel"}
                            />
                            <div>
                                <p style={{margin:"0"}}>{item?.varCustomerFirstName +" "+item?.varCustomerLastName}</p>
                            </div>
                        </div>
                        <div style={{display:"flex",  marginTop:"10px", flexDirection:"column"}}>
                            <p style={{margin:"0", fontWeight:"600"}}>Transferred Date</p>
                            <p style={{margin:"0", marginTop:"8px", fontWeight:"500", fontSize:"14px", color:"gray"}}>{shortDate(item?.dtTransferredDate)}</p>
                        </div>
                    </div> 
                    )
                })
                :
                <p style={{ color: "#777E90", fontWeight: "400", fontSize: "13px" }}>No previous owners found</p>
            }

         

        </div>
    );
}

export default Ownership;