import { Button, Modal, Tooltip, Button as MUIButton, Box, IconButton, Tab, Tabs, TextField, Checkbox, Switch } from "@mui/material"
import styles from "./MakeReservation.module.scss"
import { useEffect, useRef, useState } from "react"

// Import Swiper React components
import { Bounce, ToastContainer, toast } from "react-toastify"

// Import Swiper styles
import axios, { axiosPrivate } from "../../Apis/axios"

import { ImgComparisonSlider } from '@img-comparison-slider/react';

// import required modules
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectCoverflow, Pagination } from "swiper/modules"
import SwiperCore from "swiper"
import "swiper/swiper-bundle.css"
import "swiper/css/effect-coverflow"
import "swiper/css/pagination"

import BookingLocation from "../BookingLocation"
import moment from "moment"
import { AccessTimeOutlined, AddCircleOutline, ArrowDownward, ArrowUpward, Delete, DirectionsCarOutlined, InfoOutlined, LocalOffer, RemoveCircleOutline, Search, ShoppingCart, Star, Verified } from "@mui/icons-material"
import { CloseButton } from "../CloseButton/CloseButton"
import { Truncate } from "../../Utils/Validation"
import { Avatar, Chip, Input } from "../Components"
import { formatToLocal, getDayName } from "../../Utils/timeHandler"
import { sendAppointmentStaticMessage, sendOutNotification } from "../../Utils/Messaging/Messaging"

SwiperCore.use([EffectCoverflow, Pagination])


const MakeReservation = (props: { isOpen; closeModal: CallableFunction; customerData, isSuccess? }) => {

    const [currentStep, setCurrentStep] = useState(1)
    const [currDate, setCurrDate] = useState<any>(moment().format("MM/DD/YYYY"))
    const [additionalServiceList, setAdditionalServiceList] = useState([]) as any
    const [servicePet, setServicePet] = useState({}) as any
    const [isLoading, setIsLoading] = useState(false)
    const [productList, setProductList] = useState<any>([])
    const [swiperSelectedPet, setSwiperSelectedPet] = useState(0) as any
    const [productTabIndex, setProductTabIndex] = useState(0)
    const [cartItemCount, setCartItemCount] = useState<any>(0)
    const [productCartAmount, setProductCartAmount] = useState<any>(0)
    const [pastAppointmentList, setPastAppointmentList] = useState([]) as any
    const [selectedPastAppointment, setSelectedPastAppointment] = useState("") as any
    const [isOpenSummary, setIsOpenSummary] = useState(false)
    const [requestedDate, setRequestedDate] = useState(moment().format('YYYY-MM-DD'));
    const [requestedTimeOfDay, setRequestedTimeOfDay] = useState(9)
    const [timeOfDayList, setTimeOfDayList] = useState<any>([])
    const [slotMsg, setSlotMsg] = useState<any>("")
    const myRefTimeOFDay = useRef<any>()
    const [uniqueParam] = useState(new Date().getTime())
    const [store, setStores] = useState<any>([])

    const [lng, setLng] = useState<any>(40.741895)
    const [lat, setLat] = useState<any>(-73.989308)

    const getLocationServiceItemsPriceList = async (locationID, dateOfService, petBreedID, petSizeID, employeeID, employeeName, petID, petTypeID) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetLocationServiceItemsPriceList`

        try {
            const response = await axios.get(apiUrl, {
                params: {
                    numLocationID: locationID,
                    dtDate_of_service: dateOfService,
                    numPetBreedID: petBreedID,
                    numPetSizeID: petSizeID,
                    numEmployeeID: employeeID,
                    varEmployeeName: employeeName,
                    numPetID: petID,
                    numPetTypeID: petTypeID,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }
    const getLocationProductItemsPriceList = async (locationID, dateOfService, employeeID, employeeName, petID) => {
        console.log("all product fetch - sent", locationID, dateOfService, employeeID, employeeName, petID)
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetLocationProductItemsPriceList`

        try {
            const response = await axios.get(apiUrl, {
                params: {
                    numLocationID: locationID,
                    dtDate_of_service: dateOfService,
                    numEmployeeID: employeeID,
                    varEmployeeName: employeeName,
                    numPetID: petID,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    const getAppointmentsbyPetID = async (PetID, PageSize, PageNumber) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}Appointment/GetAppointmentsbyPetID`

        try {
            const response = await axios.get(apiUrl, {
                params: {
                    PetID: PetID,
                    PageSize: PageSize,
                    PageNumber: PageNumber,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    async function getAllScheduleTimeOfDay() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`TimeOfDay/GetAll`, {})
        return result.data.timeOfDay
    }

    async function getLocations() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Location/GetAll`, {})
        return result.data.location
    }

    const getPrimaryBreedByPetBreedID = async (breedID) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}PetBreed/GetPrimaryBreedIDByPetBreedID/${breedID}`

        try {
            const response = await axios.get(apiUrl, {
              
            })
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    useEffect(() => {
        if (props.isOpen === true) {
            setSwiperSelectedPet(0)
            setCurrentStep(1)
            setSlotMsg("")
            setSelectedPastAppointment("")
            setRequestedDate(moment().format('YYYY-MM-DD'))
            setServicePet({
                id: props.customerData.customer?.customerPet[0].numPetID,
                name: props.customerData.customer?.customerPet[0].varPetName,
                breed: props.customerData.customer?.customerPet[0].varPrimaryBreed,
                size: props.customerData.customer?.customerPet[0].varPetSize,
                img: props.customerData.customer?.customerPet[0].varPetImagePath,
                preferredGroomer: props.customerData.customer?.customerPet[0].numPetPreferredGroomerID,
                additionalService: [],
                selectedProducts: [],
                slotInfo: {},
                petType: props.customerData.customer?.customerPet[0].numPetTypeID,
                customerID: props.customerData.customer?.customerPet[0].numCustomerID,
                weight: props.customerData.customer?.customerPet[0].numPetWeight,
                breedID: props.customerData.customer?.customerPet[0].numPetBreedID,
                sizeID: props.customerData.customer?.customerPet[0].numPetSizeID,
                preferredLocation: props.customerData.customer?.customerPet[0].numPetDefaultGroomLocationID,
                employeeID: 1,
                employeeName: "Ravin",
                numPetDefaultGroomLocationID: props.customerData.customer?.customerPet[0].numPetDefaultGroomLocationID,
            })

            console.log('pastD',props.customerData.customer?.customerPet[0].numPetID, 100, 1)
            getAppointmentsbyPetID(props.customerData.customer?.customerPet[0].numPetID, 100, 1)
                .then((data) => {
                    if (data.PastAppointmentData !== undefined) {
                        console.log("res - pet-PastAppointmentData", data)
                        setPastAppointmentList(data.PastAppointmentData)
                        setIsLoading(false)
                    } else {
                        setPastAppointmentList([])
                        setIsLoading(false)
                    }
                })
                .catch((error) => {
                    console.log("error", error)
                })

            getAllScheduleTimeOfDay()
                .then((response) => {
                    setTimeOfDayList(response)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrongT!")
                })

                getLocations()
                .then((res) => {
                    const allLocations = [] as any
                    res.map((location) => {

                            allLocations.push({
                                longitude: parseFloat(location.varLocationLongitude),
                                latitude: parseFloat(location.varLocationLatitude),
                                title: location.varLocationName,
                                distance: "--",
                                duration: "--",
                                value: 0,
                                img: location.varLocationImagePath,
                                id: location.numLocationID,
                            })
                    })
                    setStores(allLocations)
                })
                .catch((err) => {
                    console.log(err)
                })  
        }
    }, [props.isOpen])


    const calcTax = (storeDiscount, seasonalDiscount, mainPrice, percentage) => {
        let finalTaxAmount = 0

        if (percentage !== null) {
            let adjustments = parseFloat(seasonalDiscount) + parseFloat(storeDiscount)
            let afterAdjustment = parseFloat(mainPrice) - adjustments
            finalTaxAmount = afterAdjustment * percentage
        }

        return finalTaxAmount.toFixed(2)
    }

    const selectAdditionalService = (index) => {
        const petList = { ...servicePet }
        let matching = petList.additionalService.some((x) => x.id === additionalServiceList[index].numServiceItemID)
        if (!matching) {
            let additional = {
                id: additionalServiceList[index].numServiceItemID,
                name: additionalServiceList[index].varServiceItemName,
                price: additionalServiceList[index].numServiceItemSalesPrice,
                desc: additionalServiceList[index].varServiceItemDescription,
                orderID: additionalServiceList[index].numOrderID,
                taxAmount: calcTax(
                    additionalServiceList[index].numStorePromotionDiscount,
                    additionalServiceList[index].numSeasonalPromotionDiscount,
                    additionalServiceList[index].numServiceItemSalesPrice,
                    additionalServiceList[index].numSalesTaxPercentage
                ),
                numLocationServiceProductItemID: additionalServiceList[index].numLocationServiceItemID,
                numSalesTaxPercentage: additionalServiceList[index].numSalesTaxPercentage,
                numSeasonalPromotionDiscount: additionalServiceList[index].numSeasonalPromotionDiscount,
                numStorePromotionDiscount: additionalServiceList[index].numStorePromotionDiscount,
            }
            petList.additionalService.push(additional)
            setServicePet(petList)
        } else {
                 let delIndex = petList.additionalService.findIndex((x) => x.id === additionalServiceList[index].numServiceItemID)
                petList.additionalService.splice(delIndex, 1)
                setServicePet(petList)
        }
    }

    const handleProductTabChange = (event, newTabIndex) => {
        setProductTabIndex(newTabIndex)
    }

    const decrementQty = (id, index) => {
        const productArr = [...productList]
        let selected = productArr[index].SelectedQuantity

        if (selected <= 0) {
            productArr[index].SelectedQuantity = 0
        } else {
            productArr[index].SelectedQuantity = productArr[index].SelectedQuantity - 1
            productArr[index].TempAvailableQuantity = productArr[index].TempAvailableQuantity + 1
        }
        setProductList(productArr)
    }

    const incrementQty = (id, index) => {
        const productArr = [...productList]
        let selected = productArr[index].SelectedQuantity
        let totalAvailable = productArr[index].numAvailableQuantity
        if (selected >= totalAvailable) {
            toast.info("Maximum quantity!")
        } else {
            productArr[index].SelectedQuantity = productArr[index].SelectedQuantity + 1
            productArr[index].TempAvailableQuantity = productArr[index].TempAvailableQuantity - 1
        }
        setProductList(productArr)
    }

    const decrementCartQty = (id, index) => {
        const serPet = { ...servicePet }
        const productArr = [...productList]
        let selected = serPet.selectedProducts[index].numQuantity

        if (selected <= 1) {
            serPet.selectedProducts[index].numQuantity = 1
        } else {
            serPet.selectedProducts[index].numQuantity = serPet.selectedProducts[index].numQuantity - 1
            let productListIndex = productList.findIndex((x) => x.numLocationProductID === id)
            productArr[productListIndex].TempAvailableQuantity = productArr[productListIndex].TempAvailableQuantity + 1
        }
        setServicePet(serPet)
        setProductList(productArr)
    }

    const incrementCartQty = (id, index) => {
        let productListIndex = productList.findIndex((x) => x.numLocationProductID === id)

        const serPet = { ...servicePet }
        const productArr = [...productList]
        let selected = serPet.selectedProducts[index].numQuantity
        let totalAvailable = productArr[productListIndex].numAvailableQuantity

        if (selected >= totalAvailable) {
            toast.info("Maximum quantity!")
        } else {
            serPet.selectedProducts[index].numQuantity = serPet.selectedProducts[index].numQuantity + 1
            productArr[productListIndex].TempAvailableQuantity = productArr[productListIndex].TempAvailableQuantity - 1
        }
        setServicePet(serPet)
        setProductList(productArr)
    }

    const removeFromCart = (id, index) => {
        const serPet = { ...servicePet }
        const productArr = [...productList]
        let productListIndex = productList.findIndex((x) => x.numLocationProductID === id)

        if (serPet.selectedProducts[index].numOrderID !== null) {
            serPet.selectedProducts[index].bitActive = false
            productArr[productListIndex].TempAvailableQuantity = productArr[productListIndex].TempAvailableQuantity + serPet.selectedProducts[index].numQuantity
            serPet.selectedProducts[index].numQuantity = 0
        } else {
            productArr[productListIndex].TempAvailableQuantity = productArr[productListIndex].TempAvailableQuantity + serPet.selectedProducts[index].numQuantity
            serPet.selectedProducts.splice(index, 1)
            productArr[productListIndex].isAddedToCart = false
        }
        setServicePet(serPet)
        setProductList(productArr)
    }

    const addToCart = (id, index) => {
        const cartList = { ...servicePet }
        const pList = [...productList]
        let cartItemIndex = servicePet.selectedProducts.findIndex((x) => x.numLocationProductID === id)

        if (cartItemIndex < 0) {
            if (productList[index].SelectedQuantity < 1) {
                toast.info("Please select quantity!")
            } else {
                cartList.selectedProducts.push({
                    numOrderID: null,
                    numInvoiceID: null,
                    numLocationID: servicePet?.preferredLocation,
                    numSchID: null,
                    numAppointmentID: null,
                    numSchEventID: null,
                    numPetTypeID: servicePet.petType,
                    numPetSizeID: servicePet.sizeID,
                    numPetBreedID: servicePet.breedID,
                    numGroomerID: 1,
                    numBatherID: null,
                    numSalesPersonID: 1,
                    decUnit_Price: productList[index].numProductSalesPrice,
                    decChargeAmount: productList[index].numProductSalesPrice,
                    decTaxAmt:
                        parseFloat(
                            calcTax(
                                productList[index].numStorePromotionDiscount,
                                productList[index].numSeasonalPromotionDiscount,
                                productList[index].numProductSalesPrice,
                                productList[index].numSalesTaxPercentage
                            )
                        ) * productList[index].SelectedQuantity,
                    dtBegin_date_of_service: null,
                    dtEnd_date_of_service: null,
                    varStatus: "",
                    numSeq_nbr: null,
                    numReferringCustomerID: 154,
                    varReferringCustomerName: "Kewin 3",
                    numCancelReasonID: null,
                    varCancelReason: "",
                    numReasonCodeID: null,
                    varReasonCodeDescription: "",
                    numEmployeeID: 1,
                    varEmployeeName: "Sean",
                    varServiceProductDescription: productList[index].varProductDescription === null ? "" : productList[index].varProductDescription,
                    numLocationProductID: productList[index].numLocationProductID,
                    varProductBarCode: productList[index].varProductBarCode,
                    numProductID: productList[index].numProductID,
                    varProductVendorID: productList[index].varProductVendorID,
                    varProductName: productList[index].varProductName,
                    varProductDescription: productList[index].varProductDescription,
                    LocID: productList[index].LocID,
                    numCustomerID: servicePet.customerID,
                    ServiceType: productList[index].ServiceType,
                    dtEffectiveDate: productList[index].dtEffectiveDate,
                    dtExpiryDate: productList[index].dtExpiryDate,
                    numProductSalesPrice: productList[index].numProductSalesPrice,
                    varStorePromotionMsg: productList[index].varStorePromotionMsg,
                    numStorePromotionDiscount: productList[index].numStorePromotionDiscount,
                    varSeasonalPromotionMsg: productList[index].varSeasonalPromotionMsg,
                    numSeasonalPromotionDiscount: productList[index].numSeasonalPromotionDiscount,
                    bitTaxableItem: productList[index].bitTaxableItem,
                    numSalesTaxPercentage: productList[index].numSalesTaxPercentage,
                    TotalTaxAmount: productList[index].TotalTaxAmount,
                    numAvailableQuantity: productList[index].numAvailableQuantity,
                    numQuantity: productList[index].SelectedQuantity,
                    bitActivePromotionFlag: productList[index].bitActivePromotionFlag,
                    varLinkToPromotionMaterial: productList[index].varLinkToPromotionMaterial,
                    varLinkToGlobalCoupon: productList[index].varLinkToGlobalCoupon,
                    varMessageToEmployee: productList[index].varMessageToEmployee,
                    varPromotionalMessage: productList[index].varPromotionalMessage,
                    numLocationSpecificAdjustmentPercent: productList[index].numLocationSpecificAdjustmentPercent,
                    numLocationSpecificAdjustmentAmount: productList[index].numLocationSpecificAdjustmentAmount,
                    numDateSpecificAdjustmentPercent: productList[index].numDateSpecificAdjustmentPercent,
                    numDateSpecificAdjustmentAmount: productList[index].numDateSpecificAdjustmentAmount,
                    varSalesCommissionMessage: productList[index].varSalesCommissionMessage,
                    btSalesCommissionPercent: productList[index].btSalesCommissionPercent,
                    numSalesCommissionPercent: productList[index].numSalesCommissionPercent,
                    btSalesCommissionAmount: productList[index].btSalesCommissionAmount,
                    numSalesCommissionAmount: productList[index].numSalesCommissionAmount,
                    bitAutoReOrder: productList[index].bitAutoReOrder,
                    numMinimumQuantityReOrderLevel: productList[index].numMinimumQuantityReOrderLevel,
                    numMaximumLocationQuantity: productList[index].numMaximumLocationQuantity,
                    bitActive: productList[index].bitActive,
                    numServiceProductItemID: productList[index].numServiceProductItemID,
                    varServiceOrProduct: "P",
                    numPetID: servicePet.id,
                    DOS: productList[index].DOS,
                    OrderSelected: productList[index].OrderSelected,
                    ChargeEntered: productList[index].ChargeEntered,
                    PaymentEntered: productList[index].PaymentEntered,
                    numLocationServiceProductItemID: productList[index].numLocationProductID,
                })
                productList[index].SelectedQuantity = 0
                pList[index].isAddedToCart = true
            }
        } else {
            if (productList[index].SelectedQuantity < 1) {
                toast.info("Please select quantity!")
            } else {
                cartList.selectedProducts[cartItemIndex].bitActive = true
                cartList.selectedProducts[cartItemIndex].numQuantity = cartList.selectedProducts[cartItemIndex].numQuantity + productList[index].SelectedQuantity
                productList[index].SelectedQuantity = 0
                pList[index].isAddedToCart = true
            }
        }
        setServicePet(cartList)
        setProductList(pList)
    }

    useEffect(() => {
        console.log("serPet", servicePet)
        let amountT = 0
        let cartItem = 0
        if (servicePet.selectedProducts !== undefined) {
            servicePet.selectedProducts.map((product, index) => {
                if (product.numProductSalesPrice !== null && product.bitActive === true) {
                    amountT = amountT + Math.round(parseFloat(product.numProductSalesPrice) * product.numQuantity * 100) / 100
                }
                if (product.bitActive === true) {
                    cartItem = cartItem + product.numQuantity
                }
            })
            setProductCartAmount(amountT)
            setCartItemCount(cartItem)
        } else {
            setProductCartAmount(amountT)
            setCartItemCount(cartItem)
        }
    }, [servicePet])

    const goNext = () => {
        setCurrentStep(currentStep + 1)
    }

    const goPrevious = () => {
        setCurrentStep(currentStep - 1)
    }

    const setPetInfo = (e) => {
        setSelectedPastAppointment("")
        setSlotEmployeeList([])
        let index = 0
        if (isNaN(e)) {
            index = 0
        } else {
            index = e
        }
        setSwiperSelectedPet(index)
        setSlotMsg("")
        setServicePet({
            id: props.customerData.customer?.customerPet[index].numPetID,
            name: props.customerData.customer?.customerPet[index].varPetName,
            breed: props.customerData.customer?.customerPet[index].varPrimaryBreed,
            size: props.customerData.customer?.customerPet[index].varPetSize,
            img: props.customerData.customer?.customerPet[index].varPetImagePath,
            preferredGroomer: props.customerData.customer?.customerPet[index].numPetPreferredGroomerID,
            additionalService: [],
            selectedProducts: [],
            slotInfo: {},
            petType: props.customerData.customer?.customerPet[index].numPetTypeID,
            customerID: props.customerData.customer?.customerPet[index].numCustomerID,
            weight: props.customerData.customer?.customerPet[index].numPetWeight,
            breedID: props.customerData.customer?.customerPet[index].numPetBreedID,
            sizeID: props.customerData.customer?.customerPet[index].numPetSizeID,
            preferredLocation: props.customerData.customer?.customerPet[index].numPetDefaultGroomLocationID,
            employeeID: 1,
            employeeName: "Ravin",
            numPetDefaultGroomLocationID: props.customerData.customer?.customerPet[index].numPetDefaultGroomLocationID,
        })
        getAppointmentsbyPetID(props.customerData.customer?.customerPet[index].numPetID, 5, 1)
            .then((data) => {
                if (data.PastAppointmentData !== undefined) {
                    console.log("res - pet-PastAppointmentData", data)
                    setPastAppointmentList(data.PastAppointmentData)
                    setIsLoading(false)
                } else {
                    setPastAppointmentList([])
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                console.log("error", error)
            })
    }

    useEffect(() => {
        if (currentStep === 3) {
            if (servicePet.preferredLocation !== null && servicePet.preferredLocation !== "" && servicePet.preferredLocation !== undefined) {
                getLocationServiceItemsPriceList(
                    servicePet.preferredLocation,
                    currDate,
                    servicePet.breedID,
                    servicePet.sizeID,
                    servicePet.employeeID,
                    servicePet.employeeName,
                    servicePet.id,
                    servicePet.petType
                )
                    .then((data) => {
                        if (data.Services !== undefined) {
                            setAdditionalServiceList(data.Services)
                            console.log("res - All services with SP-selected services", data)
                            setIsLoading(false)
                        } else {
                            setAdditionalServiceList([])
                            setIsLoading(false)
                        }
                    })
                    .catch((error) => {
                        console.log("error", error)
                    })
            } else {
                toast.info("Please select a location")
                setCurrentStep(2)
                setAdditionalServiceList([])
            }
        }
        if (currentStep === 4) {
            if (selectedPastAppointment === "") {
                if (servicePet.selectedProducts.length > 0) {
                } else {
                    if (servicePet.preferredLocation !== null && servicePet.preferredLocation !== "" && servicePet.preferredLocation !== undefined) {
                        getLocationProductItemsPriceList(servicePet.preferredLocation, currDate, servicePet.employeeID, servicePet.employeeName, servicePet.id)
                            .then((data) => {
                                console.log("res - All products list", data)
                                setProductList(data)
                                setIsLoading(false)
                            })
                            .catch((error) => {
                                console.log("error", error)
                            })
                    } else {
                        toast.info("Please select a location")
                        setCurrentStep(2)
                        setProductList([])
                    }
                }
            }

            if (servicePet.selectedProducts.length > 0) {
                setProductTabIndex(1)
            } else {
                setProductTabIndex(0)
            }
        } if (currentStep === 5) {
            getSlots()
        }
    }, [currentStep])

    const selectPrevAppointment = (index) => {
        const petList = { ...servicePet }
        petList.preferredLocation = pastAppointmentList[index].numLocationID

        setSelectedPastAppointment((prevIndex) => {
            if (prevIndex === index) {
                petList.additionalService = []
                const prodList = [...productList] as any
                petList.selectedProducts.map((prodSelected, prodSelectedIndex) => {
                    const matchingMainProduct = productList.findIndex((mainProduct) => prodSelected.numProductID === mainProduct.numProductID)
                    prodList[matchingMainProduct].TempAvailableQuantity = prodList[matchingMainProduct].TempAvailableQuantity + prodSelected.numQuantity
                    prodList[matchingMainProduct].SelectedQuantity = 0
                    prodList[matchingMainProduct].isAddedToCart = false
                    setProductList(prodList)
                })
                petList.selectedProducts = []
                petList.preferredLocation = props.customerData.customer?.customerPet[swiperSelectedPet].numPetDefaultGroomLocationID
                setServicePet(petList)
                return ""
            } else {
                getLocationServiceItemsPriceList(
                    pastAppointmentList[index].numLocationID,
                    currDate,
                    servicePet.breedID,
                    servicePet.sizeID,
                    servicePet.employeeID,
                    servicePet.employeeName,
                    servicePet.id,
                    servicePet.petType
                )
                    .then((data) => {
                        if (data.Services !== undefined) {
                            setAdditionalServiceList(data.Services)
                            console.log("res - All services with SP-selected services", data.Services)
                            pastAppointmentList[index].ServicesList.map((pastService, pastIndex) => {
                                data.Services.map((mainService, mainIndex) => {
                                    if (pastService.ServiceID === mainService.numServiceItemID) {
                                        let additional = {
                                            id: mainService.numServiceItemID,
                                            name: mainService.varServiceItemName,
                                            price: mainService.numServiceItemSalesPrice,
                                            desc: mainService.varServiceItemDescription,
                                            orderID: mainService.numOrderID,
                                            taxAmount: calcTax(
                                                mainService.numStorePromotionDiscount,
                                                mainService.numSeasonalPromotionDiscount,
                                                mainService.numServiceItemSalesPrice,
                                                mainService.numSalesTaxPercentage
                                            ),
                                            numLocationServiceProductItemID: mainService.numLocationServiceItemID,
                                            numSalesTaxPercentage: mainService.numSalesTaxPercentage,
                                            numSeasonalPromotionDiscount: mainService.numSeasonalPromotionDiscount,
                                            numStorePromotionDiscount: mainService.numStorePromotionDiscount,
                                        }
                                        petList.additionalService.push(additional)
                                    } else {
                                        //console.log("failed to add service ", pastService.ServiceID, " since its not available on", currDate)
                                    }
                                })
                            })

                            setServicePet(petList)
                            setIsLoading(false)
                        } else {
                            setAdditionalServiceList([])
                            setIsLoading(false)
                        }
                    })
                    .catch((error) => {
                        console.log("error", error)
                    })
                getLocationProductItemsPriceList(pastAppointmentList[index].numLocationID, currDate, servicePet.employeeID, servicePet.employeeName, servicePet.id)
                    .then((data) => {
                        console.log("res - All products list", data)
                        const pList = [] as any
                        const cartList = { ...servicePet }

                        pastAppointmentList[index].ProductsList.forEach((pastProduct, pastIndex) => {
                            const matchingMainProduct = data.find((mainProduct) => pastProduct.numProductID === mainProduct.numProductID)

                            if (matchingMainProduct) {
                                matchingMainProduct.SelectedQuantity = pastProduct.numQuantity

                                let selected = matchingMainProduct.SelectedQuantity
                                let totalAvailable = matchingMainProduct.numAvailableQuantity

                                if (selected >= totalAvailable) {
                                    console.log("failed to add product ", pastProduct.numProductID, " since it's low on quantity", currDate)
                                } else {
                                    matchingMainProduct.TempAvailableQuantity = matchingMainProduct.TempAvailableQuantity - selected
                                }

                                cartList.selectedProducts.push({
                                    numOrderID: null,
                                    numInvoiceID: null,
                                    numLocationID: servicePet?.preferredLocation,
                                    numSchID: null,
                                    numAppointmentID: null,
                                    numSchEventID: null,
                                    numPetTypeID: servicePet.petType,
                                    numPetSizeID: servicePet.sizeID,
                                    numPetBreedID: servicePet.breedID,
                                    numGroomerID: 1,
                                    numBatherID: null,
                                    numSalesPersonID: 1,
                                    decUnit_Price: matchingMainProduct.numProductSalesPrice,
                                    decChargeAmount: matchingMainProduct.numProductSalesPrice,
                                    decTaxAmt:
                                        parseFloat(
                                            calcTax(
                                                matchingMainProduct.numStorePromotionDiscount,
                                                matchingMainProduct.numSeasonalPromotionDiscount,
                                                matchingMainProduct.numProductSalesPrice,
                                                matchingMainProduct.numSalesTaxPercentage
                                            )
                                        ) * matchingMainProduct.SelectedQuantity,
                                    dtBegin_date_of_service: null,
                                    dtEnd_date_of_service: null,
                                    varStatus: "",
                                    numSeq_nbr: null,
                                    numReferringCustomerID: 154,
                                    varReferringCustomerName: "Kewin 3",
                                    numCancelReasonID: null,
                                    varCancelReason: "",
                                    numReasonCodeID: null,
                                    varReasonCodeDescription: "",
                                    numEmployeeID: 1,
                                    varEmployeeName: "Sean",
                                    varServiceProductDescription: matchingMainProduct.varProductDescription === null ? "" : matchingMainProduct.varProductDescription,
                                    numLocationProductID: matchingMainProduct.numLocationProductID,
                                    varProductBarCode: matchingMainProduct.varProductBarCode,
                                    numProductID: matchingMainProduct.numProductID,
                                    varProductVendorID: matchingMainProduct.varProductVendorID,
                                    varProductName: matchingMainProduct.varProductName,
                                    varProductDescription: matchingMainProduct.varProductDescription,
                                    LocID: matchingMainProduct.LocID,
                                    numCustomerID: servicePet.customerID,
                                    ServiceType: matchingMainProduct.ServiceType,
                                    dtEffectiveDate: matchingMainProduct.dtEffectiveDate,
                                    dtExpiryDate: matchingMainProduct.dtExpiryDate,
                                    numProductSalesPrice: matchingMainProduct.numProductSalesPrice,
                                    varStorePromotionMsg: matchingMainProduct.varStorePromotionMsg,
                                    numStorePromotionDiscount: matchingMainProduct.numStorePromotionDiscount,
                                    varSeasonalPromotionMsg: matchingMainProduct.varSeasonalPromotionMsg,
                                    numSeasonalPromotionDiscount: matchingMainProduct.numSeasonalPromotionDiscount,
                                    bitTaxableItem: matchingMainProduct.bitTaxableItem,
                                    numSalesTaxPercentage: matchingMainProduct.numSalesTaxPercentage,
                                    TotalTaxAmount: matchingMainProduct.TotalTaxAmount,
                                    numAvailableQuantity: matchingMainProduct.numAvailableQuantity,
                                    numQuantity: matchingMainProduct.SelectedQuantity,
                                    bitActivePromotionFlag: matchingMainProduct.bitActivePromotionFlag,
                                    varLinkToPromotionMaterial: matchingMainProduct.varLinkToPromotionMaterial,
                                    varLinkToGlobalCoupon: matchingMainProduct.varLinkToGlobalCoupon,
                                    varMessageToEmployee: matchingMainProduct.varMessageToEmployee,
                                    varPromotionalMessage: matchingMainProduct.varPromotionalMessage,
                                    numLocationSpecificAdjustmentPercent: matchingMainProduct.numLocationSpecificAdjustmentPercent,
                                    numLocationSpecificAdjustmentAmount: matchingMainProduct.numLocationSpecificAdjustmentAmount,
                                    numDateSpecificAdjustmentPercent: matchingMainProduct.numDateSpecificAdjustmentPercent,
                                    numDateSpecificAdjustmentAmount: matchingMainProduct.numDateSpecificAdjustmentAmount,
                                    varSalesCommissionMessage: matchingMainProduct.varSalesCommissionMessage,
                                    btSalesCommissionPercent: matchingMainProduct.btSalesCommissionPercent,
                                    numSalesCommissionPercent: matchingMainProduct.numSalesCommissionPercent,
                                    btSalesCommissionAmount: matchingMainProduct.btSalesCommissionAmount,
                                    numSalesCommissionAmount: matchingMainProduct.numSalesCommissionAmount,
                                    bitAutoReOrder: matchingMainProduct.bitAutoReOrder,
                                    numMinimumQuantityReOrderLevel: matchingMainProduct.numMinimumQuantityReOrderLevel,
                                    numMaximumLocationQuantity: matchingMainProduct.numMaximumLocationQuantity,
                                    bitActive: matchingMainProduct.bitActive,
                                    numServiceProductItemID: matchingMainProduct.numServiceProductItemID,
                                    varServiceOrProduct: "P",
                                    numPetID: servicePet.id,
                                    DOS: matchingMainProduct.DOS,
                                    OrderSelected: matchingMainProduct.OrderSelected,
                                    ChargeEntered: matchingMainProduct.ChargeEntered,
                                    PaymentEntered: matchingMainProduct.PaymentEntered,
                                    numLocationServiceProductItemID: matchingMainProduct.numLocationProductID,
                                })
                                matchingMainProduct.SelectedQuantity = 0
                                matchingMainProduct.isAddedToCart = true

                                pList.push(matchingMainProduct)
                            } else {
                                console.log("Product not found in the main list for numProductID:", pastProduct.numProductID)
                            }
                        })

                        const remainingProducts = data.filter((mainProduct) => !pastAppointmentList[index].ProductsList.some((pastProduct) => pastProduct.numProductID === mainProduct.numProductID))
                        pList.push(...remainingProducts)

                        setProductList(pList)
                        setServicePet(cartList)
                        setIsLoading(false)
                    })
                    .catch((error) => {
                        console.log("error", error)
                    })
                toast.success("Appointment selected! You can continue to step 5")

                return index
            }
        })
    }

    const onLocationChange = (locationID) => {
        setServicePet((prevServicePet) => {
            if (prevServicePet.preferredLocation !== locationID) {
                const petList = { ...prevServicePet }
                petList.additionalService = []
                const prodList = [...productList]

                petList.selectedProducts.forEach((prodSelected) => {
                    const matchingMainProduct = prodList.findIndex((mainProduct) => prodSelected.numProductID === mainProduct.numProductID)

                    prodList[matchingMainProduct].TempAvailableQuantity = prodList[matchingMainProduct].TempAvailableQuantity + prodSelected.numQuantity
                    prodList[matchingMainProduct].SelectedQuantity = 0
                    prodList[matchingMainProduct].isAddedToCart = false
                })

                petList.selectedProducts = []
                petList.preferredLocation = locationID
                setSelectedPastAppointment("")

                setProductList(prodList)

                return petList
            } else {
                return prevServicePet
            }
        })
    }

    const [totalTax, setTotalTax] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)

    useEffect(() => {
        if (servicePet && servicePet.selectedProducts && servicePet.additionalService) {
            // Calculate total tax
            const selectedProductsTax = servicePet.selectedProducts.reduce((sum, item) => sum + (item.decTaxAmt || 0), 0)
            const additionalServiceTax = servicePet.additionalService.reduce((sum, item) => sum + (parseFloat(item.taxAmount) || 0), 0)
            const taxSum = selectedProductsTax + additionalServiceTax

            // Calculate total amount
            const selectedProductsAmount = servicePet.selectedProducts.reduce((sum, item) => sum + (item.decChargeAmount || 0), 0)
            const additionalServiceAmount = servicePet.additionalService.reduce((sum, item) => sum + (item.price || 0), 0)
            const amountSum = selectedProductsAmount + additionalServiceAmount

            // Update state with calculated values
            setTotalTax(taxSum)
            setTotalAmount(amountSum)
        }
    }, [servicePet])

    const [slotEmployeeList, setSlotEmployeeList] = useState([]) as any

    const [isOpenSlotPicker, setIsOpenSlotPicker] = useState(false)


    useEffect(()=>{console.log("SEL", slotEmployeeList)},[slotEmployeeList])

    const getSlots = () => {
        setIsLoading(true)
        setSelectedDate({ empIndex: "", dateIndex: "" })
        const serPet  = {...servicePet};
        serPet.slotInfo = {};
        setServicePet(serPet);

        getPrimaryBreedByPetBreedID(servicePet.breedID)
            .then((dataBreed) => {

                console.log("dataB", dataBreed)

                if (servicePet.additionalService.length > 0) {
                    if(servicePet.additionalService[0].id !== null){
                            if (requestedDate !== "") {
                                const selectedTimeOfDay = timeOfDayList.find((item) => item.numID === requestedTimeOfDay)
                                const { tmToTime, tmFromTime } = selectedTimeOfDay
        
                                let slotObj = [
                                    {
                                        petTypeID: servicePet.petType,
                                        serviceList: [
                                            {
                                                serviceID: servicePet.additionalService[0].id,
                                            },
                                        ],
                                        petSizeID: servicePet.sizeID,
                                        petBreedID: dataBreed,
                                        prefferedEmpID: servicePet.preferredGroomer === null ? 0 : servicePet.preferredGroomer,
                                        locationID: servicePet.preferredLocation,
                                        timeRanges: [
                                            {
                                                timeOfFromDate: tmFromTime,
                                                timeOfToDate: tmToTime,
                                            },
                                        ],
                                        customerID: servicePet.customerID,
                                        petID: servicePet.id,
                                        requestDate: requestedDate, //"2023-11-28T10:33:17.806Z",
                                        userID: servicePet.employeeID,
                                    },
                                ]

                                let slotBatherAxiosObj = [
                                    {
                                        petTypeID: servicePet.petType,
                                        serviceList: [
                                            {
                                                serviceID: servicePet.additionalService[0].id,
                                            },
                                        ],
                                        petSizeID: servicePet.sizeID,
                                        petBreedID: dataBreed,
                                        prefferedEmpID: servicePet.preferredGroomer === null ? 0 : servicePet.preferredGroomer,
                                        locationID: servicePet.preferredLocation,
                                        timeRanges: [
                                            {
                                                timeOfFromDate: tmFromTime,
                                                timeOfToDate: tmToTime,
                                            },
                                        ],
                                        customerID: servicePet.customerID,
                                        petID: servicePet.id,
                                        requestDate: requestedDate, //"2023-11-28T10:33:17.806Z",
                                        userID: servicePet.employeeID,
                                    },
                                ]
        
                                console.log("slotOBJ-sent", slotObj)
        
                                axios
                                .post(`${process.env.REACT_APP_API_URL}Schedule/GetSlot`, slotObj)
                                .then(function (response) {
                                    console.log("app_success_slots", response)
                                    let timeSlots: any = response.data
        
                                    timeSlots.forEach((slot) => {
                                        slot.AvailableStartTime = new Date(slot.AvailableStartTime)
                                        slot.AvailableEndTime = new Date(slot.AvailableEndTime)
                                    })
        
                                    const groupedSlots = [] as any
                                    let obj = [] as any
        
                                    for (let i = 0; i < timeSlots.length; i += 2) {
                                        if (timeSlots[i].IsPackage) {
                                       
                                            if(timeSlots[i]?.IsBath){
                                             
                                                if(timeSlots[i]?.TempEmpID === timeSlots[i]?.DealEmpID){
        
                                                    const startTime = timeSlots[i].AvailableStartTime
                                                    const endTime = timeSlots[i + 1].AvailableEndTime
            
                                                    const durationMilliseconds = endTime - startTime
                                                    const durationMinutes = Math.floor(durationMilliseconds / (1000 * 60))
            
                                                    let objj = {
                                                        date: timeSlots[i].AvailableStartTime.toISOString().split("T")[0],
                                                        slot:
                                                            timeSlots[i].AvailableStartTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
                                                            " - " +
                                                            timeSlots[i + 1].AvailableEndTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
                                                        duration: durationMinutes,
                                                        slotID: timeSlots[i].TimeSlotID,
                                                        start: startTime,
                                                        end: endTime
                                                    }
            
                                                    obj.push({
                                                        employeeID: timeSlots[i]?.TempEmpID,
                                                        name: `${timeSlots[i]?.varEmployeeFirstName}  ${timeSlots[i]?.varEmployeeLastName}`,
                                                        occupation: "Groomer",
                                                        rating: 4,
                                                        image: timeSlots[i]?.varEmployeeImagePath,
                                                        dates: [
                                                            {
                                                                date: objj.date,
                                                                slots: [objj],
                                                            },
                                                        ],
                                                    })
        
                                                } else {
                                                  
                                                    const batherStartTime = timeSlots[i].AvailableStartTime
                                                    const batherEndTime = timeSlots[i].AvailableEndTime
                                                  
                                                    const batherDurationMilliseconds = batherEndTime - batherStartTime
                                                    const batherDurationMinutes = Math.floor(batherDurationMilliseconds / (1000 * 60))
            
                                                    let objjBather = {
                                                        date: timeSlots[i].AvailableStartTime.toISOString().split("T")[0],
                                                        slot:
                                                            timeSlots[i].AvailableStartTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
                                                            " - " +
                                                            timeSlots[i].AvailableEndTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
                                                        duration: batherDurationMinutes,
                                                        slotID: timeSlots[i].DealTimeSlotID,
                                                        start: batherStartTime,
                                                        end: batherEndTime,
                                                        type:"Bather"
                                                    }
        
                                                    const groomerStartTime = timeSlots[i+1].AvailableStartTime
                                                    const groomerEndTime = timeSlots[i+1].AvailableEndTime
        
                                                    const groomerDurationMilliseconds = groomerEndTime - groomerStartTime
                                                    const groomerDurationMinutes = Math.floor(groomerDurationMilliseconds / (1000 * 60))
            
                                                    let objjGroomer = {
                                                        date: timeSlots[i+1].AvailableStartTime.toISOString().split("T")[0],
                                                        slot:
                                                            timeSlots[i+1].AvailableStartTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
                                                            " - " +
                                                            timeSlots[i + 1].AvailableEndTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
                                                        duration: groomerDurationMinutes,
                                                        slotID: timeSlots[i+1].TimeSlotID,
                                                        start: groomerStartTime,
                                                        end: groomerEndTime,
                                                        type:"Groomer"
                                                    }
        
                                                    let objjMainSlot = {
                                                        date: timeSlots[i].AvailableStartTime.toISOString().split("T")[0],
                                                        slot:
                                                            timeSlots[i].AvailableStartTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
                                                            " - " +
                                                            timeSlots[i + 1].AvailableEndTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
                                                        duration: groomerDurationMinutes + batherDurationMinutes,
                                                        slotID: timeSlots[i].TimeSlotID,
                                                        start: batherStartTime,
                                                        end: groomerEndTime,
                                                        type:"Main"
                                                    }
            
                                                    obj.push({
                                                        employeeID: timeSlots[i+1]?.TempEmpID,
                                                        name: `${timeSlots[i+1]?.varEmployeeFirstName}  ${timeSlots[i+1]?.varEmployeeLastName}`,
                                                        occupation: "Groomer",
                                                        rating: 4,
                                                        image: timeSlots[i+1]?.varEmployeeImagePath,
                                                        dates: [
                                                            {
                                                                date: objjGroomer.date,
                                                                slots: [objjMainSlot, objjGroomer, objjBather],
                                                            },
                                                        ],
                                                    })
                                                }
                                            }                                    
                                            
                                        }else{
                                            const startTime = timeSlots[i].AvailableStartTime
                                            const endTime = timeSlots[i].AvailableEndTime
        
                                            const durationMilliseconds = endTime - startTime
                                            const durationMinutes = Math.floor(durationMilliseconds / (1000 * 60))
        
                                            let objj = {
                                                date: timeSlots[i].AvailableStartTime.toISOString().split("T")[0],
                                                slot:
                                                    timeSlots[i].AvailableStartTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
                                                    " - " +
                                                    timeSlots[i].AvailableEndTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
                                                duration: durationMinutes,
                                                slotID: timeSlots[i].TimeSlotID,
                                                start: startTime,
                                                end: endTime
                                            }
        
                                            obj.push({
                                                employeeID: timeSlots[i]?.TempEmpID,
                                                name: `${timeSlots[i]?.varEmployeeFirstName}  ${timeSlots[i]?.varEmployeeLastName}`,
                                                occupation: "Groomer",
                                                rating: 4,
                                                image: timeSlots[i]?.varEmployeeImagePath,
                                                dates: [
                                                    {
                                                        date: objj.date,
                                                        slots: [objj],
                                                    },
                                                ],
                                            })
                                        }
                                    }
                                
        
                                    // Group objects by employeeID and date
                                    const groupedByEmployeeAndDate = {}
        
                                    obj.forEach((slot) => {
                                        const employeeIDKey = slot.employeeID
                                        const dateKey = slot.dates[0].date
        
                                        if (!groupedByEmployeeAndDate[employeeIDKey]) {
                                            groupedByEmployeeAndDate[employeeIDKey] = {}
                                        }
        
                                        if (!groupedByEmployeeAndDate[employeeIDKey][dateKey]) {
                                            groupedByEmployeeAndDate[employeeIDKey][dateKey] = []
                                        }
        
                                        groupedByEmployeeAndDate[employeeIDKey][dateKey].push(slot.dates[0])
                                    })
        
                                    console.log("grouped", groupedByEmployeeAndDate)
        
        
                                    const uniqueEmployeeIDs = Array.from(new Set(timeSlots.map(slot => slot.TempEmpID)));
        
                                    // Create the final result array
                             const finalResult = uniqueEmployeeIDs.map((employeeID:any) => {
                                        // Find the slot corresponding to the employeeID
                                        const employeeSlot = timeSlots.find(slot => slot.TempEmpID.toString() === employeeID.toString());
                                        console.log("Employee Slot:", employeeSlot);
        
                                        if (!employeeSlot) {
                                            console.log(`No slot found for employeeID ${employeeID}`);
                                            // If no slot found, return default values
                                            return {
                                                employeeID: employeeID,
                                                name: "Unknown",
                                                occupation: "Groomer",
                                                rating: 4,
                                                image: "default.jpg",
                                                dates: []
                                            };
                                        }
        
                                        // If slot found, extract name and image
                                        const name = `${employeeSlot.varEmployeeFirstName || ""} ${employeeSlot.varEmployeeLastName || ""}`;
                                        const image = employeeSlot.varEmployeeImagePath || "default.jpg";
        
                                        return {
                                            employeeID: employeeID,
                                            name: name.trim(), // Trim to remove extra spaces
                                            occupation: "Groomer",
                                            rating: 4,
                                            image: image,
                                            dates: Object.keys(groupedByEmployeeAndDate[employeeID])
                                            // Sort dates in ascending order
                                            .sort((a, b) => {
                                                return new Date(a).getTime() - new Date(b).getTime();
                                            })
                                            .map((date) => {
                                                // Map each date to their respective object
                                                return {
                                                    date: date,
                                                    slots: groupedByEmployeeAndDate[employeeID][date],
                                                };
                                            }),
                                        };
                                    });
                                    console.log("TSL", timeSlots)
                                    // Wrap the final result with location ID and name object
                                    if(timeSlots.length > 0){
                                        const wrappedResult = [{
                                            locationID: timeSlots[0].LocationID,
                                            name: timeSlots[0].varLocationName,
                                            employees: finalResult
                                        }];
        
                                        setSelectedDate({ empIndex: 0, dateIndex: 0 })
                                        const serPet = {...servicePet};
                                        serPet.slotInfo = {
                                            slot: wrappedResult[0]?.employees[0]?.dates?.[0]?.slots?.[0],
                                            empIndex: 0,
                                            dateIndex: 0,
                                            slotIndex: 0
                                        }
                                        setSelectedLocation(0)
                                        setServicePet(serPet)
                                        setSlotEmployeeList(wrappedResult)
                                        setIsLoading(false)
                                    }else{
                                        setIsLoading(false)
                                        toast.warn(`No slots found in ${store.find(item => item.id === servicePet?.preferredLocation)?.title} fetching slots from next nearest location...`, {
                                            position: "top-center",
                                            autoClose: false,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                            transition: Bounce,
                                            })
                                        setSlotMsg(`No slots found in ${store.find(item => item.id === servicePet?.preferredLocation)?.title} fetching slots from next nearest location...`)
                                        setSelectedDate({ empIndex: "", dateIndex: "" })
                                        const serPet = {...servicePet};
                                        serPet.slotInfo = {};
                                        setServicePet(serPet)
                                        searchLatAndLngByStreet(props.customerData?.customer?.customerAddress[0]?.varCustomerAddress1 + " " + props.customerData?.customer?.customerAddress[0]?.varCustomerCity + ", " + props.customerData?.customer?.customerAddress[0]?.varCustomerState)
                                    }
        
        
        
                                    })
                                    .catch(function (error) {
                                        console.log(error)
                                        setIsLoading(false)
                                    })
                        } else {
                            toast.info("Please select a date!")
                            setIsLoading(false)
                        }
                    }else {
                        toast.info("Please select a service!")
                        setSlotEmployeeList([])
                        setIsLoading(false)
                        setCurrentStep(3)
                    }
        
                } else {
                    toast.info("Please select a service!")
                    setSlotEmployeeList([])
                    setIsLoading(false)
                    setCurrentStep(3)
                }
    
            })
            .catch((error) => {
                console.log("error", error)
            })


  
    }


    const getSlotsMultiple = (loc) => {
        console.log("passed-loc", loc)
        setIsLoading(true)
        setSelectedDate({ empIndex: "", dateIndex: "" })
        const serPet  = {...servicePet};
        serPet.slotInfo = {};
        setServicePet(serPet);

        const axiosRequests: Promise<any>[] = [];


        getPrimaryBreedByPetBreedID(servicePet.breedID)
            .then((dataBreed) => {

                console.log("dataB", dataBreed)

                loc.map((location, index) => {
                    if (servicePet.additionalService.length > 0) {
                        if (servicePet.additionalService[0].id !== null) {
                            if (requestedDate !== "") {
                                const selectedTimeOfDay = timeOfDayList.find((item) => item.numID === requestedTimeOfDay);
                                const { tmToTime, tmFromTime } = selectedTimeOfDay;
                
                                let slotObj = [{
                                    petTypeID: servicePet.petType,
                                    serviceList: [{
                                        serviceID: servicePet.additionalService[0].id,
                                    }, ],
                                    petSizeID: servicePet.sizeID,
                                    petBreedID: dataBreed,
                                    prefferedEmpID: servicePet.preferredGroomer === null ? 0 : servicePet.preferredGroomer,
                                    locationID: location.id,
                                    timeRanges: [{
                                        timeOfFromDate: tmFromTime,
                                        timeOfToDate: tmToTime,
                                    }, ],
                                    customerID: servicePet.customerID,
                                    petID: servicePet.id,
                                    requestDate: requestedDate,
                                    userID: servicePet.employeeID,
                                }, ];

                                let slotObjAxiosBather = [{
                                    petTypeID: servicePet.petType,
                                    serviceList: [{
                                        serviceID: servicePet.additionalService[0].id,
                                    }, ],
                                    petSizeID: servicePet.sizeID,
                                    petBreedID: dataBreed,
                                    prefferedEmpID: servicePet.preferredGroomer === null ? 0 : servicePet.preferredGroomer,
                                    locationID: location.id,
                                    timeRanges: [{
                                        timeOfFromDate: tmFromTime,
                                        timeOfToDate: tmToTime,
                                    }, ],
                                    customerID: servicePet.customerID,
                                    petID: servicePet.id,
                                    requestDate: requestedDate,
                                    userID: servicePet.employeeID,
                                }, ];
        
                                console.log("multi-1", slotObj)
                
                                // Push the axios request into the array
                                axiosRequests.push(axios.post(`${process.env.REACT_APP_API_URL}Schedule/GetSlot`, slotObj));
                            } else {
                                toast.info("Please select a date!");
                                setIsLoading(false);
                            }
                        } else {
                            toast.info("Please select a service!");
                            setSlotEmployeeList([]);
                            setIsLoading(false);
                            setCurrentStep(3);
                        }
                    } else {
                        toast.info("Please select a service!");
                        setSlotEmployeeList([]);
                        setIsLoading(false);
                        setCurrentStep(3);
                    }
                });

                                    // After mapping, wait for all axios requests to complete
                    Promise.all(axiosRequests)
                    .then((responses) => {
                        // Array to store all location slots
                        const allLocationSlots: any[] = [];

                        // Loop through responses and push data into allLocationSlots
                        responses.forEach((response) => {
                            let timeSlots: any[] = response.data; // Cast to array of any
                            if (timeSlots.length > 0) {
                                allLocationSlots.push(...timeSlots);
                            }
                        });

                        // Now iterate over allLocationSlots and merge store info
                        allLocationSlots.forEach(slot => {
                            const matchingStore = store.find(store => store.id === slot.LocationID);
                            if (matchingStore) {
                                // Merge store properties into slot object
                                slot.distance = matchingStore.distance;
                                slot.duration = matchingStore.duration;
                                slot.img = matchingStore.img;
                            }
                        });

                        // Log the combined object array with store info
                        generateSlots(allLocationSlots)
                        console.log("all location slots with store info", allLocationSlots);
                    })
                    .catch((error) => {
                        console.error(error);
                        setIsLoading(false);
                    });
    
            })
            .catch((error) => {
                console.log("error", error)
            })

    }

    const generateSlots = (timeSlots) => {
        timeSlots.forEach((slot) => {
                    slot.AvailableStartTime = new Date(slot.AvailableStartTime)
                    slot.AvailableEndTime = new Date(slot.AvailableEndTime)
                })
                    let obj = [] as any

                    for (let i = 0; i < timeSlots.length; i += 2) {
                        if (timeSlots[i].IsPackage) {
                        
                            if(timeSlots[i]?.IsBath){
                                
                                if(timeSlots[i]?.TempEmpID === timeSlots[i]?.DealEmpID){

                                    const startTime = timeSlots[i].AvailableStartTime
                                    const endTime = timeSlots[i + 1].AvailableEndTime

                                    const durationMilliseconds = endTime - startTime
                                    const durationMinutes = Math.floor(durationMilliseconds / (1000 * 60))

                                    let objj = {
                                        date: timeSlots[i].AvailableStartTime.toISOString().split("T")[0],
                                        slot:
                                            timeSlots[i].AvailableStartTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
                                            " - " +
                                            timeSlots[i + 1].AvailableEndTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
                                        duration: durationMinutes,
                                        slotID: timeSlots[i].TimeSlotID,
                                        start: startTime,
                                        end: endTime
                                    }

                                    obj.push({
                                        employeeID: timeSlots[i]?.TempEmpID,
                                        name: `${timeSlots[i]?.varEmployeeFirstName}  ${timeSlots[i]?.varEmployeeLastName}`,
                                        occupation: "Groomer",
                                        rating: 4,
                                        image: timeSlots[i]?.varEmployeeImagePath,
                                        dates: [
                                            {
                                                date: objj.date,
                                                slots: [objj],
                                            },
                                        ],
                                    })

                                } else {
                                    
                                    const batherStartTime = timeSlots[i].AvailableStartTime
                                    const batherEndTime = timeSlots[i].AvailableEndTime
                                    
                                    const batherDurationMilliseconds = batherEndTime - batherStartTime
                                    const batherDurationMinutes = Math.floor(batherDurationMilliseconds / (1000 * 60))

                                    let objjBather = {
                                        date: timeSlots[i].AvailableStartTime.toISOString().split("T")[0],
                                        slot:
                                            timeSlots[i].AvailableStartTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
                                            " - " +
                                            timeSlots[i].AvailableEndTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
                                        duration: batherDurationMinutes,
                                        slotID: timeSlots[i].DealTimeSlotID,
                                        start: batherStartTime,
                                        end: batherEndTime,
                                        type:"Bather"
                                    }

                                    const groomerStartTime = timeSlots[i+1].AvailableStartTime
                                    const groomerEndTime = timeSlots[i+1].AvailableEndTime

                                    const groomerDurationMilliseconds = groomerEndTime - groomerStartTime
                                    const groomerDurationMinutes = Math.floor(groomerDurationMilliseconds / (1000 * 60))

                                    let objjGroomer = {
                                        date: timeSlots[i+1].AvailableStartTime.toISOString().split("T")[0],
                                        slot:
                                            timeSlots[i+1].AvailableStartTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
                                            " - " +
                                            timeSlots[i + 1].AvailableEndTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
                                        duration: groomerDurationMinutes,
                                        slotID: timeSlots[i+1].TimeSlotID,
                                        start: groomerStartTime,
                                        end: groomerEndTime,
                                        type:"Groomer"
                                    }

                                    let objjMainSlot = {
                                        date: timeSlots[i].AvailableStartTime.toISOString().split("T")[0],
                                        slot:
                                            timeSlots[i].AvailableStartTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
                                            " - " +
                                            timeSlots[i + 1].AvailableEndTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
                                        duration: groomerDurationMinutes + batherDurationMinutes,
                                        slotID: timeSlots[i].TimeSlotID,
                                        start: batherStartTime,
                                        end: groomerEndTime,
                                        type:"Main"
                                    }

                                    obj.push({
                                        employeeID: timeSlots[i+1]?.TempEmpID,
                                        name: `${timeSlots[i+1]?.varEmployeeFirstName}  ${timeSlots[i+1]?.varEmployeeLastName}`,
                                        occupation: "Groomer",
                                        rating: 4,
                                        image: timeSlots[i+1]?.varEmployeeImagePath,
                                        dates: [
                                            {
                                                date: objjGroomer.date,
                                                slots: [objjMainSlot, objjGroomer, objjBather],
                                            },
                                        ],
                                    })
                                }
                            }                                    
                            
                        }else{
                            const startTime = timeSlots[i].AvailableStartTime
                            const endTime = timeSlots[i].AvailableEndTime

                            const durationMilliseconds = endTime - startTime
                            const durationMinutes = Math.floor(durationMilliseconds / (1000 * 60))

                            let objj = {
                                date: timeSlots[i].AvailableStartTime.toISOString().split("T")[0],
                                slot:
                                    timeSlots[i].AvailableStartTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
                                    " - " +
                                    timeSlots[i].AvailableEndTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
                                duration: durationMinutes,
                                slotID: timeSlots[i].TimeSlotID,
                                start: startTime,
                                end: endTime
                            }

                            obj.push({
                                employeeID: timeSlots[i]?.TempEmpID,
                                name: `${timeSlots[i]?.varEmployeeFirstName}  ${timeSlots[i]?.varEmployeeLastName}`,
                                occupation: "Groomer",
                                rating: 4,
                                image: timeSlots[i]?.varEmployeeImagePath,
                                dates: [
                                    {
                                        date: objj.date,
                                        slots: [objj],
                                    },
                                ],
                            })
                        }
                    }
                            

                    // Group objects by employeeID and date
                    const groupedByEmployeeAndDate = {}

                    obj.forEach((slot) => {
                        const employeeIDKey = slot.employeeID
                        const dateKey = slot.dates[0].date

                        if (!groupedByEmployeeAndDate[employeeIDKey]) {
                            groupedByEmployeeAndDate[employeeIDKey] = {}
                        }

                        if (!groupedByEmployeeAndDate[employeeIDKey][dateKey]) {
                            groupedByEmployeeAndDate[employeeIDKey][dateKey] = []
                        }

                        groupedByEmployeeAndDate[employeeIDKey][dateKey].push(slot.dates[0])
                    })

                    const uniqueEmployeeIDs = Array.from(new Set(timeSlots.map(slot => slot.TempEmpID)));

                const finalResult = uniqueEmployeeIDs.map((employeeID:any) => {
                    // Find the slot corresponding to the employeeID
                    const employeeSlot = timeSlots.find(slot => slot.TempEmpID.toString() === employeeID.toString());
                
                    if (!employeeSlot) {
                        console.log(`No slot found for employeeID ${employeeID}`);
                        // If no slot found, return default values
                        return {
                            employeeID: employeeID,
                            name: "Unknown",
                            occupation: "Groomer",
                            rating: 4,
                            image: "default.jpg",
                            dates: []
                        };
                    }
                
                    const name = `${employeeSlot.varEmployeeFirstName || ""} ${employeeSlot.varEmployeeLastName || ""}`;
                    const image = employeeSlot.varEmployeeImagePath || "default.jpg";
                
                    return {
                        employeeID: employeeID,
                        name: name.trim(),
                        occupation: "Groomer",
                        rating: 4,
                        image: image,
                        locationID: employeeSlot.LocationID,
                        varLocationName: employeeSlot.varLocationName,
                        img: employeeSlot.img,
                        duration: employeeSlot.duration,
                        distance: employeeSlot.distance,
                        dates: Object.keys(groupedByEmployeeAndDate[employeeID])
                            // Sort dates in ascending order
                            .sort((a, b) => {
                                return new Date(a).getTime() - new Date(b).getTime();
                            })
                            .map((date) => {
                                // Map each date to their respective object
                                return {
                                    date: date,
                                    slots: groupedByEmployeeAndDate[employeeID][date],
                                };
                            }),
                    };
                });



                        // Group employees by location
                    const employeesByLocation = finalResult.reduce((acc, employee) => {
                        const locationID = employee.locationID;
                        if (!acc[locationID]) {
                            // Initialize location object if it doesn't exist
                            acc[locationID] = {
                                locationID: locationID,
                                name: employee.varLocationName,
                                img: employee.img,
                                distance: employee.distance,
                                duration: employee.duration,
                                employees: []
                            };
                        }
                        // Add employee to the respective location
                        acc[locationID].employees.push({
                            employeeID: employee.employeeID,
                            name: employee.name,
                            occupation: employee.occupation,
                            rating: employee.rating,
                            image: employee.image,
                            dates: employee.dates
                        });
                        return acc;
                    }, {});

                    // Convert object to array of location objects
                    const locationArray:any = Object.values(employeesByLocation);
                    
                    console.log("finalResult", locationArray)
                if(locationArray.length > 0){

                    setSlotEmployeeList(locationArray);
                    setSelectedDate({ empIndex: 0, dateIndex: 0 })
                    const serPet = {...servicePet};
                    serPet.slotInfo = {
                        slot: locationArray[0]?.employees[0]?.dates?.[0]?.slots?.[0],
                        empIndex: 0,
                        dateIndex: 0,
                        slotIndex: 0
                    }
                    serPet.selectedLocation = locationArray[0]?.locationID
                    setSelectedLocation(0)
                    setServicePet(serPet)
            
            
                    setIsLoading(false)
                }else{
                    setSlotMsg(`Currently we don't have any slots available in any of our locations, Please try again later`)
                    
                    toast.warn('Currently we dont have any slots available in any of our locations, Please try again later', {
                        position: "top-center",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                        })
                    setIsLoading(false)
                    setSelectedDate({ empIndex: "", dateIndex: "" })
                    const serPet = {...servicePet};
                    serPet.slotInfo = {};
                    setServicePet(serPet)
                }
    }

    const selectSlot = (slot, empIndex, dateIndex, slotIndex) => {
        console.log(slot, empIndex, dateIndex, slotIndex)
        const serPet = {...servicePet};
        serPet.slotInfo = {
            slot: slot,
            empIndex: empIndex,
            dateIndex: dateIndex,
            slotIndex: slotIndex
        }
        setServicePet(serPet)
        setIsOpenSlotPicker(false)
    }

    const saveReservation = () => {
        if (servicePet.additionalService.length > 0) {
            if(Object.keys(servicePet.slotInfo).length > 0){

                const result:any = [];
                servicePet.additionalService.forEach(additional => {
                    const additionalServiceObj:any = {
                        numOrderID: (additional.orderID !== "") ? additional.orderID : null,
                        numInvoiceID: null,
                        numLocationID: (servicePet?.selectedLocation === undefined)? servicePet?.preferredLocation : servicePet?.selectedLocation,
                        numSchID: null,
                        numAppointmentID: null,
                        numSchEventID: null,
                        numCustomerID: servicePet.customerID,
                        numPetID: servicePet.id,
                        numPetTypeID: servicePet.petType,
                        numPetSizeID: servicePet.sizeID,
                        numPetBreedID: servicePet.breedID,
                        bitAddOnService: null,
                        varServiceOrProduct: "S",
                        numServiceProductItemID: additional.id,
                        numLocationServiceProductItemID: additional.numLocationServiceProductItemID,
                        varServiceProductDescription: (additional.desc === null)? "" : additional.desc,
                        bitTaxableItem: additional.numSalesTaxPercentage === null ? false : additional.numSalesTaxPercentage > 0,
                        numSalesTaxPercentage: additional.numSalesTaxPercentage,
                        numGroomerID: 1,
                        numBatherID: null,
                        numSalesPersonID: 1,
                        numQuantity: 1,
                        decUnit_Price: additional.price,
                        decChargeAmount: additional.price,
                        decTaxAmt: parseFloat(additional.taxAmount),
                        dtBegin_date_of_service: null,
                        dtEnd_date_of_service: null,
                        varStatus: "",
                        numSeq_nbr: null,
                        numReferringCustomerID: 154,
                        varReferringCustomerName: "Kewin 3",
                        numCancelReasonID: null,
                        varCancelReason: "",
                        numReasonCodeID: null,
                        varReasonCodeDescription: "",
                        numEmployeeID: 1,
                        varEmployeeName: "Sean",
                        bitActive: true,
                        numStorePromotionDiscount: additional.numStorePromotionDiscount,
                        numSeasonalPromotionDiscount: additional.numSeasonalPromotionDiscount,
                    };
            
                    result.push(additionalServiceObj);
                });

                const combinedArray = [...result, ...servicePet.selectedProducts];

                console.log("ser-prod-sent-holding", combinedArray)
                addOrdersToHoldingTank(combinedArray)
            }else {
                toast.info("Please select a slot!")
        }
        }else {
            toast.info("Please select a service!")
        }
    }

    async function addOrdersToHoldingTank(data) {
        setIsLoading(true)
        await axios.post(`${process.env.REACT_APP_API_URL}Payment/AddHoldingOrderForProductOrService`,
        JSON.stringify(data),
        {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',                
            },
        }).then((result) => {
            console.log("save-res", result)
            if (result.status === 200) {
                makeBooking()
            }
        }).catch((err) => {
            console.log(err);
            toast.error('Something went wrong!');
            setIsLoading(false)
        })
    }

    const handleUploadICS = (event, fileName, appID) => {
        const icsData = createICSData(event);
        downloadICSFile(icsData, fileName, appID, event);
      };

      const createICSData = (event) => {
        const { title, description, startTime, endTime, location, email } = event;
        const startDate = formatICSDate(new Date(startTime));
        const endDate = formatICSDate(new Date(endTime));
    
        return [
          'BEGIN:VCALENDAR',
          'VERSION:2.0',
          'BEGIN:VEVENT',
          `UID:${createUUID()}`,
          `SUMMARY:${title}`,
          `DESCRIPTION:${description}`,
          `DTSTART:${startDate}`,
          `DTEND:${endDate}`,
          `LOCATION:${location}`,
          `ORGANIZER:mailto:${email}`,
          'END:VEVENT',
          'END:VCALENDAR',
        ].join('\n');
      };

      const formatICSDate = (date) => {
        return date.toISOString().replace(/-|:|\.\d{3}/g, '') + 'Z';
      };
    
      const createUUID = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
          const r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        });
      };

      const downloadICSFile = (icsData, fileName, appID, event) => {
        const blob = new Blob([icsData], { type: 'text/calendar' });
        const formData = new FormData();
        formData.append("folderpath", "email_attachments/ics_files");
        formData.append("method", "N");
        formData.append("deletingFileName", "-");
        formData.append("renameFileNames", "0");
        
        const blobname = fileName; 
        const renamedFile = new File([blob], `${blobname}.ics`, { type: blob.type });
        formData.append("files", renamedFile);
        
        axios.post(`${process.env.REACT_APP_API_URL}FileUpload/UploadFile`, formData)
          .then((response) => {
            console.log("Upload-Res", response.data.files[0]);
                        //Send Out Message
                    let sendFiles = [
                        {
                          "messageID": 1,
                          "fileName": response.data.files[0]?.renamedFileName+".ics",
                          "filePath": `${process.env.REACT_APP_MEDIA_URL}email_attachments/ics_files/${response.data.files[0]?.renamedFileName}.ics`
                        }
                      ]    
                    sendAppointmentStaticMessage(appID, "Appointment", 1, "E", sendFiles)

                    let fileAttachments = `<p>Click <a href="${process.env.REACT_APP_MEDIA_URL}email_attachments/ics_files/${response.data.files[0]?.renamedFileName}.ics" download="appointment.ics">here</a> to download the ICS file for your appointment.</p>`;
                    let notificationPostBody ={
                        "notificationType": "success",
                        "subject": "Appointment Confirmation Ref#:"+appID,
                        "notificationMessage": event?.description + fileAttachments,
                        "locationID": event?.locationID,
                        "locationName": event?.location,
                        "recipientType": "C",
                        "recipientID": event?.recipientID,
                        "recipientName": event?.recipientName,
                        "isSeen": false
                    }
                    sendOutNotification(notificationPostBody)

          })
          .catch((error) => {
            console.error(error);
          });
      };


    const makeBooking = () => {
        if(props.customerData.customer.customerAddress.length > 0){
            let billingAddress  = props.customerData.customer.customerAddress.filter(x => x.bitIsBilling === true).map((billingAddress, index) => {
                return billingAddress;
            }) 

            let reservationObj = {} as any;

            if(servicePet?.slotInfo.slot.slots.length > 1){
                reservationObj = {
                    "numSchID": 0,
                    "varScheduleType": 0,
                    "numPetID": servicePet?.id,
                    "numSchedulingCustomerID":servicePet?.customerID,
                    "numSchEventID": 0,
                    "dtApptStartTime": require('moment')(servicePet?.slotInfo?.slot?.slots[0]?.start).format('YYYY-MM-DDTHH:mm:ss'),
                    "numApptDuration": 0,
                    "numPrecedingScheduleID": 0,
                    "numPrecedingEventID": 0,
                    "numSucceedingEventID": 0,
                    "numSucceedingSceduleID": 0,
                    "numSchApptStatusID": 0,
                    "bitActive": true,
                    "numLocationID": (servicePet?.selectedLocation === undefined)? servicePet?.preferredLocation : servicePet?.selectedLocation,
                    "dtApptEndTime": require('moment')(servicePet?.slotInfo?.slot?.slots[0]?.end).format('YYYY-MM-DDTHH:mm:ss'),
                    "apptStatus": "SCHEDULING",
                    "numCustomerID": servicePet?.customerID,
                    "numBillToCustomerID": servicePet?.customerID,
                    "varBillToAddress1": billingAddress[0]?.varCustomerAddress1,
                    "varBillToAddress2": billingAddress[0]?.varCustomerAddress2,
                    "varBillToCity": billingAddress[0]?.varCustomerCity,
                    "varBillToState": billingAddress[0]?.varCustomerState,
                    "varBillToZip": billingAddress[0]?.varCustomerZip,
                    "varTerms": "'Due on Receipt'",
                    "numEmployeeID": 1,
                    "varEmployeeName": "Ravin",
                    "schedulingBookings": [
                        {
                            "timeSlotID": servicePet?.slotInfo.slot.slots[2].slotID,
                            "serviceID": servicePet?.additionalService[0].id,
                            "startTime": require('moment')(servicePet?.slotInfo?.slot?.slots[2]?.start).format('YYYY-MM-DDTHH:mm:ss'),
                            "endTime": require('moment')(servicePet?.slotInfo?.slot?.slots[2]?.end).format('YYYY-MM-DDTHH:mm:ss'),
                            "appointmentID": 0,
                            "userID": 1,
                            "IsBather": true
                        },
                        {
                            "timeSlotID": servicePet?.slotInfo.slot.slots[1].slotID,
                            "serviceID": servicePet?.additionalService[0].id,
                            "startTime": require('moment')(servicePet?.slotInfo?.slot?.slots[1]?.start).format('YYYY-MM-DDTHH:mm:ss'),
                            "endTime": require('moment')(servicePet?.slotInfo?.slot?.slots[1]?.end).format('YYYY-MM-DDTHH:mm:ss'),
                            "appointmentID": 0,
                            "userID": 1,
                            "IsBather": false
                        }

                      ]
                  }

                  let slotSentAxiosObj = {
                    "schedulingBookings": [
                        {
                            "timeSlotID": servicePet?.slotInfo.slot.slots[2].slotID,
                            "serviceID": servicePet?.additionalService[0].id,
                            "startTime": require('moment')(servicePet?.slotInfo?.slot?.slots[2]?.start).format('YYYY-MM-DDTHH:mm:ss'),
                            "endTime": require('moment')(servicePet?.slotInfo?.slot?.slots[2]?.end).format('YYYY-MM-DDTHH:mm:ss'),
                            "appointmentID": 0,
                            "userID": 1,
                            "IsBather": true
                        },
                        {
                            "timeSlotID": servicePet?.slotInfo.slot.slots[1].slotID,
                            "serviceID": servicePet?.additionalService[0].id,
                            "startTime": require('moment')(servicePet?.slotInfo?.slot?.slots[1]?.start).format('YYYY-MM-DDTHH:mm:ss'),
                            "endTime": require('moment')(servicePet?.slotInfo?.slot?.slots[1]?.end).format('YYYY-MM-DDTHH:mm:ss'),
                            "appointmentID": 0,
                            "userID": 1,
                            "IsBather": false
                        }

                      ]
                  }
            }else{
                reservationObj = {
                    "numSchID": 0,
                    "varScheduleType": 0,
                    "numPetID": servicePet?.id,
                    "numSchedulingCustomerID":servicePet?.customerID,
                    "numSchEventID": 0,
                    "dtApptStartTime": require('moment')(servicePet?.slotInfo?.slot?.slots[0]?.start).format('YYYY-MM-DDTHH:mm:ss'),
                    "numApptDuration": 0,
                    "numPrecedingScheduleID": 0,
                    "numPrecedingEventID": 0,
                    "numSucceedingEventID": 0,
                    "numSucceedingSceduleID": 0,
                    "numSchApptStatusID": 0,
                    "bitActive": true,
                    "numLocationID": (servicePet?.selectedLocation === undefined)? servicePet?.preferredLocation : servicePet?.selectedLocation,
                    "dtApptEndTime": require('moment')(servicePet?.slotInfo?.slot?.slots[0]?.end).format('YYYY-MM-DDTHH:mm:ss'),
                    "apptStatus": "SCHEDULING",
                    "numCustomerID": servicePet?.customerID,
                    "numBillToCustomerID": servicePet?.customerID,
                    "varBillToAddress1": billingAddress[0]?.varCustomerAddress1,
                    "varBillToAddress2": billingAddress[0]?.varCustomerAddress2,
                    "varBillToCity": billingAddress[0]?.varCustomerCity,
                    "varBillToState": billingAddress[0]?.varCustomerState,
                    "varBillToZip": billingAddress[0]?.varCustomerZip,
                    "varTerms": "'Due on Receipt'",
                    "numEmployeeID": 1,
                    "varEmployeeName": "Ravin",
                    "schedulingBookings": [
                        {
                          "timeSlotID": servicePet?.slotInfo.slot.slots[0].slotID,
                          "serviceID": servicePet?.additionalService[0].id,
                          "startTime": require('moment')(servicePet?.slotInfo?.slot?.slots[0]?.start).format('YYYY-MM-DDTHH:mm:ss'),
                          "endTime": require('moment')(servicePet?.slotInfo?.slot?.slots[0]?.end).format('YYYY-MM-DDTHH:mm:ss'),
                          "appointmentID": 0,
                          "userID": 1,
                          "IsBather": false
                        }

                        
                      ]
                  }
            }
    
            console.log("sent-res-object-make-res", reservationObj)
              axios.post(`${process.env.REACT_APP_API_URL}Appointment/PopulateAppointment`, reservationObj)
                  .then(function (response) {
                  console.log("app_success", response.data);
                  if(response.status === 200){
                        setIsLoading(false)

                        handleUploadICS({
                            title: 'Appointment Confirmation REF# '+response.data?.outAppointmentID,
                            description: `Appointment successfully scheduled for ${servicePet.name} at our grooming salon ${store.find(item => item.id === servicePet?.preferredLocation)?.title}`,
                            startTime: require('moment')(servicePet?.slotInfo?.slot?.slots[0]?.start).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                            endTime: require('moment')(servicePet?.slotInfo?.slot?.slots[0]?.end).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                            location: store.find(item => item.id === servicePet?.preferredLocation)?.title,
                            email:store.find(item => item.id === servicePet?.preferredLocation)?.varLocationEmail,
                            locationID: servicePet?.preferredLocation,
                            recipientID: props.customerData.customer?.numCustomerID,
                            recipientName: props.customerData.customer?.varCustomerFirstName +" "+props.customerData.customer?.varCustomerLastName,
                          }, `AppointmentConfirmationREF${response.data?.outAppointmentID}`, response.data?.outAppointmentID)


                        // sendAppointmentStaticMessage(response.data?.outAppointmentID, "Appointment", 1, "E")
                        setRequestedDate(moment().format('YYYY-MM-DD'))
                        setRequestedTimeOfDay(9)
                        myRefTimeOFDay.current?.handleClear();
                        setSlotEmployeeList([])
                        setSelectedDate({ empIndex: "", dateIndex: "" })
                        toast.success('Reservation successfully created!');
                        setSlotMsg("")
                        props.closeModal(true)
                        props.isSuccess(true)

                        
                  }
          })
          .catch(function (error) {
          console.log(error);
          setIsLoading(false)
          });
        
        }else{
            toast.success('No billing addresses ware found for the selected customer');
        }

    }

    const [selectedDate, setSelectedDate] = useState({ empIndex: "", dateIndex: "" }) as any
    const [selectedLocation, setSelectedLocation] = useState(0) as any


    let innerSwiper;

    const handleOuterSliderTouchStart = () => {
      if (innerSwiper) {
        innerSwiper.allowTouchMove = false;
      }
    };
  
    const handleOuterSliderTouchEnd = () => {
      if (innerSwiper) {
        innerSwiper.allowTouchMove = true;
      }
    };


    const searchLatAndLngByStreet = (street) => {
        console.log("street", street)
        const geocoder = new google.maps.Geocoder()
        geocoder.geocode({ address: street }, (res: any, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                setLat(JSON.stringify(res[0]?.geometry.location.lat()))
                setLng(JSON.stringify(res[0]?.geometry.location.lng()))
                getDistance(JSON.stringify(res[0]?.geometry.location.lat()), JSON.stringify(res[0]?.geometry.location.lng()))
            }
        })
    }


    function getDistance(lati, long) {
        var address = new google.maps.LatLng(lati, long)
        let arr: any = []

        store.map((location, index) => {
            if (!isNaN(location.latitude) && !isNaN(location.longitude)) {
                var locAddress = new google.maps.LatLng(location.latitude, location.longitude)
                arr.push(locAddress)
            }
        })

        let distanceService = new google.maps.DistanceMatrixService()
        distanceService.getDistanceMatrix(
            {
                origins: [address],
                destinations: arr,
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                drivingOptions: {
                    departureTime: new Date(),
                    trafficModel: google.maps.TrafficModel.BEST_GUESS,
                },
                avoidHighways: false,
                avoidTolls: false,
            },
            function (response, status) {
                if (status !== google.maps.DistanceMatrixStatus.OK) {
                    console.log("Error:", status)
                    setIsLoading(false)
                } else {
                    console.log("ress", response)

                    if (response?.rows[0].elements[0].status === "ZERO_RESULTS") {
                        console.log("errrrrrr")
                        toast.info("Invalid Gps location")
                    } else {
                        response?.rows[0].elements.map((item, index) => {
                            const editLoc = [...store];
                            let miles = item?.distance.value * 0.000621371192;
                            let Display_distance = miles.toFixed(2) + " Miles";
                            editLoc[index].distance = Display_distance;
                            editLoc[index].duration = item?.duration.text;
                            editLoc[index].value = item?.distance.value;
                            setStores(editLoc);
                        
                        });

                        getSlotsMultiple(store.filter(loc => loc.id !== servicePet?.preferredLocation)
                            .sort((a, b) => {
                                // Convert distance strings to numbers for comparison
                                const distanceA = parseFloat(a.distance);
                                const distanceB = parseFloat(b.distance);
                                return distanceA - distanceB;
                            }))
                    }
                }
            }
        )
    }

    return (
        <Modal open={props?.isOpen}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <div style={{ backgroundColor: "#fff", width: "calc(100vw - 50px)", height: "calc(100vh - 50px)", borderRadius: "10px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", height: "-webkit-fill-available", gap: "20px", padding: "20px" }}>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                            <CloseButton onClick={() => props?.closeModal()}></CloseButton>
                        </div>

                        <div className={styles.stepper}>
                            <div style={{display:"flex", flexDirection:"column", gap:"10px", alignItems:"center"}}>
                                <div className={`${styles.step} ${currentStep === 1 ? styles.active : ""}`} onClick={() => setCurrentStep(1)}>
                                    1
                                </div>
                                <div style={{textAlign:"center"}}>
                                    <p style={{fontWeight:"600", margin:"0", fontSize:"17px"}}>Pet</p>
                                </div>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", gap:"10px", alignItems:"center"}}>
                            <div className={`${styles.step} ${currentStep === 2 ? styles.active : ""}`} onClick={() => setCurrentStep(2)}>
                                2
                            </div>
                                <div style={{textAlign:"center"}}>
                                <p style={{fontWeight:"600", margin:"0", fontSize:"17px"}}>Location</p>
                                </div>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", gap:"10px", alignItems:"center"}}>
                            <div className={`${styles.step} ${currentStep === 3 ? styles.active : ""}`} onClick={() => setCurrentStep(3)}>
                                3
                            </div>
                                <div style={{textAlign:"center"}}>
                                <p style={{fontWeight:"600", margin:"0", fontSize:"17px"}}>Services</p>
                                </div>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", gap:"10px", alignItems:"center"}}>
                            <div className={`${styles.step} ${currentStep === 4 ? styles.active : ""}`} onClick={() => setCurrentStep(4)}>
                                4
                            </div>
                                <div style={{textAlign:"center"}}>
                                <p style={{fontWeight:"600", margin:"0", fontSize:"17px"}}>Products</p>
                                </div>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", gap:"10px", alignItems:"center"}}>
                            <div className={`${styles.step} ${currentStep === 5 ? styles.active : ""}`} onClick={() => setCurrentStep(5)}>
                                5
                            </div>
                                <div style={{textAlign:"center"}}>
                                <p style={{fontWeight:"600", margin:"0", fontSize:"17px"}}>Slots</p>
                                </div>
                            </div>
                        </div>

                        <div style={{ flex: "1" }}>
                            {currentStep === 1 ? (
                                <div style={{ borderRadius: "8px", backgroundColor: "#f1f1f1", width: "100%", height: "100%" }}>
                                    <div style={{ padding: "15px" }}>
                                        <Swiper
                                            effect={"coverflow"}
                                            grabCursor={true}
                                            centeredSlides={true}
                                            slidesPerView={2}
                                            coverflowEffect={{
                                                rotate: 60,
                                                stretch: 1,
                                                depth: 100,
                                                modifier: 1,
                                                slideShadows: true,
                                            }}
                                            pagination={true}
                                            modules={[EffectCoverflow, Pagination]}
                                            className="mySwiper"
                                            onSlideChange={(e) => {
                                                setPetInfo(e.activeIndex)
                                            }}
                                            initialSlide={swiperSelectedPet}
                                            nested={true}
                                            onTouchStart={handleOuterSliderTouchStart}
                                            onTouchEnd={handleOuterSliderTouchEnd}
                                        >
                                            {props.customerData.customer?.customerPet?.length > 0
                                                ? props.customerData.customer?.customerPet?.map((pet, index) => {
                                                    
                                                      return (
                                                          <SwiperSlide id={index}>
                                                              <div
                                                                  style={{
                                                                      height: "100%",
                                                                      backgroundColor: "#fff",
                                                                      borderRadius: "8px",
                                                                      padding: "10px",
                                                                      display: "flex",
                                                                      flexDirection: "column",
                                                                      justifyContent: "space-between",
                                                                  }}
                                                              >
                                                                  <div style={{ width: "100%", borderRadius: "8px" }}>
                                                                      {/* <img
                                                                          style={{ height: "250px", objectFit: "cover" }}
                                                                          src={(selectedPastAppointment === "")? `${process.env.REACT_APP_MEDIA_URL}directories/pets/${pet.varPetImagePath}?${uniqueParam}` : pastAppointmentList[selectedPastAppointment]?.numPetID === pet?.numPetID ? `${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${pastAppointmentList[selectedPastAppointment].AfterImagePath}?${uniqueParam}` : `${process.env.REACT_APP_MEDIA_URL}directories/pets/${pet.varPetImagePath}?${uniqueParam}`}
                                                                      ></img> */}

                                                                      {
                                                                        (selectedPastAppointment === "")?
                                                                        <img
                                                                        alt="404"
                                                                            style={{ height: "250px", objectFit: "cover" }}
                                                                            src={`${process.env.REACT_APP_MEDIA_URL}directories/pets/${pet.varPetImagePath}?${uniqueParam}`}
                                                                            ></img> 
                                                                        :

                                                                        pastAppointmentList[selectedPastAppointment]?.numPetID === pet?.numPetID ?
                                                                        (pastAppointmentList[selectedPastAppointment].BeforeImgPath === null && pastAppointmentList[selectedPastAppointment].AfterImagePath === null)? <p style={{color:"gray", textAlign:"center"}}>No images from past appointments</p> : 
                                                                         <ImgComparisonSlider
                                                                        style={{width:"100%"}}
                                                                       
                                                                        
                                                                        >
                                                                            <img style={{ height: "250px", objectFit: "cover" }} alt="404" slot="first" src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${pastAppointmentList[selectedPastAppointment].BeforeImgPath}`} />
                                                                            <img style={{ height: "250px", objectFit: "cover" }} alt="404" slot="second" src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${pastAppointmentList[selectedPastAppointment].AfterImagePath}`} />
                                                                        </ImgComparisonSlider> 
                                                                        : 
                                                                        <img
                                                                        alt="404"
                                                                        style={{ height: "250px", objectFit: "cover" }}
                                                                        src={`${process.env.REACT_APP_MEDIA_URL}directories/pets/${pet.varPetImagePath}?${uniqueParam}`}
                                                                        ></img> 
                                                                      }
                                                                  </div>
                                                                  <p style={{ margin: "10px 0 0 0 ", textAlign: "center", fontWeight: "500", fontSize: "26px" }}>{pet.varPetName}</p>
                                                                  <p style={{ margin: "10px 0 0 0 ", textAlign: "center", fontWeight: "400", fontSize: "18px" }}>{pet.varPetBreed}</p>
                                                              </div>
                                                          </SwiperSlide>
                                                      )
                                                  })
                                                : null}
                                        </Swiper>

                                        <p style={{ fontSize: "25px", margin: "25px 0 5px 0 ", fontWeight: 500, textAlign: "center" }}>Previous Appointments</p>

                                        <div style={{ overflowX: "scroll", width: "calc(100%)", display: "flex", gap: "15px", marginTop: "20px" }}>
                                            {pastAppointmentList.length > 0 ? (
                                                pastAppointmentList.sort((a, b) => b.numAppointmentID - a.numAppointmentID).map((app, index) => {
                                                    return (
                                                        <div
                                                            className={selectedPastAppointment === index ? styles.pre_app_active : styles.pre_app}
                                                            onClick={() => {
                                                                selectPrevAppointment(index)
                                                            }}
                                                        >
                                                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems:"center", backgroundColor:  (app?.IsPreferred)? "#fffee6" : undefined, borderRadius:"8px",  border: (app?.IsPreferred)? "1px solid #fffee6" : undefined, padding:"0px 1px 0px 1px"}}>                                                                    
                                                                <div style={{display:"flex", gap:"2px", alignItems:"center"}}>
                                                                    <p style={{  fontWeight: 600, fontSize: "16px", color: "#1976d2" }}>#{app?.numAppointmentID}</p>
                                                                    {
                                                                        (app?.IsPreferred)?
                                                                        <>
                                                                            <Verified style={{color:"#edb80b"}}/>
                                                                            <p style={{color:"#d3a71a", fontWeight:"600"}}>Your Pick</p>
                                                                        </> :null
                                                                    }
                                                                    
                                                                </div>
                                                                <span style={{ color: "black", fontSize: "14px", fontWeight: "500" }}>({formatToLocal(app?.dtApptStartTime)})</span>
                                                            </div>

                                                            <p style={{ margin: "0 ", fontSize: "20px" }}>
                                                                <b>Location: </b>
                                                                {Truncate(app.varLocationName, 22)}
                                                            </p>
                                                            <p style={{ margin: "0 ", fontSize: "20px" }}>
                                                                <b>Groomer: </b>
                                                                {app.ServicesList[0].varEmployeeFirstName + " " + app.ServicesList[0].varEmployeeLastName}
                                                            </p>
                                                            <div style={{ overflowX: "hidden", overflowY: "hidden", width: "calc(100%)", display: "flex", gap: "5px", marginTop: "10px" }}>
                                                                {app.ServicesList.map((service, appIndex) => (
                                                                    <div key={appIndex} style={{ cursor: "pointer" }}>
                                                                        <div
                                                                            style={{
                                                                                backgroundColor: "#DCFCE7",
                                                                                border: "1px solid #86EFAC",
                                                                                borderRadius: "16px",
                                                                                color: "#14532D",
                                                                                fontSize: "14px",
                                                                                fontWeight: "500",
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                alignItems: "center",
                                                                                padding: "0 10px 0 10px",
                                                                                gap: ".2rem",
                                                                                width: "max-content",
                                                                            }}
                                                                        >
                                                                            {Truncate(service.varServiceItemName, 20)}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>

                                                            <div style={{ overflowX: "hidden", overflowY: "hidden", width: "calc(100%)", display: "flex", gap: "5px", marginTop: "10px" }}>
                                                                {app.ProductsList.map((product, proIndex) => (
                                                                    <div key={proIndex} style={{ cursor: "pointer" }}>
                                                                        <div
                                                                            style={{
                                                                                backgroundColor: "#DCFCE7",
                                                                                border: "1px solid #86EFAC",
                                                                                borderRadius: "16px",
                                                                                color: "#14532D",
                                                                                fontSize: "14px",
                                                                                fontWeight: "500",
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                alignItems: "center",
                                                                                padding: "0 10px 0 10px",
                                                                                gap: ".2rem",
                                                                                width: "max-content",
                                                                            }}
                                                                        >
                                                                            {Truncate(product.varProductName, 15)} <span style={{ margin: "0", borderRight: "1px solid #86efac" }}>&nbsp;</span>{" "}
                                                                            {product.numQuantity}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            ) : (
                                                <p style={{ color: "#777E90", fontWeight: "400", fontSize: "13px" }}>No previous appointments</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : currentStep === 2 ? (
                                <div style={{ borderRadius: "8px", backgroundColor: "#f1f1f1", width: "100%", height: "100%" }}>
                                    <div style={{ padding: "15px" }}>
                                        <BookingLocation
                                            petInfo={servicePet}
                                            bookingLocation={(e) => {
                                                onLocationChange(e)
                                            }}
                                            locationList={(e)=>{console.log("locationList-ALL", e)}}
                                        />
                                    </div>
                                </div>
                            ) : currentStep === 3 ? (
                                <div style={{ borderRadius: "8px", backgroundColor: "#f1f1f1", width: "100%", height: "100%" }}>
                                    <div style={{ padding: "15px" }}>
                                        <div style={{ overflowY: "scroll", height: "650px", paddingRight: "5px" }}>
                                            {isLoading ? (
                                                <p>Loading...</p>
                                            ) : additionalServiceList.length > 0 ? (
                                                additionalServiceList
                                                    .sort((a, b) => {
                                                        if (a.bitIsAdditional === false && b.bitIsAdditional === true) {
                                                            return -1
                                                        } else if (a.bitIsAdditional === true && b.bitIsAdditional === false) {
                                                            return 1
                                                        } else {
                                                            return 0
                                                        }
                                                    })
                                                    .map((service, index) => {
                                                        let matching
                                                        if (servicePet !== undefined) {
                                                            matching = servicePet.additionalService.some((x) => x.id === service.numServiceItemID)
                                                        }
                                                        return (
                                                            <>
                                                                {service.numServiceItemPreviousSalesPrice === 0 ? (
                                                                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                            <Checkbox 
                                                                                style={{ padding: '0' }}
                                                                                key={index} 
                                                                                checked={(matching) ? true : false} 
                                                                                onClick={() => { selectAdditionalService(index) }}
                                                                            /> 
                                                                        <div
                                                                            style={{ width: "100%" }}
                                                                            className={matching ? styles.main_service_accordion_active : styles.main_service_accordion}
                                                                            onClick={() => {
                                                                                selectAdditionalService(index)
                                                                            }} //selectAdditionalService(index)
                                                                        >
                                                                            <div>
                                                                                <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                                    {service.bitIsAdditional === false ? (
                                                                                        <Tooltip
                                                                                            title={
                                                                                                <>
                                                                                                    <div style={{ padding: "5px" }}>
                                                                                                        <p style={{ fontSize: "14px", color: "white", fontWeight: "600", marginBottom: "5px" }}></p>
                                                                                                        <p style={{ fontSize: "14px", color: "white", fontWeight: "600" }}>
                                                                                                            {service.varServiceItemName}
                                                                                                        </p>
                                                                                                        <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                                                                                                            {service.SubServices.length > 0
                                                                                                                ? service.SubServices.map((sub, index) => {
                                                                                                                      return (
                                                                                                                          <p
                                                                                                                              key={index}
                                                                                                                              style={{ whiteSpace: "pre-wrap", fontWeight: "500", color: "white" }}
                                                                                                                          >
                                                                                                                              - {sub.SubServiceName}
                                                                                                                          </p>
                                                                                                                      )
                                                                                                                  })
                                                                                                                : null}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            }
                                                                                        >
                                                                                            <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(M)</p>
                                                                                        </Tooltip>
                                                                                    ) : (
                                                                                        <Tooltip
                                                                                            style={{ paddingLeft: "5px" }}
                                                                                            title={
                                                                                                <>
                                                                                                    <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Additional Service</p>
                                                                                                    <p style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>{service.varServiceItemName}</p>
                                                                                                </>
                                                                                            }
                                                                                        >
                                                                                            <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(A)</p>
                                                                                        </Tooltip>
                                                                                    )}
                                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>{service.varServiceItemName}</p>
                                                                                </div>
                                                                                <p style={{ color: "#4A5163", fontWeight: "400", fontSize: "12px", margin: "0", lineHeight: "1.25em" }}>
                                                                                    {service.varServiceItemDescription}

                                                                                    {service.varPriceChangeComment !== null ? <span> | {service.varPriceChangeComment}</span> : null}
                                                                                </p>
                                                                            </div>

                                                                            <div style={{ borderRadius: "4px", padding: "0 5px 0 5px", backgroundColor: "#EFF5FF" }}>
                                                                                <p
                                                                                    style={{
                                                                                        color: "#2630B9",
                                                                                        fontSize: "16px",
                                                                                        fontWeight: "400",
                                                                                        margin: "0",
                                                                                        padding: "0 5px",
                                                                                        height: "100%",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        whiteSpace: "nowrap",
                                                                                    }}
                                                                                >
                                                                                    <b>${service.numServiceItemSalesPrice}</b>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : service.numServiceItemPreviousSalesPrice === service.numServiceItemSalesPrice ? (
                                                                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                            <Checkbox 
                                                                                style={{ padding: '0' }}
                                                                                key={index} 
                                                                                checked={(matching) ? true : false} 
                                                                                onClick={() => { selectAdditionalService(index) }}
                                                                            /> 
                                                                        <div
                                                                            style={{ width: "100%" }}
                                                                            className={matching ? styles.main_service_accordion_active : styles.main_service_accordion}
                                                                            onClick={() => {
                                                                                selectAdditionalService(index)
                                                                            }} //selectAdditionalService(index)
                                                                        >
                                                                            <div>
                                                                                <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                                    {service.bitIsAdditional === false ? (
                                                                                        <Tooltip
                                                                                            title={
                                                                                                <>
                                                                                                    <div style={{ padding: "5px" }}>
                                                                                                        <p style={{ fontSize: "14px", color: "white", fontWeight: "600", marginBottom: "5px" }}></p>
                                                                                                        <p style={{ fontSize: "14px", color: "white", fontWeight: "600" }}>
                                                                                                            {service.varServiceItemName}
                                                                                                        </p>
                                                                                                        <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                                                                                                            {service.SubServices.length > 0
                                                                                                                ? service.SubServices.map((sub, index) => {
                                                                                                                      return (
                                                                                                                          <p
                                                                                                                              key={index}
                                                                                                                              style={{ whiteSpace: "pre-wrap", fontWeight: "500", color: "white" }}
                                                                                                                          >
                                                                                                                              - {sub.SubServiceName}
                                                                                                                          </p>
                                                                                                                      )
                                                                                                                  })
                                                                                                                : null}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            }
                                                                                        >
                                                                                            <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(M)</p>
                                                                                        </Tooltip>
                                                                                    ) : (
                                                                                        <Tooltip
                                                                                            style={{ paddingLeft: "5px" }}
                                                                                            title={
                                                                                                <>
                                                                                                    <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Additional Service</p>
                                                                                                    <p style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>{service.varServiceItemName}</p>
                                                                                                </>
                                                                                            }
                                                                                        >
                                                                                            <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(A)</p>
                                                                                        </Tooltip>
                                                                                    )}
                                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>{service.varServiceItemName}</p>
                                                                                </div>
                                                                                <p style={{ color: "#4A5163", fontWeight: "400", fontSize: "12px", margin: "0", lineHeight: "1.25em" }}>
                                                                                    {service.varServiceItemDescription}
                                                                                    {service.varPriceChangeComment !== null ? <span> | {service.varPriceChangeComment}</span> : null}
                                                                                </p>
                                                                            </div>

                                                                            <div style={{ borderRadius: "4px", padding: "0 5px 0 5px", backgroundColor: "#EFF5FF" }}>
                                                                                <p
                                                                                    style={{
                                                                                        color: "#2630B9",
                                                                                        fontSize: "16px",
                                                                                        fontWeight: "400",
                                                                                        margin: "0",
                                                                                        padding: "0 5px",
                                                                                        height: "100%",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        whiteSpace: "nowrap",
                                                                                    }}
                                                                                >
                                                                                    <b>${service.numServiceItemSalesPrice}</b>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : service.numServiceItemSalesPrice > service.numServiceItemPreviousSalesPrice ? (
                                                                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                            <Checkbox 
                                                                                style={{ padding: '0' }}
                                                                                key={index} 
                                                                                checked={(matching) ? true : false} 
                                                                                onClick={() => { selectAdditionalService(index) }}
                                                                            /> 
                                                                        <div
                                                                            style={{ backgroundColor: "#FEE8E7", width: "100%", display: "flex", flexDirection: "column" }}
                                                                            className={matching ? styles.main_service_accordion_active : styles.main_service_accordion}
                                                                            onClick={() => {
                                                                                selectAdditionalService(index)
                                                                            }} //selectAdditionalService(index)
                                                                        >
                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                <div>
                                                                                    <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                                        {service.bitIsAdditional === false ? (
                                                                                            <Tooltip
                                                                                                title={
                                                                                                    <>
                                                                                                        <div style={{ padding: "5px" }}>
                                                                                                            <p style={{ fontSize: "14px", color: "white", fontWeight: "600", marginBottom: "5px" }}></p>
                                                                                                            <p style={{ fontSize: "14px", color: "white", fontWeight: "600" }}>
                                                                                                                {service.varServiceItemName}
                                                                                                            </p>
                                                                                                            <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                                                                                                                {service.SubServices.length > 0
                                                                                                                    ? service.SubServices.map((sub, index) => {
                                                                                                                          return (
                                                                                                                              <p
                                                                                                                                  key={index}
                                                                                                                                  style={{
                                                                                                                                      whiteSpace: "pre-wrap",
                                                                                                                                      fontWeight: "500",
                                                                                                                                      color: "white",
                                                                                                                                      margin: "0",
                                                                                                                                  }}
                                                                                                                              >
                                                                                                                                  {sub.SubServiceName}
                                                                                                                              </p>
                                                                                                                          )
                                                                                                                      })
                                                                                                                    : null}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                            >
                                                                                                <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(M)</p>
                                                                                            </Tooltip>
                                                                                        ) : (
                                                                                            <Tooltip
                                                                                                style={{ paddingLeft: "5px" }}
                                                                                                title={
                                                                                                    <>
                                                                                                        <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Additional Service</p>
                                                                                                        <p style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>
                                                                                                            {service.varServiceItemName}
                                                                                                        </p>
                                                                                                    </>
                                                                                                }
                                                                                            >
                                                                                                <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(A)</p>
                                                                                            </Tooltip>
                                                                                        )}
                                                                                        <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>
                                                                                            {service.varServiceItemName}
                                                                                        </p>
                                                                                    </div>
                                                                                    <p style={{ color: "#4A5163", fontWeight: "400", fontSize: "12px", margin: "0", lineHeight: "1.25em" }}>
                                                                                        {service.varServiceItemDescription}
                                                                                        {service.varPriceChangeComment !== null ? <span> | {service.varPriceChangeComment}</span> : null}
                                                                                    </p>
                                                                                </div>

                                                                                <div style={{ display: "flex", gap: "5px" }}>
                                                                                    <div style={{ borderRadius: "4px", padding: "0 5px 0 5px", backgroundColor: "#FCDADA" }}>
                                                                                        <p
                                                                                            style={{
                                                                                                color: "#802B2B",
                                                                                                fontSize: "16px",
                                                                                                fontWeight: "400",
                                                                                                margin: "0",
                                                                                                padding: "0 5px",
                                                                                                height: "100%",
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                                whiteSpace: "nowrap",
                                                                                            }}
                                                                                        >
                                                                                            <ArrowUpward style={{ color: "#EB1A1A", fontSize: "18px" }} />
                                                                                        </p>
                                                                                    </div>

                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            minWidth: "110px",
                                                                                            border: "1px solid #F9B5B5",
                                                                                            borderRadius: "4px",
                                                                                            padding: "0 5px 0 5px",
                                                                                            backgroundColor: "#FCDADA",
                                                                                        }}
                                                                                    >
                                                                                        <Tooltip
                                                                                            style={{ paddingLeft: "5px" }}
                                                                                            title={
                                                                                                <>
                                                                                                    <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Price Change Comment</p>
                                                                                                    <p style={{ fontSize: "12px", color: "#fff" }}>{service.varPriceChangeComment}</p>
                                                                                                </>
                                                                                            }
                                                                                        >
                                                                                            <InfoOutlined style={{ fontSize: "18px", color: "#802B2B" }} />
                                                                                        </Tooltip>

                                                                                        <p
                                                                                            style={{
                                                                                                color: "#802B2B",
                                                                                                fontSize: "16px",
                                                                                                fontWeight: "400",
                                                                                                margin: "0",
                                                                                                padding: "0 5px",
                                                                                                height: "100%",
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                                whiteSpace: "nowrap",
                                                                                                justifyContent: "center",
                                                                                            }}
                                                                                        >
                                                                                            <del>${service.numServiceItemPreviousSalesPrice}</del>&nbsp;/&nbsp;
                                                                                            <b>${service.numServiceItemSalesPrice}</b>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {service.varSeasonalPromotionMsg !== "" ? (
                                                                                <p
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        gap: "5px",
                                                                                        alignItems: "center",
                                                                                        padding: "4px",
                                                                                        borderRadius: "5px",
                                                                                        backgroundColor: "#fff",
                                                                                        color: "#16A34A",
                                                                                        fontWeight: 500,
                                                                                        fontSize: "12px",
                                                                                        margin: "0px",
                                                                                        lineHeight: "1.25em",
                                                                                    }}
                                                                                >
                                                                                    <LocalOffer style={{ fontSize: "18px" }} /> {service.varSeasonalPromotionMsg}
                                                                                </p>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                            <Checkbox 
                                                                                style={{ padding: '0' }}
                                                                                key={index} 
                                                                                checked={(matching) ? true : false} 
                                                                                onClick={() => { selectAdditionalService(index) }}
                                                                            /> 
                                                                        <div
                                                                            style={{ backgroundColor: "#F0FDF4", width: "100%" }}
                                                                            className={matching ? styles.main_service_accordion_active : styles.main_service_accordion}
                                                                            onClick={() => {
                                                                                selectAdditionalService(index)
                                                                            }} //selectAdditionalService(index)
                                                                        >
                                                                            <div>
                                                                                <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                                    {service.bitIsAdditional === false ? (
                                                                                        <Tooltip
                                                                                            title={
                                                                                                <>
                                                                                                    <div style={{ padding: "5px" }}>
                                                                                                        <p style={{ fontSize: "14px", color: "white", fontWeight: "600", marginBottom: "5px" }}></p>
                                                                                                        <p style={{ fontSize: "14px", color: "white", fontWeight: "600" }}>
                                                                                                            {service.varServiceItemName}
                                                                                                        </p>
                                                                                                        <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                                                                                                            {service.SubServices.length > 0
                                                                                                                ? service.SubServices.map((sub, index) => {
                                                                                                                      return (
                                                                                                                          <p
                                                                                                                              key={index}
                                                                                                                              style={{ whiteSpace: "pre-wrap", fontWeight: "500", color: "white" }}
                                                                                                                          >
                                                                                                                              - {sub.SubServiceName}
                                                                                                                          </p>
                                                                                                                      )
                                                                                                                  })
                                                                                                                : null}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            }
                                                                                        >
                                                                                            <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(M)</p>
                                                                                        </Tooltip>
                                                                                    ) : (
                                                                                        <Tooltip
                                                                                            style={{ paddingLeft: "5px" }}
                                                                                            title={
                                                                                                <>
                                                                                                    <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Additional Service</p>
                                                                                                    <p style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>{service.varServiceItemName}</p>
                                                                                                </>
                                                                                            }
                                                                                        >
                                                                                            <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(A)</p>
                                                                                        </Tooltip>
                                                                                    )}
                                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>{service.varServiceItemName}</p>
                                                                                </div>
                                                                                <p style={{ color: "#4A5163", fontWeight: "400", fontSize: "12px", margin: "0", lineHeight: "1.25em" }}>
                                                                                    {service.varServiceItemDescription}
                                                                                    {service.varPriceChangeComment !== null ? <span> | {service.varPriceChangeComment}</span> : null}
                                                                                </p>
                                                                            </div>

                                                                            <div style={{ display: "flex", gap: "5px" }}>
                                                                                <div style={{ borderRadius: "4px", padding: "0 5px 0 5px", backgroundColor: "#DCFCE7" }}>
                                                                                    <p
                                                                                        style={{
                                                                                            color: "#DCFCE7",
                                                                                            fontSize: "16px",
                                                                                            fontWeight: "400",
                                                                                            margin: "0",
                                                                                            padding: "0 5px",
                                                                                            height: "100%",
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            whiteSpace: "nowrap",
                                                                                        }}
                                                                                    >
                                                                                        <ArrowDownward style={{ color: "#16A34A", fontSize: "18px" }} />
                                                                                    </p>
                                                                                </div>

                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        minWidth: "120px",
                                                                                        border: "1px solid #BBF7D0",
                                                                                        borderRadius: "4px",
                                                                                        padding: "0 5px 0 5px",
                                                                                        backgroundColor: "#DCFCE7",
                                                                                    }}
                                                                                >
                                                                                    <Tooltip
                                                                                        style={{ paddingLeft: "5px" }}
                                                                                        title={
                                                                                            <>
                                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Price Change Comment</p>
                                                                                                <p style={{ fontSize: "12px", color: "#fff" }}>{service.varPriceChangeComment}</p>
                                                                                            </>
                                                                                        }
                                                                                    >
                                                                                        <InfoOutlined style={{ fontSize: "18px", color: "#15803D" }} />
                                                                                    </Tooltip>
                                                                                    <p
                                                                                        style={{
                                                                                            color: "#15803D",
                                                                                            fontSize: "16px",
                                                                                            fontWeight: "400",
                                                                                            margin: "0",
                                                                                            padding: "0 5px",
                                                                                            height: "100%",
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            whiteSpace: "nowrap",
                                                                                            justifyContent: "center",
                                                                                        }}
                                                                                    >
                                                                                        <del>${service.numServiceItemPreviousSalesPrice}</del>&nbsp;/&nbsp;<b>${service.numServiceItemSalesPrice}</b>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )
                                                    })
                                            ) : (
                                                <p style={{ color: "#777E90", fontWeight: "400", fontSize: "13px" }}>No services found</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : currentStep === 4 ? (
                                <div style={{ borderRadius: "8px", backgroundColor: "#f1f1f1", width: "100%", height: "100%" }}>
                                    <div style={{ padding: "15px" }}>
                                        <Box>
                                            <Box>
                                                <Box>
                                                    <Tabs value={productTabIndex} onChange={handleProductTabChange}>
                                                        <Tab label="Products" style={{ padding: "0" }} />
                                                        <Tab
                                                            style={{ padding: "0" }}
                                                            label="Cart"
                                                            icon={<span style={{ color: "#FFFFFF", background: "rgb(37 119 248)", borderRadius: "24px", padding: "2px 10px" }}>{cartItemCount}</span>}
                                                            iconPosition={"end"}
                                                        />
                                                    </Tabs>
                                                </Box>
                                                {productTabIndex === 0 && (
                                                    <div style={{ width: "100%" }}>
                                                        <div style={{ overflowY: "scroll", width: "100%", height: "580px" }}>
                                                            {isLoading ? (
                                                                <p>Loading...</p>
                                                            ) : productList.length > 0 ? (
                                                                productList.map((product, index) => {
                                                                    const productImageSrc = product?.varProductBarCode 
                                                                                ? `${process.env.REACT_APP_MEDIA_URL}products/${product.varProductBarCode}.png`
                                                                                : undefined;
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            style={{
                                                                                borderRadius: "8px",
                                                                                border: "1px solid #EFF3FA",
                                                                                background: "#FFFFFF",
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                padding: "10px",
                                                                                marginBottom: "5px",
                                                                            }}
                                                                        >
                                                                            <div style={{display:"flex", gap:"10px"}}>
                                                                            <div style={{ border: "1px solid #EFF3FA", borderRadius: "6px" }}>
                                                                                <img
                                                                                    alt=""
                                                                                    src={productImageSrc}
                                                                                    style={{
                                                                                        objectFit: "scale-down",
                                                                                        borderRadius: "5px",
                                                                                        width: "110px",
                                                                                        height: "110px",
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                                <div style={{ display: "flex", gap: "10px" }}>
                                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                                                            <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600", margin: 0 }}>{product.varProductName}</p>
                                                                                            <Tooltip
                                                                                                style={{ paddingLeft: "5px" }}
                                                                                                title={
                                                                                                    <>
                                                                                                        <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Product Description</p>
                                                                                                        <p style={{ fontSize: "12px", color: "#fff" }}>{product.varProductDescription}</p>
                                                                                                    </>
                                                                                                }
                                                                                            >
                                                                                                <InfoOutlined style={{ fontSize: "18px", color: "#606D93" }} />
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                            <div>
                                                                                                {
                                                                                                    (product?.numLocationSpecificAdjustmentPercent !== null && product?.numLocationSpecificAdjustmentPercent !== "" && product?.numLocationSpecificAdjustmentPercent !== 0) ||
                                                                                                    (product?.numLocationSpecificAdjustmentAmount !== null && product?.numLocationSpecificAdjustmentAmount !== "" && product?.numLocationSpecificAdjustmentAmount !== 0) ? (
                                                                                                        <p style={{color:"#1c8316", fontSize:"12px", fontWeight:"500", margin:"0"}}>
                                                                                                            {product?.numLocationSpecificAdjustmentPercent !== null && product?.numLocationSpecificAdjustmentPercent !== "" && product?.numLocationSpecificAdjustmentPercent !== 0 ? `Discount percentage: ${product?.numLocationSpecificAdjustmentPercent}% ` : null}
                                                                                                            {product?.numLocationSpecificAdjustmentAmount !== null && product?.numLocationSpecificAdjustmentAmount !== "" && product?.numLocationSpecificAdjustmentAmount !== 0 ? `Discount amount: ${product?.numLocationSpecificAdjustmentAmount}$` : null}
                                                                                                        </p>
                                                                                                    ) : null
                                                                                                }    
                                                                                            </div>
                                                                                        <div style={{ display: "flex", gap: "10px" }}>
                                                                                            <p style={{ color: "#606D93", fontSize: "12px", fontWeight: "400", margin: 0 }}>
                                                                                                Available Quantity: {product.TempAvailableQuantity}
                                                                                            </p>
                                                                                            <p style={{ color: "#606D93", fontSize: "12px", fontWeight: "400", margin: 0 }}>
                                                                                                Unit Price: {product.UnityItemPrice}$
                                                                                            </p>
                                                                                            {product.isAddedToCart === true ? (
                                                                                                <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
                                                                                                    <ShoppingCart style={{ color: "#00a340", fontSize: "15px" }} />
                                                                                                    <p style={{ color: "#00a340", margin: "0", fontSize: "12px", fontWeight: "500" }}>Added to cart</p>
                                                                                                </div>
                                                                                            ) : null}
                                                                                        </div>

                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                                alignItems: "center",
                                                                                                width: "fit-content",
                                                                                                gap: "10px",
                                                                                                marginTop: "1px",
                                                                                            }}
                                                                                        >
                                                                                            <IconButton
                                                                                                onClick={() => {
                                                                                                    decrementQty(product.numLocationProductID, index)
                                                                                                }}
                                                                                            >
                                                                                                <RemoveCircleOutline className={styles.products_add_remove_button} />
                                                                                            </IconButton>
                                                                                            <p style={{ fontSize: "14px", fontWeight: "600", color: "#192252", margin: 0 }}>{product.SelectedQuantity}</p>
                                                                                            <IconButton
                                                                                                onClick={() => {
                                                                                                    incrementQty(product.numLocationProductID, index)
                                                                                                }}
                                                                                            >
                                                                                                <AddCircleOutline className={styles.products_add_remove_button} />
                                                                                            </IconButton>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                     
                                                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                                <p style={{ color: "#192252", fontSize: "18px", fontWeight: "700", textAlign: "right", margin: 0 }}>
                                                                                    $
                                                                                    {product.SelectedQuantity > 0
                                                                                        ? Math.round(product.numProductSalesPrice * product.SelectedQuantity * 100) / 100
                                                                                        : product.numProductSalesPrice}
                                                                                </p>
                                                                                <MUIButton
                                                                                    variant="outlined"
                                                                                    disabled={product.SelectedQuantity < 1 ? true : false}
                                                                                    children={
                                                                                        <ShoppingCart
                                                                                            style={
                                                                                                product.SelectedQuantity < 1
                                                                                                    ? { color: "#9097a9", padding: "5px" }
                                                                                                    : { color: "#2076ff", padding: "5px" }
                                                                                            }
                                                                                        />
                                                                                    }
                                                                                    onClick={() => {
                                                                                        addToCart(product.numLocationProductID, index)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            ) : (
                                                                <p style={{ color: "#777E90", fontWeight: "400", fontSize: "13px" }}>No products found</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}

                                                {productTabIndex === 1 && (
                                                    <div style={{ width: "100%" }}>
                                                        <div style={{ overflowY: "scroll", width: "100%", minHeight: "500px", maxHeight: "500px" }}>
                                                            {isLoading ? (
                                                                <p>Loading...</p>
                                                            ) : servicePet !== undefined ? (
                                                                servicePet.id !== undefined ? (
                                                                    servicePet.selectedProducts
                                                                        .filter((x) => x.bitActive === true)
                                                                        .map((product, index) => {
                                                                            return (
                                                                                <div
                                                                                    key={index}
                                                                                    style={{
                                                                                        borderRadius: "8px",
                                                                                        border: "1px solid #EFF3FA",
                                                                                        background: "#FFFFFF",
                                                                                        display: "flex",
                                                                                        justifyContent: "space-between",
                                                                                        padding: "10px",
                                                                                        marginBottom: "5px",
                                                                                    }}
                                                                                >
                                                                                    <div style={{ display: "flex", gap: "15px" }}>
                                                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                                                                <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600", margin: 0 }}>
                                                                                                    {product.varProductName}
                                                                                                </p>
                                                                                                <Tooltip
                                                                                                    style={{ paddingLeft: "5px" }}
                                                                                                    title={
                                                                                                        <>
                                                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Product Description</p>
                                                                                                            <p style={{ fontSize: "12px", color: "#fff" }}>{product.varProductDescription}</p>
                                                                                                        </>
                                                                                                    }
                                                                                                >
                                                                                                    <InfoOutlined style={{ fontSize: "18px", color: "#606D93" }} />
                                                                                                </Tooltip>
                                                                                            </div>                                               
                                                                                            <div style={{ color: "#606D93", fontSize: "12px", fontWeight: "400" }}>
                                                                                                {product.varSalesCommissionMessage} {product.varSeasonalPromotionMsg}
                                                                                            </div>
                                                                                            <div
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    justifyContent: "space-between",
                                                                                                    alignItems: "center",
                                                                                                    width: "fit-content",
                                                                                                    gap: "10px",
                                                                                                    marginTop: "15px",
                                                                                                }}
                                                                                            >
                                                                                                <IconButton
                                                                                                    onClick={() => {
                                                                                                        decrementCartQty(product.numLocationProductID, index)
                                                                                                    }}
                                                                                                >
                                                                                                    <RemoveCircleOutline className={styles.products_add_remove_button} />
                                                                                                </IconButton>
                                                                                                <p style={{ fontSize: "14px", fontWeight: "600", color: "#192252", margin: 0 }}>
                                                                                                    {product.numQuantity}
                                                                                                </p>
                                                                                                <IconButton
                                                                                                    onClick={() => {
                                                                                                        incrementCartQty(product.numLocationProductID, index)
                                                                                                    }}
                                                                                                >
                                                                                                    <AddCircleOutline className={styles.products_add_remove_button} />
                                                                                                </IconButton>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                                        <p style={{ color: "#192252", fontSize: "18px", fontWeight: "700", textAlign: "right", margin: 0 }}>
                                                                                            ${Math.round(product.numProductSalesPrice * product.numQuantity * 100) / 100}
                                                                                        </p>
                                                                                        <MUIButton
                                                                                            variant="outlined"
                                                                                            children={<Delete style={{ color: "#2076ff", padding: "5px" }} />}
                                                                                            onClick={() => {
                                                                                                removeFromCart(product.numLocationProductID, index)
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                ) : (
                                                                    ""
                                                                )
                                                            ) : (
                                                                <p>Cart is empty</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </Box>
                                        </Box>
                                    </div>
                                </div>
                            ) : currentStep === 5 ? (
                                <div style={{ borderRadius: "8px", backgroundColor: "#f1f1f1", width: "100%", height: "100%" }}>
                                    <div style={{ padding: "15px" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", gap: "5px", marginBottom:"10px" }}>
                                            <TextField
                                                size="medium"
                                                fullWidth
                                                required={true}
                                                type="date"
                                                value={requestedDate}
                                                onChange={(e) => {
                                                    setRequestedDate(e.target.value);
                                                }}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />

                                            <div style={{ width: "100%" }}>
                                                {timeOfDayList !== undefined && timeOfDayList !== null ? (
                                                    <Input
                                                    value={requestedTimeOfDay}
                                                    size={"medium"}
                                                    outline={true}
                                                    variant={"outlined"}
                                                    label={"Select Time Of Day"}
                                                    items={
                                                        timeOfDayList.map((i) => ({ text: i.varTimeOfDay, value: i.numID }))
                                                    }
                                                    onChange={(e) => {
                                                        setRequestedTimeOfDay(e)
                                                   
                                                    }}
                                                />
                                                ) : null}
                                            </div>
                                            <Button
                                                size="medium"
                                                variant="contained"
                                                onClick={() => {
                                                    getSlots()
                                                }}
                                            >
                                                <Search />
                                            </Button>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", gap: "15px", overflowY: "scroll", height: "450px" }}>
                                            
                                            {(isLoading)?  <p style={{ color: "#777E90", fontWeight: "400", fontSize: "13px" }}>Loading...</p> : (slotEmployeeList.length > 0)? slotEmployeeList?.map((empl, index) => {
                                               return (
                                                    <div>

                                                        <div style={{display:"flex", gap:"10px"}}>
                                                            <p style={{background: "#ffffff",width: "fit-content",padding: "10px",borderRadius: "10px",color: "#5e5e5e", display:"flex", alignItems:"center"}}>{empl.name}</p>
                                                            <p style={{background: "#ffffff",width: "fit-content",padding: "10px",borderRadius: "10px",color: "#5e5e5e", display:"flex", alignItems:"center"}}><DirectionsCarOutlined style={{color:"#d1c200"}}/>&nbsp;{empl.distance}</p>
                                                            <p style={{background: "#ffffff",width: "fit-content",padding: "10px",borderRadius: "10px",color: "#5e5e5e", display:"flex", alignItems:"center"}}><AccessTimeOutlined style={{color:"#49ab0e"}}/>&nbsp;{empl.duration}</p>

                                                            
                                                        </div>
                                                        {empl.employees.map((emp,empIndex)=>{
                                                            return(
                                                                <div style={{ backgroundColor: "#fff", padding: "20px", borderRadius: "8px" }}>
                                                                <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "24px" }}>
                                                                    <Avatar
                                                                        img={`${process.env.REACT_APP_MEDIA_URL}directories/employees/` + emp?.image}
                                                                        isVip={false}
                                                                        className={"medium"}
                                                                        labelType={"largeLabel"}
                                                                    />
                                                                    <div>
                                                                        <p style={{ margin: "0", fontWeight: "600", fontSize: "18px" }}>{emp?.name}</p>
                                                                        <p style={{ margin: "0" }}>{emp?.occupation}</p>
                                                                        <div style={{ display: "flex", alignItems: "center", gap: ".1rem" }}>
                                                                            <Star style={{ color: "#F7AE32", fontSize: "20px" }} />
                                                                            <p style={{ margin: "0", fontWeight: "500" }}>{emp?.rating}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
        
                                                                <div style={{ display: "flex", width: "calc(100% - 1px)", gap: "15px", overflowX: "scroll" }}>
                                                                    {emp?.dates?.slice().sort((a, b) => {
                                                                            const dateA = a.date ? new Date(a.date) : null;
                                                                            const dateB = b.date ? new Date(b.date) : null;
        
                                                                            if (dateA && dateB) {
                                                                                return dateA.getTime() - dateB.getTime();
                                                                            }
        
                                                                            return 0; // Handle the case where either date is undefined
                                                                            }).map((date, dateIndex) => {
                                                                        return (
                                                                            <div
                                                                                onClick={() => {
                                                                                    setSelectedDate({ empIndex: empIndex, dateIndex: dateIndex })
                                                                                    setSelectedLocation(index)
                                                                                    setIsOpenSlotPicker(true)

                                                                                    const serPet = {...servicePet};
                                                                                    serPet.selectedLocation = empl.locationID
                                                                                    setServicePet(serPet)


                                                                                }}
                                                                                style={{
                                                                                    flexShrink: 0,
                                                                                    backgroundColor: (selectedLocation === index  && selectedDate?.empIndex === empIndex && selectedDate?.dateIndex === dateIndex) ? '#1e5721' : '#3f9544',
                                                                                    padding: "10px 15px",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    height: "40px",
                                                                                    borderRadius: "8px",
                                                                                    fontWeight: "600",
                                                                                    color: "#fff",
                                                                                    width: "fit-content",
                                                                                    cursor: "pointer",
                                                                                    flexDirection: "column"
                                                                                }}
                                                                            >
                                                                                <p style={{ margin: 0 }}>{date?.date}</p>
                                                                                <p style={{ margin: 0 }}>({date.slots.length}) ({getDayName(date?.date)})</p>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                            )
                                                        })}

                                                    </div>
  
                                                )
                                            }) :  <p style={{ color: "#777E90", fontWeight: "400", fontSize: "13px" }}>{slotMsg}</p>}
                                        </div>
                                    </div>
                                    <div style={{display:"flex", justifyContent:"center"}}>
                                        <div
                                            style={{
                                                flexShrink: 0,
                                                border: "1px solid #3f9544",
                                                padding: "10px 15px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "40px",
                                                borderRadius: "8px",
                                                fontWeight: "600",
                                                width: "fit-content",
                                                color: "#3f9544",
                                                flexDirection: "column",
                                                background:"white",
                                                marginBottom:"10px"
                                            }}
                                        >
                                       
                                            <p style={{ margin: 0 }}>{slotEmployeeList[selectedLocation]?.employees[selectedDate?.empIndex]?.dates[selectedDate?.dateIndex]?.date} : {slotEmployeeList[selectedLocation]?.employees[selectedDate?.empIndex]?.dates[selectedDate?.dateIndex]?.slots[servicePet?.slotInfo?.slotIndex]?.slots[0]?.slot}</p>
                                            <p style={{ margin: 0 }}>(~{slotEmployeeList[selectedLocation]?.employees[selectedDate?.empIndex]?.dates[selectedDate?.dateIndex]?.slots[servicePet?.slotInfo?.slotIndex]?.slots[0]?.duration} min)</p>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Button
                                            size="large"
                                            variant="outlined"
                                            color="info"
                                            onClick={() => {
                                                setIsOpenSummary(true)
                                            }}
                                        >
                                            View Summary
                                        </Button>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <Modal open={isOpenSummary}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                                <div style={{ backgroundColor: "#fff", width: "calc(100vw - 100px)", height: "calc(100vh - 100px)", borderRadius: "10px" }}>
                                    <div style={{ display: "flex", flexDirection: "column", height: "-webkit-fill-available", gap: "20px", padding: "20px" }}>
                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                            <CloseButton onClick={() => setIsOpenSummary(false)}></CloseButton>
                                        </div>

                                        <div>
                                            <div>
                                                <Avatar img={`${process.env.REACT_APP_MEDIA_URL}directories/pets/` + servicePet.img} isVip={false} className={"large"} labelType={"largeLabel"} />
                                                <p style={{ fontWeight: "600", fontSize: "20px", textAlign: "center", margin: "7px 0" }}>{servicePet.name}</p>
                                                <p style={{ color: "gray", textAlign: "center", margin: "0" }}>
                                                    Breed: {servicePet.breed} | Size: {servicePet.size}
                                                </p>
                                                <div style={{ textAlign: "center", marginTop: "7px" }}>
                                                    <Chip size="small" customBackgroundColor={"#399ec7"} customBorder={"#399ec7"} customBorderRadius={"16px"} fontColor={"#ffffff"} label={"Syosset"} />
                                                </div>
                                            </div>
                                            <div style={{ padding: "0 15px", height: "300px", overflowY: "scroll" }}>
                                                <p style={{ fontWeight: "600", fontSize: "16px" }}>Services</p>
                                                <table style={{ fontSize: "14px", borderBottom: "1px solid #E5E7EB", paddingBottom: ".25rem", marginBottom: ".25rem", width: "100%" }}>
                                                    {servicePet !== undefined ? (
                                                        servicePet?.additionalService?.length > 0 ? (
                                                            servicePet?.additionalService?.map((items) => {
                                                                return (
                                                                    <tr>
                                                                        <td style={{ width: "100%" }}>{items?.name}</td>
                                                                        <td style={{ textAlign: "right" }}>${items?.price}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        ) : (
                                                            <p>--</p>
                                                        )
                                                    ) : (
                                                        <></>
                                                    )}
                                                </table>
                                                <p style={{ fontWeight: "600", fontSize: "16px", marginTop: "10px" }}>Products</p>
                                                <table style={{ fontSize: "14px", borderBottom: "1px solid #E5E7EB", paddingBottom: ".25rem", marginBottom: ".25rem", width: "100%" }}>
                                                    {servicePet !== undefined ? (
                                                        servicePet?.selectedProducts?.length > 0 ? (
                                                            servicePet?.selectedProducts?.map((items) => {
                                                                return (
                                                                    <tr>
                                                                        <td style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                                                            {items?.varProductName} <p style={{ fontWeight: 500, color: "#6B7280", margin: "0" }}>&nbsp;({items?.numQuantity}x)</p>
                                                                        </td>
                                                                        <td style={{ textAlign: "right" }}>${items?.decChargeAmount}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        ) : (
                                                            <p>--</p>
                                                        )
                                                    ) : (
                                                        <></>
                                                    )}
                                                </table>
                                            </div>
                                            <table style={{ fontSize: "14px", width: "100%" }}>
                                                <tr>
                                                    <td style={{ width: "100%", fontWeight: "600", color: "#255fb9" }}>Tax</td>
                                                    <td style={{ textAlign: "right", fontWeight: "600", color: "#255fb9" }}>${parseFloat(totalTax.toString()).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "100%", fontWeight: "600", color: "#255fb9" }}>Total Amount</td>
                                                    <td style={{ textAlign: "right", fontWeight: "600", color: "#255fb9" }}>${parseFloat(totalAmount.toString()).toFixed(2)}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <p style={{ fontWeight: "600", fontSize: "16px", marginTop: "10px" }}>Schedule Information</p>
                                        <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                                            <div style={{display:"flex", gap:"10px"}}>
                                                <Avatar img={`${process.env.REACT_APP_MEDIA_URL}directories/employees/` + slotEmployeeList[selectedLocation]?.employees[selectedDate?.empIndex]?.image} isVip={false} className={"medium"} labelType={"largeLabel"} />
                                                <div>
                                                    <p style={{margin:"2px", fontWeight:"500", fontSize:"14px"}}>{slotEmployeeList[selectedLocation]?.employees[selectedDate?.empIndex]?.name}</p>
                                                    <p style={{margin:"2px", color:"gray", fontSize:"12px"}}>{slotEmployeeList[selectedLocation]?.employees[selectedDate?.empIndex]?.occupation}</p>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    flexShrink: 0,
                                                    border: "1px solid #3f9544",
                                                    padding: "10px 15px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    height: "40px",
                                                    borderRadius: "8px",
                                                    fontWeight: "600",
                                                    width: "fit-content",
                                                    color: "#3f9544",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <p style={{ margin: 0 }}>{slotEmployeeList[selectedLocation]?.employees[selectedDate?.empIndex]?.dates[selectedDate?.dateIndex]?.date} : {slotEmployeeList[selectedLocation]?.employees[selectedDate?.empIndex]?.dates[selectedDate?.dateIndex]?.slots[servicePet?.slotInfo?.slotIndex]?.slots[0]?.slot}</p>
                                                <p style={{ margin: 0 }}>(~{slotEmployeeList[selectedLocation]?.employees[selectedDate?.empIndex]?.dates[selectedDate?.dateIndex]?.slots[servicePet?.slotInfo?.slotIndex]?.slots[0]?.duration} min)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <Modal open={isOpenSlotPicker}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                                <div style={{ backgroundColor: "#fff", width: "calc(100vw - 100px)", height: "calc(100vh - 100px)", borderRadius: "10px" }}>
                                    <div style={{ display: "flex", flexDirection: "column", height: "-webkit-fill-available", gap: "20px", padding: "20px" }}>
                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                            <CloseButton onClick={() => setIsOpenSlotPicker(false)}></CloseButton>
                                        </div>

                                        <div>
                                            <p style={{ fontWeight: "600", fontSize: "18px" }}>Slots</p>
                                            <div style={{ display: "flex", gap: "15px", flexWrap: "wrap", justifyContent: "space-between" }}>
                                                {selectedDate?.empIndex !== ""
                                                    ? slotEmployeeList[selectedLocation]?.employees[selectedDate?.empIndex]?.dates?.slice().sort((a, b) => {
                                                        const dateA = a.date ? new Date(a.date) : null;
                                                        const dateB = b.date ? new Date(b.date) : null;

                                                        if (dateA && dateB) {
                                                            return dateA.getTime() - dateB.getTime();
                                                        }

                                                        return 0; // Handle the case where either date is undefined
                                                        })[selectedDate?.dateIndex]?.slots?.map((slots, slotIndex) => {
                                                          // console.log("loc ", selectedLocation, servicePet?.selectedLocation," emp", selectedDate?.empIndex, servicePet?.slotInfo?.empIndex," date ", selectedDate?.dateIndex ,servicePet?.slotInfo?.dateIndex, "slot ", slotIndex,  servicePet?.slotInfo?.slotIndex)
                                                          return (
                                                              <div
                                                                  style={{
                                                                      flexShrink: 0,
                                                                      backgroundColor: (slotEmployeeList[selectedLocation]?.locationID === servicePet?.selectedLocation && selectedDate?.empIndex === servicePet?.slotInfo?.empIndex && selectedDate?.dateIndex ===servicePet?.slotInfo?.dateIndex && slotIndex === servicePet?.slotInfo?.slotIndex)? "#1a641f" : "#3f9544",
                                                                      padding: "10px 15px",
                                                                      display: "flex",
                                                                      alignItems: "center",
                                                                      justifyContent: "center",
                                                                      height: "40px",
                                                                      borderRadius: "8px",
                                                                      fontWeight: "600",
                                                                      color: "#fff",
                                                                      width: "fit-content",
                                                                      cursor: "pointer",
                                                                      flexDirection: "column",
                                                                  }}
                                                                  onClick={() => {
                                                                      selectSlot(slots, selectedDate?.empIndex, selectedDate?.dateIndex,  slotIndex)
                                                                  }}
                                                              >
                                                                  <p style={{ margin: 0 }}>{slots?.slots[0]?.slot}</p>
                                                                  <p style={{ margin: 0 }}>(~{slots?.slots[0]?.duration} min)</p>
                                                              </div>
                                                          )
                                                      })
                                                    : "Loading slots..."}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                {currentStep !== 1 ? (
                                    <Button size="large" variant="contained" onClick={() => goPrevious()}>
                                        PREVIOUS
                                    </Button>
                                ) : (
                                    <div></div>
                                )}
                                {currentStep !== 5 ? (
                                    <Button size="large" variant="contained" onClick={() => goNext()}>
                                        NEXT
                                    </Button>
                                ) : (
                                    <Button
                                        size="large"
                                        variant="contained"
                                        onClick={() => {
                                            saveReservation()
                                        }}
                                    >
                                        MAKE RESERVATION
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default MakeReservation
