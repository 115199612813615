import { Link } from 'react-router-dom';
import styles from './NotFound.module.scss';

const NotFound = () => {
    return (
        <div className={styles.oops}>
            <h2>OOPS!!!</h2>
            <p>404 - Page Not Found :(</p>
            <Link to={'/'}>
                <button>Back to Home</button>
            </Link>

        </div>
    )
}

export default NotFound;