import {
    GoogleMap,
    Marker,
    DirectionsRenderer,
    InfoWindow
} from '@react-google-maps/api'
import { useEffect, useRef, useState } from 'react'
import './Map.module.scss'
import pin from './pin.svg'
import { useJsApiLoader } from '@react-google-maps/api'
import { Star } from '@mui/icons-material';
let MapLoad = require('../../images/Mapload.jpg');
  
export const Map = (props:{
  directions,
  location?,
  salons?,
  getDirection?
  children?;
  isPickLocation?;
  onPickLocation?: CallableFunction;
  getRoutes?;
  border?;
  showBaseMarker?;
  zoom?;
  isSub;
}) => {
    const GOOGLE_MAP_KEY:any = process.env.REACT_APP_GMAP_API_KEY

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAP_KEY,
        libraries: ['places']
      });
        let groupedObjects = {}; // Default value

        if (props.salons && Array.isArray(props.salons)) {
          groupedObjects = props.salons.reduce((result, obj) => {
            if (obj.id in result) {
              result[obj.id].push(obj);
            } else {
              result[obj.id] = [obj];
            }
            return result;
          }, {});
          // Rest of your code that uses groupedObjects
        } else {
          // Handle the case when props.salons is undefined or not an array
          console.log("props.salons is undefined or not an array.");
          // Or perform any other necessary action
        }

        const [map, setMap] = useState<any>((null))
        const [directionsResponse, setDirectionsResponse] = useState<any>(null)
        const [distance, setDistance] = useState<any>('')
        const [duration, setDuration] = useState<any>('')
        const [selectedStore, setSelectedStore] = useState<any>(null);
        const [showInfo, setShowInfo] = useState(false);
        const [addCustomMarker, setAddCustomMarker] = useState(false);
        const [customLoc, setCustomLoc] = useState<any>('');
        const [customerLoc, setCustomerLoc] = useState<any>(props.location);
        const [selectedSub, setSelectedSub] = useState<any>([]);
      
    useEffect(()=>{
      if (isLoaded) {
        if(props.getDirection != undefined){
          let obj = {
            latitude: props.getDirection[0]?.lat,
            longitude: props.getDirection[0]?.lng,
            title: props.getDirection[0]?.title,
            duration: props.getDirection[0]?.duration,
            distance: props.getDirection[0]?.distance,
            img: props.getDirection[0]?.img
          }
          setSelectedStore(obj);
          calculateSelectedRoute(props.getDirection)
        }
      }
     },[props.getDirection])

     useEffect(()=>{
      if(props.isPickLocation != true){
        if(!isNaN(props.location.lat) && !isNaN(props.location.lng)){
          setCustomerLoc(props.location)
        }
      }
    },[props.location])

    if (!isLoaded) {
       <div>Loading...</div>
    }
    async function calculateRoute(salon) {
     
      const directionsService = new google.maps.DirectionsService()
      const results = await directionsService.route({
        origin: customerLoc,
        destination: new google.maps.LatLng(salon.latitude, salon.longitude),
        travelMode: google.maps.TravelMode.DRIVING
      })
      setDirectionsResponse(results)
      setDistance(results?.routes[0]?.legs[0]?.distance?.text)
      setDuration(results?.routes[0]?.legs[0]?.duration?.text)
    }

    async function calculateSelectedRoute(salon) {
      if(props.getRoutes){
        const directionsService = new google.maps.DirectionsService()
        const results = await directionsService.route({
          origin: customerLoc,
   
          destination: new google.maps.LatLng(salon[0].lat, salon[0].lng),
        
          travelMode: google.maps.TravelMode.DRIVING
        })
        setDirectionsResponse(results)
        setDistance(results?.routes[0]?.legs[0]?.distance?.text)
        setDuration(results?.routes[0]?.legs[0]?.duration?.text)
      }
    }

    function clearRoute() {
      setDirectionsResponse(null)
      setDistance('')
      setDuration('')
    }

    const triggerInfo = () => {
      setShowInfo(true)
     }

   const generateRoute = (salon) => {
    if(props.getRoutes == true){
      calculateRoute(salon);
    }
   }

   const addMarker = (data) => {
    if(props.isPickLocation == true){
      setCustomLoc(data.latLng)
      setAddCustomMarker(true)
      const centerLoc:any = {...customerLoc}
      centerLoc.lat = data.latLng.lat();
      centerLoc.lng = data.latLng.lng();
      setCustomerLoc(centerLoc)
    }
   }
   const getLocations = (salon) => {
    if(props.isSub === true){
      const subObjects = groupedObjects[salon.id];
      setSelectedSub(subObjects)
    }
   }
  
    return (
          <GoogleMap
              center={customerLoc}
              zoom={props.zoom}
              mapContainerStyle={(props.border)? { width: '100%', height: '100%', borderRadius: '0 12px 12px 0' } : { width: '100%', height: '100%'}}
              options={{
                zoomControl: true,
                streetViewControl: false,
                mapTypeControl: true,
                fullscreenControl: true,
              }}
              onClick={(e)=>{
                addMarker(e)
                props.onPickLocation?.(e)
              }}
              onLoad={map => setMap(map)}
          >
              {
                (addCustomMarker) ?
                <Marker 
                position={customLoc}
                />:null
              }
            {

              (props.getRoutes && directionsResponse == undefined) ? null :
              (props.isPickLocation)? 
                null :
                (props.showBaseMarker)?
                <Marker 
                position={customerLoc}
                onClick={() => {
                    triggerInfo()
                }}
                animation={2}
                icon={{
                  url: pin,
                  scaledSize:  new google.maps.Size(50,50)
                }}   
                /> : null
            }
            {
            showInfo && (
              <InfoWindow
              position={{
                lat: customerLoc.lat,
                lng: customerLoc.lng
              }}
              onCloseClick={()=>{
                setShowInfo(false)
              }}
              ><>
                <div>
                  {(customerLoc.img == null)? 
                  <img style={{height:"100px", width:"100%", objectFit:"cover", borderRadius:"4px"}} src={MapLoad} alt="Logo" />
                    :
                  <img style={{height:"100px", width:"100%", objectFit:"cover", borderRadius:"4px"}} src={`${process.env.REACT_APP_MEDIA_URL}directories/locations/` +customerLoc.img} alt="Logo" />}          
                </div>
                <div style={{display:"flex", gap:".2rem"}}>
                  <p style={{fontSize:"14px", fontWeight:"400"}}>{customerLoc.name}</p>
                  <p>🐶</p>
                </div>
              </>
              </InfoWindow>
            )
          }
          {
            (props.directions) ?
            props.salons.map((salon,index) => {
              return ( 
                <Marker key={index}
                  position={{lat:salon.latitude, lng:salon.longitude}}
                  onClick={()=>{
                    setSelectedStore(salon)
                    generateRoute(salon);
                    getLocations(salon)    
                    console.log("sal", salon)   
                  }}
                
              />
            )
            }) : null
          }
          {
            selectedStore && (
              <InfoWindow
              position={{
                lat: selectedStore.latitude,
                lng: selectedStore.longitude
              }}
              onCloseClick={()=>{
                setSelectedStore(null)
                setSelectedSub([])
              }}
              >
                <>
                <div style={{display:"flex", gap:"5px"}}>
                  {(selectedStore?.img == null)? 
                  <img style={{height:"70px", width:"60%", objectFit:"cover", borderRadius:"4px"}} src={MapLoad} alt="Logo" />
                    :
                  <img style={{height:"70px", width:"60%", objectFit:"cover", borderRadius:"4px"}} src={`${process.env.REACT_APP_MEDIA_URL}directories/locations/` +selectedStore?.img} alt="Logo" />}          
                {
                  (props.getRoutes)?
                  <div style={{display:"flex", flexDirection:"column"}}>
                    <div>⏰ {selectedStore?.duration}</div>
                    <div>🚗 {selectedStore?.distance}</div>
                  </div> : null
                }
                </div>
                <div style={{display:"flex", gap:".2rem"}}>
                  <p style={{fontSize:"14px", fontWeight:"500"}}>{selectedStore?.title}</p>
                  <p>🐶</p>
                </div>
                {
                  (props.isSub)?
                  (selectedSub.length > 0)?
                  <div style={{display:"flex", flexDirection:"column", gap:"10px", padding:"10px", maxHeight:"120px", overflowY:"scroll", minWidth:"300px"}}>
                  {

                    selectedSub.map((sub, index)=>{
                      return <div style={{ display: 'flex', gap: '5px', height: '100%', width: 'auto' }}>
                        <img style={{ height: '50px', borderRadius:"50px", border:"2px solid #fff", boxShadow: "3px 3px 10px 3px #ddd" }} src={sub.varEmployeeImagePath} alt="Avatar" />

                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <div style={{ fontSize: '16px' }}>{sub.varEmployeeFirstName +" "+sub.varEmployeeLastName}</div>
                            <div>
                                <div style={{ fontSize: '12px', color:"#7a7a7a" }}>{sub.numOccupationID}</div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '.1rem' }}><Star style={{ color: '#F7AE32', fontSize:"20px" }}/>
                                        <p style={{ margin: '0', fontWeight: '500' }}>
                                        {'4.1'}
                                        </p>
                                </div>
                            </div>
                        </div>                       
                    </div>
                    })
                  }
              </div> : null
              : null
                }
                </>
              </InfoWindow>
            )
          }
            {directionsResponse && (
              <DirectionsRenderer 
              directions={directionsResponse}
              options={{
                suppressMarkers: true      
              }}
              />
            )}
            {props.children}
          </GoogleMap>
    )
  }
  
  