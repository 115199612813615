import moment from "moment"
import dateFormat from "dateformat";

export const addMinutes = (timestamp, minutesToAdd) => {
    console.log("timessss", minutesToAdd, timestamp)
    return moment(timestamp).add(minutesToAdd, "minutes").format("YYYY-MM-DDThh:mm:ss")
}

export const calculateWeeklyTiming = (startTimestamp) => {
    let updatedDate = moment(startTimestamp).set({ year: moment().year(), month: moment().month(), weekday: moment(startTimestamp).weekday() })
    return updatedDate.format("YYYY-MM-DDTHH:mm:ss")
}

export const formatDateTime = (startTimestamp) => {
    let time = moment(startTimestamp)
    return time.format("YYYY-MM-DD HH:mm:ss")
}

export const getMinutesDuration = (startTimestamp, endTimestamp) => {
    let st = moment(startTimestamp)
    let end = moment(endTimestamp)
    let duration = moment.duration(end.diff(st))
    let diff = duration.asMinutes()

    return diff
}

export const formatDateTimeScheduler = (timestamp) => {
    let timestampAfterTrim = timestamp.substring(0, timestamp.length - 10)
    let time = moment(timestampAfterTrim)

    return time.format("YYYY-MM-DDTHH:mm:ss")
}

export const formatToLocal = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleDateString()
}
export const convertToReadableDate = (dateString)=>{
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
    return date.toLocaleString('en-US', options);
}

export const shortDate = (unformattedDate) => {
    return dateFormat(unformattedDate, "yyyy-mm-dd")
}


export const getDayName = (inputDate) => {
    // Try to parse the input date string using Moment.js
    const momentDate = moment(inputDate, 'YYYY-MM-DD');
  
    // Check if the parsed date is valid
    if (!momentDate.isValid()) {
      console.error("Invalid date");
      return null;
    }
  
    // Get the abbreviated day name
    const abbreviatedDayName = momentDate.format('ddd');
  
    return abbreviatedDayName;
  };
  
  export function daysPassedOrInFuture(dateString) {
    // Parse the given date string using Moment.js
    const givenDate = moment(dateString);
  
    // Get the current date using Moment.js
    const currentDate = moment();
  
    // Calculate the difference in days
    const daysDifference = givenDate.diff(currentDate, 'days');
  
    if (daysDifference < 0) {
      // Date is in the past
      return `${Math.abs(daysDifference)} days passed`;
    } else if (daysDifference === 0) {
      // Date is today
      return 'Today';
    } else {
      // Date is in the future
      return `In ${daysDifference} days`;
    }
  }

  /* Format Expiry Date Check */
export const expiryDateCheck = (unformattedDate) => {
  let date1 = new Date();
  let date2 = (new Date(unformattedDate)).getTime();
  if(date1 > date2){
      return true
  }else{
      return false
  }
}

export const expiryDate = (unformattedDate) => {
  return dateFormat(unformattedDate, "yyyy-mm")
}

export const formatDateDisplay = (dateTimeStr) => {
  // Parse the input date-time string using Moment.js
  const dateTime = moment(dateTimeStr);

  // Format the date and time string
  const formattedDateTime = dateTime.format('YY/MM/DD h.mmA');

  return formattedDateTime;
}
  