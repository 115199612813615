import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import axios, { axiosPrivate } from '../../Apis/axios';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import { encrypt } from '../../Utils/encrypt';
import { useNavigate } from "react-router-dom"

const ForgotPassword = (props) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false) as any;
    const navigate = useNavigate()

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    async function validateEmail(id) {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Auth/CheckUserName/${id}`)
        return result
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!email) {
            setError('Email is required');
        } else if (!isValidEmail(email)) {
            setError('Please enter a valid email address');
        } else {
            setError('');
            validateEmail(email)
            .then((response) => {
                console.log(response)
                if(response.status === 200){
                   if(response?.data?.success === false){
                     toast.error(response?.data?.message)
                     setIsLoading(false)
                   }else{

                    let customerID = response?.data?.numCustomerID
                    let userID = response?.data?.numUserID
                    const encrypted = encrypt(userID.toString());

                    getCustomerInfo(customerID, encrypted)

                    }
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            }) 
            
        }
    };

    async function getCustomerInfo(id, encID) {

      
        await axios
            .get(`${process.env.REACT_APP_API_URL}Customer/GetByID/${id}`)
            .then((result) => {
                if (result.status === 200) {
                    console.log("customerDD", result?.data?.customer)

                       let emailBody = 
                          `<!DOCTYPE html>
                                <html lang="en">
                                <head>
                                    <meta charset="UTF-8">
                                    <title>Reset Your Password</title>
                                    <style>
                                        body {
                                            font-family: Arial, sans-serif;
                                            margin: 0;
                                            padding: 20px;
                                            background-color: #f4f4f4;
                                        }
                                        .container {
                                            background-color: #ffffff;
                                            padding: 20px;
                                            border-radius: 8px;
                                            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                                            max-width: 600px;
                                            margin: 0 auto;
                                        }
                                        .header {
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                        }
                                        .logo {
                                            max-width: 100px;
                                            height: auto;
                                        }
                                        .content {
                                            margin-top: 20px;
                                        }
                                        .button {
                                            background-color: #28a745;
                                            color: white;
                                            padding: 10px 15px;
                                            text-decoration: none;
                                            border-radius: 5px;
                                            display: inline-block;
                                            margin-top: 20px;
                                        }
                                        .button:hover {
                                            background-color: #218838;
                                        }
                                    </style>
                                </head>
                                <body>
                                    <div class="container">
                                        <div class="header">
                                            <img src="https://test.esypet.net/static/media/esypet-logo.5f7c03070095ad942f20.png" alt="EsyPet Logo" class="logo" />
                                        </div>
                                        <div class="content">
                                            <p><strong>Dear Valued Customer,</strong></p>
                                            <p>We received a request to reset the password for your EsyPet account. If you made this request, please click the button below to set a new password:</p>
                                            <a href="http://localhost:3000/reset-password/${encID}" class="button">Reset Your Password</a>
                                            <p>If you did not request a password reset, please ignore this email or contact our support team if you have concerns.</p>
                                            <p>Thank you for using EsyPet. We’re here to help keep your pet looking its best!</p>
                                        </div>
                                    </div>
                                </body>
                                </html>
                                `;

        let payload = {
          messageBody: [
              {
                  messageID: 1,
                  recipientID: result?.data?.customer?.numCustomerID,
                  recipientType: "C",
                  senderID: result?.data?.customer?.numCustomerID,
                  senderLocationID: result?.data?.customer?.numCustomerDefaultLocationID,
                  subject: `Account activation`,
                  smsBody: emailBody,
                  emailBody: emailBody,
                  communicationMethod: "E",
                  sender: result?.data?.customer?.varCustomerFirstName +""+ result?.data?.customer?.varCustomerLastName,
                  senderLocation: result?.data?.customer?.varCustomerDefaultLocation,
                  contactNumber: result?.data?.customer?.varCustomerMobilePhone,
                  emailAddress: result?.data?.customer?.varCustomerEmail,
                  appointmentID: null,
                  option: result?.data?.customer?.varCustomerEmail === null ? 1 : 2,
                  sendDate: new Date().toISOString().split("T")[0],
                  messageType: "I",
                  workPhoneNumber: result?.data?.customer?.varCustomerWorkPhone,
                  homePhoneNumber: result?.data?.customer?.varCustomerHomePhone,
                  bitCustomerNotificationEmail: true,
                  numCustomerNotificationEmailSequence: 1,
                  bitCustomerNotificationHomePhone: false,
                  numCustomerNotificationHomePhoneSequence: 0,
                  bitCustomerNotificationMobilePhoneCall: false,
                  numCustomerNotificationMobilePhoneCallSequence: 0,
                  bitCustomerNotificationMobilePhoneSMS: false,
                  numCustomerNotificationMobilePhoneSMSSequence: 0,
                  bitCustomerNotificationWorkPhone: false,
                  numCustomerNotificationWorkPhoneSequence: 0,
                  messageTemplateID: null,
                  attachmentModel: []
              },
          ],
      }
      console.log("payloadEMail", payload)
      
      sendMessagePost(payload)

                }
            })
            .catch((err) => {
                console.log(err)
                console.log("Something went wrong!")
                setIsLoading(false)

            })
      }

      async function sendMessagePost(data) {
        await axios
            .post(`${process.env.REACT_APP_TEXT_API_URL}UpdateMessages/UpdateMessagesPool`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("Message sent to pool")
                    showRegistrationSuccessAlert()
                }
            })
            .catch((err) => {
                console.log("Messaging -Err-", err)
                setIsLoading(false)
            })
    }

    const showRegistrationSuccessAlert = () => {
        Swal.fire({
          title: 'Email Sent Successfully!',
          html: `
            <div style="font-size: 16px; margin-top: 10px;">
              Please confirm your email by clicking the link that has been sent to your email address to recover your account.
            </div>
          `,
          iconHtml: `<div style="display: flex; justify-content: center;">
                       <img src="https://cdn-icons-png.flaticon.com/512/190/190411.png" alt="success-icon" style="width: 80px; height: 80px;" />
                     </div>`,
          showCloseButton: true,
          focusConfirm: false,
          confirmButtonText: 'OK',
          customClass: {
            icon: 'no-border',
          }
        }).then((result) => {
          if (result.isConfirmed || result.dismiss) {
            console.log("SweetAlert was closed.");
            setIsLoading(false)
            navigate("/login");
          }
        });
      };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (error) setError(''); 
    };

    const Logo = styled('img')({
        display: 'block',
        margin: '0 auto 20px',
        width: '150px',
    });

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: '#f5f5f5',
            }}
        >
              <ToastContainer />
            <Box
                sx={{
                    width: '400px',
                    padding: '30px',
                    backgroundColor: '#fff',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    textAlign: 'center',
                }}
            >
                <Logo src="https://test.esypet.net/static/media/esypet-logo.5f7c03070095ad942f20.png" alt="Esypet Logo" />
                <Typography variant="h5" gutterBottom>
                    Forgot Password
                </Typography>
                <Typography variant="body2" gutterBottom>
                    Enter your email address and we'll send you a link to reset your password.
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        value={email}
                        onChange={handleEmailChange}
                        error={!!error}
                        helperText={error}
                        sx={{ mb: 3 }}
                    />
                    <Button type="submit" variant="contained" disabled={isLoading} fullWidth>
                        Send Reset Link
                    </Button>
                </form>
            </Box>
        </Box>
    );
};

export default ForgotPassword;
