import { CircularProgress, Button as MaterialButton } from "@mui/material"
import styles from "./button.module.scss"

export const Button = (props: {
    variant: "text" | "outlined" | "contained"
    text?: string
    color: "primary" | "secondary" | "default" | "danger" | "success" | "utility" | "pagination" | "transparent" | "info" | "discard"
    disabled?: boolean
    iconLeft?
    iconRight?
    onClick?: CallableFunction | any
    loading?: boolean
    type?: "button" | "submit"
    shape?: "circle" | "square"
    active?: boolean
    style?: "style-01" | "style-02"
    children?
    className?
    size?
}) => {
    const sharedProps = {
        className: `${styles[props.color]} ${props.className}`,
        startIcon: props.iconLeft,
        endIcon: props.iconRight,
        variant: props.variant,
        onClick: props.onClick,
        disabled: props.disabled,
        type: props.type,
    }

    const buttonContent = () => {
        if (props.loading) {
            return <CircularProgress size={20} sx={{ color: props.variant === "contained" ? "white" : "#005DF1" }} />
        }

        return (
            <>
                {props.text}
                {props.children}
            </>
        )
    }

    return (
        <MaterialButton size={props.size} {...sharedProps}>
            {buttonContent()}
        </MaterialButton>
    )
}
