import { toast } from "react-toastify"
import axios from "../../Apis/axios"
import { removeUnnecessaryWhitespace } from "./HtmlValidation"
import { formatTemplate } from "./MessagingDecoder"

const getTemplateByID = async (templateID) => {
    const apiUrl = `${process.env.REACT_APP_TEXT_API_URL}Template?TemplateID=${templateID}`

    try {
        const response = await axios.get(apiUrl, {})
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

const getAppointmentInfo = async (appID) => {
    const apiUrl = `${process.env.REACT_APP_TEXT_API_URL}UpdateMessages/GetAppointmnetDetailsByAppintmentID?AppointmentID=${appID}`

    try {
        const response = await axios.get(apiUrl, {})
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

const getAppointmentDynamicValues = async (entity, id) => {
  const apiUrl = `${process.env.REACT_APP_TEXT_API_URL}Template/GetDynamicParameterDataByEntityAndID?EntityName=${entity}&ID=${id}`

  try {
      const response = await axios.post(apiUrl, {})
      return response.data
  } catch (error) {
      console.error(error)
      throw error
  }
}

  async function sendMessagePost(data) {
    await axios.post(`${process.env.REACT_APP_TEXT_API_URL}UpdateMessages/UpdateMessagesPool`, 
    JSON.stringify(data),
    {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            
        },
    }).then((result) => {
        if (result.status === 200) {

            console.log("Message sent to pool")
        }
    }).catch((err) => {

        console.log("Messaging -Err-", err);
    })
}

async function sendNotification(data) {
    console.log("msg-body",data)
    await axios.post(`${process.env.REACT_APP_TEXT_API_URL}Notification/PopulateNotifications`, 
    JSON.stringify(data),
    {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            
        },
    }).then((result) => {
        if (result.status === 200) {

            console.log("Notification sent to the recipient")
        }
    }).catch((err) => {

        console.log("Messaging -Err-", err);
    })
}

export const sendOutNotification = (payload) => {
    sendNotification(payload)
    .then((data) => {
    })
    .catch((error) => {
        console.log("error", error);
    });
} 


export function sendAppointmentStaticMessage(id, entity, templateID, method, files) {

    //Email
    getTemplateByID(templateID)
    .then((templateData) => {
        if(templateData[0].isActive){
            console.log("entitiy", entity, id)
            getAppointmentDynamicValues(entity, id)
            .then((DynamicData) => {
      
              let dynamicD = DynamicData;
              let template = templateData[0]


                //   //Notification
                //     let notificationPostBody ={
                //         "notificationType": "success",
                //         "subject": template?.templateReference+" Ref#:"+id,
                //         "notificationMessage": "string",
                //         "locationID": 1,
                //         "locationName": "string",
                //         "recipientType": "string",
                //         "recipientID": 0,
                //         "recipientName": "string",
                //         "isSeen": false
                //     }
      
                const templateDDD = template.emailMessage;
                const templateSMS = template.smsMessage;
                const formattedEmail = formatTemplate(templateDDD, dynamicD);
                const formattedSms = formatTemplate(templateSMS, dynamicD);
      
                getAppointmentInfo(id)
                .then((appData) => {
        
                    let payload = {
                        "messageBody": [
                          {
                            "messageID": 1,
                            "recipientID": appData[0]?.CustomerID,
                            "recipientType": "C",
                            "senderID": appData[0]?.EmployeeID,
                            "senderLocationID": appData[0]?.numLocationID,
                            "subject": template?.subject+" Ref#:"+id,
                            "smsBody": formattedSms,
                            "emailBody": removeUnnecessaryWhitespace(formattedEmail),
                            "communicationMethod": method,
                            "sender": appData[0]?.varLocationName,
                            "senderLocation": appData[0]?.varLocationName,
                            "contactNumber": appData[0]?.CustomerMobilePhone,
                            "emailAddress": appData[0]?.CustomerEmail,
                            "appointmentID": appData[0]?.AppointmentID.toString(),
                            "option": (appData[0]?.CustomerEmail === null) ? 1 : 2,
                            "sendDate": new Date().toISOString().split('T')[0],
                            "messageType": "I",
                            "workPhoneNumber": appData[0]?.varCustomerWorkPhone,
                            "homePhoneNumber":appData[0]?.varCustomerHomePhone,
                            "bitCustomerNotificationEmail": appData[0]?.bitCustomerNotificationEmail,
                            "numCustomerNotificationEmailSequence": appData[0]?.numCustomerNotificationEmailSequence,
                            "bitCustomerNotificationHomePhone": appData[0]?.bitCustomerNotificationHomePhone,
                            "numCustomerNotificationHomePhoneSequence": appData[0]?.numCustomerNotificationHomePhoneSequence,
                            "bitCustomerNotificationMobilePhoneCall": appData[0]?.bitCustomerNotificationMobilePhoneCall,
                            "numCustomerNotificationMobilePhoneCallSequence": appData[0]?.numCustomerNotificationMobilePhoneCallSequence,
                            "bitCustomerNotificationMobilePhoneSMS": appData[0]?.bitCustomerNotificationMobilePhoneSMS,
                            "numCustomerNotificationMobilePhoneSMSSequence": appData[0]?.numCustomerNotificationMobilePhoneSMSSequence,
                            "bitCustomerNotificationWorkPhone": appData[0]?.bitCustomerNotificationWorkPhone,
                            "numCustomerNotificationWorkPhoneSequence": appData[0]?.numCustomerNotificationWorkPhoneSequence,
                            "messageTemplateID": templateID,
                            "attachmentModel": (files.length > 0)? files : []
                          }
                        ]
                      }
        
                      console.log("sent-payload", payload)
        
                    sendMessagePost(payload)
                    
                })
                .catch((error) => {
                    console.log("error", error)
                })
          
              })
              .catch((error) => {
                  console.log("error", error)
              })
        }else{
            toast.warning(`Message did not sent, template ${templateData[0]?.templateReference} have been deactivated.`)
        }

    })
    .catch((error) => {
        console.log("error", error)
    })
  }



