import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from '../../Apis/axios';
import styles from './TabDashboard.module.scss';
import { Avatar, Button } from '../../Components';
import { convertToReadableDate, formatDateTime, formatDateTimeScheduler, shortDate } from '../../Utils/timeHandler';
import { Chip, IconButton } from '@mui/material';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import Summery from '../Summery/Summery';
import { Download } from '@mui/icons-material';
import { CloseButton } from '../../Components/CloseButton/CloseButton';
import { Modal } from '../../Components/Components';
import { axiosPrivate } from "../../Apis/axios"
import CustomerInvoices from '../CustomerInvoices/CustomerInvoices';
import { Truncate } from '../../Utils/Validation';



const ViewAppointment = (props: {
    appID: any;
}) => {

    

    const [summaryDetails, setSummaryDetails] = useState({}) as any
    const [checkinInfo, setCheckInInfo] = useState({}) as any
    const [appointmentServices, setAppointmentServices] = useState([]) as any
    const [appointmentProducts, setAppointmentProducts] = useState([]) as any
    const [appointmentCharges, setAppointmentCharges] = useState([]) as any
    const [totPaid, setTotPaid] = useState<any>(0)
    const [totTax, setTotTax] = useState(0) as any
    const [totRemain, setTotRemain] = useState<any>(0)
    const [isOpenWellnessRep, setIsOpenWellnessRep] = useState<any>(false)
    const [wellnessBaseDataList, setWellnessBaseDataList] = useState<any>([])
    const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false)
    const [remainingTotal, setRemainingTotal] = useState(0)
    

    async function getAppointmentInfo(bookingID) {
        await axios.get(`${process.env.REACT_APP_API_URL}Appointment/GetAppointmnetByID?AppointmentID=${bookingID}`).then((result) => {
            if (result.status === 200) {
                console.log(result.data.Appointment)
                setSummaryDetails(result.data.Appointment)
            }
        }).catch((err) => {
            console.log(err);
            toast.error('Something went wrong!');
        })
    }

    async function getCheckinDetails(bookingID) {
        await axios
            .get(`${process.env.REACT_APP_API_URL}CheckIn/getCheckInByAppointmentID?AppointmentID=${bookingID}`)
            .then((result) => {
                if (result.status === 200) {
                    console.log("checkIn", result.data.CheckIn)
                    setCheckInInfo(result.data.CheckIn)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    useEffect(() => {
        getAppointmentInfo(props?.appID)
        getCheckinDetails(props?.appID)
        fetchInvoiceData(props.appID, "app");    
    }, [props.appID])


    const GetInvoiceDetails = async (id, type) => {
        let para:any = {};
        if(type === "inv"){
            para = {
                numInvoiceID: id
            }
        }else{
            para = {
                numAppointmentID: id
            }
        }
        console.log("sent params to fetch-", para)
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetInvoiceDetails`;
      
        try {
          const response = await axios.get(apiUrl, {
            params: para,
          });
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
    };

    async function GetWellnessByPetTypeID(id) {
        const result: any = await axiosPrivate.get(`Wellness/GetWelnessByPetTypeID?PetTypeID=${id}`)
        return result
    }
    async function GetWellnessByCheckInID(id) {
        const result: any = await axiosPrivate.get(`Wellness/GetWellnessByWelnessCheckID?WellnessCheckInID=${id}`)
        return result
    }

    const fetchInvoiceData = (id , type) => {
        console.log("calledXX")
        if(id !== null && id !== undefined){
            GetInvoiceDetails(id , type)
            .then((data) => {
                console.log("res - summary-from-inq", data);
                setAppointmentCharges(data)
                setTotPaid(data[0].totalPaid)
                setTotTax(data[0].taxTotal)
                const appointmentServices:any = [];
                const appointmentProducts:any = [];

                data.forEach(item => {
                    if (item.varServiceOrProduct === "S") {
                        appointmentServices.push(item);
                    } else if (item.varServiceOrProduct === "P") {
                        appointmentProducts.push(item);
                    }
                });
                setAppointmentProducts(appointmentProducts)
                setAppointmentServices(appointmentServices)
            })
            .catch((error) => {
                console.log("error", error);
            });
        }else{
            console.log("cancelled")
        }
    }

    useEffect(()=>{
        if(isOpenWellnessRep === true){
            GetWellnessByPetTypeID(summaryDetails?.Pet.PetTypeID)
            .then((response) => {
                GetWellnessByCheckInID(checkinInfo?.CheckInID)
                    .then((response_app_wellness) => {
                        if (response_app_wellness.data.length > 0) {
                            console.log(response_app_wellness)
                            let firstArray = response.data.Wellness
                            let secondArray = response_app_wellness.data[0].Wellness


                            for (let i = 0; i < firstArray.length; i++) {
                                const concerns1 = firstArray[i].Concerns
                                const bodyPartID = firstArray[i].BodyPartID

                                const matchingObj = secondArray.find((item) => item.BodyPartID === bodyPartID)

                                if (matchingObj) {
                                    firstArray[i].isAbnormal = matchingObj.IsAbnormal

                                    for (let j = 0; j < concerns1.length; j++) {
                                        const concernID = concerns1[j].ConcernID

                                        const isMatchingConcern = matchingObj.Concerns.some((concern) => concern.ConcernID === concernID)
                                        concerns1[j].checked = isMatchingConcern
                                    }
                                } else {
                                    firstArray[i].isAbnormal = false

                                    for (let j = 0; j < concerns1.length; j++) {
                                        concerns1[j].checked = false
                                    }
                                }
                            }

                            console.log("sorted", firstArray)

                            const checkImageList = response_app_wellness.data[0].checkImageList.map((item) => {
                                return {
                                    varImagePath: item.WellnessCheckImagePath,
                                }
                            })

                            let wellObj = {
                                WellnessCheckID: response_app_wellness.data[0].WellnessCheckID,
                                NotesForClient: response_app_wellness.data[0].NotesForClient,
                                Suggestions: response_app_wellness.data[0].Suggestions,
                                EvaluatorID: response_app_wellness.data[0].EvaluatorID,
                                NumCreatedBy: response_app_wellness.data[0].NumCreatedBy,
                                dtCreatedDate: response_app_wellness.data[0].dtCreatedDate,
                                Wellness: firstArray,
                                checkImageList: checkImageList,
                                RecommendedServices: response_app_wellness.data[0].RecommendedServices,
                                RecommendedProducts: response_app_wellness.data[0].RecommendedProducts
                            }
                            setWellnessBaseDataList(wellObj)
                            console.log("on-Open-Obj", wellObj)
                        } else {
                            setWellnessBaseDataList({
                                WellnessCheckID: null,
                                NotesForClient: null,
                                Suggestions: null,
                                EvaluatorID: null,
                                NumCreatedBy: null,
                                dtCreatedDate: null,
                                Wellness: response.data.Wellness,
                                checkImageList: [],
                                RecommendedServices: [],
                                RecommendedProducts: []
                            })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
        }
    },[isOpenWellnessRep])

    useEffect(()=>{
        if(appointmentCharges.length > 0) {
            let amt = 0
            let totRemainAmt = 0;
            appointmentCharges.map((charge)=>{
                amt = amt + parseFloat(charge.remaining)
                totRemainAmt = totRemainAmt + parseFloat(charge.remaining)
            })
            setRemainingTotal(amt)
            setTotRemain(totRemainAmt)
        }
    },[appointmentCharges])


      
    useEffect(() => {
        // Initialize Facebook SDK
        (window as any).fbAsyncInit = function() {
          (window as any).FB.init({
            appId: '685161653764252',
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v14.0', 
          });
        };
    
        // Load the SDK asynchronously
        (function(d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          js.src = 'https://connect.facebook.net/en_US/sdk.js';
          fjs.parentNode?.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      }, []);


    
      const handleFacebookShare = (imageUrlBfr:any, imageUrlAftr:any) => {

        // const imageUrl = imageUrlBfr;
        // const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(imageUrl)}`;
        // window.open(facebookShareUrl, '_blank', 'width=600,height=400');

        const shareUrl = ` https://1c30-112-134-223-253.ngrok-free.app/image-share.html?image=${encodeURIComponent(imageUrlBfr)}&description=${encodeURIComponent("")}`;
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`, '_blank', 'width=600,height=400');
      };

      


      const eneblePaymentButton = () => {

        if(parseFloat(totRemain) > 0){
            return false
        }else {
            return true
        }
            // if(summaryDetails?.apptStatus === "PICKUP-READY" || summaryDetails?.apptStatus === "CHECKED-OUT"){
            //     return false
            // }else{
            //     return true
            // }
    
      }


    return (
        <div>
            <h3>Appointment Information</h3>

            <div  style={{ padding: '10px', borderRadius: '10px', backgroundColor:"white" }}>
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                        <p style={{margin:"0", fontWeight:"600"}}>Appointment ID</p>
                        <p style={{margin:"10px 0", fontWeight:"600"}}>#{summaryDetails?.numAppointmentID}</p>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                        <p style={{margin:"0 0 10px 0", fontWeight:"600"}}>Location</p>
                        <Chip label={summaryDetails?.varLocationName} color='info' />
                    </div>
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                        <p style={{margin:"0 0 10px 0", fontWeight:"600"}}>Status</p>
                        <Chip label={(summaryDetails?.apptStatus === null) ? "UPCOMING" : summaryDetails?.apptStatus} color='success' />
                    </div>
                </div>
            </div>

            <div style={{ padding: '10px', borderRadius: '10px', backgroundColor:"white", marginTop:"10px" }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', paddingBottom: '10px' }}>
                    <Avatar shape="circular" size='extra-small' img={`${process.env.REACT_APP_MEDIA_URL}directories/pets/${summaryDetails?.Pet?.PetImagePath}`} isVip={false} className={'medium'} />
                    <div style={{fontWeight:"600"}}>
                        {summaryDetails?.Pet?.PetName}
                    </div>
                </div>
                <div style={{display:"flex", justifyContent:"space-between", background:"#efefef", borderRadius:"10px", padding:"10px"}}>
                    <p style={{ fontSize: '14px', margin: 0, lineHeight: '1.5em', fontWeight:"500" }}><b>Type:</b> {summaryDetails?.Pet?.PetType}</p>
                    <p style={{ fontSize: '14px', margin: 0, lineHeight: '1.5em', fontWeight:"500" }}><b>Gender:</b> {(summaryDetails?.Pet?.PetSex === "M")? "Male" : "Female"}</p>
                    <p style={{ fontSize: '14px', margin: 0, lineHeight: '1.5em', fontWeight:"500" }}><b>Size:</b> {summaryDetails?.Pet?.varPetSizeName}</p>
                    <p style={{ fontSize: '14px', margin: 0, lineHeight: '1.5em', fontWeight:"500" }}><b>Breed:</b> {summaryDetails?.Pet?.PetBreed}</p>
                </div>

                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", borderBottom:"1px solid #e3e3e3", paddingBottom:"10px", padding:"10px"}}>
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", marginTop:"5px"}}>
                        <p style={{ fontSize: '14px', margin: 0, lineHeight: '1.5em', fontWeight:"500" }}>Cage ID</p>
                        <p style={{ fontSize: '14px', margin: 0, lineHeight: '1.5em', fontWeight:"600" }}>{summaryDetails?.CageID}</p>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                        <p style={{ fontSize: '14px', margin: 0, lineHeight: '1.5em', fontWeight:"500" }}>Fragrance</p>
                        <p style={{ fontSize: '14px', margin: 0, lineHeight: '1.5em', fontWeight:"600" }}>{(checkinInfo?.IsFragrance)? "Added" : "Not added"}</p>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                        <p style={{ fontSize: '14px', margin: 0, lineHeight: '1.5em', fontWeight:"500" }}>Allergies</p>
                        {
                            (checkinInfo?.IsPetHasAllergies)? <Chip label={"Yes"} color='warning' /> 
                            : 
                            <Chip label={"No"} color='success' />
                        }
                    </div>
                </div>

                <p style={{fontSize:"16px", textAlign:"center", fontWeight:"600", marginTop:"10", marginBottom:"0", color:"#192252" }}>Before & after images</p>
                {
                     (summaryDetails?.varBeforeImgPath === null && summaryDetails?.varAfterImgPath === null)? <p style={{color:"gray", textAlign:"center", fontSize:"14px"}}>No images from past appointments</p> : 
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                            <div
                                style={{
                                    height: "100%",
                                    backgroundColor: "#fff",
                                    borderRadius: "8px",
                                    padding: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between"
                                }}
                            >
                                <div style={{ width: "100%", borderRadius: "8px" }}>
                                    {
                                        <ImgComparisonSlider
                                            style={{width:"100%"}}>
                                                <img style={{ maxHeight: "400px", objectFit: "cover" }} alt="404" slot="first" src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${summaryDetails.varBeforeImgPath}`} />
                                                <img style={{ maxHeight: "400px", objectFit: "cover" }} alt="404" slot="second" src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${summaryDetails.varAfterImgPath}`} />
                                        </ImgComparisonSlider>  
                                    }
                                </div>
                            </div>
                            <div>

                            <Button variant='contained' color='primary'  onClick={()=>{handleFacebookShare(`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${summaryDetails.varAfterImgPath}`, `${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${summaryDetails.varBeforeImgPath}`)}}>Share on Facebook</Button>
                            </div>
                        </div>
  
                }

                <div style={{ padding: '0 15px' }}>

                <p style={{ fontWeight: "600", fontSize: "16px", color:"#192252" }}>Services & products</p>
                    <p style={{ fontWeight: '600', fontSize: '14px' }}>Services</p>
                    <table style={{ fontSize: '14px', borderBottom: '1px solid #E5E7EB', paddingBottom: '.25rem', marginBottom: '.25rem', width: "100%" }}>
                        {       
                            (appointmentServices !== undefined)?
                            (appointmentServices.length > 0)?
                            appointmentServices.map(items => {
                                return  <tr>
                                    <td style={{ width: '100%' }}>{items?.varServiceOrProductName}</td>
                                    <td style={{ textAlign: 'right' }}>${items?.decChargeAmount}</td>
                                </tr>
                            }) : <p style={{margin:"0"}}>--</p>
                            : <></>
                        }                                                           
                    </table>
                    <p style={{ fontWeight: '600', fontSize: '14px', marginTop:"10px" }}>Products</p>
                    <table style={{ fontSize: '14px', borderBottom: '1px solid #E5E7EB', paddingBottom: '.25rem', marginBottom: '.25rem', width: "100%" }}>
                        {        
                            (appointmentProducts !== undefined)?  
                            (appointmentProducts.length > 0)?                                                                          
                            appointmentProducts.map(items => {
                                return  <tr>
                                    <td style={{ width: '100%', display:"flex", alignItems:"center" }}>{items?.varServiceOrProductName} <p style={{fontWeight: 500, color: '#6B7280', margin: '0'}}>&nbsp;({items?.numQuantity}x)</p></td>
                                    <td style={{ textAlign: 'right' }}>${items?.decChargeAmt}</td>
                                </tr>
                            }) : <p style={{margin:"0"}}>--</p>
                            : <></>
                        }                                                           
                    </table>
                    <div style={{ fontSize: '14px', width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '500' }}>
                            <div>Tax</div>
                            <div style={{ fontSize: '14px', textAlign: 'left', color: '#002867', fontWeight: '600' }}>
                                ${parseFloat(totTax.toString()).toFixed(2)}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '500' }}>
                            <div>Total Amount</div>
                            <div style={{ fontSize: '14px', textAlign: 'left', color: '#002867', fontWeight: '600' }}>
                                ${parseFloat((totPaid + totRemain).toString()).toFixed(2)}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '500' }}>
                            <div>Paid Amount</div>
                            <div style={{ fontSize: '14px', textAlign: 'left', color: '#002867', fontWeight: '600' }}>
                                ${parseFloat(totPaid.toString()).toFixed(2)}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '500' }}>
                            <div>Balance Amount</div>
                            <div style={{ fontSize: '14px', textAlign: 'left', color: '#002867', fontWeight: '600' }}>
                                ${parseFloat(totRemain.toString()).toFixed(2)}
                            </div>
                        </div>
                    </div> 
                    <div style={{ paddingTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => setIsOpenPaymentModal(true)} color={parseFloat(totRemain) > 0 ? "primary" : "default"} variant={parseFloat(totRemain) > 0 ? "contained" : "outlined"} text="Make Payment" />
                    </div>  

                    <CustomerInvoices  
                        isOpenCustomerInvoicesModal={isOpenPaymentModal} 
                        setIsOpenCustomerInvoicesModal={(value) => {
                            setIsOpenPaymentModal(value)
                        }} 
                        isPassedInvoice={false} appointmentDetails={summaryDetails} isSuccess={(e)=>{
                            if(e === true){
                                console.log("payment made")  
                                setIsOpenPaymentModal(false)
                                fetchInvoiceData(summaryDetails.numAppointmentID, "app")
                            }
                        }}
                        isSuccessFullPayment={(e)=>{
                            if(e === true){
                                fetchInvoiceData(summaryDetails.numAppointmentID, "app"); 
                                getAppointmentInfo(summaryDetails.numAppointmentID)  
                            }
                        }}
                    />                                                    
                </div>

                <div style={{ padding: '0 15px' }}>
                    <p style={{ fontWeight: "600", fontSize: "16px", color:"#192252" }}>Waivers</p>
                     <div style={{display:"flex", gap:"10px"}}>
                        {
                            (checkinInfo?.Waivers === undefined)? "" : 
                            (checkinInfo?.Waivers.length > 0)?
                            (checkinInfo?.Waivers[0].numAppointmentWaiverDocumentID === null)? <p style={{color:"gray", textAlign:"center", fontSize:"14px"}}>No waivers signed</p> :
                            checkinInfo?.Waivers.map((w, index)=>{
                                    return(
                                        
                                        <div key={index} style={{display:"flex", justifyContent:"space-between", width:"100%", alignItems:"center", border:"1px solid #d7d7d7", borderRadius:"10px", padding:"0 10px 0 10px"}}>
                                            <p style={{fontSize:"14px", fontWeight:"500", margin:"0"}}>{w.varWaiverDocumentName}</p>
                                            <IconButton  onClick={() => {
                                                    window.open(`${process.env.REACT_APP_MEDIA_URL}waiver/${w.varAppWaiverDocumentPath}`)
                                                }}>
                                                <Download style={{color:"green"}}></Download>
                                            </IconButton>
                                        </div>
                                    )
                            })
                            :
                            <p style={{color:"gray", textAlign:"center", fontSize:"14px"}}>No waivers signed</p>
                        }
                    </div>                                
                </div>

                <div style={{ padding: '0 15px' }}>
                    <p style={{ fontWeight: "600", fontSize: "16px", color:"#192252" }}>Pick up & drop off details</p>
                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <p style={{margin:"0", fontWeight:"500", marginBottom:"4px"}}>Drop off type</p>
                            {(checkinInfo?.DropOffPersonType === null)? <p style={{color:"gray", fontSize:"14px", margin:"0"}}>Not selected</p> :
                                <>
                                <p style={{margin:"0", color:"gray", marginBottom:"4px"}}>{checkinInfo?.DropOffPersonType}</p>
                                {
                                    (checkinInfo?.DropOffPersonType === "Customer")? <p style={{margin:"0"}}>{checkinInfo?.Customer?.varCustomerFirstName +" "+ checkinInfo?.Customer?.varCustomerLastName}</p> : (checkinInfo?.DropOffPersonType === "Linked Customer")? checkinInfo?.DropOffLinkedCustomerName : <p style={{margin:"0"}}>{checkinInfo?.DropOffThirdPartyName}</p>
                                }
                                </>
                            }

                        </div>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                            <p style={{margin:"0", fontWeight:"500", marginBottom:"4px"}}>Pick up type </p>
                            {(checkinInfo?.DropOffPersonType === null)? <p style={{color:"gray", fontSize:"14px", margin:"0"}}>Not selected</p> :
                            <>
                                <p style={{margin:"0", color:"gray", marginBottom:"4px"}}>{checkinInfo?.PickUpPersonType}</p>                  
                                {
                                    (checkinInfo?.PickUpPersonType === "Customer")? <p style={{margin:"0"}}>{checkinInfo?.Customer?.varCustomerFirstName +" "+ checkinInfo?.Customer?.varCustomerLastName}</p> : (checkinInfo?.PickUpPersonType === "Linked Customer")? checkinInfo?.PickUpLinkedCustomerName : <p style={{margin:"0"}}>{checkinInfo?.PickUpThirdPartyName}</p>
                                }
                            </>
                            }
   
                        </div>
                    </div>

                    <p style={{ fontWeight: "600", fontSize: "16px", color:"#192252" }}>Notes</p>

                    <p style={{ fontWeight: "600", fontSize: "14px", color:"#192252" }}>Check-in Note</p>
                    <p style={{margin:"0"}}>{(checkinInfo?.Notes === null)? <p style={{color:"gray", fontSize:"14px", margin:"0"}}>Groomer notes not added</p> : checkinInfo?.Notes}</p>

                    <p style={{ fontWeight: "600", fontSize: "14px", color:"#192252" }}>Groomer Notes</p>
                    <div style={{display:"flex", flexDirection:"column", gap:"10px", maxHeight:"200px", overflowY:"scroll"}}>
                        {
                            (summaryDetails?.GroomerNotes === undefined)? "" :
                            (summaryDetails?.GroomerNotes[0]?.numGroomerNoteID === 0)? <p style={{color:"gray", fontSize:"14px", margin:"0"}}>Check-in note not added</p> 
                            :
                            summaryDetails?.GroomerNotes?.map((note, index)=>{
                                return(
                                    <div style={{display:"flex", border:"1px solid #d7d7d7", borderRadius:"10px", padding:"10px 10px 10px 10px", flexDirection:"column", gap:"10px"}}>
                                        <p style={{fontWeight:"600", margin:"10px 0 0 0", fontSize:"14px"}}>Employee <span>{note?.varCreatedEmployeeFirstName +" "+ note?.varCreatedEmployeeLastName}</span></p>
                                        <p style={{margin:"0", fontSize:"14px"}}>{note?.varGroomerComment}</p>
                                        <p style={{margin:"0", fontSize:"14px", textAlign:"end", color:"gray"}}>{formatDateTime(note?.dtCreatedDate)}</p>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>

                <div style={{ padding: '0 15px' }}>
                    <p style={{ fontWeight: "600", fontSize: "16px", color:"#192252" }}>Wellness evaluation</p>
                    <div style={{display:"flex", gap:"10px", marginBottom:"10px"}}>
                        <p style={{ fontWeight: "500", margin:"0" }}>Date</p>
                        <p style={{ fontWeight: "400", margin:"0" }}>
                            {checkinInfo?.CurrentWellnessEvaluation !== undefined ? shortDate(checkinInfo?.CurrentWellnessEvaluation?.WellnessLogDate) : "--"}
                        </p>
                    </div>
                    <div style={{display:"flex", gap:"10px", marginBottom:"10px"}}>
                        <p style={{ color: "#848FAC", fontWeight: "500", margin:"0" }}>Evaluator</p>
                            <p style={{ color: "#848FAC", fontWeight: "600", margin:"0" }}>
                                {checkinInfo?.CurrentWellnessEvaluation !== undefined
                                    ? checkinInfo?.CurrentWellnessEvaluation?.varWellnessEmployeeFirstName !== null
                                        ? checkinInfo?.CurrentWellnessEvaluation?.varWellnessEmployeeFirstName
                                        : "-" + " " + (checkinInfo?.CurrentWellnessEvaluation?.varWellnessEmployeeLastName !== null)
                                        ? checkinInfo?.CurrentWellnessEvaluation?.varWellnessEmployeeLastName
                                        : "-"
                                    : "-"}
                            </p>
                    </div>


                        <div style={{ display: "flex", gap: ".5rem" }}>
                            <Button onClick={() => {setIsOpenWellnessRep(true)}} color="default" variant="outlined" text="View wellness report" />
                        </div>
                </div>            
            </div>
            

            <div style={{ padding: '10px', borderRadius: '10px', backgroundColor:"white", marginTop:"10px" }}>
                <p style={{ fontWeight: '600', fontSize: '16px' }}>Assigned Staff</p>
            <div style={{display:"flex", justifyContent:"space-between",}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Avatar shape="circular" img={`${process.env.REACT_APP_MEDIA_URL}directories/employees/${summaryDetails?.Employee?.varEmployeeImagePath}`} isVip={false} className={'medium'} />
                    <div style={{fontWeight:"600"}}>
                        {summaryDetails?.Employee?.varEmployeeFirstName} {summaryDetails?.Employee?.varEmployeeLastName}
                    </div>
                </div>
                <div style={{display:"flex", gap:"10px"}}>
                    <div
                        style={{
                            flexShrink: 0,
                            backgroundColor: "#2e7d32",
                            padding: "10px 15px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "30px",
                            borderRadius: "8px",
                            fontWeight: "500",
                            color: "#fff",
                            width: "fit-content",
                            cursor: "pointer",
                            flexDirection: "column",
                            fontSize:"14px"
                        }}
                    >
                        <p style={{ margin: 0 }}>Start</p>
                        <p style={{ margin: 0 }}>{convertToReadableDate(summaryDetails?.dtApptStartTime)}</p>
                    </div>
                    <div
                        style={{
                            flexShrink: 0,
                            backgroundColor: "#2e7d32",
                            padding: "10px 15px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "30px",
                            borderRadius: "8px",
                            fontWeight: "500",
                            color: "#fff",
                            width: "fit-content",
                            cursor: "pointer",
                            flexDirection: "column",
                            fontSize:"14px"
                        }}
                    >
                        <p style={{ margin: 0 }}>End</p>
                        <p style={{ margin: 0 }}>{convertToReadableDate(summaryDetails?.dtApptEndTime)}</p>
                    </div>
                </div>
            </div>

            </div>

            <Modal open={isOpenWellnessRep}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ backgroundColor: "#fff", width: "calc(100vw - 100px)", height: "calc(100vh - 100px)", borderRadius: "10px" }}>
                                    <div style={{ display: "flex", flexDirection: "column", height: "-webkit-fill-available", gap: "20px", padding: "20px" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                        <p style={{ fontSize: "20px", fontWeight: "600", color: "#192252" }}>Wellness report</p>
                                        <CloseButton onClick={() => setIsOpenWellnessRep(false)} />
                                    </div>
                                        <div style={{height:"900px", overflowY:"scroll"}}>
                                            <div style={{height:"500px", overflowY:"scroll"}}>
                                            {
                                                (wellnessBaseDataList?.Wellness === undefined)? "" :
                                                (wellnessBaseDataList?.Wellness.length > 0)? 
                                                wellnessBaseDataList.Wellness.map((wellness, index)=>{
                                                    return <div style={{background:"#EFF3FA", borderRadius:"8px", padding:"10px", marginBottom:"10px",}}>
                                                        <p style={{color:"#192252", fontWeight:"600"}}>{wellness?.WellnessCheckBodyPartName}</p>
                                                        <div style={{display:"flex", gap:"10px", alignItems:"center"}}>
                                                            <p>Status</p>
                                                            {
                                                                (wellness?.isAbnormal)?  <Chip label={"Abnormal"} color='success' /> : <Chip label={"Normal"} color='success' />
                                                            }
                                                        </div>

                                                        {
                                                            (wellness?.isAbnormal)? <div style={{display:"flex", gap:"10px", alignItems:"center"}}>
                                                            <p>Notes</p>
                                                            <div style={{display:"flex", gap:"10px"}}>
                                                                {
                                                                    wellness?.Concerns.map((con, index)=>{
                                                                        if(con.checked === true){
                                                                            return <Chip label={con?.ConcernName} color='default' />
                                                                        }
                                                                    })
                                                                }
                                                            </div>
                                                        </div> : null
                                                        }

                                 
                                                    </div>

                                                })
                                                :
                                                <p style={{color:"gray", textAlign:"center", fontSize:"14px"}}>No wellness data</p>
                                            }
                                            </div>

                                            <p style={{ fontWeight: "600", fontSize: "18px", color:"#192252" }}>Problem area pictures</p>

                                            <div style={{ display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gridColumnGap: "1rem", rowGap: "0ch", paddingLeft: "1rem" }}>
                                                {wellnessBaseDataList === undefined
                                                    ? "Loading.."
                                                    : wellnessBaseDataList.checkImageList === undefined
                                                    ? "Loading.."
                                                    : wellnessBaseDataList.checkImageList.length < 0
                                                    ? <p style={{color:"gray", fontSize:"14px", margin:"0"}}>Pictures not uploaded</p>
                                                    : wellnessBaseDataList.checkImageList.map((files, index) => {
                                                        return (
                                                            <img
                                                                alt=""
                                                                src={files.varImagePath == "" || files.varImagePath == null ? undefined : `${process.env.REACT_APP_MEDIA_URL}wellness/` + files.varImagePath}
                                                                style={{
                                                                    objectFit: "cover",
                                                                    borderRadius: "5px",
                                                                    marginBottom: ".3rem",
                                                                    width: "100px",
                                                                    height: "96px",
                                                                    border: "1px solid #e9e9e9",
                                                                    boxShadow: "5px 5px 5px 0px rgba(235,235,235,1)",
                                                                }}
                                                                        onClick={() => {
                                                                    window.open( `${process.env.REACT_APP_MEDIA_URL}wellness/` + files.varImagePath)
                                                                }}
                                                            />
                                                        )
                                                    })}
                                            </div>

                                            <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                                                <div>
                                                    <p style={{ fontWeight: "600", fontSize: "18px", color:"#192252" }}>Note for the client</p>
                                                    <p style={{ fontWeight: "500", fontSize: "15px" }}>{(wellnessBaseDataList?.NotesForClient === null)? <p style={{color:"gray", fontSize:"14px", margin:"0"}}>Not added</p> : wellnessBaseDataList?.NotesForClient}</p>
                                                </div>
                                                <div>
                                                    <p style={{ fontWeight: "600", fontSize: "18px", color:"#192252" }}>Suggestions for home</p>
                                                    <p style={{ fontWeight: "500", fontSize: "15px" }}>{(wellnessBaseDataList?.Suggestions === null)? <p style={{color:"gray", fontSize:"14px", margin:"0"}}>Not added</p> : wellnessBaseDataList?.Suggestions}</p>
                                                </div>



                                            </div>


                                            <p style={{ fontWeight: "600", fontSize: "18px", color:"#192252" }}>Recommended Services</p>

                                            <div  style={{ overflowX: "hidden", overflowY: "hidden", width: "calc(100%)", display: "flex", gap: "5px", paddingLeft:"10px"}}>
                                        {
                                            wellnessBaseDataList?.RecommendedServices?.map((item, index)=>{
                                                return (
                                                    <div key={index}>
                                                               <div
                                                                    style={{
                                                                        backgroundColor: "#DCFCE7",
                                                                        border: "1px solid #86EFAC",
                                                                        borderRadius: "16px",
                                                                        color: "#14532D",
                                                                        fontSize: "13px",
                                                                        fontWeight: "500",
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                        padding: "2px 10px 2px 10px",
                                                                        gap: ".2rem",
                                                                        width: "max-content",
                                                                        cursor:"pointer"
                                                                    }}
                                                                   
                                                                >
                                                                    {Truncate(item.varServiceItemName, 20)}
                                                                </div>
                                                    </div>
                                                )
                                            })
                                        }
    
                                    </div>

                                    <p style={{ fontWeight: "600", fontSize: "18px", color:"#192252" }}>Recommended Products</p>

                                    <div  style={{ overflowX: "hidden", overflowY: "hidden", width: "calc(100%)", display: "flex", gap: "5px", paddingLeft:"10px"}}>
                                        {
                                            wellnessBaseDataList?.RecommendedProducts?.map((item, index)=>{
                                                return (
                                                    <div key={index}>
                                                               <div
                                                                    style={{
                                                                        backgroundColor: "#DCFCE7",
                                                                        border: "1px solid #86EFAC",
                                                                        borderRadius: "16px",
                                                                        color: "#14532D",
                                                                        fontSize: "13px",
                                                                        fontWeight: "500",
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                        padding: "2px 10px 2px 10px",
                                                                        gap: ".2rem",
                                                                        width: "max-content",
                                                                        cursor:"pointer"
                                                                    }}
                                                                
                                                                >
                                                                    {Truncate(item.varProductName, 20)}
                                                                </div>
                                                    </div>
                                                )
                                            })
                                        }
    
                                    </div>


          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
        </div>
    );
}

export default ViewAppointment;