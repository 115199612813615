import { useEffect, useMemo, useRef, useState } from "react"
import useAuth from "../../Hooks/useAuth"
import { ReactComponent as Logo } from "../../images/logo.svg"
import { useNavigate, useLocation, Link } from "react-router-dom"
import  axios, { axiosPrivate } from "../../Apis/axios"
import InputMask from 'react-input-mask';
import { TextField, Button, Grid, Container, Typography, Box, InputAdornment, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { AccountCircle, Lock, Email, Phone, LocationOn, LocationCity, Public, Visibility, VisibilityOff } from '@mui/icons-material';
import styles from "../Login/Login.module.scss";
import { toast, ToastContainer } from "react-toastify"
import Swal from "sweetalert2"
import { encrypt } from "../../Utils/encrypt"
import debouce from "lodash.debounce"

let mainLogo = require("../../images/mesh-gradient-vertical.jpg")

function Register(props) {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: '',
        zipCode: '',
        username: '',
        password: '',
        confirmPassword: '',
        city: '',
        state: ''
      }) as any;

      const firstNameRef = useRef() as any;
      const lastNameRef = useRef()  as any;
      const emailRef = useRef()  as any;
      const mobileNumberRef = useRef()  as any;
      const zipCodeRef = useRef()  as any;
      const cityRef = useRef()  as any;
      const stateRef = useRef()  as any;

      useEffect(() => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          firstName: firstNameRef.current?.value || prevFormData.firstName,
          lastName: lastNameRef.current?.value || prevFormData.lastName,
          email: emailRef.current?.value || prevFormData.email,
          mobileNumber: mobileNumberRef.current?.value || prevFormData.mobileNumber,
          zipCode: zipCodeRef.current?.value || prevFormData.zipCode,
          city: cityRef.current?.value || prevFormData.city,
          state: stateRef.current?.value || prevFormData.state,
        }));
      }, []);
    
      const [errors, setErrors] = useState({}) as any;
      const [isLoading, setIsLoading] = useState(false) as any;
    
      const validateForm = () => {
        const newErrors:any = {};
        
        // First Name
        if (!formData.firstName) newErrors.firstName = 'First name is required';
        
        // Last Name
        if (!formData.lastName) newErrors.lastName = 'Last name is required';
        
        // Email
        if (!formData.email) {
          newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          newErrors.email = 'Email is invalid';
        }
        
        // Mobile Number
        if (!formData.mobileNumber) {
          newErrors.mobileNumber = 'Mobile number is required';
        } else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formData.mobileNumber)) { // Check for the masked format
          newErrors.mobileNumber = 'Mobile number must be in the format (999) 999-9999';
        }
    
      // Zip Code Validation
      if (!formData.zipCode) {
        newErrors.zipCode = 'Zip code is required';
      } else if (!/^\d{5}(-\d{4})?$/.test(String(formData.zipCode))) {  // Convert to string for validation
        newErrors.zipCode = 'Zip code is invalid';
      }
        
        // Password
        if (!formData.password) newErrors.password = 'Password is required';
        
        // Confirm Password
        if (formData.password !== formData.confirmPassword) {
          newErrors.confirmPassword = 'Passwords do not match';
        }
        // City
        if (!formData.city) newErrors.city = 'City is required';
        // State
        if (!formData.state) newErrors.state = 'State is required';
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
          setIsLoading(true)
          console.log('Form Data sent:', formData);
          getData()
          .then((res) => {
              console.log("Customer Account created Res-", res )
              
              createUserAccount(res?.numResponseID, formData?.email)
              .then((resUser) => {
                  
                  console.log("CUSTOMER USER ACCOUNT CREATED RES", resUser)
                  const encrypted = encrypt(resUser?.data?.numUserID.toString());
                 sendAccountActivateEmail(encrypted, res)
              })
              .catch((errUser) => {
                  console.log("CUSTOMER USER ACCOUNT Err-", errUser)
                  toast.error("Something went wrong when creating new customer!")
                  setIsLoading(false)
              })
          })
          .catch((err) => {
            setIsLoading(false)
            console.log("Customer Account Err -", err)
              if(err?.response?.data?.error?.errorMessage === "Customer email already exists."){
                  toast.error(`Email address is already registered!`)
              }else{
                  console.log("Customer Account Err -", err)
                  toast.error("Something went wrong when saving new customer!")
              }
          })
        }
      };

      async function getCustomerInfo(id, encID) {
        await axios
            .get(`${process.env.REACT_APP_API_URL}Customer/GetByID/${id}`)
            .then((result) => {
                if (result.status === 200) {
                    console.log("customerDD", result?.data?.customer)

                       let emailBody = 
                          `<!DOCTYPE html>
                          <html lang="en">
                          <head>
                              <meta charset="UTF-8">
                              <title>Activate Your Account</title>
                              <style>
                                  body {
                                      font-family: Arial, sans-serif;
                                      margin: 0;
                                      padding: 20px;
                                      background-color: #f4f4f4;
                                  }
                                  .container {
                                      background-color: #ffffff;
                                      padding: 20px;
                                      border-radius: 8px;
                                      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                                      max-width: 600px;
                                      margin: 0 auto;
                                  }
                                  .header {
                                      display: flex;
                                      align-items: center;
                                      justify-content: space-between;
                                  }
                                  .logo {
                                      max-width: 100px;
                                      height: auto;
                                  }
                                  .content {
                                      margin-top: 20px;
                                  }
                                  .button {
                                      background-color: #007bff;
                                      color: white;
                                      padding: 10px 15px;
                                      text-decoration: none;
                                      border-radius: 5px;
                                      display: inline-block;
                                      margin-top: 20px;
                                  }
                                  .button:hover {
                                      background-color: #0056b3;
                                  }
                              </style>
                          </head>
                          <body>
                              <div class="container">
                                  <div class="header">
                                      <img src="https://test.esypet.net/static/media/esypet-logo.5f7c03070095ad942f20.png" alt="EsyPet Logo" class="logo" />
                                  </div>
                                  <div class="content">
                                      <p><strong>Dear Valued Customer,</strong></p>
                                      <p>Welcome to EsyPet! We are excited to have you on board. Your account has been successfully created, and you are just one step away from exploring our pet grooming services.</p>
                                      <p>To activate your account and start scheduling appointments for your pet, please click the button below:</p>
                                      <a href="http://localhost:3000/activate-account/${encID}" class="button">Activate Your Account</a>
                                      <p>If you did not sign up for an account, please ignore this email.</p>
                                      <p>Thank you for choosing EsyPet. We look forward to providing the best grooming care for your pets!</p>
                                  </div>
                              </div>
                          </body>
                          </html>`;

        let payload = {
          messageBody: [
              {
                  messageID: 1,
                  recipientID: result?.data?.customer?.numCustomerID,
                  recipientType: "C",
                  senderID: result?.data?.customer?.numCustomerID,
                  senderLocationID: result?.data?.customer?.numCustomerDefaultLocationID,
                  subject: `Account activation`,
                  smsBody: emailBody,
                  emailBody: emailBody,
                  communicationMethod: "E",
                  sender: result?.data?.customer?.varCustomerFirstName +""+ result?.data?.customer?.varCustomerLastName,
                  senderLocation: result?.data?.customer?.varCustomerDefaultLocation,
                  contactNumber: result?.data?.customer?.varCustomerMobilePhone,
                  emailAddress: result?.data?.customer?.varCustomerEmail,
                  appointmentID: null,
                  option: result?.data?.customer?.varCustomerEmail === null ? 1 : 2,
                  sendDate: new Date().toISOString().split("T")[0],
                  messageType: "I",
                  workPhoneNumber: result?.data?.customer?.varCustomerWorkPhone,
                  homePhoneNumber: result?.data?.customer?.varCustomerHomePhone,
                  bitCustomerNotificationEmail: true,
                  numCustomerNotificationEmailSequence: 1,
                  bitCustomerNotificationHomePhone: false,
                  numCustomerNotificationHomePhoneSequence: 0,
                  bitCustomerNotificationMobilePhoneCall: false,
                  numCustomerNotificationMobilePhoneCallSequence: 0,
                  bitCustomerNotificationMobilePhoneSMS: false,
                  numCustomerNotificationMobilePhoneSMSSequence: 0,
                  bitCustomerNotificationWorkPhone: false,
                  numCustomerNotificationWorkPhoneSequence: 0,
                  messageTemplateID: null,
                  attachmentModel: []
              },
          ],
      }
      console.log("payloadEMail", payload)
      
      sendMessagePost(payload)

                }
            })
            .catch((err) => {
                console.log(err)
                console.log("Something went wrong!")
                setIsLoading(false)

            })
      }

      const sendAccountActivateEmail = (encID, customerData) => {
        console.log("final", encID, customerData)
        getCustomerInfo(customerData?.numResponseID, encID)
      }

      async function sendMessagePost(data) {
        await axios
            .post(`${process.env.REACT_APP_TEXT_API_URL}UpdateMessages/UpdateMessagesPool`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("Message sent to pool")
                    showRegistrationSuccessAlert()
                }
            })
            .catch((err) => {
                console.log("Messaging -Err-", err)
                setIsLoading(false)
            })
    }
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
      };
      const theme: any = useTheme();
    
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // Desktop


    async function getData() {

      const result = await axiosPrivate.post(
          "Customer",
          JSON.stringify({
              customer: {
                  bitActive: true,
                  numCreatedBy: 0,
                  varCreatedBy: "",
                  dtCreatedDate: "2022-08-18T11:23:17.463Z",
                  numEditedBy: 0,
                  varEditedBy: "",
                  dtEditedDate: "2022-08-18T11:23:17.463Z",
                  numDeletedBy: 0,
                  varDeletedBy: "",
                  dtDeletedDate: "2022-08-18T11:23:17.463Z",
                  numCustomerID: 0,
                  varAltCustomerID: "",
                  numCustomerDefaultLocationID: 1,//Needs to be fixed
                  numCustomerLastLocationID: 0,
                  varCustomerBC: "",
                  varCustomerFirstName: formData?.firstName,
                  varCustomerLastName: formData?.lastName,
                  varCustomerSalutation: "",
                  bitPushNotificationEnabled: true,
                  varCustomerEmail: formData?.email,
                  bitCustomerNotificationEmail: true,
                  varCustomerHomePhone: null,
                  bitCustomerNotificationHomePhone: true,
                  varCustomerMobilePhone: formData?.mobileNumber,
                  bitCustomerNotificationMobilePhone: true,
                  varCustomerWorkPhone: "",
                  bitCustomerNotificationWorkPhone: true,
                  varCustomerStatus: "",
                  varCustomerComments: null,
                  varCustomerWarningAlert: "",
                  varCustomerFinancialAlert: "",
                  numCustomerPreferredGroomerID: 0,
                  varCustomerUsername: "",
                  varCustomerPassword: "",
                  varCustomerUsernameResetQ1: "",
                  varCustomerUsernameResetA1: "",
                  varCustomerUsernameResetQ2: "",
                  varCustomerUsernameResetA2: "",
                  varCustomerUsernameResetQ3: "",
                  varCustomerUsernameResetA3: "",
                  varEmergContactFirstName: "",
                  varEmergContactLastName: "",
                  varEmergContactType: "",
                  varEmergContactNumber: "",
                  bitIsVip: false,
                  bitCustomerAlertNoShow: false,
                  bitCustomerAlertOutstandingBill: false,
                  bitCustomerAlertDiscountOnFile: false,
                  bitCustomerAlertAlwaysLate: false,
                  bitCustomerAlertVerbalConfirmationNeeded: false,
                  bitCustomerAlertDifficultToHandle: false,
                  varCustomerImagePath: "",
                  varCustomerCustomText1: "",
                  varCustomerCustomText2: "",
                  dtCustomerCustomDate1: "",
                  dtCustomerCustomDate2: "",
                  numCustomerCustomerList1ID: 0,
                  numCustomerCustomerList2ID: 0,
                  bitCustomerCustomBit1: false,
                  bitCustomerCustomBit2: false,
              },
              customerAddress: [
                  {
                      bitActive: true,
                      numCustomerAddressID: 0,
                      numAddressTypeID: 1,
                      varCustomerAddress1: "",
                      varCustomerAddress2: "",
                      varCustomerCity: formData?.city,
                      varCustomerState: formData?.state,
                      varCustomerZip: formData?.zipCode,
                      varCustomerAddressNotes: "",
                      bitIsPrimaryDefault: true,
                      bitIsPrimary: true,
                      bitIsBillingDefault: true,
                      bitIsBilling: true,
                  },
              ],
          })
      )
      return result.data
  }

  const showRegistrationSuccessAlert = () => {
    Swal.fire({
      title: 'Registration Successful!',
      html: `
        <div style="font-size: 16px; margin-top: 10px;">
          Please confirm your email by clicking the link that has been sent to your email address.
        </div>
      `,
      iconHtml: `<div style="display: flex; justify-content: center;">
                   <img src="https://cdn-icons-png.flaticon.com/512/190/190411.png" alt="success-icon" style="width: 80px; height: 80px;" />
                 </div>`,
      showCloseButton: true,
      focusConfirm: false,
      confirmButtonText: 'OK',
      customClass: {
        icon: 'no-border',
      }
    }).then((result) => {
      if (result.isConfirmed || result.dismiss) {
        console.log("SweetAlert was closed.");
        setIsLoading(false)
        navigate("/login");
      }
    });
  };

  async function getStateCity(zipCode) {
    const result = await axiosPrivate.get(`ZipCode/GetCityStateByZipCode/${zipCode}`)
    return result
}

const handleZipSearch = (value) => {
  getStateCity(value)
    .then((response) => {
      const resultZip = response?.data;
      setFormData((prevData) => ({
        ...prevData,
        state: resultZip?.varState,
        city: resultZip?.varCity,
      }));
    })
    .catch((err) => {
      setFormData((prevData) => ({
        ...prevData,
        state: '',
        city: '',
      }));
    });
};


  const debouceZipSearch = useMemo(() => {
    return debouce(handleZipSearch, 500)
}, [])

const [showConfirmPassword, setShowConfirmPassword] = useState(false);
const toggleConfirmPasswordVisibility = () => {
  setShowConfirmPassword((prevState) => !prevState);
};

  async function createUserAccount(customerID, email) {
    let payload = {
        varCustomerFirstName: "",
        varCustomerLastName: "",
        varCustomerEmail: "",
        varCustomerMobilePhone: "",
        varCustomerZip: "",
        varUserName: email,
        varUserPassword: formData?.confirmPassword,
        numEmployeeID: null,
        numCustomerID: customerID,
    }

    const result = await axiosPrivate.post(`Auth/Register`, payload)
    return result
}
    return (
        <>
             <ToastContainer />
  <div
    style={{
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1000,
        display: "flex",
        justifyContent: "space-between",
        height: "79px",
        alignItems: "center",
        padding: "0 20px",
        backgroundColor: "#fff",
        borderBottom: "1px solid #eee",
        boxSizing: "border-box",
    }}
    >
        <div>
            <Logo />
        </div>
        <div
            style={{
            fontSize: "16px",
            fontWeight: "400",
            color: "#606D93",
            maxWidth: "50%", 
            display: "flex",
            flexWrap: "wrap",  
            justifyContent: "flex-end",
            }}
        >
            Already a member?{" "}
            <span
            style={{
                fontSize: "16px",
                fontWeight: "700",
                color: "#2076FF",
                marginLeft: "5px", 
            }}
            >
            <Link to={"/login"}>Sign In</Link>
            </span>
        </div>
    </div>
    {!isDesktop && (
    <Container maxWidth="lg" disableGutters style={{ backgroundColor: "white", height: "100vh", width: "100%" }}>
      <Grid container spacing={0} sx={{ mt: 3 }} style={{ marginTop: "0", height: "100%" }}>

        {/* Image section for Mobile and Tablet views */}
        {!isDesktop && (
          <Grid
            item
            xs={12}
            sx={{
              display: { xs: 'block', md: 'none' },
              position: 'relative',
              height: { xs: '250px', sm: '300px' },
              backgroundImage: `url(${mainLogo})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          />
        )}

        {/* Form for Mobile and Tablet Views */}
        {!isDesktop && (
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              position: { xs: 'relative', md: 'static' },
              top: { xs: '-80px', sm: '-120px', md: '0' },
              backgroundColor: { xs: 'white', md: 'transparent' },
              mx: { xs: 2, sm: 4, md: 0 },
              borderRadius: { xs: '50px', sm: '50px', md: '0' },
              boxShadow: { xs: '0px 4px 10px rgba(0, 0, 0, 0.1)', md: 'none' },
              p: { xs: 2, sm: 4 },
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: { xs: 3 } }}>
              {/* Title */}
              <Typography
                variant="h5"
                gutterBottom
                sx={{ color: "#192252", fontSize: { md: "2rem", xs: "1.5rem" }, mt: { md: 6 } }}
              >
                Tell us more about yourself
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontSize: "12px", color: "#606d93" }}
              >
                Enter your details to proceed further
              </Typography>

              {/* Form */}
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  {/* First Name */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="First name"
                      name="firstName"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle style={{ color: "#a9b4cd" }} />
                          </InputAdornment>
                        ),
                      }}
                      value={formData.firstName}
                      onChange={handleInputChange}
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                      inputRef={firstNameRef}
                      autoFocus
                    />
                  </Grid>

                  {/* Last Name */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="Last name"
                      name="lastName"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle style={{ color: "#a9b4cd" }} />
                          </InputAdornment>
                        ),
                      }}
                      value={formData.lastName}
                      onChange={handleInputChange}
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                      inputRef={lastNameRef}
                    />
                  </Grid>

                  {/* Mobile Number */}
                  <Grid item xs={12} sm={6}>
                    <InputMask
                      mask="(999) 999-9999"
                      maskChar={null}
                      value={formData.mobileNumber}
                      onChange={handleInputChange}
                    >
                      {() => (
                        <TextField
                          required
                          fullWidth
                          label="Mobile number"
                          name="mobileNumber"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Phone style={{ color: "#a9b4cd" }} />
                              </InputAdornment>
                            ),
                          }}
                          error={!!errors.mobileNumber}
                          helperText={errors.mobileNumber}
                          inputRef={mobileNumberRef}
                        />
                      )}
                    </InputMask>
                  </Grid>

                  {/* Zip Code */}
                  <Grid item xs={12} sm={6}>
                    <InputMask
                      mask="99999"
                      value={formData.zipCode}
                      onChange={(e) => {
                        handleInputChange(e);
                        const zipCode = e.target.value;
                        if (zipCode.length === 5 && /^\d{5}$/.test(zipCode)) {
                          debouceZipSearch(zipCode);
                        }
                      }}
                      maskPlaceholder={null}
                    >
                      {() => (
                        <TextField
                          required
                          fullWidth
                          label="Zip code"
                          name="zipCode"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LocationOn style={{ color: "#a9b4cd" }} />
                              </InputAdornment>
                            ),
                          }}
                          error={!!errors.zipCode}
                          helperText={errors.zipCode}
                          inputRef={zipCodeRef}
                        />
                      )}
                    </InputMask>
                  </Grid>

                  {/* City */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="City"
                      name="city"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationCity style={{ color: "#a9b4cd" }} />
                          </InputAdornment>
                        ),
                      }}
                      value={formData.city}
                      onChange={handleInputChange}
                      error={!!errors.city}
                      helperText={errors.city}
                      inputRef={cityRef}
                    />
                  </Grid>

                  {/* State */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="State"
                      name="state"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                              <Public style={{ color: "#a9b4cd" }} />
                          </InputAdornment>
                        ),
                      }}
                      value={formData.state}
                      onChange={handleInputChange}
                      error={!!errors.state}
                      helperText={errors.state}
                      inputRef={stateRef}
                    />
                  </Grid>

                  {/* Email Address */}
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="Email address/Username"
                      name="email"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email style={{ color: "#a9b4cd" }} />
                          </InputAdornment>
                        ),
                      }}
                      value={formData.email}
                      onChange={handleInputChange}
                      error={!!errors.email}
                      helperText={errors.email}
                      inputRef={emailRef}
                    />
                  </Grid>

                  {/* Password */}
                  <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Lock style={{ color: "#a9b4cd" }} />
                            </InputAdornment>
                          ),
                        }}
                        value={formData.password}
                        onChange={handleInputChange}
                        error={!!errors.password}
                        helperText={errors.password}
                      />
                    </Grid>

                    {/* Confirm Password */}
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Lock style={{ color: "#a9b4cd" }} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={toggleConfirmPasswordVisibility} edge="end">
                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword}
                      />
                    </Grid>
                </Grid>

                {/* Submit Button */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={isLoading}
                >
                  Get Started
                </Button>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
    )}
            {/* Desktop View */}
            {isDesktop && (
 <>
 <div style={{ display: 'flex', justifyContent: 'space-between', height: '79px', alignItems: 'center', padding: '0 20px', backgroundColor: '#fff', borderBottom: '1px solid #eee' }}>
   <div><Logo /></div>
   <div style={{ fontSize: "16px", fontWeight: "400", color: "#606D93" }}>New member? <span style={{ fontSize: "16px", fontWeight: "700", color: "#2076FF" }}><Link to={'/register'}>Sign Up</Link></span></div>
 </div>
 <div className={styles.responsiveLayout} style={{ display: 'flex', height: 'calc(100vh - 80px)' }}>
   <div className={styles.formWrapper} style={{ flex: 1, display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center', flexDirection: 'column', backgroundColor: '#fff' }}>

     <div  style={{ width: '80%' }}>
     <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: { xs: 3 } }}>
              {/* Title */}
              <Typography
                variant="h5"
                gutterBottom
                sx={{ color: "#192252", fontSize: { md: "2rem", xs: "1.5rem" }, mt: { md: 6 } }}
              >
                Tell us more about yourself
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontSize: "12px", color: "#606d93" }}
              >
                Enter your details to proceed further
              </Typography>

              {/* Form */}
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  {/* First Name */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="First name"
                      name="firstName"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle style={{ color: "#a9b4cd" }} />
                          </InputAdornment>
                        ),
                      }}
                      value={formData.firstName}
                      onChange={handleInputChange}
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                      inputRef={firstNameRef}
                      autoFocus
                    />
                  </Grid>

                  {/* Last Name */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="Last name"
                      name="lastName"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle style={{ color: "#a9b4cd" }} />
                          </InputAdornment>
                        ),
                      }}
                      value={formData.lastName}
                      onChange={handleInputChange}
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                      inputRef={lastNameRef}
                    />
                  </Grid>

                  {/* Mobile Number */}
                  <Grid item xs={12} sm={6}>
                    <InputMask
                      mask="(999) 999-9999"
                      maskChar={null}
                      value={formData.mobileNumber}
                      onChange={handleInputChange}
                    >
                      {() => (
                        <TextField
                          required
                          fullWidth
                          label="Mobile number"
                          name="mobileNumber"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Phone style={{ color: "#a9b4cd" }} />
                              </InputAdornment>
                            ),
                          }}
                          error={!!errors.mobileNumber}
                          helperText={errors.mobileNumber}
                          inputRef={mobileNumberRef}
                        />
                      )}
                    </InputMask>
                  </Grid>

                  {/* Zip Code */}
                  <Grid item xs={12} sm={6}>
                    <InputMask
                      mask="99999"
                      value={formData.zipCode}
                      onChange={(e) => {
                        handleInputChange(e);
                        const zipCode = e.target.value;
                        if (zipCode.length === 5 && /^\d{5}$/.test(zipCode)) {
                          debouceZipSearch(zipCode);
                        }
                      }}
                      maskPlaceholder={null}
                    >
                      {() => (
                        <TextField
                          required
                          fullWidth
                          label="Zip code"
                          name="zipCode"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LocationOn style={{ color: "#a9b4cd" }} />
                              </InputAdornment>
                            ),
                          }}
                          error={!!errors.zipCode}
                          helperText={errors.zipCode}
                          inputRef={zipCodeRef}
                        />
                      )}
                    </InputMask>
                  </Grid>

                  {/* City */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="City"
                      name="city"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                                <LocationCity style={{ color: "#a9b4cd" }} />
                          </InputAdornment>
                        ),
                      }}
                      value={formData.city}
                      onChange={handleInputChange}
                      error={!!errors.city}
                      helperText={errors.city}
                      inputRef={cityRef}
                    />
                  </Grid>

                  {/* State */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="State"
                      name="state"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Public style={{ color: "#a9b4cd" }} />
                          </InputAdornment>
                        ),
                      }}
                      value={formData.state}
                      onChange={handleInputChange}
                      error={!!errors.state}
                      helperText={errors.state}
                      inputRef={stateRef}
                    />
                  </Grid>

                  {/* Email Address */}
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="Email address/Username"
                      name="email"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email style={{ color: "#a9b4cd" }} />
                          </InputAdornment>
                        ),
                      }}
                      value={formData.email}
                      onChange={handleInputChange}
                      error={!!errors.email}
                      helperText={errors.email}
                      inputRef={emailRef}
                    />
                  </Grid>

                  {/* Password */}
                  <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Lock style={{ color: "#a9b4cd" }} />
                            </InputAdornment>
                          ),
                        }}
                        value={formData.password}
                        onChange={handleInputChange}
                        error={!!errors.password}
                        helperText={errors.password}
                      />
                    </Grid>

                    {/* Confirm Password */}
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Lock style={{ color: "#a9b4cd" }} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={toggleConfirmPasswordVisibility} edge="end">
                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword}
                      />
                    </Grid>
                </Grid>

                {/* Submit Button */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={isLoading}
                >
                  Get Started
                </Button>
              </Box>
            </Box>
     </div>

   </div>
   <div className={styles.bgWrapper} style={{ flex: 1, backgroundImage: `url(${mainLogo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>

   </div>
 </div>
 <div className={styles.footer}>
       Copyright©{" "}
       <a href="https://www.esyntaxis.com/" target="_blank">
         ESyntaxis Corporation
       </a>
       . ESyPet Version {process.env.REACT_APP_VERSION}
     </div>
</>
  
        )}
        </>

    )
}

export default Register
