import { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import AsyncSelect from 'react-select/async';

const  AdvancedDropDown = forwardRef((props:{ 
  data?;
  onChange?: CallableFunction;
  clearState?: CallableFunction;
  placeHolder?;
  value?;
  passByID?: boolean;
  isDisabled?: boolean;
  isMultiple?: boolean;
}, ref) => {

  interface LocationOptions {
    readonly value: string;
    readonly label: string;
  }
  
  const [locationValue, setLocationValue] = useState<any>([]); 
    const filterColors = (inputValue: string) => {
        return props.data.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
      };
      
      const loadOptions = (
        inputValue: string,
        callback: (options: LocationOptions[]) => void
      ) => { 
        setTimeout(() => {
          callback(filterColors(inputValue));
        }, 1000);
      };

      const handleClear = () => {
        setLocationValue([]);
      };

      useImperativeHandle(ref, () => ({
        handleClear
      }))

    const styles = {
        control: base => ({
            ...base,
            fontFamily: "Inter",
            fontSize:"14px",
            fontWeight: "400"
        }),
        menu: base => ({
            ...base,
            fontFamily: "Inter",
            fontSize:"14px",
            fontWeight: "400"
        }),
        menuPortal: base => ({ 
            ...base, zIndex: 9999 
        })
    };



    return(
        <AsyncSelect 
            isMulti={props.isMultiple}
            defaultOptions={props.data} 
            onChange={(e)=>{
                props.onChange?.(e);
                if(!props?.passByID)
                    setLocationValue(e)                
            }} 
            loadOptions={loadOptions}
            styles={styles}
            menuPosition={'fixed'}
            value={
                (props?.passByID) ?
                (props.value === "")? "" :
                    props.onChange ? props.data.find(o => o.value === props.value) : props.data.find(o => o.value === props.value)
                : locationValue
            }
            placeholder={(props.placeHolder == undefined)? "Select..." : props.placeHolder}
            isDisabled={props.isDisabled}
            
        />
    );
 
})
export default AdvancedDropDown;