import { Close } from '@mui/icons-material';
import styles from './CloseButton.module.scss';
import { Button } from '../../Components';

function CloseButton(props: {
    onClick?: CallableFunction | any
}) {
    return (
        <div className={styles.modalHeader}>
            <Button onClick={props.onClick} variant="text" color="utility">
                <Close style={{ color: '#fff' }} />
            </Button>
        </div>
    );
}

export default CloseButton;