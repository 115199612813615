import { Avatar, Text } from "../Components";
import styles from './AvatarLabel.module.scss'

export const AvatarLabel = (props: {
    isVip;
    img;
    name;
    imageShape?;
    size?
}) => {
    return (
        <div className={styles.clientContainer}>
            <div className={styles.avatarContainer}>
                <Avatar shape={props.imageShape} key={Math.random()} size={(props.size !== undefined)? props.size : "extra-small"} img={props.img}/>
                {(props.isVip) ? <div className={styles.chipAvatar}>VIP</div> : null}
            </div>                        
            <Text color="black" weight="fw_400">{props.name}</Text>
        </div>
    );
}
