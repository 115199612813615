import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { decrypt } from '../../Utils/encrypt';
import axios, { axiosPrivate } from '../../Apis/axios';
import Swal from 'sweetalert2';
import { toast, ToastContainer } from 'react-toastify';
import { Box, Card, CardContent, Typography, TextField, Button, CircularProgress } from '@mui/material';
import styled from '@emotion/styled';

function ResetPassword(props) {
    const { userId } = useParams()
    const [userIDDec, setUserIDDec] = useState("") as any
    const [usersQuestion, setUsersQuestion] = useState([]) as any
    const navigate = useNavigate()
    const [securityAnswer, setSecurityAnswer] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [retypeNewPassword, setRetypeNewPassword] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    async function validateUserID(id) {
        const result = await axiosPrivate.get(`Auth/CheckUserActivation/${id}`)
        return result
    }
    async function getUserQuestions(userId) {
        const result = await axiosPrivate.get(`UserQuestion/GetSecurityQuestionsByUserID?numUserID=${userId}`)
        return result
    }

    useEffect(() => {
        if (newPassword === retypeNewPassword) {
            setError("")
        } else {
            setError("Passwords do not match.")
        }
    }, [newPassword, retypeNewPassword])

    useEffect(() => {
        if(userId !== null && userId !== ""){
             const decrypted = decrypt(userId);
             setUserIDDec(decrypted)
             validateUserID(decrypted)
             .then((response) => {
                 console.log(response)
                 if(response.status === 200){
                    if(response?.data?.success === false){
                      if(response?.data?.message === "User already activated"){
                        getUserQuestions(decrypted)
                        .then((response) => {
                            let allQs = response?.data
                            if (allQs.length > 0) {
                                const ids = new Set() as any
            
                                allQs.map((qs) => {
                                    ids.add(qs?.numQuestionID)
                                })
            
                                let idsArr = Array.from(ids)
                                console.log(allQs[genRandom(0, idsArr.length - 1)])
                                setUsersQuestion(allQs[genRandom(0, idsArr.length - 1)])
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                            toast.error("Something went wrong!")
                        })
                      }else{
                        showRegistrationErrorAlert(response?.data?.message)
                      }
                    }else{
                        getUserQuestions(decrypted)
                        .then((response) => {
                            let allQs = response?.data
                            if (allQs.length > 0) {
                                const ids = new Set() as any
            
                                allQs.map((qs) => {
                                    ids.add(qs?.numQuestionID)
                                })
            
                                let idsArr = Array.from(ids)
                                console.log(allQs[genRandom(0, idsArr.length - 1)])
                                setUsersQuestion(allQs[genRandom(0, idsArr.length - 1)])
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                            toast.error("Something went wrong!")
                        })
                     }
                 }
             })
             .catch((err) => {
                 console.log(err)
                 showRegistrationErrorAlert("Something went wrong!")
             }) 
        }else{
            console.log("not ok")
        }
    }, [])

    const genRandom = (min, max) => {
        min = Math.ceil(min)
        max = Math.floor(max)
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    const handleSubmit = () => {
        validateAnswer()
    }

    const showRegistrationErrorAlert = (msg) => {
        Swal.fire({
          title: 'Error!',
          html: `
            <div style="font-size: 16px; margin-top: 10px;">
              ${msg}
            </div>
          `,
          iconHtml: `<div style="display: flex; justify-content: center;">
                       <img src="https://cdn-icons-png.flaticon.com/512/753/753345.png" alt="success-icon" style="width: 80px; height: 80px;" />
                     </div>`,
          showCloseButton: true,
          focusConfirm: false,
          confirmButtonText: 'OK',
          customClass: {
            icon: 'no-border',
          }
        }).then((result) => {
          if (result.isConfirmed || result.dismiss) {
            console.log("SweetAlert was closed.");
            navigate("/login");
          }
        });
      };

      async function validateAnswer() {
        let payload = {
            numUserID: userIDDec,
            questionsAndAnswers: [
                {
                    numQuestionID: usersQuestion?.numQuestionID,
                    varAnswer: securityAnswer,
                },
            ],
        }

        await axiosPrivate
            .post(`UserQuestion/ValidateSecurityQuestions`, JSON.stringify(payload), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result?.data) {
                    if (newPassword !== "" || retypeNewPassword !== "") {
                        if (newPassword === retypeNewPassword) {
                            setLoading(true)
                            loginAccountReset()
                                .then((res) => {
                                    showRegistrationSuccessAlert()
                                })
                                .catch((err) => {
                                    setLoading(false)
                                    console.log(err)
                                    toast.error("Something went wrong!")
                                })
                        } else {
                            setLoading(false)
                            toast.warning("Passwords don't match!")
                        }
                    } else {
                        setLoading(false)
                        toast.warning("Invalid Password!")
                    }
                } else {
                    toast.warning(`Security Answer Incorrect!`)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong when validating security question!")
            })
    }

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value)
    }

    const handleRetypeNewPasswordChange = (e) => {
        setRetypeNewPassword(e.target.value)
    }

    const showRegistrationSuccessAlert = () => {
        Swal.fire({
          title: 'Password Changed Successfully!',
          html: `
            <div style="font-size: 16px; margin-top: 10px;">
              Please use your updated credentials to login.
            </div>
          `,
          iconHtml: `<div style="display: flex; justify-content: center;">
                       <img src="https://cdn-icons-png.flaticon.com/512/190/190411.png" alt="success-icon" style="width: 80px; height: 80px;" />
                     </div>`,
          showCloseButton: true,
          focusConfirm: false,
          confirmButtonText: 'OK',
          customClass: {
            icon: 'no-border',
          }
        }).then((result) => {
          if (result.isConfirmed || result.dismiss) {
            console.log("SweetAlert was closed.");
            setLoading(false)
            navigate("/login");
          }
        });
      };

    async function loginAccountReset() {
        const response = await axios.post(
            "/Auth/Reset",
            JSON.stringify({
                numUserID: userIDDec,
                varUserPassword: newPassword,
            }),
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        )

        return response
    }
    const Logo = styled('img')({
        display: 'block',
        margin: '0 auto 20px',
        width: '150px',
    })


    return (
<>
    <ToastContainer />
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            backgroundColor: '#f5f5f5',
        }}
    >
    <Card sx={{ maxWidth: 500, padding: '20px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
        <CardContent>
            <Logo src="https://test.esypet.net/static/media/esypet-logo.5f7c03070095ad942f20.png" alt="EsyPet Logo" />
            <Typography variant="h5" align="center" gutterBottom>
                Security Question
            </Typography>
            <p style={{ fontWeight: "600", textAlign: "center" }}>
                {usersQuestion?.varQuestion}
            </p>
            <TextField
                fullWidth
                label="Security Answer"
                variant="outlined"
                value={securityAnswer}
                onChange={(e) => setSecurityAnswer(e.target.value)}
                sx={{ mb: 3 }}
                autoComplete="off"
            />
            <TextField
                fullWidth
                label="New Password"
                type="password"
                variant="outlined"
                value={newPassword}
                onChange={handleNewPasswordChange}
                sx={{ mb: 3 }}
                autoComplete="new-password"
            />
            <TextField
                fullWidth
                label="Retype New Password"
                type="password"
                variant="outlined"
                value={retypeNewPassword}
                onChange={handleRetypeNewPasswordChange}
                sx={{ mb: 3 }}
                autoComplete="new-password"
            />
            
            {/* Add the contact support message here */}
            <Typography align="center" sx={{ mb: 2, fontWeight: 'bold' }}>
                Forgot your security question? 
            </Typography>
            <Typography align="center" sx={{ mb: 3 }}>
                Contact our support at <strong>1-800-123-4567</strong> to reset your account.
            </Typography>
            
            {error && <Typography color="error" align="center" sx={{ mb: 2 }}>{error}</Typography>}
            
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                    fullWidth
                    sx={{ padding: '10px 0' }}
                >
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Change Password'}
                </Button>
            </Box>
        </CardContent>
    </Card>

    </Box>
</>

    );
}

export default ResetPassword;