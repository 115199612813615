import { Link } from 'react-router-dom';

function Unauthorized() {
    return (
        <div>
            <p>Unauthorized</p>
            <Link to={'/'}>
            <p>Take me home</p>
            </Link>
        </div>
    );
}

export default Unauthorized;