import CustomerInvoices from "../InvoicesList/InvoiceList";

const Payments = (props:{
    loginData?;
}) => {
    return <div style={{padding:"20px"}}>
        <h2 style={{marginBottom:"10px"}}>Invoices</h2>
        <CustomerInvoices type={""} data={{numCustomerID:props.loginData?.customer?.numCustomerID}}/>
    </div>
}

export default Payments
