import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, InputAdornment, IconButton, Typography, Modal, Accordion, AccordionSummary, AccordionDetails, Chip } from '@mui/material';
import { AccountCircle, Check, CheckCircleOutline, ClearOutlined, Edit, FiberNew, Lock, NewReleases, Visibility, VisibilityOff } from '@mui/icons-material';
import axios, { axiosPrivate } from '../../Apis/axios';
import { toast } from 'react-toastify';
import { CloseButton } from '../../Components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Security(props:{
    mode
    data
    loggedUser
}) {

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [answers, setAnswers] = useState({});
  
    const handleTogglePasswordVisibility = () => {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    };
  
    const handleResetPassword = () => {
      if (!currentPassword || !newPassword || !confirmPassword) {
        setError('All fields are required.');
        return;
      }
      if (newPassword !== confirmPassword) {
        setError('New password and confirm password do not match.');
        return;
      }
      setError('');
      changePassword({
        "userId": props?.loggedUser?.userid,
        "userPassword": currentPassword,
        "newPassword": confirmPassword
      })

    };

    async function getAllQuestions() {
      const result = await axiosPrivate.get(`UserQuestion/GetAll`, {
          headers: { "Content-Type": "application/json" },
      })
      return result.data
    }

    async function getUserQuestions(id) {
      const result = await axiosPrivate.get(`UserQuestion/GetSecurityQuestionsByUserID?numUserID=${id}`, {
          headers: { "Content-Type": "application/json" },
      })
      return result.data
    }

    async function changePassword(data) {
        setIsLoading(true)
        await axios
            .post(`${process.env.REACT_APP_API_URL}Auth/ChangePassword`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                console.log(result)
                if (result.status === 200) {
                    toast.success("Password changed successfully")
                    setCurrentPassword("")
                    setNewPassword("")
                    setConfirmPassword("")
                    setIsLoading(false)
                }
            })
            .catch((err) => {
                if(err?.response?.data === "Current password is incorrect."){
                    toast.warning("Current password is incorrect!")
                }else{
                    console.log("Messaging -Err-", err)
                }

                setIsLoading(false)
            })
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [selectedQuestions, setSelectedQuestions] = useState([]) as any;
    const [selectedQuestionsOG, setSelectedQuestionsOG] = useState([]) as any;
    const [questions, setQuestions] = useState([]) as any;

    useEffect(()=>{ 
      if(open === true){
        fetchData()
      }
    },[open])

    const fetchData = () => {
      getAllQuestions()
      .then((allQ) => {
          console.log("ques", allQ.userQuestion)
          getUserQuestions(props?.loggedUser?.userid)
          .then((res) => {
              console.log("user-ques", res)

              const transformedQuestions = allQ.userQuestion.map((question) => ({
                ...question, 
                numQuestionID: question.numUserQuestionID, 
                numUserQuestionID: undefined,
              }));
            
              const finalQuestions = transformedQuestions.map(({ numUserQuestionID, ...rest }) => rest)

              const mergedQuestions = finalQuestions.map((q) => ({
                ...q,
                selected: !!selectedQuestions.find((sel) => sel.numQuestionID === q.numQuestionID),
              }));
              mergedQuestions.sort((a, b) => (a.selected === b.selected ? 0 : a.selected ? -1 : 1));

              setQuestions(mergedQuestions);
              setSelectedQuestions(res)
              setSelectedQuestionsOG(res)
          })
          .catch((err) => {
              console.log(err)
              console.log("Something went wrong!")
          })
      })
      .catch((err) => {
          console.log(err)
          console.log("Something went wrong!")
      })
    }

    const handleSelectQuestion = (questionID) => {
      const alreadySelected = selectedQuestions.find((q) => q.numQuestionID === questionID);
      if (alreadySelected) {
        setSelectedQuestions(selectedQuestions.filter((q) => q.numQuestionID !== questionID));
      } else {
        if (selectedQuestions.length >= 3) {
          toast.warning('You can only select 3 questions at a time. Please unselect one to add a new one.')
          return;
        }
        const selected = questions.find((q) => q.numQuestionID === questionID);
        setSelectedQuestions([...selectedQuestions, selected]);
      }
    };
  
  
    const handleAnswerChange = (questionID, answer) => {
      setSelectedQuestions((prevSelectedQuestions) => 
        prevSelectedQuestions.map((q) => 
          q.numQuestionID === questionID ? { ...q, answer } : q
        )
      );
    };
  
    const handleSubmit = () => {
      const payload:any = [];
      let unansweredNewQuestions:any = [];
    
      selectedQuestions.forEach((selected) => {
        const originalQuestion = selectedQuestionsOG.find((og) => og.numQuestionID === selected.numQuestionID);
    
        if (originalQuestion) {
          if (selected.answer && selected.answer.trim()) {
            payload.push({
              id: originalQuestion.ID, 
              numUserID: originalQuestion.numUserID,
              numQuestionID: selected.numQuestionID,
              varAnswer: selected.answer,
              isEdited: true
            });
          } else {
            payload.push({
              id: originalQuestion.ID,
              numUserID: originalQuestion.numUserID,
              numQuestionID: selected.numQuestionID,
              varAnswer: selected.answer || "", 
              isEdited: false
            });
          }
        } else {
          if (!selected.answer || !selected.answer.trim()) {
            unansweredNewQuestions.push(selected.varQuestion);
          }
    

          payload.push({
            id: 0,
            numUserID: props?.loggedUser?.userid,
            numQuestionID: selected.numQuestionID,
            varAnswer: selected.answer || "", 
            isEdited: false
          });
        }
      });
    

      if (unansweredNewQuestions.length > 0) {

        toast.error(`You must answer the following questions:\n\n${unansweredNewQuestions.join('\n')}`);
        return; 
      }
    
      console.log("Final Payload:", payload);
    
      postAnswers(payload);
    };

    async function postAnswers(payloadData) {
      await axiosPrivate
          .post(`UserQuestion/PopulateSecurityAnsweres`, JSON.stringify(payloadData), {
              headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
              },
          })
          .then((result) => {
            console.log(result)
              if (result.status === 200) {
                setOpen(false)
                toast.success("Changes saved successfully")
              }
          })
          .catch((err) => {
              console.log(err)
              toast.error("Something went wrong!")
              setIsLoading(false)
          })
  }

    const handleChipDelete = (questionID) => {
      setSelectedQuestions(selectedQuestions.filter((q) => q.numQuestionID !== questionID));
      const updatedAnswers = { ...answers };
      delete updatedAnswers[questionID];
      setAnswers(updatedAnswers);
    };

    console.log(questions)

    return (
        <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 400,
        }}
      >
        <Box sx={{ marginBottom: 3, width: '100%' }}>
          <p style={{ fontWeight: '600' }}>Login details</p>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            disabled
            value={props?.data?.varCustomerEmail}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
        </Box>
  
        <Box sx={{ marginBottom: 3, width: '100%' }}>
          <p style={{ fontWeight: '600' }}>Change password</p>
          <TextField
            label="Current password"
            type={'password'}
            variant="outlined"
            fullWidth
            margin="normal"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="New password"
            type={'password'}
            variant="outlined"
            fullWidth
            margin="normal"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </Box>
        <div style={{textAlign:"right"}}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleResetPassword}
          >
            Change Password
          </Button>
        </div>

        <Box
      sx={{
        width: '100%',
        maxWidth: 600,
        padding: { xs: 1, sm: 2 }, 
        margin: '0 auto', 
      }}
    >
      <Typography sx={{ fontWeight: '600', marginBottom: 2 }}>
        Security questions
      </Typography>
      <Button
        variant="outlined"
        startIcon={<Lock/>}
        fullWidth
        sx={{
          justifyContent: 'start',
          textTransform: 'none',
          padding: { xs: '10px', sm: '12px' }, 
          borderRadius: 2,
        }}
        onClick={handleOpen}
      >
        Setup security question
      </Button>
      <Typography
        sx={{
          color: 'gray',
          marginTop: '4px',
          fontSize: { xs: '12px', sm: '14px' }, 
        }}
      >
        Add an extra layer of security to block unauthorized access
      </Typography>
    </Box>

    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            padding: { xs: 3, sm: 4 }, // Padding around the modal content
            width: {
              xs: '90%', // For mobile devices
              sm: '70%', // For tablets
              md: '600px', // For desktops and larger screens
            },
            borderRadius: 2,
            boxShadow: 24,
            position: 'relative',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Lock sx={{ marginRight: 1 }} />
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                sx={{ fontWeight: '600', fontSize: { xs: '16px', sm: '18px' } }}
              >
                Security Questions
              </Typography>
            </Box>
            <CloseButton onClick={handleClose} />
          </Box>
          <Box sx={{ marginTop: 2 }}>
          <div>
      <h4>Select 3 security questions</h4>

      <div style={{ marginBottom: '20px' }}>
        {selectedQuestions.map((q) => (
          <Chip
            key={q.numQuestionID}
            label={q.varQuestion}
            color="success"
            style={{ margin: '5px' }}
            onDelete={() => handleChipDelete(q.numQuestionID)} 
          />
        ))}
      </div>
      <div style={{height:"400px", overflowY:"scroll", width:"100%"}}>
        {questions  .sort((a, b) => {

    if (a.selected === b.selected) {
      return 0; 
    }
    return a.selected ? -1 : 1; 
  }).map((q) => (
          <div key={q.numQuestionID}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p>{q.varQuestion}</p>
              <IconButton
                  color={selectedQuestions.some((selected) => selected.numQuestionID === q.numQuestionID) ? 'secondary' : 'primary'}
                  onClick={() => handleSelectQuestion(q.numQuestionID)}
                >
                  {selectedQuestions.some((selected) => selected.numQuestionID === q.numQuestionID) ? (
                    <ClearOutlined />
                  ) : (
                    <CheckCircleOutline />
                  )}
                </IconButton>
            </div>
            {selectedQuestions.some((selected) => selected.numQuestionID === q.numQuestionID) && (
              <TextField
                label="Answer"
                fullWidth
                variant="outlined"
                value={
                  selectedQuestions.find((sq) => sq.numQuestionID === q.numQuestionID)?.answer || ''
                }
                onChange={(e) => handleAnswerChange(q.numQuestionID, e.target.value)}
                style={{ marginBottom: '15px' }}
                InputProps={{
                  startAdornment: selectedQuestionsOG.some((selected) => selected.numQuestionID === q.numQuestionID) ? (
                    <InputAdornment position="end">
                    <Edit style={{ color: "#a9b4cd" }} />
                  </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                    <FiberNew style={{ color: "#a9b4cd" }} /> 
                  </InputAdornment>
                  )
                }}
              />
            )}
          </div>
        ))}
      </div>

        <div style={{textAlign:"right", marginTop:"10px"}}>
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={selectedQuestions.length !== 3}>
          Save Changes
        </Button>
        </div>
    </div>
          </Box>
        </Box>
      </Modal>

      </Box>
    );
}

export default Security;