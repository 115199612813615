import { AddCommentTwoTone, ArrowBack, Assignment, CheckCircleOutline, Collections, ContentCut, DashboardOutlined, ErrorOutline, EventAvailable, LocalAtm, LocalAtmRounded, LocalAtmSharp, LocalMall, Margin, MenuBookOutlined, NotificationsNone, Pets, RedeemOutlined, WarningAmber } from "@mui/icons-material"
import { Outlet } from "react-router-dom"

import { useNavigate, useLocation } from "react-router-dom"
import styles from "./TabLayout.module.scss"
import { Avatar } from "../../../Components/Avatar/Avatar"
import { IconButton, List, ListItem, ListItemText, Menu, MenuItem, Popover } from "@mui/material"
import { useEffect, useState } from "react"
import useLogout from "../../../Hooks/useLogout"
import FloatingButton from "../../../Components/FloatingButton/FloatingButton"
import axios from "../../../Apis/axios"
import MakeReservation from "../../../Components/MakeReservation/MakeReservation"
import { ToastContainer } from "react-toastify"
import { formatDateDisplay, formatDateTime, formatDateTimeScheduler, formatToLocal } from "../../../Utils/timeHandler"
import { Button, Modal } from "../../../Components"
import CloseButton from "../../../Components/Modal/CloseButton/CloseButton"

const TabLayout = (props: { children?; loginData; isReservationMade?; onFetchNotifications, receivedData }) => {
    const navigate = useNavigate()
    const location = useLocation() as any
    const Logo = require("../../../images/logo.svg").default
    const logout = useLogout() as any

    const [dataLoading, setDataLoading] = useState(false)
    const [customerData, setCustomerData] = useState({}) as any
    const [makeReservationOpen, setMakeReservationOpen] = useState(false)

    const [activeTab, setActiveTab] = useState("/tablet")

    const handleLink = (page) => {
        const from = location.state?.from?.pathname || `${page}`
        navigate(from, { replace: true })
        setActiveTab(page)
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const [notificationAnchor, setNotificationAnchor] = useState(null);



    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const signOut = async () => {
        await logout()
        navigate("/")
    }

    console.log("RDATAFROM TAB LAYOUT", props.receivedData)

   
    async function getCustomerInfo(id) {
        setDataLoading(true)

        await axios
            .get(`${process.env.REACT_APP_API_URL}Customer/GetByID/${id}`)
            .then((result) => {
                if (result.status === 200) {
                    setCustomerData(result?.data)
                    console.log("customer-TAB-LAYOUT", result)

                    setDataLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                console.log("Something went wrong!")


                setDataLoading(false)
            })
    }

    async function getCustomerNotifications(location, customerID) {
        setDataLoading(true)

        await axios
            .get(`${process.env.REACT_APP_TEXT_API_URL}Notification/GetNotificationsByLocationAndRecipientID?LocationID=${location}&RecipientID=${customerID}`)
            .then((result) => {
                if (result.status === 200) {
                    setNotificationList(result?.data)
                    console.log("notilistcus", result?.data)
                    checkNotificationStatus(result?.data)
                    setDataLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                console.log("Something went wrong!")


                setDataLoading(false)
            })
    }

    useEffect(() => {
        if (props.receivedData !== null) {
            handleNotificationAnchor();  // Open the popover without event
            const index = notificationList.findIndex(notification => notification.ID === props.receivedData);
    
            if (index !== -1) {
                showSelectedItemDesc(index);  // Then show the selected notification
            }
        }
    }, [props.receivedData]);
    
    
    

    useEffect(() => {
        if (props.onFetchNotifications === true) {
            getCustomerNotifications(1, props.loginData?.customer?.numCustomerID);

        }
    }, [props.onFetchNotifications, props.loginData]); 

    useEffect(()=>{
        getCustomerInfo(props.loginData?.customer?.numCustomerID)
        getCustomerNotifications(1, props.loginData?.customer?.numCustomerID)
    },[])

    const handleNotificationAnchor = (event:any = null) => {
        setNotificationAnchor(event ? event.currentTarget : document.body); // Fallback if no event
    };
  
    const handleCloseNotificationAnchor = () => {
      setNotificationAnchor(null);
    };
  
    const open = Boolean(notificationAnchor);
    const id = open ? 'notification-popover' : undefined;

    const [notificationList, setNotificationList] = useState([]) as any; 

    const [notificationStatus, setNotificationStatus] = useState(false);

    const checkNotificationStatus = (notificationList) => {
        const atLeastOneUnseen = notificationList.some(notification => !notification.IsSeen);
        setNotificationStatus(atLeastOneUnseen);
    };

    const [seeAllNotification, setSeeAllNotification] = useState(false);


    const notificationIcon = (type) => {
        switch (type) {
            case "success":
                return <CheckCircleOutline style={{color:"#37dd37"}} />
            case "warning":
                return <WarningAmber style={{color:"#dd7d37"}} />
            case "error":
                return <ErrorOutline style={{color:"#dd3737"}} />
            case "info":
                return <RedeemOutlined style={{color:"#3797dd"}} />
          }
    }


    const [selectedItemIndex, setSelectedItemIndex] = useState(null);

    const showSelectedItemDesc = (index) => {
        const notiList = [...notificationList];
        if(notiList[index].IsSeen !== true){
            notiList[index].IsSeen = true;
            setNotificationList(notiList)
            let updateNotiBody = {
                ID: notiList[index].ID,
                IsSeen: true
            }
            axios.put(`${process.env.REACT_APP_TEXT_API_URL}Notification/UpdateNotificationsSeen?ID=${updateNotiBody?.ID}&IsSeen=${updateNotiBody?.IsSeen}`)
            .then((response) => {
                if (response.status === 200) {
                    console.log("Notification marked as read")
                }
            })
            .catch((error) => {
              console.error(error);
            });
            checkNotificationStatus(notificationList);
        }
        setSelectedItemIndex(index);
    };

    const showSelectedItemDescModal = (index) => {
        const notiList = [...notificationList];
        if(notiList[index].IsSeen !== true){
            notiList[index].IsSeen = true;
            setNotificationList(notiList)
            let updateNotiBody = {
                ID: notiList[index].ID,
                IsSeen: true
            }
            axios.put(`${process.env.REACT_APP_TEXT_API_URL}Notification/UpdateNotificationsSeen?ID=${updateNotiBody?.ID}&IsSeen=${updateNotiBody?.IsSeen}`)
            .then((response) => {
                if (response.status === 200) {
                    console.log("Notification marked as read")
                }
            })
            .catch((error) => {
              console.error(error);
            });
            checkNotificationStatus(notificationList);
        }
        setSelectedItemIndex(index);
    };
  
    const handleBackButtonClick = () => {
      setSelectedItemIndex(null);
    };

    const seeAllNotifications = () => {
        setSeeAllNotification(true)
        handleCloseNotificationAnchor()
    }
    const closeSeeAllNotifications = () => {
        setSeeAllNotification(false)
    }

    return (
        <div>
            <ToastContainer />
            <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
                <div style={{ backgroundColor: "#fff", boxShadow: "0px 2px 16px rgba(0, 26, 66, 0.05)", padding: "25px", borderBottom: "1px solid #e3e3e3" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                            <img alt="logo-ESyPet" src={Logo}></img>

                        </div>
                        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <IconButton onClick={handleNotificationAnchor}>
                                    <div style={{ position: 'relative' }}>
                                        <NotificationsNone style={{ color: '#848FAC' }} />
                                        {
                                            (notificationStatus)?
                                            <i style={{ width: '10px', height: '10px', backgroundColor: '#449aef', borderRadius: '50px', position: 'absolute', right: 0, zIndex: 99999, top: 0 }}></i> : null
                                        }
                                       
                                    </div>
                                </IconButton>

                                <Popover
    id={id}
    open={open}
    anchorEl={notificationAnchor}
    onClose={handleCloseNotificationAnchor}
    anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
    }}
    transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
    }}
    PaperProps={{
        style: {
            backgroundColor: '#f1f1f1',
            padding: "5px",
        },
    }}
>
<div>
                                        {selectedItemIndex !== null ? (
                                        <div>
                                            <IconButton onClick={handleBackButtonClick}>
                                            <ArrowBack />
                                            </IconButton>
                                            <div style={{padding:"10px", background:"white", borderRadius:"8px"}}>
                                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                    {notificationIcon(notificationList[selectedItemIndex].NotificationType)}
                                                    <div>
                                                        <p className={styles.notification_font}>{notificationList[selectedItemIndex].Subject}</p>
                                                    </div>
                                                </div>
                                                <div
                                                    className={styles.notification_font_small}
                                                    style={{ marginTop: "10px", width: "60%" }}
                                                    dangerouslySetInnerHTML={{ __html: notificationList[selectedItemIndex].NotificationMessage }}
                                                />
                                            </div>
                                        </div>
                                        ) : (
                                        <>
                                            {notificationList
                                            .sort((a, b) => new Date(b.CreatedDateTime).getTime() - new Date(a.CreatedDateTime).getTime())
                                            .slice(0, 5)
                                            .map((item, index) => (
                                                <div key={index} style={{ padding: "10px", background: "white", marginBottom: "5px", borderRadius: "8px", cursor: "pointer" }} onClick={() => { showSelectedItemDesc(index) }}>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                        {notificationIcon(item.NotificationType)}
                                                        <div>
                                                            <p className={styles.notification_font}>{item.Subject}</p>
                                                        </div>
                                                    </div>
                                                    {!item.IsSeen && <i style={{ width: '10px', height: '10px', backgroundColor: '#449aef', borderRadius: '50px' }}></i>}
                                                </div>
                                                <p style={{ margin: "0", textAlign: "end", color: "gray", fontSize: "12px" }}>{formatDateDisplay(item.CreatedDateTime)}</p>
                                                </div>
                                            ))}
                                            {notificationList.length > 5 && (
                                            <div style={{ textAlign: "center" }}>
                                                <p className={styles.notification_font} style={{ color: "#449aef", margin: "0", textDecoration: "underline" }} onClick={()=>{seeAllNotifications()}}>See All</p>
                                            </div>
                                            )}
                                        </>
                                        )}
                                    </div>
</Popover>
                            </div>

                            <Modal open={seeAllNotification}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ backgroundColor: "#fff", width: "calc(100vw - 50px)", borderRadius: "10px" }}>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "20px"}}>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                        <p style={{ fontSize: "20px", fontWeight: "600", color: "#192252", width:"-webkit-fill-available" }}>All Notifications</p>
                                        <CloseButton onClick={() => {closeSeeAllNotifications()}} />
                                    </div>
                                        <div style={{maxHeight:"400px", overflowY:"scroll"}}>
                                            {notificationList
                                                .sort((a, b) => new Date(b.CreatedDateTime).getTime() - new Date(a.CreatedDateTime).getTime())
                                                .map((item, index) => (
                                                    <div key={index} style={{ padding: "10px", background: "white", marginBottom: "5px", borderRadius: "8px", cursor: "pointer" }} onClick={() => { showSelectedItemDescModal(index) }}>
                                                    <div style={{ display: "flex", justifyContent: "space-between", width:"100%" }}>
                                                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                            {notificationIcon(item.NotificationType)}
                                                            <div>
                                                                <p className={styles.notification_font}>{item.Subject}</p>
                                                                <div
                                                                    className={styles.notification_font_small}
                                                                    style={{ marginTop: "10px", width: "60%" }}
                                                                    dangerouslySetInnerHTML={{ __html: item.NotificationMessage }}
                                                                />
                                                            </div>
                                                        </div>
                                                        {!item.IsSeen && <i style={{ width: '10px', height: '10px', backgroundColor: '#449aef', borderRadius: '50px' }}></i>}
                                                    </div>
                                                    <p style={{ margin: "0", textAlign: "end", color: "gray", fontSize: "12px" }}>{formatDateDisplay(item.CreatedDateTime)}</p>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                            <div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                                    <div onClick={handleMenuOpen}>
                                        <Avatar className={styles.avatarContainer} text={props.loginData.customer.varCustomerFirstName} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    <MenuItem onClick={signOut}>Logout</MenuItem>
                    <MenuItem onClick={() => handleLink("/profile")}>My Profile</MenuItem>
                </Menu>

                <div style={{ backgroundColor: "#fff", flex: "1" }}>
                    <Outlet />
                    {props.children}
                </div>

                <div style={{ bottom: 0, width: "100%" }}>
                    <div style={{ backgroundColor: "#EFF3FA" }}>
                    <FloatingButton onLoading={dataLoading} onClick={() => setMakeReservationOpen(true)}  />
                    <MakeReservation customerData={customerData} isOpen={makeReservationOpen} closeModal={() => setMakeReservationOpen(false)} isSuccess={(e)=>{
                        if(e === true){
                            props.isReservationMade(true)
                        }
                    }} />
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className={(activeTab === "/tablet")? styles.navTab_active : styles.navTab} onClick={() => handleLink("/tablet")}>
                                <DashboardOutlined style={{ color: "#606D93" }} />
                                <p style={{ margin: "0", color: "#606D93", fontSize: "12px" }}>Dashboard</p>
                            </div>
                            <div className={(activeTab === "/bookings")? styles.navTab_active : styles.navTab} onClick={() => handleLink("/bookings")}>
                                <EventAvailable style={{ color: "#606D93" }} />
                                <p style={{ margin: "0", color: "#606D93", fontSize: "12px" }}>Bookings</p>
                            </div>
                            <div className={(activeTab === "/pets")? styles.navTab_active : styles.navTab} onClick={() => handleLink("/pets")}>
                                <Pets style={{ color: "#606D93" }} />
                                <p style={{ margin: "0", color: "#606D93", fontSize: "12px" }}>Pets</p>
                            </div>
                            <div className={(activeTab === "/payments")? styles.navTab_active : styles.navTab} onClick={() => handleLink("/payments")}>
                                <LocalAtmRounded style={{ color: "#606D93" }} />
                                <p style={{ margin: "0", color: "#606D93", fontSize: "12px" }}>Payments</p>
                            </div>
                            <div className={(activeTab === "/working")? styles.navTab_active : styles.navTab} onClick={() => handleLink("/gallery")}>
                                <Collections style={{ color: "#606D93" }} />
                                <p style={{ margin: "0", color: "#606D93", fontSize: "12px" }}>Gallery</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TabLayout
