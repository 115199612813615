import {
    Assignment,
    DatasetLinked,
    MedicalServices,
    MoveDown,
    Person,
    Pets,
    TagFaces,
    ThumbUpSharp,

} from "@mui/icons-material"
import 'react-toastify/dist/ReactToastify.css';
import styles from "./PetProfile.module.scss"
import { useState } from "react"
import { ToastContainer } from "react-toastify"
import { Avatar, Button } from "../../Components";
import { formatDateTime } from "../../Utils/timeHandler";
import Preferences from "../Preferences/Preferences";
import MedicalConditions from "../MedicalConditions/MedicalConditions";
import Personality from "../Personality/Personality";
import Ownership from "../Ownership/Ownership";


const PetProfile = (props:{
    index?
    details?
}) => {
    const [selectedTab, setSelectedTab] = useState("0")
    const [dataLoading, setDataLoading] = useState(false)


    const selectTab = (index) => {
        setSelectedTab(index)
    }


    return (
        <div className={styles.mobileMargin}>
            <div
                className={styles.breakColumns}
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "100px",
                }}
            >
                <div style={{ flex: 1 }}>
                    <div className={styles.navigation}>
                        <p onClick={() => selectTab("0")} className={`${styles.navlink} ${selectedTab === "0" ? styles.activeTab : null}`}>
                            <Assignment />
                            Overview
                        </p>
                        <p onClick={() => selectTab("1")} className={`${styles.navlink} ${selectedTab === "1" ? styles.activeTab : null}`}>
                            <MedicalServices />
                            Medical Details
                        </p>
                        <p onClick={() => selectTab("2")} className={`${styles.navlink} ${selectedTab === "2" ? styles.activeTab : null}`}>
                            <TagFaces />
                            Pet Personality
                        </p>
                        <p onClick={() => selectTab("3")} className={`${styles.navlink} ${selectedTab === "3" ? styles.activeTab : null}`}>
                            <MoveDown />
                            Ownership Details
                        </p>
                        <p onClick={() => selectTab("4")} className={`${styles.navlink} ${selectedTab === "4" ? styles.activeTab : null}`}>
                            <ThumbUpSharp />
                            Preferences
                        </p>
                    </div>
                </div>
                <div style={{ flex: 4 }}>
                    {dataLoading ? (
                        <p>Loading...</p>
                    ) : selectedTab === "0" ? (
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    marginBottom: "20px",
                                    flexDirection:"column"
                                }}
                            >
                                <h2 style={{ color: "#333" }}>Overview</h2>

                                <div
                                                className={styles.breakColumnsPet}
                                                style={{
                                                    backgroundColor: "#EFF3FA",
                                                    padding: "20px 20px",
                                                    borderRadius: "15px",
                                                    display: "flex",
                                                    gap: "50px",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "15px",
                                                    }}
                                                >
                                                        <>
                                                            <Avatar
                                                                img={`${process.env.REACT_APP_MEDIA_URL}directories/pets/` + props.details?.customer?.customerPet[props.index]?.varPetImagePath}
                                                                isVip={false}
                                                                className={"large"}
                                                                labelType={"largeLabel"}
                                                            />
                                                            <p
                                                                style={{
                                                                    fontWeight: "600",
                                                                    fontSize: "20px",
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                {props.details?.customer?.customerPet[props.index]?.varPetName}{" "}
                                                            </p>
                                                        </>
                                                
                                                </div>

                                                <>
                                                    <div
                                                        style={{
                                                            lineHeight: "1.2em",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "15px",
                                                                color: "#3678e1",
                                                                marginBottom: "5px",
                                                            }}
                                                        >
                                                            <b>General</b>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                                marginBottom: "3px",
                                                            }}
                                                        >
                                                            <b>Type:</b> {props.details?.customer?.customerPet[props.index]?.varPetType}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                                marginBottom: "3px",
                                                            }}
                                                        >
                                                            <b>Breed:</b> {props.details?.customer?.customerPet[props.index]?.varPetBreed}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                                marginBottom: "3px",
                                                            }}
                                                        >
                                                            <b>Breed Type:</b> {props.details?.customer?.customerPet[props.index]?.varPetBreedType}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                                marginBottom: "3px",
                                                            }}
                                                        >
                                                            <b>Color:</b> {props.details?.customer?.customerPet[props.index]?.varPetColor === null ? "-" : props.details?.customer?.customerPet[props.index]?.varPetColor}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                                marginBottom: "3px",
                                                            }}
                                                        >
                                                            <b>Weight:</b> {props.details?.customer?.customerPet[props.index]?.numPetWeight}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                                marginBottom: "3px",
                                                            }}
                                                        >
                                                            <b>Size:</b> {props.details?.customer?.customerPet[props.index]?.varPetSize}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                                marginBottom: "3px",
                                                            }}
                                                        >
                                                            <b>Gender:</b> {props.details?.customer?.customerPet[props.index]?.varPetSex === "M" ? "Male" : "Female"}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                                marginBottom: "3px",
                                                            }}
                                                        >
                                                            <b>Age:</b> {props.details?.customer?.customerPet[props.index]?.varPetAge === null || props.details?.customer?.customerPet[props.index]?.varPetAge === "" ? "-" : props.details?.customer?.customerPet[props.index]?.varPetAge}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                                marginBottom: "3px",
                                                            }}
                                                        >
                                                            <b>Spay:</b> {props.details?.customer?.customerPet[props.index]?.varPetSpay ? "Yes" : "No"}
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            lineHeight: "1.2em",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "15px",
                                                                color: "#3678e1",
                                                                marginBottom: "5px",
                                                            }}
                                                        >
                                                            <b>More Info.</b>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                                marginBottom: "3px",
                                                            }}
                                                        >
                                                            <b>Description:</b> {props.details?.customer?.customerPet[props.index]?.varPetDescription === null || props.details?.customer?.customerPet[props.index]?.varPetDescription === "" ? "-" : props.details?.customer?.customerPet[props.index]?.varPetDescription}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                                marginBottom: "3px",
                                                            }}
                                                        >
                                                            <b>Temperament Status:</b>{" "}
                                                            {props.details?.customer?.customerPet[props.index]?.varTemperamentTestStatus === null || props.details?.customer?.customerPet[props.index]?.varTemperamentTestStatus === "" ? "-" : props.details?.customer?.customerPet[props.index]?.varTemperamentTestStatus}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                                marginBottom: "3px",
                                                            }}
                                                        >
                                                            <b>Temperament Tested Date:</b> {props.details?.customer?.customerPet[props.index]?.dtTemperamentTestedDate === null ? "-" : formatDateTime(props.details?.customer?.customerPet[props.index]?.dtTemperamentTestedDate)}
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            lineHeight: "1.2em",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "15px",
                                                                color: "#3678e1",
                                                                marginBottom: "5px",
                                                            }}
                                                        >
                                                            <b>Veterinarian</b>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                                marginBottom: "3px",
                                                            }}
                                                        >
                                                            <b>Name:</b> {props.details?.customer?.customerPet[props.index]?.petVet?.varPetVetName === null || props.details?.customer?.customerPet[props.index]?.petVet === null ? "-" : props.details?.customer?.customerPet[props.index]?.petVet?.varPetVetName}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                                marginBottom: "3px",
                                                            }}
                                                        >
                                                            <b>Work:</b> {props.details?.customer?.customerPet[props.index]?.petVet?.varPetVetPhone1 === null || props.details?.customer?.customerPet[props.index]?.petVet === null ? "-" : props.details?.customer?.customerPet[props.index]?.petVet?.varPetVetPhone1}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                                marginBottom: "3px",
                                                            }}
                                                        >
                                                            <b>Mobile:</b> {props.details?.customer?.customerPet[props.index]?.petVet?.varPetVetPhone2 === null || props.details?.customer?.customerPet[props.index]?.petVet === null ? "-" : props.details?.customer?.customerPet[props.index]?.petVet?.varPetVetPhone2}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                                marginBottom: "3px",
                                                            }}
                                                        >
                                                            <b>Email:</b> {props.details?.customer?.customerPet[props.index]?.petVet?.varPetVetEmail === null || props.details?.customer?.customerPet[props.index]?.petVet === null ? "-" : props.details?.customer?.customerPet[props.index]?.petVet?.varPetVetEmail}
                                                        </p>
                                                    </div>
                                                </>
                                            </div>
                            </div>

                        </div>
                    ) : selectedTab === "1" ? (
                        <div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px" }}>
                            <h2>Medical & Vaccinations</h2>
                        </div>
                        <MedicalConditions data={props.details?.customer?.customerPet} index={props.index} type={'petProfile'} mode={true}/>
                    </div>
                    ) : selectedTab === "2" ? (
                        <div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px" }}>
                                <h2>{props.details?.customer?.customerPet[props.index].varPetName}'s Personality</h2>
                            </div>
                            <Personality data={props.details?.customer?.customerPet} index={props.index} isLoading={dataLoading}/>
                        </div>
                    ) : selectedTab === "3" ? (
                        <div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px" }}>
                                <h2>Ownership Details</h2>
                            </div>
                            <Ownership data={props.details?.customer} index={props.index} isLoading={dataLoading}/>
                        </div>
                    ) : selectedTab === "4" ? (
                        <div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px" }}>
                                <h2>Preferences</h2>
                            </div>
                            <Preferences data={props.details?.customer?.customerPet} index={props.index} type={'petProfile'} mode={true}/>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default PetProfile
