import { Grid } from '@mui/material';

function Personality(props:{
    data?
    isLoading?
    index?
}) {
    return (
        <div style={{display:"flex", gap:"10px"}}>
        {(props.isLoading) ?  <p style={{ color: "#777E90", fontWeight: "400", fontSize: "13px" }}>Loading...</p> :
            (props.data[props.index]?.petPersonality.length > 0)?
            props.data[props.index]?.petPersonality?.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
                <div
                    style={{
                        backgroundColor: "#DCFCE7",
                        border: "1px solid #86EFAC",
                        borderRadius: "16px",
                        color: "#14532D",
                        fontSize: "14px",
                        fontWeight: "500",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "2px 10px 2px 10px",
                        gap: ".2rem",
                        width: "max-content",
                    }}
                >
                    {item.varPersonality}
                </div>
            </Grid>
            )) : 
            <p style={{ color: "#777E90", fontWeight: "400", fontSize: "13px" }}>No personalities selected</p>
        }
        </div>
    );
}

export default Personality;