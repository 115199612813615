import { DragIndicator } from "@mui/icons-material";
import { Box, Button, Checkbox, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { Draggable } from "react-drag-reorder";
import axios from "axios"
import { toast } from "react-toastify";
import { axiosPrivate } from "../../Apis/axios";

const Notifications = (props: {
    data?;
    type;
    index?;
    mode?: boolean;
}) => {

    const [isEmail, setIsEmail] = useState(false)
    const [isSMS, setIsSMS] = useState(false)
    const [loading, setLoading] = useState(false)

    const [draggableKey, setDraggableKey] = useState("")
    const [NotiList, setNotiList] = useState([]) as any


    const [list, setList] = useState([
        {
            mode: "SMS", 
            type: "Mobile",
            status: true,
            name: "S"
        },       
        {
            mode: "Email", 
            type: "",
            status: true,
            name: "E"
        },
        {
            mode: "Call", 
            type: "Mobile",
            status: true,
            name: "C"
        },
        {
            mode: "Call", 
            type: "Home",
            status: true,
            name: "H"
        },
        {
            mode: "Call", 
            type: "Work",
            status: false,
            name: "W"
        },
    ])

    async function getOrder(cusID) {
        setLoading(true)
        await axios.get(`${process.env.REACT_APP_API_URL}CommunicationPriorityOrder/GetCommunicationPriorityOrder?CustomerID=${cusID}`).then((result) => {
            if (result.status === 200) {

                let savedObj = result?.data[0]
              
                const convertedObject = [
                    {
                        id: "numCustomerNotificationMobilePhoneSMSSequence",
                        mode: "SMS",
                        type: "Mobile",
                        status: (savedObj.bitCustomerNotificationMobilePhoneSMS === "") ? false : savedObj.bitCustomerNotificationMobilePhoneSMS,
                        name: "S"
                    },
                    {
                        id: "numCustomerNotificationEmailSequence",
                        mode: "Email",
                        type: "",
                        status: (savedObj.bitCustomerNotificationEmail === "") ? false : savedObj.bitCustomerNotificationEmail,
                        name: "E"
                    },
                    {
                        id: "numCustomerNotificationMobilePhoneCallSequence",
                        mode: "Call",
                        type: "Mobile",
                        status: (savedObj.bitCustomerNotificationMobilePhoneCall === "") ? false : savedObj.bitCustomerNotificationMobilePhoneCall,
                        name: "C"
                    },
                    {
                        id: "numCustomerNotificationHomePhoneSequence",
                        mode: "Call",
                        type: "Home",
                        status: (savedObj.bitCustomerNotificationHomePhone === "") ? false : savedObj.bitCustomerNotificationHomePhone,
                        name: "H"
                    },
                    {
                        id: "numCustomerNotificationWorkPhoneSequence",
                        mode: "Call",
                        type: "Work",
                        status: (savedObj.bitCustomerNotificationWorkPhone === "") ? false : savedObj.bitCustomerNotificationWorkPhone,
                        name: "W"
                    }
                ]                                              

                const sortedObject = convertedObject.sort((a, b) => {
                    const sequenceA = savedObj[a.id];
                    const sequenceB = savedObj[b.id];
                    return sequenceA - sequenceB;
                  });
                
                setList(sortedObject) 
                setLoading(false)
            }
        }).catch((err) => {
            console.log(err);
            setLoading(false)
            toast.error('Something went wrong!');
        })
    }

    const getChangedPos = (currentPos, newPos) => {
        if (currentPos === newPos) {
            return; 
        }
        
        const newList = [...list];
        const item = newList.splice(currentPos, 1)[0];
        newList.splice(newPos, 0, item);
        setList(newList);        
    };

    const updateOrder = (index) => {
        const updatedList = [...list];
        updatedList[index] = { ...updatedList[index], status: !updatedList[index].status };
        const sortedList = updatedList.sort((a, b) => {
            if (a.status === b.status) return 0; 
            return a.status ? -1 : 1;
        });
    
        setList(sortedList);
    };
    
    

    useEffect(() => {
        setDraggableKey(Date.now().toString())
    }, [list])

    useEffect(() => {
        getOrder(props.data?.customer?.numCustomerID)
    }, [])

    async function sendPriority(data) {
        await axios.put(`${process.env.REACT_APP_API_URL}CommunicationPriorityOrder/UpdateCommunicationPriority`,
        JSON.stringify(data),
        {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',                
            },
        }).then((result) => {
            if (result.status === 200) { 
                const payload = createPayload(selectedNotifications, props.data?.customer?.numCustomerID);
                console.log(payload);   
                if(payload.length > 0){
                    sendReceiveItems(payload)
                }else{
                    deleteAllSelected()
                }

            }
        }).catch((err) => {
            console.log(err);
            toast.error('Something went wrong!');
        })
    }

    async function deleteAllSelected() {
        await axiosPrivate.delete(`Notification/DeletePreferredCustomerNotificationByCustomerID?numCustomerID=${props.data?.customer?.numCustomerID}`
        ).then((result) => {
            if(result.status === 200){        
                toast.success(`Changes saved successfully!`)
            }
        }).catch((err)=>{
            console.log(err);
            toast.error('Something went wrong!');
        })        
    }


    async function sendReceiveItems(data) {
        await axios.post(`${process.env.REACT_APP_API_URL}Notification/PopulatePreferredCustomerNotification`,
        JSON.stringify(data),
        {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',                
            },
        }).then((result) => {
            if (result.status === 200) {
                toast.success(`Notification priorities changed successfully!`)  
  

            }
        }).catch((err) => {
            console.log(err);
            toast.error('Something went wrong!');
        })
    }

    const postNewOrder = () => {
        let sendObj = {
            "customerID": props.data?.customer?.numCustomerID,
            "bitNotificationEmail": list[list.findIndex(item => item.name === "E")].status,
            "numNotificationEmailSequence": list.findIndex(item => item.name === "E") + 1,
            "bitNotificationHomePhone": list[list.findIndex(item => item.name === "H")].status,
            "numNotificationHomePhoneSequence": list.findIndex(item => item.name === "H") + 1,
            "bitNotificationMobilePhoneCall": list[list.findIndex(item => item.name === "C")].status,
            "numNotificationMobilePhoneCallSequence": list.findIndex(item => item.name === "C") + 1,
            "bitNotificationMobilePhoneSMS": list[list.findIndex(item => item.name === "S")].status,
            "numNotificationMobilePhoneSMSSequence": list.findIndex(item => item.name === "S") + 1,
            "bitNotificationWorkPhone": list[list.findIndex(item => item.name === "W")].status,
            "numNotificationWorkPhoneSequence": list.findIndex(item => item.name === "W") + 1
        }

        console.log(sendObj)
        sendPriority(sendObj)        
    }

    async function getNotificationTypes() {
        const result = await axiosPrivate.get(`Notification/GetPreferedNotificationsByCustomerID?numCustomerID=${props.data?.customer?.numCustomerID}&NotificationType=${selectedItem?.name}`, {
            headers: { "Content-Type": "application/json" },
        })
        return result.data
      }

    const isTablet = useMediaQuery('(min-width:768px)');
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const [selectedItem, setSelectedItem] = useState({
        "id": "numCustomerNotificationEmailSequence",
        "mode": "Email",
        "type": "",
        "status": true,
        "name": "E"
    }) as any;

    console.log(selectedItem)

    useEffect(() => {
        if (selectedItem !== null) {
            if (selectedItem?.status !== true) {
                toast.warning("Select set the priority first, To select the notifications that you need to receive.");
                setNotiList([]);
                setSelectedNotifications((prevSelected) => 
                    prevSelected.filter((notification) => notification.name !== selectedItem.name)
                );
            } else {
                    getNotificationTypes()
                        .then((allT) => {
                            console.log("All", allT);
                            if (allT.length > 0) {
                                setNotiList(allT);
    
                                const activeNotifications = allT.filter(
                                    (notification) => notification.NotificationType !== "" && notification.IsActive === true
                                );
    
                                setSelectedNotifications((prevSelected) => {
                                    const existingSet = new Set(prevSelected.map(item => `${item.id}-${item.name}`));
    
                                    const updatedSelected = activeNotifications.reduce((acc, notification) => {
                                        const uniqueKey = `${notification.ID}-${notification.NotificationType}`;
                                        
                                        if (!existingSet.has(uniqueKey) && notification.NotificationType === selectedItem.name) {
                                            acc.push({ id: notification.ID, name: notification.NotificationType });
                                        }
                                        return acc;
                                    }, [...prevSelected]);
    
                                    return updatedSelected;
                                });
                            } else {
                                console.log("here");
                                setNotiList([]);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            console.log("Something went wrong!");
                        });
            }
        } else {
            setNotiList([]);
            setSelectedNotifications([]);
        }
    }, [selectedItem]);
    

    const [selectedNotifications, setSelectedNotifications] = useState([]) as any;

    const handleCheckboxChange = (notification) => {
        const { ID } = notification;
    
        setSelectedNotifications((prevSelected) => {
            const exists = prevSelected.some(
                (selected) => selected.id === ID && selected.name === selectedItem.name
            );
    
            if (exists) {
                return prevSelected.filter(
                    (selected) => !(selected.id === ID && selected.name === selectedItem.name)
                );
            } else {
                return [...prevSelected, { id: ID, name: selectedItem.name }];
            }
        });
    };

    const isNotificationSelected = (notification) => {
        return selectedNotifications.some(
            (selected) => selected.id === notification.ID && selected.name === selectedItem?.name
        );
    };

    const createPayload = (selectedNotifications, customerId) => {
        return selectedNotifications.map(notification => ({
            numCustomerID: customerId, 
            notificationID: notification.id, 
            notificationType: notification.name, 
            isActive: true
        }));
    };

    const fetchNotiListOnLoad = async () => {
        try {
            const allNotifications = await getNotificationTypes();
            console.log("Fetched on load:", allNotifications);
    
            const activeNotifications = allNotifications.filter(
                (notification) => notification.NotificationType !== "" && notification.IsActive === true
            );
    
            setSelectedNotifications((prevSelected) => {
                const existingSet = new Set(prevSelected.map(item => `${item.id}-${item.name}`));
    
                const selectedFromLoad = activeNotifications.reduce((acc, notification) => {
                    const uniqueKey = `${notification.ID}-${notification.NotificationType}`;
    
                    if (!existingSet.has(uniqueKey)) {
                        acc.push({
                            id: notification.ID,
                            name: notification.NotificationType,
                        });
                    }
                    return acc;
                }, [...prevSelected]);
    
                return selectedFromLoad;
            });
    
            setNotiList(allNotifications);
        } catch (error) {
            console.error("Error fetching notifications on load:", error);
        }
    };
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        fetchNotiListOnLoad();
    }, []);

    useEffect(() => {
        if (selectedItem?.status !== true) {
            setNotiList([]);
            setSelectedNotifications((prevSelected) =>
                prevSelected.filter((notification) => notification.name !== selectedItem.name)
            );
            setErrorMessage("You need to select a priority before choosing notifications.");
        } else {
            setErrorMessage("");
        }
    }, [selectedItem]);

    useEffect(()=>{console.log("selNOT", selectedNotifications)},[selectedNotifications])
    return (
        <Box sx={{ padding: '2rem' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            <Box sx={{ width: '100%' }}>
                <Typography sx={{ color: '#000', fontWeight: 600, margin: 0 }}>
                    Notification Priorities
                </Typography>
                <Typography sx={{ color: '#777E90' }}>
                Drag to position the notification priorities in the way the customer prefers. Click on draggable items to select the notifications you want to receive.
                </Typography>
            </Box>

            {
                loading ? (
                    <Typography>Loading...</Typography>
                ) : (
                    <Box sx={{ display: 'flex', flexDirection: isDesktop ? 'row' : 'column', gap: '2rem' }}>
                        {/* Draggable List Section */}
                        <Box sx={{ flex: 1 }}>
                            <Draggable
                                key={draggableKey}
                                onPosChange={(currentPos, newPos) => getChangedPos(currentPos, newPos)}
                            >
                                {
                                    list.map<any>((word, idx) => (
                                        <Box
                                            key={idx}
                                            sx={{
                                                backgroundColor: '#EFF5FF',
                                                padding: '.5rem 1rem',
                                                borderRadius: '4px',
                                                marginBottom: '.5rem',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => setSelectedItem(word)}
                                        >
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}>
                                                <Box sx={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
                                                    <Checkbox
                                                        sx={{ padding: '0 .2rem 0 0' }}
                                                        onChange={() => updateOrder(idx)}
                                                        checked={list[idx].status}
                                                    />
                                                    <Typography sx={{ color: '#002867' }}>
                                                        <b>{word.mode}</b> - {word.type}
                                                    </Typography>
                                                </Box>
                                                <DragIndicator sx={{ marginLeft: '1rem', color: '#9CA3AF' }} />
                                            </Box>
                                        </Box>
                                    ))
                                }
                            </Draggable>
                        </Box>

             {/* Notifications List Section */}
             <Box
    sx={{
        flex: 1,
        padding: '1rem',
        border: '1px solid #ddd',
        borderRadius: '8px',
        backgroundColor: '#fff',
    }}
>
    {selectedItem ? (
        <Box>
            <Typography variant="h5" sx={{ color: '#002867' }}>
                Select the notifications that you need to receive via {selectedItem.mode}  {(selectedItem.type !== "")? "- "+selectedItem.type : ""}
            </Typography>

            <Typography variant="h5" sx={{ color: '#002867' }}>
               {errorMessage}
            </Typography>

            {NotiList
                .filter(notification => {
                    const isMatchingType = notification.NotificationType === selectedItem.name;

                    const isExcludedType = selectedItem.name === "E" && notification.NotificationType === "S";

                    return isMatchingType || !isExcludedType;
                })
                .map((notification) => (
                    <Box key={notification.ID} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Checkbox
                            checked={isNotificationSelected(notification)} 
                            onChange={() => handleCheckboxChange(notification)}
                        />
                        <Typography>{notification.NotificationName}</Typography>
                    </Box>
                ))
            }
        </Box>
    ) : (
        <Typography>Select an item to see details.</Typography>
    )}
</Box>


            </Box>
                )
            }

            {/* Save Button */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Button variant="contained" onClick={postNewOrder}>Save Changes</Button>
            </Box>
        </Box>
    </Box>
    )
}

export default Notifications;