import {
    AddCircleOutline,
    ArrowDownward,
    ArrowUpward,
    CreditCard,
    DatasetLinked,
    Delete,
    Edit,
    Email,
    FileUploadOutlined,
    ImageOutlined,
    InfoOutlined,
    LocalOffer,
    MonetizationOn,
    Notifications as Noti,
    NotificationAdd,
    Payments,
    Person,
    Pets,
    RemoveCircleOutline,
    ShoppingCart,
    Menu as MenuIcon,
    Lock
} from "@mui/icons-material"
import 'react-toastify/dist/ReactToastify.css';
import styles from "./Profile.module.scss"
import { useEffect, useState, useRef } from "react"
import axios, { axiosPrivate } from "../../Apis/axios"
import { ToastContainer, toast } from "react-toastify"
import { formatDateTime } from "../../Utils/timeHandler"
import { Avatar, Button, Input } from "../../Components"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
    Button as MUIButton,
    InputAdornment,
    useMediaQuery,
    ListItem,
    List,
    Drawer,
    Menu,
} from "@mui/material"
import { FileUploader } from "react-drag-drop-files"
import { AdvancedInput } from "../../Components/AdvancedInput/AdvancedInput"
import Compressor from "compressorjs"
import { Modal } from "../../Components/Components"
import CloseButton from "../../Components/Modal/CloseButton/CloseButton"
import InputMask from "react-input-mask"
import AdvancedDropDown from "../../Components/AdvancedDropDown/AdvancedDropDown"
import moment from "moment"
import { AvatarLabel } from "../../Components/AvatarLabel/AvatarLabel"
import { Truncate } from "../../Utils/Validation"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import SearchIcon from "@mui/icons-material/Search"
import MakeReservation from "../../Components/MakeReservation/MakeReservation"
import LinkedAccounts from "../LinkedAccounts/LinkedAccounts";
import Notifications from "../Notifications/Notifications";
import PaymentDetails from "../PaymentDetails/PaymentDetails";
import Security from "../Security/Security";

const Profile = (props:{
    loginData?
}) => {
    const [selectedTab, setSelectedTab] = useState("0")
    const [dataLoading, setDataLoading] = useState(false)

    const [isEditCustomer, setIsEditCustomer] = useState(false)
    const [isEditPet, setIsEditPet] = useState(false)
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)

    const [customerData, setCustomerData] = useState({}) as any
    const [petData, setPetData] = useState({}) as any
    const [petDataEdit, setPetDataEdit] = useState({}) as any

    const [servicePet, setServicePet] = useState({}) as any
    const [isOpenNewAppointment, setIsOpenNewAppointment] = useState(false)
    const [currDate, setCurrDate] = useState<any>(moment().format("MM/DD/YYYY"))
    const [additionalServiceList, setAdditionalServiceList] = useState([]) as any
    const [isLoading, setIsLoading] = useState(false)
    const [selectedAdditionalService, setSelectedAdditionalService] = useState("") as any

    const [productTabIndex, setProductTabIndex] = useState(0)
    const [productList, setProductList] = useState<any>([])
    const [cartItemCount, setCartItemCount] = useState<any>(0)
    const [productCartAmount, setProductCartAmount] = useState<any>(0)

    const selectTab = (index) => {
        setSelectedTab(index)
    }

    const [customerLoading, setCustomerLoading] = useState(false)
    const [petLoading, setPetLoading] = useState(false)

    const fileTypes = ["JPEG", "jpg", "WEBP"]
    const [loading, setLoading] = useState(false)
    const [fileSelected, setFileSelected] = useState(false)
    const [fileSelectedPet, setFileSelectedPet] = useState(false)
    const [importedData, setImportedData] = useState<any>("")
    const [imageName, setImageName] = useState<any>("")
    const [imageNamePet, setImageNamePet] = useState<any>("")
    const [petBreed, setPetBreed] = useState("")

    const [importedDataPet, setImportedDataPet] = useState<any>("")
    const [importedDataPetEdit, setImportedDataPetEdit] = useState<any>("")

    const readFile = (files) => {
        if (files.size > 1000000) {
            // Compress the image if it's too large
            const options = {
                maxWidth: 800,
                maxHeight: 800,
                quality: 0.8,
                mimeType: files.type,
            }

            new Compressor(files, {
                ...options,
                success(result) {
                    console.info(result)
                    setImportedData(result)
                    setFileSelected(true)
                    setImageName(result.name)
                },
                error(err) {
                    console.error("Error compressing image:", err)
                    alert("Error compressing image")
                },
            })
        } else {
            setImportedData(files)
            setFileSelected(true)
            setImageName(files.name)
        }
    }

    const readFilePet = (files) => {
        if (files.size > 1000000) {
            // Compress the image if it's too large
            const options = {
                maxWidth: 800,
                maxHeight: 800,
                quality: 0.8,
                mimeType: files.type,
            }

            new Compressor(files, {
                ...options,
                success(result) {
                    console.info(result)
                    setImportedDataPet(result)
                    setFileSelectedPet(true)
                    setImageNamePet(result.name)
                },
                error(err) {
                    console.error("Error compressing image:", err)
                    alert("Error compressing image")
                },
            })
        } else {
            console.info(files)
            setImportedDataPet(files)
            setFileSelectedPet(true)
            setImageNamePet(files.name)
        }
    }

    async function saveData() {
        const payload = {
            customer: customerData?.customer,
            customerAddress: customerData?.customer.customerAddress,
        }

        console.log(payload, "payladddd")
        const result = await axiosPrivate.put("Customer", JSON.stringify(payload))
        return result
    }

    async function saveDataPet() {
        const payload = {
            pet: petDataEdit?.pet,
        }

        console.log(payload, "payladddd pettt")
        const result = await axiosPrivate.put("Pet", JSON.stringify(payload))
        return result
    }

    const upload = () => {
        if (!fileSelected) {
            saveData()
                .then((res) => {
                    console.log(res)
                    alert("Customer was updated successfully!")
                    // toast.success("Customer was updated successfully!");

                    setCustomerLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setCustomerLoading(false)
                    alert("Something went wrong when saving new customer!")
                    // toast.error("Something went wrong when saving new customer!");
                })
        } else {
            const formData = new FormData()
            formData.append("folderpath", `directories/customers`)
            formData.append("method", "N")
            formData.append("deletingFileName", "-")
            formData.append("renameFileNames", "0")

            const file = importedData
            const fileExtension = file.name.split(".").pop()
            const renamedFile = new File([file], `${customerData?.customer?.numCustomerID}.${fileExtension}`, {
                type: file.type,
            })
            formData.append("files", renamedFile)

            axios
                .post(`${process.env.REACT_APP_API_URL}FileUpload/UploadFile`, formData)
                .then((response) => {
                    console.log(response)
                    if (response.data.files.length > 0) {
                        const newValue = { ...customerData }
                        newValue.customer.varCustomerImagePath = newValue.customer.numCustomerID + "." + fileExtension
                        setCustomerData(newValue)

                        saveData()
                            .then((res) => {
                                console.log(res)
                                alert("Customer was updated successfully!")
                                // toast.success("Customer was updated successfully!");

                                setCustomerLoading(false)
                            })
                            .catch((err) => {
                                console.log(err)
                                setCustomerLoading(false)
                                alert("Something went wrong when saving new customer!")
                                // toast.error("Something went wrong when saving new customer!");
                            })
                    }
                    setCustomerLoading(false)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    const uploadPet = () => {
        if (!fileSelectedPet) {
            saveDataPet()
                .then((res) => {
                    console.log(res)
                    alert("Pet was updated successfully!")
                    // toast.success("Pet was updated successfully!");

                    setCustomerLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setCustomerLoading(false)
                    alert("Something went wrong when saving new pet!")
                    // toast.error("Something went wrong when saving new pet!");
                })
        } else {
            const formData = new FormData()
            formData.append("folderpath", `directories/pets`)
            formData.append("method", "N")
            formData.append("deletingFileName", "-")
            formData.append("renameFileNames", "0")

            const file = importedDataPet
            const fileExtension = file.name.split(".").pop()
            const renamedFile = new File([file], `${petDataEdit?.pet?.numPetID}.${fileExtension}`, { type: file.type })
            formData.append("files", renamedFile)

            axios
                .post(`${process.env.REACT_APP_API_URL}FileUpload/UploadFile`, formData)
                .then((response) => {
                    console.log("fileupd", response)
                    if (response.data.files.length > 0) {
                        const newValue = { ...petDataEdit }
                        newValue.pet.varPetImagePath = newValue.pet.numPetID + "." + fileExtension
                        setPetData(newValue)

                        saveDataPet()
                            .then((res) => {
                                console.log(res)
                                // toast.success("Pet was updated successfully!");
                                alert("Pet was updated successfully!")

                                setCustomerLoading(false)
                                setFileSelectedPet(false)
                            })
                            .catch((err) => {
                                console.log(err)
                                setCustomerLoading(false)
                                alert("Something went wrong when saving new pet!")
                                // toast.error("Something went wrong when saving new pet!");
                            })
                    }
                    setCustomerLoading(false)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    async function getCustomerInfo(id) {
        setDataLoading(true)

        await axios
            .get(`${process.env.REACT_APP_API_URL}Customer/GetByID/${id}`)
            .then((result) => {
                if (result.status === 200) {
                    setCustomerData(result?.data)
                    console.log("customer", result)
                    setImportedData(result?.data?.customer?.varCustomerImagePath)
                    if (result?.data?.customer?.customerPet.length > 0) {
                        setImportedDataPet(result?.data?.customer?.customerPet[0]?.varPetImagePath)
                    }
                    setDataLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                console.log("Something went wrong!")


                setDataLoading(false)
            })
    }

    async function getPetInfo(id) {
        setDataLoading(true)

        await axios
            .get(`${process.env.REACT_APP_API_URL}Pet/GetByID/${id}`)
            .then((result) => {
                if (result.status === 200) {
                    setPetData(result?.data)
                    setImportedDataPet(result?.data?.pet?.varPetImagePath)
                    setDataLoading(false)

                    console.log(result?.data)
                }
            })
            .catch((err) => {
                console.log(err)
                // toast.error('Something went wrong!');
                setDataLoading(false)
            })
    }

    async function getPetInfoEdit(id) {
        setDataLoading(true)

        await axios
            .get(`${process.env.REACT_APP_API_URL}Pet/GetByID/${id}`)
            .then((result) => {
                if (result.status === 200) {
                    setPetDataEdit(result?.data)
                    setImportedDataPetEdit(result?.data?.pet?.varPetImagePath)
                    setDataLoading(false)

                    console.log(result?.data)
                }
            })
            .catch((err) => {
                console.log(err)
                // toast.error('Something went wrong!');
                setDataLoading(false)
            })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        // Handle form submission here
        setCustomerLoading(true)

        var Form: any = document.getElementById("editForm")

        if (Form.checkValidity() == false) {
            console.log("invalid")
            setCustomerLoading(false)
        } else {
            upload()
        }

        console.log(customerData)
    }

    const handleSubmitPet = (event) => {
        event.preventDefault()
        // Handle form submission here
        setCustomerLoading(true)

        var Form: any = document.getElementById("editFormPet")

        if (Form.checkValidity() == false) {
            console.log("invalid")
            setCustomerLoading(false)
        } else {
            uploadPet()
        }

        console.log(petData)
    }

    const handleSubmitAddPet = (event) => {
        event.preventDefault()
        // Handle form submission here
        setCustomerLoading(true)

        var Form: any = document.getElementById("addFormPet")

        if (Form.checkValidity() == false) {
            console.log("invalid")
            setCustomerLoading(false)
        } else {
            handleSubmitNewPet()
        }

        console.log(petData)
    }

    useEffect(() => {
        if (props.loginData.customer.numCustomerID !== null) {
            getCustomerInfo(props.loginData.customer.numCustomerID)
        }
    }, [])

    const editAPet = (petID) => {
        console.log(petID)
        getPetInfoEdit(petID)
        setShowEditModal(true)
    }

    const addNewPet = () => {
        setShowAddModal(true)
    }

    async function getSizes(id) {
        const result = await axiosPrivate.get(`PetSize/GetAllByPetTypeID/${id}`, {
            headers: { "Content-Type": "application/json" },
        })
        return result.data
    }

    const getSizeData = (id) => {
        getSizes(id)
            .then((res) => {
                setSizeData(res?.petSize)
            })
            .catch((err) => {
                console.log(err)
                console.log("Something went wrong!")
            })
    }

    const [petName, setPetName] = useState("")
    const [sizeData, setSizeData] = useState([] as any)
    const [typeData, setTypeData] = useState([] as any)
    const [petType, setPetType] = useState("")
    const [petSize, setPetSize] = useState("")
    const [petGender, setPetGender] = useState("")
    const [petWeight, setPetWeight] = useState("")

    const [MixBreedData, setMixBreedData] = useState([] as any)
    const [PureBreedData, setPureBreedData] = useState([] as any)

    const BreedRef = useRef()

    async function getBreeds() {
        const result = await axiosPrivate.get(`PetBreed/GetAllByPetTypeID/${petType}`, {
            headers: { "Content-Type": "application/json" },
        })
        return result.data
    }

    const handleSubmitNewPet = async () => {
        const result = await axiosPrivate.post(
            "Pet",
            JSON.stringify({
                pet: {
                    numPetID: 0,
                    varPetNumber: "",
                    varPetBC: "",
                    varPetRFID: "",
                    numCustomerID: customerData?.customer?.numCustomerID,
                    numPetDefaultGroomLocationID: 0,
                    numPetDefaultBoardLocationID: 0,
                    numPetDefaultDaycareLocationID: 0,
                    numPetDefaultSittingLocationID: 0,
                    numPetDefaultTrainingLocationID: 0,
                    numPetPreferredGroomerID: 0,
                    numPetPreferredBatherID: 0,
                    varPetName: petName,
                    varPetDescription: "",
                    numPetTypeID: parseInt(petType),
                    numPetBreedID: petBreed,
                    numPetColorID: 0,
                    bitIsFilledByCatalog: true,
                    numPetCatalogID: 0,
                    numPetWeight: parseInt(petWeight),
                    numPetSizeID: parseInt(petSize),
                    varPetSex: petGender,
                    varPetBirthDate: "",
                    varPetAge: "",
                    varPetSpay: "",
                    varPetImagePath: "",
                    varPetLicenseNo: "",
                    varPetChipID: "",
                    varPetChipMaker: "",
                    varPetWarningAlert: "",
                    varPetWarningAlertGroom: "",
                    varPetWarningAlertBoard: "",
                    varPetWarningAlertDaycare: "",
                    varPetWarningAlertSitting: "",
                    varPetWarningAlertTraining: "",
                    varPetWarningAlertDriver: "",
                    numActiveLocationID: 0,
                    varPetLocationStatus: "",
                    dtPetLocationLastScanDateTime: "2022-09-15T13:17:07.693Z",
                    numPetVetID: 0,
                    varPetComments: "",
                    dtPurgeDate: "2022-09-15T13:17:07.693Z",
                    varTemperamentTestStatus: "",
                    varTemperamentTestedLocation: "",
                    dtTemperamentTestedDate: "2022-09-15T13:17:07.693Z",
                    bitDaycareApproved: true,
                    dtDayCareApprovedDate: "2022-09-15T13:17:07.693Z",
                    numDayCareApprovedBy: 0,
                    varPetCustomText1: "",
                    varPetCustomText2: "",
                    varPetCustomText3: "",
                    varPetCustomText4: "",
                    varPetCustomText5: "",
                    dtPetCustomDate1: "2022-09-15T13:17:07.693Z",
                    dtPetCustomDate2: "2022-09-15T13:17:07.693Z",
                    dtPetCustomDate3: "2022-09-15T13:17:07.693Z",
                    dtPetCustomDate4: "2022-09-15T13:17:07.693Z",
                    dtPetCustomDate5: "2022-09-15T13:17:07.693Z",
                    numPetCustomNumber1: 0,
                    numPetCustomNumber2: 0,
                    numPetCustomNumber3: 0,
                    numPetCustomNumber4: 0,
                    numPetCustomNumber5: 0,
                    numPetCustomFloatNumber1: 0,
                    numPetCustomFloatNumber2: 0,
                    numPetCustomFloatNumber3: 0,
                    numPetCustomFloatNumber4: 0,
                    numPetCustomFloatNumber5: 0,
                    numPetCustomList1ID: 0,
                    numPetCustomList2ID: 0,
                    numPetCustomList3ID: 0,
                    numPetCustomList4ID: 0,
                    numPetCustomList5ID: 0,
                    numPetCustomList6ID: 0,
                    numPetCustomList7ID: 0,
                    numPetCustomList8ID: 0,
                    numPetCustomList9ID: 0,
                    numPetCustomList10ID: 0,
                    bitPetCustomBit1: true,
                    bitPetCustomBit2: true,
                    bitPetCustomBit3: true,
                    bitPetCustomBit4: true,
                    bitPetCustomBit5: true,
                    bitActive: true,
                    numCreatedBy: 0,
                    varCreatedBy: "",
                    dtCreatedDate: "2022-09-15T13:17:07.693Z",
                    numEditedBy: 0,
                    varEditedBy: "",
                    dtEditedDate: "2022-09-15T13:17:07.693Z",
                    numDeletedBy: 0,
                    varDeletedBy: "",
                    dtDeletedDate: "2022-09-15T13:17:07.693Z",
                    varPetDeletedReason: "",
                },
            })
        )
        console.log(result.data)
        alert("New Pet Saved Successfully!")
        setCustomerLoading(false)
    }
    async function getTypes() {
        const result = await axiosPrivate.get("PetType/GetAll", {
            headers: { "Content-Type": "application/json" },
        })
        return result.data
    }

    const getTypesData = () => {
        getTypes()
            .then((res) => {
                setTypeData(res?.petType)
            })
            .catch((err) => {
                console.log(err)
                console.log("Something went wrong!")
            })
    }

    const getBreedData = () => {
        getBreeds()
            .then((res) => {
                console.log("res", res)
                let pureBreeds = [] as any
                let mixBreeds = [] as any

                res?.petBreed.map((breed) => {
                    let objPure = {
                        numPetBreedID: breed?.numPetBreedID,
                        varPetBreedType: breed?.varPetBreedType,
                        varPetBreedName: breed?.varPetBreedName,
                        varPetBreedDescription: breed?.varPetBreedDescription,
                        numPrimaryPetBreedID: breed?.numPrimaryPetBreedID,
                        varPrimaryPetBreed: breed?.varPrimaryPetBreed,
                        numSecondaryPetBreedID: breed?.numSecondaryPetBreedID,
                        varSecondaryPetBreed: breed?.varSecondaryPetBreed,
                        bitActive: breed?.bitActive,
                    }

                    if (breed.varPetBreedType === "PURE") {
                        pureBreeds.push(objPure)
                    } else {
                        mixBreeds.push(objPure)
                    }
                })

                setPureBreedData(pureBreeds)
                setMixBreedData(mixBreeds)
            })
            .catch((err) => {
                console.log(err)
                console.log("Something went wrong!")
            })
    }

    useEffect(() => {
        getTypesData()
    }, [])

    useEffect(() => {
        console.log("change")
        if (petType != "") getBreedData()
    }, [petType])

    useEffect(() => {
        getSizeData(petDataEdit?.pet?.numPetTypeID)
    }, [petDataEdit?.pet?.numPetTypeID])

    useEffect(() => {
        getSizeData(petDataEdit?.pet?.numPetTypeID)
        setPetType(petDataEdit?.pet?.numPetTypeID)
        if (petType != "") getBreedData()
    }, [petDataEdit?.pet?.numPetTypeID])

    function removeLbs(inputString) {
        return inputString.replace(/lbs/g, "")
    }

    const addAppointment = (index, petID) => {
        console.log(customerData?.customer?.customerPet[index], petID)

        setServicePet({
            id: customerData?.customer?.customerPet[index].numPetID,
            name: customerData?.customer?.customerPet[index].varPetName,
            breed: customerData?.customer?.customerPet[index].varPetBreed,
            size: customerData?.customer?.customerPet[index].varPetSize,
            img: customerData?.customer?.customerPet[index].varPetImagePath,
            preferredGroomer: customerData?.customer?.customerPet[index].numPetPreferredGroomerID,
            additionalService: [],
            selectedProducts: [],
            petType: customerData?.customer?.customerPet[index].numPetTypeID,
            customerID: customerData?.customer?.customerPet[index].numCustomerID,
            weight: customerData?.customer?.customerPet[index].numPetWeight,
            breedID: customerData?.customer?.customerPet[index].numPetBreedID,
            sizeID: customerData?.customer?.customerPet[index].numPetSizeID,
        })
        setIsOpenNewAppointment(true)
    }

    const getLocationServiceItemsPriceList = async (locationID, dateOfService, petBreedID, petSizeID, employeeID, employeeName, petID, petTypeID) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetLocationServiceItemsPriceList`

        try {
            const response = await axios.get(apiUrl, {
                params: {
                    numLocationID: locationID,
                    dtDate_of_service: dateOfService,
                    numPetBreedID: petBreedID,
                    numPetSizeID: petSizeID,
                    numEmployeeID: employeeID,
                    varEmployeeName: employeeName,
                    numPetID: petID,
                    numPetTypeID: petTypeID,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    const getLocationProductItemsPriceList = async (locationID, dateOfService, employeeID, employeeName, petID) => {
        console.log("all product fetch - sent", locationID, dateOfService, employeeID, employeeName, petID)
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetLocationProductItemsPriceList`

        try {
            const response = await axios.get(apiUrl, {
                params: {
                    numLocationID: locationID,
                    dtDate_of_service: dateOfService,
                    numEmployeeID: employeeID,
                    varEmployeeName: employeeName,
                    numPetID: petID,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    const getLocationProductSelectedItemsPriceList = async (locationID, dateOfService, employeeID, employeeName, petID, CustomerID, appointmentID) => {
        console.log("sent-selected - product", locationID, dateOfService, employeeID, employeeName, petID, CustomerID, appointmentID)
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetLocationProductSelectedItemsPriceList`

        try {
            const response = await axios.get(apiUrl, {
                params: {
                    numLocationID: locationID,
                    dtDate_of_service: dateOfService,
                    numEmployeeID: employeeID,
                    varEmployeeName: employeeName,
                    numPetID: petID,
                    numCustomerID: CustomerID,
                    numAppointmentID: appointmentID,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }
    console.log("cusd", customerData)

    useEffect(() => {
        if (isOpenNewAppointment === true) {
            console.log("sent", 1, currDate, servicePet.breedID, servicePet.sizeID, 1, "Ravin", servicePet.id, servicePet.petType)
            getLocationServiceItemsPriceList(1, currDate, servicePet.breedID, servicePet.sizeID, 1, "Ravin", servicePet.id, servicePet.petType)   
                .then((data) => {
                    if (data.Services !== undefined) {
                        console.log("res - All services with SP-selected services", data)

                        const { additionalServiceList } = data.Services.reduce(
                            (lists, service) => {
                                const existingIndex = lists.additionalServiceList.findIndex(
                                    (existingService) => existingService.id === service.numServiceItemID && existingService.orderID === service.numOrderID
                                )

                                if (existingIndex === -1) {
                                    lists.additionalServiceList.push({
                                        ...service,
                                        desc: service.varServiceItemDescription,
                                    })
                                }

                                return lists
                            },
                            { additionalServiceList: [] }
                        )

                        setAdditionalServiceList(additionalServiceList)

                        const updatedServicePets: any = { ...servicePet }
                        updatedServicePets.additionalService = []

                        data.Services.forEach((service) => {
                            if (service.OrderSelected !== true) return

                            const updatedService = {
                                id: service.numServiceItemID,
                                name: service.varServiceItemName,
                                price: service.numServiceItemSalesPrice,
                                orderID: service.numOrderID,
                                taxAmount: service.TaxAmount,
                                numLocationServiceProductItemID: service.numLocationServiceItemID,
                                numSalesTaxPercentage: service.numSalesTaxPercentage,
                                numStorePromotionDiscount: service.numStorePromotionDiscount,
                                numSeasonalPromotionDiscount: service.numSeasonalPromotionDiscount,
                            }

                            const existingAdditionalIndex = updatedServicePets.additionalService.findIndex(
                                (existingService) => existingService.id === updatedService.id && existingService.orderID === updatedService.orderID
                            )

                            if (existingAdditionalIndex === -1) {
                                updatedServicePets[0].additionalService.push({
                                    ...updatedService,
                                    desc: service.varServiceItemDescription,
                                })
                            }
                        })

                        setServicePet(updatedServicePets)

                        //calculateSummary(0);
                        setIsLoading(false)
                    } else {
                        setAdditionalServiceList([])
                        setIsLoading(false)
                    }
                })
                .catch((error) => {
                    console.log("error", error)
                })

            getLocationProductItemsPriceList(1, currDate, 1, "Ravin", servicePet.id)
                .then((data) => {
                    console.log("res - All products list", data)
                    setProductList(data)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.log("error", error)
                })

            getLocationProductSelectedItemsPriceList(1, currDate, 1, "Ravin", null, props.loginData.customer.numCustomerID, null)
                .then((data) => {
                    console.log("res - selected products list", data)
                    const petProducts = { ...servicePet }
                    let arr: any = []

                    data.map((prod, index) => {
                        if (servicePet.id === prod.numPetID) {
                            arr.push(prod)
                        }
                    })
                    petProducts.selectedProducts = arr

                    setServicePet(petProducts)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.log("error", error)
                })
        }
    }, [isOpenNewAppointment])

    const calcTax = (storeDiscount, seasonalDiscount, mainPrice, percentage) => {
        let finalTaxAmount = 0

        if (percentage !== null) {
            let adjustments = parseFloat(seasonalDiscount) + parseFloat(storeDiscount)
            let afterAdjustment = parseFloat(mainPrice) - adjustments
            finalTaxAmount = afterAdjustment * percentage
        }

        return finalTaxAmount.toFixed(2)
    }

    const selectAdditionalService = (index) => {
        setSelectedAdditionalService(index)
        const petList = { ...servicePet }
        //Checking to avoid duplicates
        let matching = petList.additionalService.some((x) => x.id === additionalServiceList[index].numServiceItemID)
        if (!matching) {
            let additional = {
                id: additionalServiceList[index].numServiceItemID,
                name: additionalServiceList[index].varServiceItemName,
                price: additionalServiceList[index].numServiceItemSalesPrice,
                desc: additionalServiceList[index].varServiceItemDescription,
                orderID: additionalServiceList[index].numOrderID,
                taxAmount: calcTax(
                    additionalServiceList[index].numStorePromotionDiscount,
                    additionalServiceList[index].numSeasonalPromotionDiscount,
                    additionalServiceList[index].numServiceItemSalesPrice,
                    additionalServiceList[index].numSalesTaxPercentage
                ),
                numLocationServiceProductItemID: additionalServiceList[index].numLocationServiceItemID,
                numSalesTaxPercentage: additionalServiceList[index].numSalesTaxPercentage,
                numSeasonalPromotionDiscount: additionalServiceList[index].numSeasonalPromotionDiscount,
                numStorePromotionDiscount: additionalServiceList[index].numStorePromotionDiscount,
            }
            petList.additionalService.push(additional)
            setServicePet(petList)
        } else {
            //Remove on click
            let delIndex = petList.additionalService.findIndex((x) => x.id === additionalServiceList[index].numServiceItemID)
            if (petList.additionalService[delIndex].orderID === "") {
                /// needs to be changed to check null//////////////////
                petList.additionalService.splice(delIndex, 1)
            } else {
                petList.additionalService[delIndex].id = null
                petList.additionalService[delIndex].name = null
                petList.additionalService[delIndex].price = null
                petList.additionalService[delIndex].desc = ""
                petList.additionalService[delIndex].taxAmount = null
                petList.additionalService[delIndex].numLocationServiceProductItemID = null
                petList.additionalService[delIndex].numSalesTaxPercentage = null
                petList.additionalService[delIndex].numSeasonalPromotionDiscount = null
                petList.additionalService[delIndex].numStorePromotionDiscount = null
            }
            setServicePet(petList)
        }
    }

    const handleProductTabChange = (event, newTabIndex) => {
        setProductTabIndex(newTabIndex)
    }

    const decrementQty = (id, index) => {
        const productArr = [...productList]
        let selected = productArr[index].SelectedQuantity

        if (selected <= 0) {
            productArr[index].SelectedQuantity = 0
        } else {
            productArr[index].SelectedQuantity = productArr[index].SelectedQuantity - 1
            productArr[index].TempAvailableQuantity = productArr[index].TempAvailableQuantity + 1
        }
        setProductList(productArr)
    }

    const incrementQty = (id, index) => {
        const productArr = [...productList]
        let selected = productArr[index].SelectedQuantity
        let totalAvailable = productArr[index].numAvailableQuantity
        if (selected >= totalAvailable) {
            toast.info("Maximum quantity!")
        } else {
            productArr[index].SelectedQuantity = productArr[index].SelectedQuantity + 1
            productArr[index].TempAvailableQuantity = productArr[index].TempAvailableQuantity - 1
        }
        setProductList(productArr)
    }

    const decrementCartQty = (id, index) => {
        const serPet = { ...servicePet }
        const productArr = [...productList]
        let selected = serPet.selectedProducts[index].numQuantity

        if (selected <= 1) {
            serPet.selectedProducts[index].numQuantity = 1
        } else {
            serPet.selectedProducts[index].numQuantity = serPet.selectedProducts[index].numQuantity - 1
            let productListIndex = productList.findIndex((x) => x.numLocationProductID === id)
            productArr[productListIndex].TempAvailableQuantity = productArr[productListIndex].TempAvailableQuantity + 1
        }
        setServicePet(serPet)
        setProductList(productArr)
    }

    const incrementCartQty = (id, index) => {
        let productListIndex = productList.findIndex((x) => x.numLocationProductID === id)

        const serPet = { ...servicePet }
        const productArr = [...productList]
        let selected = serPet.selectedProducts[index].numQuantity
        let totalAvailable = productArr[productListIndex].numAvailableQuantity

        if (selected >= totalAvailable) {
            toast.info("Maximum quantity!")
        } else {
            serPet.selectedProducts[index].numQuantity = serPet.selectedProducts[index].numQuantity + 1
            productArr[productListIndex].TempAvailableQuantity = productArr[productListIndex].TempAvailableQuantity - 1
        }
        setServicePet(serPet)
        setProductList(productArr)
    }

    const removeFromCart = (id, index) => {
        const serPet = { ...servicePet }
        const productArr = [...productList]
        let productListIndex = productList.findIndex((x) => x.numLocationProductID === id)

        if (serPet.selectedProducts[index].numOrderID !== null) {
            serPet.selectedProducts[index].bitActive = false
            productArr[productListIndex].TempAvailableQuantity = productArr[productListIndex].TempAvailableQuantity + serPet.selectedProducts[index].numQuantity
            serPet.selectedProducts[index].numQuantity = 0
        } else {
            productArr[productListIndex].TempAvailableQuantity = productArr[productListIndex].TempAvailableQuantity + serPet.selectedProducts[index].numQuantity
            serPet.selectedProducts.splice(index, 1)
            productArr[productListIndex].isAddedToCart = false
        }
        setServicePet(serPet)
        setProductList(productArr)
    }

    const addToCart = (id, index) => {
        const cartList = { ...servicePet }
        const pList = [...productList]
        let cartItemIndex = servicePet.selectedProducts.findIndex((x) => x.numLocationProductID === id)

        if (cartItemIndex < 0) {
            if (productList[index].SelectedQuantity < 1) {
                toast.info("Please select quantity!")
            } else {
                cartList.selectedProducts.push({
                    numOrderID: null,
                    numInvoiceID: null,
                    numLocationID: 1,
                    numSchID: null,
                    numAppointmentID: null,
                    numSchEventID: null,
                    numPetTypeID: servicePet.petType,
                    numPetSizeID: servicePet.sizeID,
                    numPetBreedID: servicePet.breedID,
                    numGroomerID: 1,
                    numBatherID: null,
                    numSalesPersonID: 1,
                    decUnit_Price: productList[index].numProductSalesPrice,
                    decChargeAmount: productList[index].numProductSalesPrice,
                    decTaxAmt:
                        parseFloat(
                            calcTax(
                                productList[index].numStorePromotionDiscount,
                                productList[index].numSeasonalPromotionDiscount,
                                productList[index].numProductSalesPrice,
                                productList[index].numSalesTaxPercentage
                            )
                        ) * productList[index].SelectedQuantity,
                    dtBegin_date_of_service: null,
                    dtEnd_date_of_service: null,
                    varStatus: "",
                    numSeq_nbr: null,
                    numReferringCustomerID: 154,
                    varReferringCustomerName: "Kewin 3",
                    numCancelReasonID: null,
                    varCancelReason: "",
                    numReasonCodeID: null,
                    varReasonCodeDescription: "",
                    numEmployeeID: 1,
                    varEmployeeName: "Sean",
                    varServiceProductDescription: productList[index].varProductDescription === null ? "" : productList[index].varProductDescription,
                    numLocationProductID: productList[index].numLocationProductID,
                    varProductBarCode: productList[index].varProductBarCode,
                    numProductID: productList[index].numProductID,
                    varProductVendorID: productList[index].varProductVendorID,
                    varProductName: productList[index].varProductName,
                    varProductDescription: productList[index].varProductDescription,
                    LocID: productList[index].LocID,
                    numCustomerID: props.loginData.customer.numCustomerID,
                    ServiceType: productList[index].ServiceType,
                    dtEffectiveDate: productList[index].dtEffectiveDate,
                    dtExpiryDate: productList[index].dtExpiryDate,
                    numProductSalesPrice: productList[index].numProductSalesPrice,
                    varStorePromotionMsg: productList[index].varStorePromotionMsg,
                    numStorePromotionDiscount: productList[index].numStorePromotionDiscount,
                    varSeasonalPromotionMsg: productList[index].varSeasonalPromotionMsg,
                    numSeasonalPromotionDiscount: productList[index].numSeasonalPromotionDiscount,
                    bitTaxableItem: productList[index].bitTaxableItem,
                    numSalesTaxPercentage: productList[index].numSalesTaxPercentage,
                    TotalTaxAmount: productList[index].TotalTaxAmount,
                    numAvailableQuantity: productList[index].numAvailableQuantity,
                    numQuantity: productList[index].SelectedQuantity,
                    bitActivePromotionFlag: productList[index].bitActivePromotionFlag,
                    varLinkToPromotionMaterial: productList[index].varLinkToPromotionMaterial,
                    varLinkToGlobalCoupon: productList[index].varLinkToGlobalCoupon,
                    varMessageToEmployee: productList[index].varMessageToEmployee,
                    varPromotionalMessage: productList[index].varPromotionalMessage,
                    numLocationSpecificAdjustmentPercent: productList[index].numLocationSpecificAdjustmentPercent,
                    numLocationSpecificAdjustmentAmount: productList[index].numLocationSpecificAdjustmentAmount,
                    numDateSpecificAdjustmentPercent: productList[index].numDateSpecificAdjustmentPercent,
                    numDateSpecificAdjustmentAmount: productList[index].numDateSpecificAdjustmentAmount,
                    varSalesCommissionMessage: productList[index].varSalesCommissionMessage,
                    btSalesCommissionPercent: productList[index].btSalesCommissionPercent,
                    numSalesCommissionPercent: productList[index].numSalesCommissionPercent,
                    btSalesCommissionAmount: productList[index].btSalesCommissionAmount,
                    numSalesCommissionAmount: productList[index].numSalesCommissionAmount,
                    bitAutoReOrder: productList[index].bitAutoReOrder,
                    numMinimumQuantityReOrderLevel: productList[index].numMinimumQuantityReOrderLevel,
                    numMaximumLocationQuantity: productList[index].numMaximumLocationQuantity,
                    bitActive: productList[index].bitActive,
                    numServiceProductItemID: productList[index].numServiceProductItemID,
                    varServiceOrProduct: "P",
                    numPetID: servicePet.id,
                    DOS: productList[index].DOS,
                    OrderSelected: productList[index].OrderSelected,
                    ChargeEntered: productList[index].ChargeEntered,
                    PaymentEntered: productList[index].PaymentEntered,
                    numLocationServiceProductItemID: productList[index].numLocationProductID,
                })
                productList[index].SelectedQuantity = 0
                pList[index].isAddedToCart = true
            }
        } else {
            if (productList[index].SelectedQuantity < 1) {
                toast.info("Please select quantity!")
            } else {
                cartList.selectedProducts[cartItemIndex].bitActive = true
                cartList.selectedProducts[cartItemIndex].numQuantity = cartList.selectedProducts[cartItemIndex].numQuantity + productList[index].SelectedQuantity
                productList[index].SelectedQuantity = 0
                pList[index].isAddedToCart = true
            }
        }
        setServicePet(cartList)
        setProductList(pList)
    }

    useEffect(() => {
        let amountT = 0
        let cartItem = 0
        if (servicePet.selectedProducts !== undefined) {
            servicePet.selectedProducts.map((product, index) => {
                if (product.numProductSalesPrice !== null && product.bitActive === true) {
                    amountT = amountT + Math.round(parseFloat(product.numProductSalesPrice) * product.numQuantity * 100) / 100
                }
                if (product.bitActive === true) {
                    cartItem = cartItem + product.numQuantity
                }
            })
            setProductCartAmount(amountT)
            setCartItemCount(cartItem)
        } else {
            setProductCartAmount(amountT)
            setCartItemCount(cartItem)
        }
    }, [servicePet])

    useEffect(() => {
        console.log("servicePet", servicePet)
    }, [servicePet])

    const [filter, setFilter] = useState("")

    // Filter services based on the filter input
    const filteredServices = additionalServiceList.filter((service) => {
        if (filter === "") {
            return true // Show all services when the filter input is empty
        }
        return service.varServiceItemName.toLowerCase().includes(filter.toLowerCase())
    })

    const [makeReservationOpen, setMakeReservationOpen] = useState(false)
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTabletOrDesktop = useMediaQuery('(min-width:600px)');
    
    const [drawerOpen, setDrawerOpen] = useState(false);

    // Toggle Drawer on mobile
    const toggleDrawer = (open) => () => {
      setDrawerOpen(open);
    };

    const renderNavItems = () => (
        <>
          <ListItem
            button
            onClick={() => selectTab("0")}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: selectedTab === "0" ? '#007bff' : 'inherit',
              mb: 2,
            }}
          >
            <Person sx={{ mr: 1 }} />
            Account Information
          </ListItem>
    
          <ListItem
            button
            onClick={() => selectTab("1")}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: selectedTab === "1" ? '#007bff' : 'inherit',
              mb: 2,
            }}
          >
            <Pets sx={{ mr: 1 }} />
            My Pets
          </ListItem>
    
          <ListItem
            button
            onClick={() => selectTab("2")}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: selectedTab === "2" ? '#007bff' : 'inherit',
              mb: 2,
            }}
          >
            <DatasetLinked sx={{ mr: 1 }} />
            Linked Accounts
          </ListItem>
    
          <ListItem
            button
            onClick={() => selectTab("3")}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: selectedTab === "3" ? '#007bff' : 'inherit',
              mb: 2,
            }}
          >
            <NotificationAdd sx={{ mr: 1 }} />
            Notifications
          </ListItem>
    
          <ListItem
            button
            onClick={() => selectTab("4")}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: selectedTab === "4" ? '#007bff' : 'inherit',
              mb: 2,
            }}
          >
            <CreditCard sx={{ mr: 1 }} />
            Payment Information
          </ListItem>
          <ListItem
            button
            onClick={() => selectTab("5")}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: selectedTab === "5" ? '#007bff' : 'inherit',
              mb: 2,
            }}
          >
            <Lock sx={{ mr: 1 }} />
            Security
          </ListItem>
        </>
      );

    return (
        <div className={styles.mobileMargin}>
            <h1>My Profile</h1>
            <div
                className={styles.breakColumns}
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "100px",
                }}
            >
            <Box
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start', 
                height: '100%', 
                minHeight: "auto", 
                paddingTop: isTabletOrDesktop ? 2 : 0, 
                backgroundColor: '#f5f5f5',
                p: 2,
            }}
            >
            {/* Mobile: Hamburger Menu */}
            {isMobile && (
                <>
                <IconButton onClick={toggleDrawer(true)} sx={{ mr: 2 }}>
                    <MenuIcon />
                </IconButton>
                <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                    <List>
                    {renderNavItems()}
                    </List>
                </Drawer>
                </>
            )}

            {/* Tablet and Desktop: Show icons and labels */}
            {isTabletOrDesktop && (
                <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start', // Align items to the start (top left)
                }}
                >
                <List>
                    {renderNavItems()}
                </List>
                </Box>
            )}
            </Box>
                <div style={{ flex: 4 }}>
                    {dataLoading ? (
                        <p>Loading...</p>
                    ) : selectedTab === "0" ? (
                        <div style={{maxHeight:"65vh", overflowY:"scroll"}}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    marginBottom: "20px",
                                }}
                            >
                                <h2 style={{ color: "#333" }}>Account Information</h2>
                                <Button variant="contained" color="primary" onClick={() => setIsEditCustomer(!isEditCustomer)}>
                                    <b>Edit</b>
                                </Button>
                            </div>

                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "20px",
                                    }}
                                >
                                    {isEditCustomer ? (
                                        <></>
                                    ) : (
                                        <>
                                            <Avatar
                                                img={fileSelected ? URL.createObjectURL(importedData) : `${process.env.REACT_APP_MEDIA_URL}directories/customers/` + importedData}
                                                isVip={false}
                                                className={"large"}
                                                labelType={"largeLabel"}
                                            />
                                            <p
                                                style={{
                                                    fontWeight: "600",
                                                    fontSize: "20px",
                                                }}
                                            >
                                                {customerData?.customer?.varSalutation} {customerData?.customer?.varCustomerFirstName} {customerData?.customer?.varCustomerLastName}{" "}
                                                {customerData?.customer?.bitIsVip ? "VIP" : null}
                                            </p>
                                        </>
                                    )}
                                </div>

                                {isEditCustomer ? (
                                    <div style={{ display: "flex" }} className={styles.breakColumns}>
                                        <div
                                            style={{
                                                flex: 1,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-around",
                                                marginBottom: "15px",
                                            }}
                                        >
                                            <Avatar
                                                img={fileSelected ? URL.createObjectURL(importedData) : `${process.env.REACT_APP_MEDIA_URL}directories/customers/` + importedData}
                                                isVip={false}
                                                className={"large"}
                                                labelType={"largeLabel"}
                                            />
                                            <FileUploader
                                                handleChange={readFile}
                                                onDrop={readFile}
                                                disabled={loading ? true : false}
                                                name="file"
                                                types={fileTypes}
                                                multiple={false}
                                                label=""
                                                children={
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <FileUploadOutlined
                                                            style={{
                                                                color: "#2076FF",
                                                            }}
                                                        />
                                                        <p className={styles.uploadText}>Upload Image</p>
                                                    </div>
                                                }
                                            />
                                        </div>

                                        <form id="editForm" className={styles.formEdit} onSubmit={handleSubmit}>
                                            <AdvancedInput
                                                required={true}
                                                isButton={false}
                                                label={"Salutation"}
                                                onChange={(e) => {
                                                    const newValue = {
                                                        ...customerData,
                                                    }
                                                    newValue.customer.numSalutationID = e
                                                    setCustomerData(newValue)
                                                }}
                                                ObjectName={"Salutation"}
                                                isCustom={false}
                                                Sequence={""}
                                                selectedValue={customerData?.customer?.numSalutationID}
                                            />
                                            <TextField
                                                inputProps={{
                                                    pattern: "^[a-zA-Z ]*$",
                                                }}
                                                label="First Name"
                                                name="firstName"
                                                variant="outlined"
                                                value={customerData?.customer?.varCustomerFirstName}
                                                onChange={(e) => {
                                                    const newValue = {
                                                        ...customerData,
                                                    }
                                                    newValue.customer.varCustomerFirstName = e.target.value
                                                    setCustomerData(newValue)
                                                }}
                                                required
                                            />
                                            <TextField
                                                inputProps={{
                                                    pattern: "^[a-zA-Z ]*$",
                                                }}
                                                label="Last Name"
                                                name="lastName"
                                                variant="outlined"
                                                value={customerData?.customer?.varCustomerLastName}
                                                onChange={(e) => {
                                                    const newValue = {
                                                        ...customerData,
                                                    }
                                                    newValue.customer.varCustomerLastName = e.target.value
                                                    setCustomerData(newValue)
                                                }}
                                                required
                                            />
                                            <TextField
                                                label="Mobile"
                                                name="mobile"
                                                variant="outlined"
                                                value={customerData?.customer?.varCustomerMobilePhone}
                                                onChange={(e) => {
                                                    const newValue = {
                                                        ...customerData,
                                                    }
                                                    newValue.customer.varCustomerMobilePhone = e.target.value
                                                    setCustomerData(newValue)
                                                }}
                                                required
                                            />
                                            <TextField
                                                label="Home"
                                                name="home"
                                                variant="outlined"
                                                value={customerData?.customer?.varEmergContactNumber}
                                                onChange={(e) => {
                                                    const newValue = {
                                                        ...customerData,
                                                    }
                                                    newValue.customer.varEmergContactNumber = e.target.value
                                                    setCustomerData(newValue)
                                                }}
                                            />
                                            <TextField
                                                label="Work"
                                                name="work"
                                                variant="outlined"
                                                value={customerData?.customer?.varCustomerWorkPhone}
                                                onChange={(e) => {
                                                    const newValue = {
                                                        ...customerData,
                                                    }
                                                    newValue.customer.varCustomerWorkPhone = e.target.value
                                                    setCustomerData(newValue)
                                                }}
                                            />
                                            <TextField
                                                label="Email"
                                                name="email"
                                                type="email"
                                                variant="outlined"
                                                value={customerData?.customer?.varCustomerEmail}
                                                onChange={(e) => {
                                                    const newValue = {
                                                        ...customerData,
                                                    }
                                                    newValue.customer.varCustomerEmail = e.target.value
                                                    setCustomerData(newValue)
                                                }}
                                                required
                                            />
                                            <Button loading={customerLoading} type="submit" variant="contained" color="primary">
                                                Save Changes
                                            </Button>
                                        </form>
                                    </div>
                                ) : (
                                    <div
                                        className={styles.breakColumns}
                                        style={{
                                            display: "flex",
                                            gap: "50px",
                                            paddingTop: "20px",
                                        }}
                                    >
                                        <div>
                                            <h2
                                                style={{
                                                    fontSize: "18px",
                                                    padding: "14px 0",
                                                    color: "#333",
                                                }}
                                            >
                                                System Reference
                                            </h2>
                                            <table>
                                                <tr>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                            width: "300px",
                                                        }}
                                                    >
                                                        Customer ID
                                                    </td>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {customerData?.customer?.numCustomerID}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Bar Code
                                                    </td>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {customerData?.customer?.varCustomerBC === null ? "-" : customerData?.customer?.varCustomerBC}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Status
                                                    </td>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Active
                                                    </td>
                                                </tr>
                                            </table>

                                            <h2
                                                style={{
                                                    fontSize: "18px",
                                                    padding: "14px 0",
                                                    color: "#333",
                                                }}
                                            >
                                                Contact Details
                                            </h2>
                                            <table>
                                                <tr>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                            width: "300px",
                                                        }}
                                                    >
                                                        Mobile Phone
                                                    </td>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {customerData?.customer?.varCustomerMobilePhone}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Home Phone
                                                    </td>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {customerData?.customer?.varCustomerHomePhone}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Work Phone
                                                    </td>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {customerData?.customer?.varCustomerWorkPhone}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Email
                                                    </td>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {customerData?.customer?.varCustomerEmail}
                                                    </td>
                                                </tr>
                                            </table>

                                            <h2
                                                style={{
                                                    fontSize: "18px",
                                                    padding: "14px 0",
                                                    color: "#333",
                                                }}
                                            >
                                                Emergency Contact Details
                                            </h2>
                                            <table>
                                                <tr>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                            width: "300px",
                                                        }}
                                                    >
                                                        Contact Person Relationship
                                                    </td>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {customerData?.customer?.varContactPerson}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Emergency Contact First Name
                                                    </td>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {customerData?.customer?.varEmergContactFirstName}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Emergency Contact Last Name
                                                    </td>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {customerData?.customer?.varEmergContactLastName}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Emergency Contact Phone
                                                    </td>
                                                    <td
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {customerData?.customer?.varEmergContactNumber}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div>
                                            <h2
                                                style={{
                                                    fontSize: "18px",
                                                    padding: "14px 0",
                                                    color: "#333",
                                                }}
                                            >
                                                Addresses
                                            </h2>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "25px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {customerData?.customer?.customerAddress?.map((address) => {
                                                    return (
                                                        <table
                                                            style={{
                                                                backgroundColor: "aliceblue",
                                                                padding: "10px 20px",
                                                                borderRadius: "15px",
                                                            }}
                                                        >
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        fontSize: "14px",
                                                                        fontWeight: "600",
                                                                    }}
                                                                >
                                                                    {address?.varAddressType}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        fontSize: "14px",
                                                                    }}
                                                                >
                                                                    {address?.varCustomerAddress1}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        fontSize: "14px",
                                                                    }}
                                                                >
                                                                    {address?.varCustomerAddress2}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        fontSize: "14px",
                                                                    }}
                                                                >
                                                                    {address?.varCustomerCity}, {address?.varCustomerState}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        fontSize: "14px",
                                                                    }}
                                                                >
                                                                    {address?.varCustomerZip}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        fontSize: "14px",
                                                                    }}
                                                                >
                                                                    {address?.varCustomerAddressNotes}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : selectedTab === "1" ? (
                        <div  style={{maxHeight:"65vh", overflowY:"scroll"}}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px" }}>
                                <h2>My Pets</h2>

                                <div style={{display:"flex", gap:"10px"}}>
                                    <Button variant="contained" color="primary" onClick={() => addNewPet()}>
                                        <b>Add New Pet</b>
                                    </Button>

                                    <Button variant="contained" color="primary" onClick={() => setMakeReservationOpen(true)}>
                                        <b>Make Reservation</b>
                                    </Button>
                                </div>


                                <MakeReservation customerData={customerData} isOpen={makeReservationOpen} closeModal={() => setMakeReservationOpen(false)} isSuccess={(e)=>{}} />
                            </div>
                            <div style={{ overflowY: "scroll", height: "60vh" }}>
                                {customerData?.customer?.customerPet?.map((pet, index) => {
                                    if (index < 0) {
                                    } else {
                                        return (
                                            <div
                                                className={styles.breakColumnsPet}
                                                style={{
                                                    backgroundColor: "aliceblue",
                                                    padding: "20px 20px",
                                                    borderRadius: "15px",
                                                    display: "flex",
                                                    gap: "50px",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "15px",
                                                    }}
                                                >
                                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                        {/* <Button variant="outlined" color="info" onClick={() => addAppointment(index, pet?.numPetID)}>
                                                            <b>Add Appointment</b>
                                                        </Button> */}
                                                        <IconButton onClick={() => editAPet(pet?.numPetID)}>
                                                            <Edit style={{ color: "#1976d2" }} />
                                                        </IconButton>
                                                    </div>

                                                    {isEditPet ? (
                                                        <></>
                                                    ) : (
                                                        <>
                                                            <Avatar
                                                                img={`${process.env.REACT_APP_MEDIA_URL}directories/pets/` + pet?.varPetImagePath}
                                                                isVip={false}
                                                                className={"large"}
                                                                labelType={"largeLabel"}
                                                            />
                                                            <p
                                                                style={{
                                                                    fontWeight: "600",
                                                                    fontSize: "20px",
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                {pet?.varPetName}{" "}
                                                            </p>
                                                        </>
                                                    )}
                                                </div>

                                                <>
                                                    <div
                                                        style={{
                                                            lineHeight: "1.2em",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "15px",
                                                                color: "#3678e1",
                                                                marginBottom: "5px",
                                                            }}
                                                        >
                                                            <b>General</b>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <b>Type:</b> {pet?.varPetType}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <b>Breed:</b> {pet?.varPetBreed}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <b>Breed Type:</b> {pet?.varPetBreedType}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <b>Color:</b> {pet?.varPetColor === null ? "-" : pet?.varPetColor}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <b>Weight:</b> {pet?.numPetWeight}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <b>Size:</b> {pet?.varPetSize}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <b>Gender:</b> {pet?.varPetSex === "M" ? "Male" : "Female"}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <b>Age:</b> {pet?.varPetAge === null || pet?.varPetAge === "" ? "-" : pet?.varPetAge}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <b>Spay:</b> {pet?.varPetSpay ? "Yes" : "No"}
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            lineHeight: "1.2em",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "15px",
                                                                color: "#3678e1",
                                                                marginBottom: "5px",
                                                            }}
                                                        >
                                                            <b>More Info.</b>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <b>Description:</b> {pet?.varPetDescription === null || pet?.varPetDescription === "" ? "-" : pet?.varPetDescription}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <b>Temperament Status:</b>{" "}
                                                            {pet?.varTemperamentTestStatus === null || pet?.varTemperamentTestStatus === "" ? "-" : pet?.varTemperamentTestStatus}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <b>Temperament Tested Date:</b> {pet?.dtTemperamentTestedDate === null ? "-" : formatDateTime(pet?.dtTemperamentTestedDate)}
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            lineHeight: "1.2em",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "15px",
                                                                color: "#3678e1",
                                                                marginBottom: "5px",
                                                            }}
                                                        >
                                                            <b>Veterinarian</b>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <b>Name:</b> {pet?.petVet?.varPetVetName === null || pet?.petVet === null ? "-" : pet?.petVet?.varPetVetName}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <b>Work:</b> {pet?.petVet?.varPetVetPhone1 === null || pet?.petVet === null ? "-" : pet?.petVet?.varPetVetPhone1}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <b>Mobile:</b> {pet?.petVet?.varPetVetPhone2 === null || pet?.petVet === null ? "-" : pet?.petVet?.varPetVetPhone2}
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <b>Email:</b> {pet?.petVet?.varPetVetEmail === null || pet?.petVet === null ? "-" : pet?.petVet?.varPetVetEmail}
                                                        </p>
                                                    </div>
                                                </>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <Modal open={showEditModal}>
                                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", padding: ".5rem 1.5rem" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                        <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600" }}>Edit Pet</p>
                                        <CloseButton onClick={() => setShowEditModal(false)} />
                                    </div>
                                    <div style={{ backgroundColor: "#fff", width: "400px" }}>
                                        <div style={{ display: "flex", gap: "1.5rem", flexDirection: "column", padding: "0 0 .8rem 0" }}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                }}
                                                className={styles.breakColumns}
                                            >
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-around",
                                                        marginBottom: "15px",
                                                    }}
                                                >
                                                    <Avatar
                                                        img={fileSelectedPet ? URL.createObjectURL(importedDataPet) : `${process.env.REACT_APP_MEDIA_URL}directories/pets/` + importedDataPet}
                                                        isVip={false}
                                                        className={"large"}
                                                        labelType={"largeLabel"}
                                                    />
                                                    <FileUploader
                                                        handleChange={readFilePet}
                                                        onDrop={readFilePet}
                                                        disabled={loading ? true : false}
                                                        name="filepet"
                                                        types={fileTypes}
                                                        multiple={false}
                                                        label=""
                                                        children={
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <FileUploadOutlined
                                                                    style={{
                                                                        color: "#2076FF",
                                                                    }}
                                                                />
                                                                <p className={styles.uploadText}>Upload Image</p>
                                                            </div>
                                                        }
                                                    />
                                                </div>

                                                <form id="editFormPet" className={styles.formEdit} onSubmit={handleSubmitPet}>
                                                    <TextField
                                                        size="small"
                                                        inputProps={{
                                                            pattern: "^[a-zA-Z ]*$",
                                                        }}
                                                        label="Pet Name"
                                                        name="petName"
                                                        variant="outlined"
                                                        value={petDataEdit?.pet?.varPetName}
                                                        onChange={(e) => {
                                                            const newValue = {
                                                                ...petDataEdit,
                                                            }
                                                            newValue.pet.varPetName = e.target.value
                                                            setPetDataEdit(newValue)
                                                        }}
                                                        required
                                                    />

                                                    <AdvancedInput
                                                        size="small"
                                                        required={true}
                                                        isButton={false}
                                                        label={"Pet Type"}
                                                        onChange={(e) => {
                                                            const newValue = {
                                                                ...petDataEdit,
                                                            }
                                                            newValue.pet.numPetTypeID = e
                                                            setPetDataEdit(newValue)
                                                        }}
                                                        ObjectName={"PetType"}
                                                        isCustom={false}
                                                        Sequence={""}
                                                        selectedValue={petDataEdit?.pet?.numPetTypeID}
                                                    />

                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        variant="outlined"
                                                        select={!!sizeData}
                                                        required={true}
                                                        label={"Pet Size"}
                                                        value={petDataEdit?.pet?.numPetSizeID}
                                                        onChange={(e) => {
                                                            const newValue = {
                                                                ...petDataEdit,
                                                            }
                                                            newValue.pet.numPetSizeID = e.target.value
                                                            setPetDataEdit(newValue)
                                                        }}
                                                    >
                                                        {sizeData?.map((item, i) => {
                                                            return (
                                                                <MenuItem key={i} value={item.numPetSizeID} style={{ fontSize: `medium` }}>
                                                                    {item.varPetSizeName}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </TextField>

                                                    <InputMask
                                                        required
                                                        size="small"
                                                        alwaysShowMask="true"
                                                        mask="99\lbs"
                                                        maskChar="_"
                                                        value={petDataEdit?.pet?.numPetWeight}
                                                        onChange={(e) => {
                                                            const newValue = {
                                                                ...petDataEdit,
                                                            }
                                                            newValue.pet.numPetWeight = removeLbs(e.target.value)
                                                            setPetDataEdit(newValue)
                                                        }}
                                                    >
                                                        {(inputProps) => <TextField {...inputProps} type="tel" required={true} label={"Pet Weight"} variant="outlined" />}
                                                    </InputMask>

                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        variant="outlined"
                                                        select={!!true}
                                                        required={true}
                                                        label={"Pet Gender"}
                                                        value={petDataEdit?.pet?.varPetSex}
                                                        onChange={(e) => {
                                                            const newValue = {
                                                                ...petDataEdit,
                                                            }
                                                            newValue.pet.varPetSex = e.target.value
                                                            setPetDataEdit(newValue)
                                                        }}
                                                    >
                                                        <MenuItem key={1} value={"M"} style={{ fontSize: `medium` }}>
                                                            Male
                                                        </MenuItem>

                                                        <MenuItem key={2} value={"F"} style={{ fontSize: `medium` }}>
                                                            Female
                                                        </MenuItem>
                                                    </TextField>

                                                    <AdvancedDropDown
                                                        data={PureBreedData.map((i) => ({ label: i.varPetBreedName, value: i.numPetBreedID }))}
                                                        onChange={(e) => {
                                                            const newValue = {
                                                                ...petDataEdit,
                                                            }
                                                            newValue.pet.numPetBreedID = e?.value
                                                            setPetDataEdit(newValue)
                                                        }}
                                                        passByID={true}
                                                        value={petDataEdit?.pet?.numPetBreedID}
                                                        ref={BreedRef}
                                                        placeHolder={"Pet Breed"}
                                                    />

                                                    <Button loading={customerLoading} type="submit" variant="contained" color="primary">
                                                        Save Changes
                                                    </Button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            <Modal open={showAddModal}>
                                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", padding: ".5rem 1.5rem" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                        <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", whiteSpace:"nowrap" }}>Add New Pet</p>
                                        <CloseButton onClick={() => setShowAddModal(false)} />
                                    </div>
                                    <div style={{ backgroundColor: "#fff", width: "400px" }}>
                                        <div style={{ display: "flex", gap: "1.5rem", flexDirection: "column", padding: "0 0 .8rem 0" }}>
                                            <form id="addFormPet" className={styles.formEdit} onSubmit={handleSubmitAddPet} style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                                                <TextField
                                                    size="small"
                                                    inputProps={{
                                                        pattern: "^[a-zA-Z ]*$",
                                                    }}
                                                    label="Pet Name"
                                                    name="petName"
                                                    variant="outlined"
                                                    value={petName}
                                                    onChange={(e) => {
                                                        setPetName(e.target.value)
                                                    }}
                                                    required
                                                />
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    variant="outlined"
                                                    select={!!typeData}
                                                    required={true}
                                                    label={"Pet Type"}
                                                    value={petType}
                                                    onChange={(e) => {
                                                        setPetType(e.target.value)
                                                        getSizeData(e.target.value)
                                                    }}
                                                >
                                                    {typeData?.map((item, i) => {
                                                        return (
                                                            <MenuItem key={i} value={item.numPetTypeID} style={{ fontSize: `medium` }}>
                                                                {item.varPetTypeName}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </TextField>

                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    variant="outlined"
                                                    select={!!sizeData}
                                                    required={true}
                                                    label={"Pet Size"}
                                                    value={petSize}
                                                    onChange={(e) => setPetSize(e.target.value)}
                                                >
                                                    {sizeData?.map((item, i) => {
                                                        return (
                                                            <MenuItem key={i} value={item.numPetSizeID} style={{ fontSize: `medium` }}>
                                                                {item.varPetSizeName}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </TextField>

                                                <InputMask
                                                    required
                                                    size="small"
                                                    alwaysShowMask="true"
                                                    mask="99\lbs"
                                                    maskChar="_"
                                                    value={petWeight}
                                                    onChange={(e) => {
                                                        setPetWeight(e.target.value)
                                                    }}
                                                >
                                                    {(inputProps) => <TextField {...inputProps} type="tel" required={true} label={"Pet Weight"} variant="outlined" />}
                                                </InputMask>

                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    variant="outlined"
                                                    select={!!true}
                                                    required={true}
                                                    label={"Pet Gender"}
                                                    value={petGender}
                                                    onChange={(e) => setPetGender(e.target.value)}
                                                >
                                                    <MenuItem key={1} value={"M"} style={{ fontSize: `medium` }}>
                                                        Male
                                                    </MenuItem>

                                                    <MenuItem key={2} value={"F"} style={{ fontSize: `medium` }}>
                                                        Female
                                                    </MenuItem>
                                                </TextField>

                                                <AdvancedDropDown
                                                    data={PureBreedData.map((i) => ({ label: i.varPetBreedName, value: i.numPetBreedID }))}
                                                    onChange={(e) => {
                                                        setPetBreed(e?.value)
                                                    }}
                                                    passByID={true}
                                                    value={petBreed}
                                                    ref={BreedRef}
                                                    placeHolder={"Pet Breed"}
                                                />

                                                <Button loading={customerLoading} type="submit" variant="contained" color="primary">
                                                    Create New Pet
                                                </Button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    ) : selectedTab === "2" ? (
                        <div  style={{maxHeight:"65vh", overflowY:"scroll"}}>
            
                                <h2>Linked Accounts</h2>
                                <LinkedAccounts data={customerData} mode={false}/>


                      
                        </div>
                    ) : selectedTab === "3" ? (
                        <div  style={{maxHeight:"65vh", overflowY:"scroll"}}>
            
                                <h2>Notifications</h2>
                                <Notifications type={""} data={customerData} mode={false}/>


                      
                        </div>
                    ) : selectedTab === "4" ? (
                        <div  style={{maxHeight:"65vh", overflowY:"scroll"}}>
            
                                <h2>Payment Information</h2>
                                <PaymentDetails mode={true} data={customerData?.customer}/>


                      
                        </div>
                    ) : selectedTab === "5" ? (
                        <div  style={{maxHeight:"65vh", overflowY:"scroll"}}>
            
                                <h2 style={{color:"#333"}}>Security</h2>
                                <Security mode={true} data={customerData?.customer} loggedUser={props.loginData}/>


                      
                        </div>
                    ) : null}
                </div>
            </div>
            <Modal open={isOpenNewAppointment}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                            backgroundColor: "#f5f5f5",
                            padding: "16px",
                            marginTop: "-16px",
                            borderTopRightRadius: "12px",
                            borderTopLeftRadius: "12px",
                        }}
                    >
                        <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>New Appointment</p>
                        <CloseButton onClick={() => setIsOpenNewAppointment(false)} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        <div>
                            {servicePet.id !== undefined ? (
                                <>
                                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}></div>
                                    <div style={{ backgroundColor: "#fff" }}>
                                        <div style={{ width: "100%", borderRadius: ".5rem", border: "1px solid #F3F4F6", padding: "0 0 10px 0" }}>
                                            {
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    {
                                                        <div style={{ display: "flex", padding: "1rem 1rem 0 1rem", gap: "20px" }}>
                                                            <div
                                                                style={{
                                                                    borderRadius: "8px",
                                                                    padding: ".6rem ",
                                                                    boxShadow: "0px 2px 8px rgba(218, 218, 218, 0.1), 0px 1px 4px rgba(97, 97, 97, 0.1)",
                                                                    width: "20%",
                                                                }}
                                                            >
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <AvatarLabel
                                                                        isVip={false}
                                                                        img={
                                                                            servicePet?.img == "" || servicePet?.img == null
                                                                                ? ""
                                                                                : `${process.env.REACT_APP_MEDIA_URL}directories/pets/` + servicePet?.img
                                                                        }
                                                                        name=""
                                                                    />
                                                                    <div>
                                                                        <p style={{ margin: "0", fontWeight: "600", textTransform: "capitalize" }}>{Truncate(servicePet?.name, 20)}</p>
                                                                        <p className={styles.petMiniCardPBreed}>{Truncate(servicePet?.breed, 10)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        backgroundColor: "#F3F4F6",
                                                                        padding: "5px 15px 15px 15px",
                                                                        borderRadius: "8px",
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <p style={{ fontWeight: "500", fontSize: "14px", color: "#6B7280", marginBottom: ".5rem" }}>Services</p>
                                                                        <div style={{ display: "flex", gap: ".5rem", maxWidth: "620px", flexWrap: "wrap" }}>
                                                                            {servicePet.additionalService.length > 0 ? (
                                                                                servicePet.additionalService.map((addService) => {
                                                                                    if (addService.id !== null) {
                                                                                        return (
                                                                                            <Tooltip
                                                                                                title={
                                                                                                    <>
                                                                                                        <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Additional Service Name</p>
                                                                                                        <p style={{ fontSize: "12px", color: "#fff" }}>{addService.name}</p>
                                                                                                    </>
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    style={{
                                                                                                        backgroundColor: "#DCFCE7",
                                                                                                        border: "1px solid #86EFAC",
                                                                                                        borderRadius: "16px",
                                                                                                        color: "#14532D",
                                                                                                        fontSize: "14px",
                                                                                                        fontWeight: "500",
                                                                                                        display: "flex",
                                                                                                        justifyContent: "space-between",
                                                                                                        alignItems: "center",
                                                                                                        padding: "0 10px 0 10px",
                                                                                                        gap: ".2rem",
                                                                                                        width: "max-content",
                                                                                                    }}
                                                                                                >
                                                                                                    {Truncate(addService.name, 20)}{" "}
                                                                                                    <span style={{ margin: "0", borderRight: "1px solid #86efac" }}>&nbsp;</span> ${addService.price}
                                                                                                </div>
                                                                                            </Tooltip>
                                                                                        )
                                                                                    } else {
                                                                                        return null
                                                                                    }
                                                                                })
                                                                            ) : (
                                                                                <p style={{ color: "#14532D", fontSize: "14px", fontWeight: "500", margin: "0" }}>--</p>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <p style={{ fontWeight: "500", fontSize: "14px", color: "#6B7280", marginBottom: ".5rem" }}>Products</p>
                                                                        <div style={{ display: "flex", gap: ".5rem", maxWidth: "620px", flexWrap: "wrap" }}>
                                                                            {servicePet.selectedProducts.length > 0 ? (
                                                                                servicePet.selectedProducts
                                                                                    .filter((x) => x.bitActive === true)
                                                                                    .map((product) => {
                                                                                        return (
                                                                                            <Tooltip
                                                                                                title={
                                                                                                    <>
                                                                                                        <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Product Name</p>
                                                                                                        <p style={{ fontSize: "12px", color: "#fff" }}>{product.varProductName}</p>
                                                                                                    </>
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    style={{
                                                                                                        backgroundColor: "#DCFCE7",
                                                                                                        border: "1px solid #86EFAC",
                                                                                                        borderRadius: "16px",
                                                                                                        color: "#14532D",
                                                                                                        fontSize: "14px",
                                                                                                        fontWeight: "500",
                                                                                                        display: "flex",
                                                                                                        justifyContent: "space-between",
                                                                                                        alignItems: "center",
                                                                                                        padding: "0 10px 0 10px",
                                                                                                        gap: ".2rem",
                                                                                                        width: "max-content",
                                                                                                    }}
                                                                                                >
                                                                                                    {Truncate(product.varProductName, 20)}{" "}
                                                                                                    <span style={{ margin: "0", borderRight: "1px solid #86efac" }}>&nbsp;</span>Qty:
                                                                                                    {product.numQuantity}
                                                                                                    <span style={{ margin: "0", borderRight: "1px solid #86efac" }}>&nbsp;</span>$
                                                                                                    {(product.numProductSalesPrice * product.numQuantity).toFixed(2)}
                                                                                                </div>
                                                                                            </Tooltip>
                                                                                        )
                                                                                    })
                                                                            ) : (
                                                                                <p style={{ color: "#14532D", fontSize: "14px", fontWeight: "500", margin: "0" }}>--</p>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6">
                                    <p style={{ color: "#002867", fontSize: "16px", fontWeight: "600", margin: "0" }}>Location</p>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Location content.</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6">
                                    <p style={{ color: "#002867", fontSize: "16px", fontWeight: "600", margin: "0" }}>Services</p>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    label="Filter services"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={filter}
                                    onChange={(e) => setFilter(e.target.value)}
                                    size="small"
                                    style={{ width: "30%", marginBottom: "5px" }}
                                />
                                <div style={{ overflowY: "scroll", height: "200px", paddingRight: "5px" }}>
                                    {isLoading ? (
                                        <p>Loading...</p>
                                    ) : additionalServiceList.length > 0 ? (
                                        additionalServiceList
                                            .sort((a, b) => {
                                                if (a.bitIsAdditional === false && b.bitIsAdditional === true) {
                                                    return -1
                                                } else if (a.bitIsAdditional === true && b.bitIsAdditional === false) {
                                                    return 1
                                                } else {
                                                    return 0
                                                }
                                            })
                                            .map((service, index) => {
                                                let matching
                                                if (servicePet !== undefined) {
                                                    matching = servicePet.additionalService.some((x) => x.id === service.numServiceItemID)
                                                }
                                                return (
                                                    <>
                                                        {service.numServiceItemPreviousSalesPrice === 0 ? (
                                                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                <div
                                                                    style={{ width: "100%" }}
                                                                    className={matching ? styles.main_service_accordion_active : styles.main_service_accordion}
                                                                    onClick={() => {
                                                                        selectAdditionalService(index)
                                                                    }} //selectAdditionalService(index)
                                                                >
                                                                    <div>
                                                                        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                            {service.bitIsAdditional === false ? (
                                                                                <Tooltip
                                                                                    title={
                                                                                        <>
                                                                                            <div style={{ padding: "5px" }}>
                                                                                                <p style={{ fontSize: "14px", color: "white", fontWeight: "600", marginBottom: "5px" }}></p>
                                                                                                <p style={{ fontSize: "14px", color: "white", fontWeight: "600" }}>{service.varServiceItemName}</p>
                                                                                                <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                                                                                                    {service.SubServices.length > 0
                                                                                                        ? service.SubServices.map((sub, index) => {
                                                                                                              return (
                                                                                                                  <p key={index} style={{ whiteSpace: "pre-wrap", fontWeight: "500", color: "white" }}>
                                                                                                                      - {sub.SubServiceName}
                                                                                                                  </p>
                                                                                                              )
                                                                                                          })
                                                                                                        : null}
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                >
                                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(M)</p>
                                                                                </Tooltip>
                                                                            ) : (
                                                                                <Tooltip
                                                                                    style={{ paddingLeft: "5px" }}
                                                                                    title={
                                                                                        <>
                                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Additional Service</p>
                                                                                            <p style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>{service.varServiceItemName}</p>
                                                                                        </>
                                                                                    }
                                                                                >
                                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(A)</p>
                                                                                </Tooltip>
                                                                            )}
                                                                            <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>{service.varServiceItemName}</p>
                                                                        </div>
                                                                        <p style={{ color: "#4A5163", fontWeight: "400", fontSize: "12px", margin: "0", lineHeight: "1.25em" }}>
                                                                            {service.varServiceItemDescription}

                                                                            {service.varPriceChangeComment !== null ? <span> | {service.varPriceChangeComment}</span> : null}
                                                                        </p>
                                                                    </div>

                                                                    <div style={{ borderRadius: "4px", padding: "0 5px 0 5px", backgroundColor: "#EFF5FF" }}>
                                                                        <p
                                                                            style={{
                                                                                color: "#2630B9",
                                                                                fontSize: "16px",
                                                                                fontWeight: "400",
                                                                                margin: "0",
                                                                                padding: "0 5px",
                                                                                height: "100%",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                whiteSpace: "nowrap",
                                                                            }}
                                                                        >
                                                                            <b>${service.numServiceItemSalesPrice}</b>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : service.numServiceItemPreviousSalesPrice === service.numServiceItemSalesPrice ? (
                                                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                <div
                                                                    style={{ width: "100%" }}
                                                                    className={matching ? styles.main_service_accordion_active : styles.main_service_accordion}
                                                                    onClick={() => {
                                                                        selectAdditionalService(index)
                                                                    }} //selectAdditionalService(index)
                                                                >
                                                                    <div>
                                                                        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                            {service.bitIsAdditional === false ? (
                                                                                <Tooltip
                                                                                    title={
                                                                                        <>
                                                                                            <div style={{ padding: "5px" }}>
                                                                                                <p style={{ fontSize: "14px", color: "white", fontWeight: "600", marginBottom: "5px" }}></p>
                                                                                                <p style={{ fontSize: "14px", color: "white", fontWeight: "600" }}>{service.varServiceItemName}</p>
                                                                                                <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                                                                                                    {service.SubServices.length > 0
                                                                                                        ? service.SubServices.map((sub, index) => {
                                                                                                              return (
                                                                                                                  <p key={index} style={{ whiteSpace: "pre-wrap", fontWeight: "500", color: "white" }}>
                                                                                                                      - {sub.SubServiceName}
                                                                                                                  </p>
                                                                                                              )
                                                                                                          })
                                                                                                        : null}
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                >
                                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(M)</p>
                                                                                </Tooltip>
                                                                            ) : (
                                                                                <Tooltip
                                                                                    style={{ paddingLeft: "5px" }}
                                                                                    title={
                                                                                        <>
                                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Additional Service</p>
                                                                                            <p style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>{service.varServiceItemName}</p>
                                                                                        </>
                                                                                    }
                                                                                >
                                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(A)</p>
                                                                                </Tooltip>
                                                                            )}
                                                                            <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>{service.varServiceItemName}</p>
                                                                        </div>
                                                                        <p style={{ color: "#4A5163", fontWeight: "400", fontSize: "12px", margin: "0", lineHeight: "1.25em" }}>
                                                                            {service.varServiceItemDescription}
                                                                            {service.varPriceChangeComment !== null ? <span> | {service.varPriceChangeComment}</span> : null}
                                                                        </p>
                                                                    </div>

                                                                    <div style={{ borderRadius: "4px", padding: "0 5px 0 5px", backgroundColor: "#EFF5FF" }}>
                                                                        <p
                                                                            style={{
                                                                                color: "#2630B9",
                                                                                fontSize: "16px",
                                                                                fontWeight: "400",
                                                                                margin: "0",
                                                                                padding: "0 5px",
                                                                                height: "100%",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                whiteSpace: "nowrap",
                                                                            }}
                                                                        >
                                                                            <b>${service.numServiceItemSalesPrice}</b>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : service.numServiceItemSalesPrice > service.numServiceItemPreviousSalesPrice ? (
                                                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                <div
                                                                    style={{ backgroundColor: "#FEE8E7", width: "100%", display: "flex", flexDirection: "column" }}
                                                                    className={matching ? styles.main_service_accordion_active : styles.main_service_accordion}
                                                                    onClick={() => {
                                                                        selectAdditionalService(index)
                                                                    }} //selectAdditionalService(index)
                                                                >
                                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                        <div>
                                                                            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                                {service.bitIsAdditional === false ? (
                                                                                    <Tooltip
                                                                                        title={
                                                                                            <>
                                                                                                <div style={{ padding: "5px" }}>
                                                                                                    <p style={{ fontSize: "14px", color: "white", fontWeight: "600", marginBottom: "5px" }}></p>
                                                                                                    <p style={{ fontSize: "14px", color: "white", fontWeight: "600" }}>{service.varServiceItemName}</p>
                                                                                                    <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                                                                                                        {service.SubServices.length > 0
                                                                                                            ? service.SubServices.map((sub, index) => {
                                                                                                                  return (
                                                                                                                      <p
                                                                                                                          key={index}
                                                                                                                          style={{
                                                                                                                              whiteSpace: "pre-wrap",
                                                                                                                              fontWeight: "500",
                                                                                                                              color: "white",
                                                                                                                              margin: "0",
                                                                                                                          }}
                                                                                                                      >
                                                                                                                          {sub.SubServiceName}
                                                                                                                      </p>
                                                                                                                  )
                                                                                                              })
                                                                                                            : null}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                    >
                                                                                        <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(M)</p>
                                                                                    </Tooltip>
                                                                                ) : (
                                                                                    <Tooltip
                                                                                        style={{ paddingLeft: "5px" }}
                                                                                        title={
                                                                                            <>
                                                                                                <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Additional Service</p>
                                                                                                <p style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>{service.varServiceItemName}</p>
                                                                                            </>
                                                                                        }
                                                                                    >
                                                                                        <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(A)</p>
                                                                                    </Tooltip>
                                                                                )}
                                                                                <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>{service.varServiceItemName}</p>
                                                                            </div>
                                                                            <p style={{ color: "#4A5163", fontWeight: "400", fontSize: "12px", margin: "0", lineHeight: "1.25em" }}>
                                                                                {service.varServiceItemDescription}
                                                                                {service.varPriceChangeComment !== null ? <span> | {service.varPriceChangeComment}</span> : null}
                                                                            </p>
                                                                        </div>

                                                                        <div style={{ display: "flex", gap: "5px" }}>
                                                                            <div style={{ borderRadius: "4px", padding: "0 5px 0 5px", backgroundColor: "#FCDADA" }}>
                                                                                <p
                                                                                    style={{
                                                                                        color: "#802B2B",
                                                                                        fontSize: "16px",
                                                                                        fontWeight: "400",
                                                                                        margin: "0",
                                                                                        padding: "0 5px",
                                                                                        height: "100%",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        whiteSpace: "nowrap",
                                                                                    }}
                                                                                >
                                                                                    <ArrowUpward style={{ color: "#EB1A1A", fontSize: "18px" }} />
                                                                                </p>
                                                                            </div>

                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    minWidth: "110px",
                                                                                    border: "1px solid #F9B5B5",
                                                                                    borderRadius: "4px",
                                                                                    padding: "0 5px 0 5px",
                                                                                    backgroundColor: "#FCDADA",
                                                                                }}
                                                                            >
                                                                                <Tooltip
                                                                                    style={{ paddingLeft: "5px" }}
                                                                                    title={
                                                                                        <>
                                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Price Change Comment</p>
                                                                                            <p style={{ fontSize: "12px", color: "#fff" }}>{service.varPriceChangeComment}</p>
                                                                                        </>
                                                                                    }
                                                                                >
                                                                                    <InfoOutlined style={{ fontSize: "18px", color: "#802B2B" }} />
                                                                                </Tooltip>

                                                                                <p
                                                                                    style={{
                                                                                        color: "#802B2B",
                                                                                        fontSize: "16px",
                                                                                        fontWeight: "400",
                                                                                        margin: "0",
                                                                                        padding: "0 5px",
                                                                                        height: "100%",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        whiteSpace: "nowrap",
                                                                                        justifyContent: "center",
                                                                                    }}
                                                                                >
                                                                                    <del>${service.numServiceItemPreviousSalesPrice}</del>&nbsp;/&nbsp;<b>${service.numServiceItemSalesPrice}</b>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {service.varSeasonalPromotionMsg !== "" ? (
                                                                        <p
                                                                            style={{
                                                                                display: "flex",
                                                                                gap: "5px",
                                                                                alignItems: "center",
                                                                                padding: "4px",
                                                                                borderRadius: "5px",
                                                                                backgroundColor: "#fff",
                                                                                color: "#16A34A",
                                                                                fontWeight: 500,
                                                                                fontSize: "12px",
                                                                                margin: "0px",
                                                                                lineHeight: "1.25em",
                                                                            }}
                                                                        >
                                                                            <LocalOffer style={{ fontSize: "18px" }} /> {service.varSeasonalPromotionMsg}
                                                                        </p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                <div
                                                                    style={{ backgroundColor: "#F0FDF4", width: "100%" }}
                                                                    className={matching ? styles.main_service_accordion_active : styles.main_service_accordion}
                                                                    onClick={() => {
                                                                        selectAdditionalService(index)
                                                                    }} //selectAdditionalService(index)
                                                                >
                                                                    <div>
                                                                        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                                            {service.bitIsAdditional === false ? (
                                                                                <Tooltip
                                                                                    title={
                                                                                        <>
                                                                                            <div style={{ padding: "5px" }}>
                                                                                                <p style={{ fontSize: "14px", color: "white", fontWeight: "600", marginBottom: "5px" }}></p>
                                                                                                <p style={{ fontSize: "14px", color: "white", fontWeight: "600" }}>{service.varServiceItemName}</p>
                                                                                                <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                                                                                                    {service.SubServices.length > 0
                                                                                                        ? service.SubServices.map((sub, index) => {
                                                                                                              return (
                                                                                                                  <p key={index} style={{ whiteSpace: "pre-wrap", fontWeight: "500", color: "white" }}>
                                                                                                                      - {sub.SubServiceName}
                                                                                                                  </p>
                                                                                                              )
                                                                                                          })
                                                                                                        : null}
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                >
                                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(M)</p>
                                                                                </Tooltip>
                                                                            ) : (
                                                                                <Tooltip
                                                                                    style={{ paddingLeft: "5px" }}
                                                                                    title={
                                                                                        <>
                                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Additional Service</p>
                                                                                            <p style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>{service.varServiceItemName}</p>
                                                                                        </>
                                                                                    }
                                                                                >
                                                                                    <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>(A)</p>
                                                                                </Tooltip>
                                                                            )}
                                                                            <p style={{ margin: "0", fontWeight: "600", color: "#002867", fontSize: "14px" }}>{service.varServiceItemName}</p>
                                                                        </div>
                                                                        <p style={{ color: "#4A5163", fontWeight: "400", fontSize: "12px", margin: "0", lineHeight: "1.25em" }}>
                                                                            {service.varServiceItemDescription}
                                                                            {service.varPriceChangeComment !== null ? <span> | {service.varPriceChangeComment}</span> : null}
                                                                        </p>
                                                                    </div>

                                                                    <div style={{ display: "flex", gap: "5px" }}>
                                                                        <div style={{ borderRadius: "4px", padding: "0 5px 0 5px", backgroundColor: "#DCFCE7" }}>
                                                                            <p
                                                                                style={{
                                                                                    color: "#DCFCE7",
                                                                                    fontSize: "16px",
                                                                                    fontWeight: "400",
                                                                                    margin: "0",
                                                                                    padding: "0 5px",
                                                                                    height: "100%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    whiteSpace: "nowrap",
                                                                                }}
                                                                            >
                                                                                <ArrowDownward style={{ color: "#16A34A", fontSize: "18px" }} />
                                                                            </p>
                                                                        </div>

                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                minWidth: "120px",
                                                                                border: "1px solid #BBF7D0",
                                                                                borderRadius: "4px",
                                                                                padding: "0 5px 0 5px",
                                                                                backgroundColor: "#DCFCE7",
                                                                            }}
                                                                        >
                                                                            <Tooltip
                                                                                style={{ paddingLeft: "5px" }}
                                                                                title={
                                                                                    <>
                                                                                        <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Price Change Comment</p>
                                                                                        <p style={{ fontSize: "12px", color: "#fff" }}>{service.varPriceChangeComment}</p>
                                                                                    </>
                                                                                }
                                                                            >
                                                                                <InfoOutlined style={{ fontSize: "18px", color: "#15803D" }} />
                                                                            </Tooltip>
                                                                            <p
                                                                                style={{
                                                                                    color: "#15803D",
                                                                                    fontSize: "16px",
                                                                                    fontWeight: "400",
                                                                                    margin: "0",
                                                                                    padding: "0 5px",
                                                                                    height: "100%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    whiteSpace: "nowrap",
                                                                                    justifyContent: "center",
                                                                                }}
                                                                            >
                                                                                <del>${service.numServiceItemPreviousSalesPrice}</del>&nbsp;/&nbsp;<b>${service.numServiceItemSalesPrice}</b>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )
                                            })
                                    ) : (
                                        <p style={{ color: "#777E90", fontWeight: "400", fontSize: "13px" }}>No services found</p>
                                    )}
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6">
                                    {" "}
                                    <p style={{ color: "#002867", fontSize: "16px", fontWeight: "600", margin: "0" }}>Products</p>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Box>
                                        <Tabs value={productTabIndex} onChange={handleProductTabChange}>
                                            <Tab label="Products" style={{ padding: "0" }} />
                                            <Tab
                                                style={{ padding: "0" }}
                                                label="Cart"
                                                icon={<span style={{ color: "#2076FF", background: "#F5F5F5", borderRadius: "24px", padding: "2px 10px" }}>{cartItemCount}</span>}
                                                iconPosition={"end"}
                                            />
                                        </Tabs>
                                    </Box>
                                    <Box>
                                        {productTabIndex === 0 && (
                                            <div style={{ width: "100%" }}>
                                                <div style={{ overflowY: "scroll", width: "100%", minHeight: "200px", maxHeight: "200px" }}>
                                                    {isLoading ? (
                                                        <p>Loading...</p>
                                                    ) : productList.length > 0 ? (
                                                        productList.map((product, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    style={{
                                                                        borderRadius: "8px",
                                                                        border: "1px solid #EFF3FA",
                                                                        background: "#FFFFFF",
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        padding: "10px",
                                                                        marginBottom: "5px",
                                                                    }}
                                                                >
                                                                    <div style={{ display: "flex", gap: "10px" }}>
                                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                                            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                                                <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600" }}>{product.varProductName}</p>
                                                                                <Tooltip
                                                                                    style={{ paddingLeft: "5px" }}
                                                                                    title={
                                                                                        <>
                                                                                            <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Product Description</p>
                                                                                            <p style={{ fontSize: "12px", color: "#fff" }}>{product.varProductDescription}</p>
                                                                                        </>
                                                                                    }
                                                                                >
                                                                                    <InfoOutlined style={{ fontSize: "18px", color: "#606D93" }} />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div style={{ color: "#606D93", fontSize: "12px", fontWeight: "400" }}>
                                                                                {product.varSalesCommissionMessage} {product.varSeasonalPromotionMsg}
                                                                            </div>
                                                                            <div style={{ display: "flex", gap: "10px" }}>
                                                                                <p style={{ color: "#606D93", fontSize: "12px", fontWeight: "400" }}>
                                                                                    Available Quantity: {product.TempAvailableQuantity}
                                                                                </p>
                                                                                <p style={{ color: "#606D93", fontSize: "12px", fontWeight: "400" }}>Unit Price: {product.UnityItemPrice}$</p>
                                                                                {product.isAddedToCart === true ? (
                                                                                    <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
                                                                                        <ShoppingCart style={{ color: "#00a340", fontSize: "18px" }} />
                                                                                        <p style={{ color: "#00a340" }}>Added to cart</p>
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>

                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                    alignItems: "center",
                                                                                    width: "fit-content",
                                                                                    gap: "10px",
                                                                                    marginTop: "1px",
                                                                                }}
                                                                            >
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        decrementQty(product.numLocationProductID, index)
                                                                                    }}
                                                                                >
                                                                                    <RemoveCircleOutline className={styles.products_add_remove_button} />
                                                                                </IconButton>
                                                                                <p style={{ fontSize: "14px", fontWeight: "600", color: "#192252" }}>{product.SelectedQuantity}</p>
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        incrementQty(product.numLocationProductID, index)
                                                                                    }}
                                                                                >
                                                                                    <AddCircleOutline className={styles.products_add_remove_button} />
                                                                                </IconButton>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                        <p style={{ color: "#192252", fontSize: "18px", fontWeight: "700", textAlign: "right" }}>
                                                                            $
                                                                            {product.SelectedQuantity > 0
                                                                                ? Math.round(product.numProductSalesPrice * product.SelectedQuantity * 100) / 100
                                                                                : product.numProductSalesPrice}
                                                                        </p>
                                                                        <MUIButton
                                                                            variant="outlined"
                                                                            disabled={product.SelectedQuantity < 1 ? true : false}
                                                                            children={
                                                                                <ShoppingCart
                                                                                    style={product.SelectedQuantity < 1 ? { color: "#9097a9", padding: "10px" } : { color: "#2076ff", padding: "10px" }}
                                                                                />
                                                                            }
                                                                            onClick={() => {
                                                                                addToCart(product.numLocationProductID, index)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    ) : (
                                                        <p>No products found</p>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {productTabIndex === 1 && (
                                            <div style={{ width: "100%", marginTop: "20px" }}>
                                                <div style={{ overflowY: "scroll", width: "100%", minHeight: "200px", maxHeight: "200px" }}>
                                                    {isLoading ? (
                                                        <p>Loading...</p>
                                                    ) : servicePet !== undefined ? (
                                                        servicePet.id !== undefined ? (
                                                            servicePet.selectedProducts
                                                                .filter((x) => x.bitActive === true)
                                                                .map((product, index) => {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            style={{
                                                                                borderRadius: "8px",
                                                                                border: "1px solid #EFF3FA",
                                                                                background: "#FFFFFF",
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                padding: "10px",
                                                                                marginBottom: "5px",
                                                                            }}
                                                                        >
                                                                            <div style={{ display: "flex", gap: "15px" }}>
                                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                    <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                                                        <p style={{ color: "#192252", fontSize: "14px", fontWeight: "600" }}>{product.varProductName}</p>
                                                                                        <Tooltip
                                                                                            style={{ paddingLeft: "5px" }}
                                                                                            title={
                                                                                                <>
                                                                                                    <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Product Description</p>
                                                                                                    <p style={{ fontSize: "12px", color: "#fff" }}>{product.varProductDescription}</p>
                                                                                                </>
                                                                                            }
                                                                                        >
                                                                                            <InfoOutlined style={{ fontSize: "18px", color: "#606D93" }} />
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                    <div style={{ color: "#606D93", fontSize: "12px", fontWeight: "400" }}>
                                                                                        {product.varSalesCommissionMessage} {product.varSeasonalPromotionMsg}
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                            alignItems: "center",
                                                                                            width: "fit-content",
                                                                                            gap: "10px",
                                                                                            marginTop: "15px",
                                                                                        }}
                                                                                    >
                                                                                        <IconButton
                                                                                            onClick={() => {
                                                                                                decrementCartQty(product.numLocationProductID, index)
                                                                                            }}
                                                                                        >
                                                                                            <RemoveCircleOutline className={styles.products_add_remove_button} />
                                                                                        </IconButton>
                                                                                        <p style={{ fontSize: "14px", fontWeight: "600", color: "#192252" }}>{product.numQuantity}</p>
                                                                                        <IconButton
                                                                                            onClick={() => {
                                                                                                incrementCartQty(product.numLocationProductID, index)
                                                                                            }}
                                                                                        >
                                                                                            <AddCircleOutline className={styles.products_add_remove_button} />
                                                                                        </IconButton>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                                <p style={{ color: "#192252", fontSize: "18px", fontWeight: "700", textAlign: "right" }}>
                                                                                    ${Math.round(product.numProductSalesPrice * product.numQuantity * 100) / 100}
                                                                                </p>
                                                                                <MUIButton
                                                                                    variant="outlined"
                                                                                    children={<Delete style={{ color: "#2076ff", padding: "10px" }} />}
                                                                                    onClick={() => {
                                                                                        removeFromCart(product.numLocationProductID, index)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                        ) : (
                                                            ""
                                                        )
                                                    ) : (
                                                        <p>Cart is empty</p>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6">
                                    {" "}
                                    <p style={{ color: "#002867", fontSize: "16px", fontWeight: "600", margin: "0" }}>Slots</p>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Slot selection content.</Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", gap: "20px" }}>
                        <Button variant="contained" color="primary" loading={isLoading} onClick={() => {}} disabled={isLoading}>
                            Make Reservation
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Profile
