import ReactDOM from 'react-dom/client';
import App from './App';

import { AuthProvider } from './Context/AuthContext';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    // <React.StrictMode> 
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path='/*' element={<App />} />                    
                </Routes>                
            </AuthProvider>     
        </BrowserRouter>          
    //  </React.StrictMode>
);

