import { Add, Search } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import styles from './SearchBox.module.scss';

export const SearchBox = (props: {
    onChange?: CallableFunction;
    onFocus?: CallableFunction;
    onBlur?: CallableFunction;
    autoFocus?: boolean;
    disabled?: boolean;
    value?:string;
    isButton?
    onAddClick?;
    children?;
}) => {
    return (
        <div>
            <div className={styles.searchContainer}>
                <div style={{display: "flex", alignItems: "center", gap:"0.3rem !important"}}>
                    <Search color={'disabled'} />
                        <input
                            autoFocus={props.autoFocus}
                            onFocus={() => {
                                props.onFocus?.();
                            }}
                            onBlur={() => {
                                props.onBlur?.();
                            }}
                            className={styles.searchInput}
                            onChange={(e) => {
                                props.onChange?.(e.target.value);
                            }}
                            placeholder={'Search'}
                            style={{width: "100%"}}
                        />
                </div>
                <div>
                    {
                        (props.isButton) ? 
                            <IconButton  onClick={props.onAddClick} style={{background: "#005DF1", borderRadius: "4px"}}>
                                <Add fontSize="small" style={{color:"#fff"}} />
                            </IconButton>
                            : null
                    }  
                </div>            
            </div>
        </div>
    );
}

