import { CheckCircle } from "@mui/icons-material"
import { MenuItem, Switch, TextField } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import Cards from "react-credit-cards-2"
import "react-credit-cards-2/dist/es/styles-compiled.css"
import { toast } from "react-toastify"
import { axiosPrivate } from "../../Apis/axios"
import { Chip, Button } from "../../Components"
import CryptoJS from "crypto-js"
import InputMask from "react-input-mask"
import { expiryDate } from "../../Utils/timeHandler"

const PaymentDetails = (props: { mode?: boolean; data }) => {
    async function getCreditCards(customerId) {
        setCardLoading(true)
        const result = await axiosPrivate.get(`Customer/GetCreditCardsByID/${customerId}`)
        return result
    }

    const [cards, setCards] = useState([] as any)

    const [customerLoading, setCustomerLoading] = useState(false)
    const [cardLoading, setCardLoading] = useState(false)
    const [selected, setSelected] = useState("")
    const [cardType, setCardType] = useState("")

    const [focused, setFocus] = useState([] as any)

    const [billingList, setBillingList] = useState([] as any)

    const formatBIllingAddress = (addressOne, addressTwo, city, state) => {
        let finalString = [] as any

        if (addressOne != "" && addressOne != undefined && addressOne != null) {
            finalString.push(addressOne)
        }

        if (addressTwo != "" && addressTwo != undefined && addressTwo != null) {
            finalString.push(addressTwo)
        }

        if (city != "" && city != undefined && city != null) {
            finalString.push(city)
        }

        if (state != "" && state != undefined && state != null) {
            finalString.push(state)
        }

        return finalString.join(", ")
    }

    useEffect(() => {
        // console.log(cards)

        getCreditCards(props.data.numCustomerID)
            .then((res) => {
                console.log(res.data.creditCard)

                setCards(res.data.creditCard)
                setCardLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })

        props.data.customerAddress.map((address, ind) => {
            if (address.bitIsBilling == null || address.bitIsBilling) {
                console.log("billas", address)
                const newValue: any = [...billingList]
                let obj = {}
                obj["value"] = address.numCustomerAddressID
                console.log("2222", address.varCustomerAddress1, address.varCustomerAddress2, address.varCustomerCity, address.varCustomerState)
                obj["text"] = formatBIllingAddress(address.varCustomerAddress1, address.varCustomerAddress2, address.varCustomerCity, address.varCustomerState)

                newValue.push(obj)
                setBillingList(newValue)
            }
        })
    }, [])

    const updateDefaultStatus = (index) => {
        const newValue = [...cards]

        newValue.map((addresss, currentIndex) => {
            if (currentIndex === index) {
                newValue[currentIndex].bitIsDefault = true
            } else {
                newValue[currentIndex].bitIsDefault = false
            }
        })

        setCards(newValue)
    }

    const [currAddress, setCurrAddress] = useState("")

    async function saveData() {
        const REACT_APP_SECRET_KEY = process.env.REACT_APP_SECRET

        const newValue = [...cards]

        newValue.map((addresss, currentIndex) => {
            newValue[currentIndex].numCustomerID = props.data.numCustomerID

            let txt = newValue[currentIndex].varCreditCardNo
            let cvc = newValue[currentIndex].varCreditCardCVV2
            let month = newValue[currentIndex].varCreditCardExpMonth
            let year = newValue[currentIndex].varCreditCardExpYear

            let combineDetails = txt + "--" + cvc + "--" + month + "--" + year

            let ciphertext = CryptoJS.AES.encrypt(combineDetails, REACT_APP_SECRET_KEY).toString()

            newValue[currentIndex].varEncryptedCreditCardNo = ciphertext

            let convert = txt.substring(0, 4) + "XXXXXXXXXXXX"

            newValue[currentIndex].varCreditCardNo = convert
            if (newValue[currentIndex].bitIsDefault === null) {
                newValue[currentIndex].bitIsDefault = false
            }
        })

        setCards(newValue)

        const payload = {
            customerCreditCard: cards,
        }

        console.log("payloadf", payload)

        const result = await axiosPrivate.post("Customer/PostCreditCards", JSON.stringify(payload))
        return result
    }

    const saveChanges = () => {
        setCustomerLoading(true)

        var Form: any = document.getElementById("editForm")

        if (Form.checkValidity() == false) {
            console.log("invalid")
            setCustomerLoading(false)
        } else {
            saveData()
                .then((res) => {
                    console.log(res)
                    toast.success("Customer was updated successfully!")

                    setCustomerLoading(false)
                    getCreditCards(props.data.numCustomerID)
                        .then((res) => {
                            console.log(res.data.creditCard)

                            setCards(res.data.creditCard)
                            setCardLoading(false)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
                .catch((err) => {
                    console.log(err)
                    setCustomerLoading(false)

                    toast.error("Something went wrong when saving new customer!")
                })

            console.log(cards)
        }
    }

    const addNewCard = () => {
        const newValue: any = [...cards]

        newValue.push({
            numCustomerID: 0,
            varNameOnCard: props.data?.varCustomerFirstName +" "+ props.data?.varCustomerLastName,
            varCreditCardNo: "",
            numCustomerCreditCardID: 0,
            varEncryptedCreditCardNo: "",
            varCreditCardExpMonth: "",
            varCreditCardExpYear: "",
            varCreditCardCVV2: "",
            varCardType: "",
            numCustomerAddressID: null,
            bitIsDefault: false,
            bitActive: true,
            billingAddress: {
                varCustomerAddress1: "",
                varCustomerAddress2: "",
                varCustomerCity: "",
                varCustomerState: "",
            },
        })

        setCards(newValue)
    }

    const expErr = useRef(false)
    const [numberErr, setNumberErr] = useState(false)
    const [cvvErr, setCvvErr] = useState(false)

    const formatExpiry = (date) => {
        let exp = date.split("-")
        return exp[1] + exp[0].slice(-2)
    }

    const setBilling = (index, value) => {
        const newValue = [...cards]
        newValue[index].numCustomerAddressID = value
        setCards(newValue)
        return true
    }

    // useEffect(() => { console.log('cards', cards) }, [cards])

    return (
        <>
            <p style={{ color: "rgb(35, 38, 47)", fontWeight: "600", fontSize: "14px" }}>Credit Cards</p>
            {props.mode ? (
                <div>
                    <form id="editForm" onSubmit={(e) => e.preventDefault()}>
                        {cards.map((card, index) => {
                            return (
                                <div style={{ marginTop: "1.5rem" }}>
                                    <Cards
                                        key={index}
                                        focused={focused[index]}
                                        expiry={formatExpiry(cards[index].varCreditCardExpYear.toString())}
                                        name={cards[index].varNameOnCard.toString()}
                                        number={cards[index].varCreditCardNo.toString()}
                                        cvc={cards[index].varCreditCardCVV2}
                                        callback={(e) => {
                                            const newValue = [...cards]
                                            newValue[index].varCardType = e.issuer
                                            setCardType(e.issuer)
                                            setCards(newValue)
                                        }}
                                    />
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                  
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: ".5rem" }}>
                                                <p>Card {index + 1}</p>
                                                {card.bitIsDefault ? (
                                                    <Chip color={"success"} label="Default" size="small" variant="filled" />
                                                ) : (
                                                    <Chip onClick={() => updateDefaultStatus(index)} color={"info"} label="Set as default" size="small" variant="outlined" />
                                                )}
                                            </div>
                                   
                                            <div>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <p>Active</p>
                                                    <Switch
                                                        onChange={(e) => {
                                                            const newValue = [...cards]
                                                            newValue[index].bitActive = !newValue[index].bitActive
                                                            setCards(newValue)
                                                        }}
                                                        checked={cards[index].bitActive}
                                                        inputProps={{ "aria-label": "controlled" }}
                                                    />
                                                </div>
                                            </div>
                                       
                                    </div>

                                    <div>
                                        <div style={{ marginTop: "1.5rem" }}>
                                            <div style={{ display: "flex", gap: "2rem" }}>
                                                <TextField
                                                    tabIndex={1}
                                                    style={{ width: "100%", marginBottom: "1.5rem" }}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {"Name on Card"}
                                                            {true ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    required={true}
                                                    onFocus={() => {
                                                        const focus: any = [...focused]
                                                        focus[index] = "name"
                                                        setFocus(focus)
                                                    }}
                                                    onChange={(e) => {
                                                        const newValue = [...cards]
                                                        newValue[index].varNameOnCard = e.target.value
                                                        setCards(newValue)
                                                    }}
                                                    type={"text"}
                                                    variant="filled"
                                                    value={cards[index].varNameOnCard}
                                                />

                                                <InputMask
                                                    tabIndex={2}
                                                    alwaysShowMask="true"
                                                    mask="9999\ 9999\ 9999\ 9999"
                                                    maskChar="_"
                                                    onFocus={() => {
                                                        const focus: any = [...focused]
                                                        focus[index] = "number"
                                                        setFocus(focus)
                                                    }}
                                                    value={cards[index].varCreditCardNo}
                                                    onChange={(e) => {
                                                        const newValue = [...cards]
                                                        newValue[index].varCreditCardNo = e.target.value
                                                        setCards(newValue)
                                                    }}
                                                >
                                                    {(inputProps) => (
                                                        <TextField
                                                            error={numberErr}
                                                            tabIndex={2}
                                                            {...inputProps}
                                                            type="tel"
                                                            required={true}
                                                            label={
                                                                <div style={{ display: "flex", fontSize: "14px" }}>
                                                                    {"Card No"}
                                                                    {true ? (
                                                                        <span
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                backgroundColor: "#FDEDED",
                                                                                color: "#EF4444",
                                                                                fontSize: "12px",
                                                                                padding: "0 5px",
                                                                                marginLeft: "5px",
                                                                            }}
                                                                        >
                                                                            Required
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                            }
                                                            variant="filled"
                                                            style={{ width: "100%", marginBottom: "1.5rem" }}
                                                        />
                                                    )}
                                                </InputMask>
                                            </div>

                                            <div style={{ display: "flex", gap: "2rem" }}>
                                                <TextField
                                                    onFocus={() => {
                                                        const focus: any = [...focused]
                                                        focus[index] = "expiry"
                                                        setFocus(focus)
                                                    }}
                                                    value={cards[index].varCreditCardExpYear}
                                                    onChange={(e) => {
                                                        const newValue = [...cards]
                                                        newValue[index].varCreditCardExpYear = e.target.value
                                                        setCards(newValue)
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    inputProps={{
                                                        min: expiryDate(new Date()),
                                                    }}
                                                    error={expErr.current}
                                                    tabIndex={3}
                                                    type="month"
                                                    required={true}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {"Exp Date"}
                                                            {true ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    variant="filled"
                                                    style={{ width: "100%", marginBottom: "1.5rem" }}
                                                />

                                                <InputMask
                                                    alwaysShowMask="true"
                                                    mask={cardType === "amex" ? "9999" : "999"}
                                                    maskChar="_"
                                                    onFocus={() => {
                                                        const focus: any = [...focused]
                                                        focus[index] = "cvc"
                                                        setFocus(focus)
                                                    }}
                                                    value={cards[index].varCreditCardCVV2}
                                                    onChange={(e) => {
                                                        const newValue = [...cards]
                                                        newValue[index].varCreditCardCVV2 = e.target.value
                                                        setCards(newValue)
                                                    }}
                                                >
                                                    {(inputProps) => (
                                                        <TextField
                                                            error={cvvErr}
                                                            {...inputProps}
                                                            type="tel"
                                                            required={true}
                                                            label={
                                                                <div style={{ display: "flex", fontSize: "14px" }}>
                                                                    {"CVV"}
                                                                    {true ? (
                                                                        <span
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                backgroundColor: "#FDEDED",
                                                                                color: "#EF4444",
                                                                                fontSize: "12px",
                                                                                padding: "0 5px",
                                                                                marginLeft: "5px",
                                                                            }}
                                                                        >
                                                                            Required
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                            }
                                                            variant="filled"
                                                            style={{ width: "100%", marginBottom: "1.5rem" }}
                                                        />
                                                    )}
                                                </InputMask>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <p>Billing Address</p>
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "10px", padding: "0 5px", marginLeft: "5px" }}>Required</span>
                                        </div>

                                    
                                            <TextField
                                                select={!!billingList}
                                                fullWidth={true}
                                                variant={"standard"}
                                                onChange={(e) => {
                                                    console.log("e", e.target.value)
                                                    const newValue = [...cards]
                                                    newValue[index].numCustomerAddressID = e.target.value
                                                    setCards(newValue)
                                                }}
                                                value={
                                                    cards.length > 0
                                                        ? cards[index].billingAddress == null
                                                            ? billingList[0].numCustomerAddressID
                                                            : cards[index].billingAddress.numCustomerAddressID
                                                        : cards[index].numCustomerAddressID
                                                }
                                                required={true}
                                            >
                                                {billingList.map((item, i) => {
                                                    return (
                                                        <MenuItem
                                                            key={i}
                                                            value={item.value}
                                                            onClick={() => {
                                                                const newValue = [...cards]
                                                                newValue[index].numCustomerAddressID = item.value
                                                                newValue[index].billingAddress.varCustomerAddress1 = item.text
                                                                setCards(newValue)
                                                            }}
                                                            style={{ fontSize: "medium" }}
                                                        >
                                                            {item.text}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                    </div>
                                </div>
                            )
                        })}

                     
                            <div style={{ padding: "1rem 0" }}>
                                <Button variant="outlined" color="default" text="Add New Card" onClick={() => addNewCard()} />
                            </div>
                 
                            <div style={{ backgroundColor: "#EFF5FF", padding: "2rem 3rem", textAlign: "end", marginTop: "2rem" }}>
                                <div style={{ display: "flex", gap: "2rem", justifyContent: "flex-end" }}>
                                    <Button onClick={() => saveChanges()} loading={customerLoading} variant="contained" color="primary" text="Save" type={"submit"} />
                                </div>
                            </div>
                    </form>
                </div>
            ) : cardLoading ? (
                <p>Loading...</p>
            ) : cards.length > 0 ? (
                <div style={{ marginTop: "1.5rem", display: "grid", gridTemplateColumns: "repeat(2, 1fr)", rowGap: "2ch" }}>
                    {cards.map((card, index) => {
                        return (
                            <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                <div style={{ display: "flex", gap: ".5rem", justifyContent: "center" }}>
                                    {card.bitIsDefault ? <Chip color={"success"} label="Default" size="small" variant="filled" /> : null}

                                    {card.bitActive ? (
                                        <Chip color={"success"} label="Active" size="small" variant="outlined" icon={<CheckCircle />} />
                                    ) : (
                                        <Chip color={"error"} label="Inactive" size="small" variant="outlined" icon={<CheckCircle />} />
                                    )}
                                </div>

                                <Cards
                                    key={index}
                                    expiry={`${card.varCreditCardExpYear}/${card.varCreditCardExpMonth}`}
                                    name={card.varNameOnCard}
                                    number={card.varCreditCardNo}
                                    cvc={""}
                                    callback={(e) => console.log("callb", e)}
                                />

                                <div style={{ textAlign: "left" }}>
                                    <p style={{ color: "gray" }}>Billing Address</p>
                                    <p>
                                        {formatBIllingAddress(
                                            card.billingAddress?.varCustomerAddress1,
                                            card.billingAddress?.varCustomerAddress2,
                                            card.billingAddress?.varCustomerCity,
                                            card.billingAddress?.varCustomerState
                                        )}
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            ) : (
                <p style={{ color: "rgb(119, 126, 144)", fontWeight: "400", fontSize: "12px", width: "600px" }}>No credit cards found</p>
            )}
        </>
    )
}

export default PaymentDetails
