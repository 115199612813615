import { ArrowBack, Warning } from '@mui/icons-material';
import { Avatar, Button, Chip } from '../../Components';
import styles from './TabDashboard.module.scss';

import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "../../Apis/axios";
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import moment from 'moment';
import ViewAppointment from './ViewAppointment';
import NotificationComponent from './NotificationComponent';


// import InquiryTab from '../Components/InquiryTab/InquiryTab';
const TabDashboard = (props: {
    children?
    loginData?
}) => {

    useEffect(() => { console.log("passed", props.loginData) }, [props.loginData])

    const [dataLoading, setDataLoading] = useState(false)
    const [viewWorking, setViewWorking] = useState(false)
    const [appointmentList, setAppointmentList] = useState([]) as any
    const [viewAppointmentDetails, setViewAppointmentDetails] = useState(false)
    const [selectedAppointmentID, setSelectedAppointmentID] = useState("")


    async function getAppointments(id) {
        setDataLoading(true)

        await axios.get(`${process.env.REACT_APP_API_URL}Appointment/GetAppointmentsByCustomerID?CustomerID=${id}`).then((result) => {
            if (result.status === 200) {
                console.log(result?.data)
                setAppointmentList(result?.data)
                setDataLoading(false)
            }
        }).catch((err) => {
            console.log(err);
            toast.error('Something went wrong!');

            setDataLoading(false)
        })
    }

    useEffect(() => {
        if (props.loginData.customer.numCustomerID !== null) {
            getAppointments(props.loginData.customer.numCustomerID)
        }

    }, [])


    const navigate = useNavigate();
    const location = useLocation() as any;

    const handleLink = (id) => {
        const from = location.state?.from?.pathname || `working`;
        navigate(from, { state: { appData: id }, replace: true })
    }

    const showDetails = (appointmentID) => {
        setViewAppointmentDetails(true)
        setSelectedAppointmentID(appointmentID)
    }

    const showWorkingTab = () => {
        setViewWorking(true)
    }

    const dateAndTime = (inputDate) => {
        const dateObj = moment(inputDate);

        const formattedDate = dateObj.format('MMM D, YYYY');
        const formattedTime = dateObj.format('ddd [at] h:mm A');

        return {
            date: formattedDate,
            time: formattedTime
        };
    }


    return (
        <>
            <div style={{ fontWeight:"600", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"4rem"}}>UNDER DEVELOPMENT</div>
            <div style={{ fontWeight:"600", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"10px"}}>👷‍♂️🚧👷‍♀️</div>
        </>

        // <>
        //     <div className={styles.mobileView} style={{ display: 'flex', justifyContent: 'space-between' }}>
        //         <div style={{ flex: 1, backgroundColor:"#f1f1f1" }}>
        //             {
        //                 (viewAppointmentDetails)? null : <h1 style={{ margin: '20px 0 15px 20px ', padding: '0', fontSize: '24px' }}>My Appointments</h1>
        //             }
                    
        //             <div style={{ flex: 1, overflowY: 'scroll', margin: '0 0 20px 0', maxHeight: "calc(100vh - 270px)" }} >
        //                 <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        //                     <div style={{ width: '100%', margin: '0 20px 20px 20px' }}>
        //                         {/* <FileUpload/> */}
        //                         {
        //                             (viewAppointmentDetails) ?
        //                                 <div style={{marginTop:"10px"}}>
        //                                     <Button onClick={() => setViewAppointmentDetails(false)} color='default' variant='outlined'>Back</Button>
        //                                     <div>
        //                                         <ViewAppointment appID={selectedAppointmentID} />
        //                                     </div>
        //                                 </div>
        //                                 :

        //                                 (dataLoading) ?
        //                                     <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '50px 0', gap: '20px' }}>
        //                                         <CircularProgress style={{ fontSize: '50px' }} />
        //                                         <p style={{ fontWeight: '600', fontSize: '18px' }}>Loading Appointments...</p>
        //                                     </div>
        //                                     :
        //                                     <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        //                                         {
        //                                             appointmentList.map((appointment, index) => {
        //                                                 return <div key={index} onClick={() => showDetails(appointment.numAppointmentID)} className={styles.appointment}>
        //                                                     <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "1rem" }}>
        //                                                         <div style={{ display: 'flex', justifyContent: 'space-between', gap: '.6rem' }}>
        //                                                             <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        //                                                                 <Avatar shape="circular" size='extra-small' img={`${process.env.REACT_APP_MEDIA_URL}directories/pets/${appointment.varPetImagePath}`} isVip={false} className={'medium-small'} />
        //                                                             </div>
        //                                                             <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between' }}>
        //                                                                 <div style={{ fontWeight: 600, fontSize: "16px", color: "#002867" }}>{appointment.varPetName}</div>
        //                                                                 <div style={{ display: "flex", gap: "12px", alignItems: "center", marginTop: ".2rem" }}>
        //                                                                     <div style={{ fontWeight: 400, fontSize: 14, color: "#848FAC" }}>{appointment.varPetBreedName}</div>
        //                                                                 </div>
        //                                                             </div>
        //                                                         </div>
        //                                                         <Chip size='small' customBackgroundColor={"#BBF7D0"} customBorder={"#14532D"} customBorderRadius={"16px"} fontColor={"#14532D"} label={(appointment.apptStatus !== "") ? appointment.apptStatus : "UPCOMING"} />
        //                                                     </div>
        //                                                     <div style={{ width: "100%", borderBottom: "1px solid #F3F4F6" }}></div>
        //                                                     <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
        //                                                         <div>
        //                                                             <p style={{ fontSize: "14px", color: "#848FAC" }}>REF.#</p>
        //                                                             <p style={{ fontWeight: 600, fontSize: "16px", color: "#002867" }}>{appointment.numAppointmentID}</p>
        //                                                         </div>
        //                                                         <div>
        //                                                             <p style={{ fontSize: "14px", color: "#848FAC" }}>SERVICE</p>
        //                                                             <p style={{ fontWeight: 600, fontSize: "16px", color: "#002867" }}>Grooming</p>
        //                                                         </div>
        //                                                         <div>
        //                                                             <p style={{ fontSize: "14px", color: "#848FAC" }}>BOOKING</p>
        //                                                             <p style={{ fontWeight: 600, fontSize: "16px", color: "#002867", margin: "0" }}>{dateAndTime(appointment.RequestedOn).date}</p>
        //                                                             <p style={{ fontWeight: 400, fontSize: "16px", color: "#002867", margin: "0" }}>{dateAndTime(appointment.RequestedOn).time}</p>
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>
        //                                             })
        //                                         }
        //                                     </div>
        //                         }
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         {/* {
        //               (viewAppointmentDetails) ? null : null  
        //             //     <div style={{ flex: 1 }}>
        //             //     <h1 style={{ margin: '20px 0 15px 20px ', padding: '0', fontSize: '24px' }}>Pending Payments</h1>
        //             //     <div style={{ margin: '0 20px' }}>
        //             //         <div style={{ display: 'flex', padding: '10px 0', fontWeight: '600', borderBottom: '1px solid #ebebeb', marginBottom: '10px' }}>
        //             //             <p style={{ margin: 0, flex: 1 }}>Appointment #</p>
        //             //             <p style={{ margin: 0, flex: 1 }}>Pet Name</p>
        //             //             <p style={{ margin: 0, flex: 2 }}>Due Date</p>
        //             //             <p style={{ margin: 0, flex: 1, textAlign: 'end' }}>Amount Paid</p>
        //             //             <p style={{ margin: 0, flex: 1, textAlign: 'end' }}>Amount Due</p>
        //             //         </div>
    
        //             //         <div style={{ display: 'flex', padding: '15px 8px', borderRadius: '10px', backgroundColor: '#eff3fa', marginBottom: '10px' }}>
        //             //             <p style={{ margin: 0, flex: 1 }}>#1234</p>
        //             //             <p style={{ margin: 0, flex: 1 }}>Jack</p>
        //             //             <p style={{ margin: 0, flex: 2 }}>22nd of December 2022</p>
        //             //             <p style={{ margin: 0, flex: 1, textAlign: 'end' }}>$ 120</p>
        //             //             <p style={{ margin: 0, flex: 1, textAlign: 'end' }}>$ 10</p>
        //             //         </div>
    
        //             //         <div style={{ display: 'flex', padding: '15px 8px', borderRadius: '10px', backgroundColor: '#eff3fa', marginBottom: '10px' }}>
        //             //             <p style={{ margin: 0, flex: 1 }}>#1234</p>
        //             //             <p style={{ margin: 0, flex: 1 }}>Max</p>
        //             //             <p style={{ margin: 0, flex: 2 }}>22nd of December 2022</p>
        //             //             <p style={{ margin: 0, flex: 1, textAlign: 'end' }}>$ 290</p>
        //             //             <p style={{ margin: 0, flex: 1, textAlign: 'end' }}>$ 20</p>
        //             //         </div>
        //             //     </div>
        //             // </div>
        //         } */}


        //     </div>

        // </>
    );
}

export default TabDashboard; 