import { useEffect, useState } from 'react';
import { Button, Chip, Modal } from '../../Components';
import axios from "../../Apis/axios";
import { shortDate } from '../../Utils/timeHandler';
import { Truncate, daysPassedOrInFuture, formatCash } from '../../Utils/Validation';
import { CloseButton } from '../../Components/CloseButton/CloseButton';
import { Checkbox } from '@mui/material';
import { toast } from 'react-toastify';
import { HourglassBottom, PaidOutlined, ReceiptOutlined } from '@mui/icons-material';
import CustomerInvoicesBulk from './CustomerInvoicesBulk';

function CustomerInvoiceList(props:{
    isOpenCustomerInvoicesModal;
    setIsOpenCustomerInvoicesModal: CallableFunction;
    customerID,
    isSuccess?
}) {

    const [invList, setInvList] = useState<any>({})
    const [selectedInvList, setSelectedInvList] = useState<any>([])
    const [msg, setMsg] = useState<any>("")

    const [sumRemaining, setSumRemaining] = useState(0);
    const [sumPayments, setSumPayments] = useState(0);
    const [sumCharges, setSumCharges] = useState(0);

    const [isOpenCustomerInvoicesBulkModal, setIsOpenCustomerInvoicesBulkModal] = useState<any>("")

    const getCustomerInvoiceList = async (cusID) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetInvoicesListByCustomerID`;
      
        try {
          const response = await axios.get(apiUrl, {
            params: {
              numCustomerID: cusID,
            },
          });
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
    };

    useEffect(()=>{
        if(props.isOpenCustomerInvoicesModal === true){
          fetchCustomerInvoices()
        }
    },[props.customerID, props.isOpenCustomerInvoicesModal])

    const fetchCustomerInvoices = () => {
      if(props.customerID !== undefined && props.customerID !== "" && props.customerID !== null){
        getCustomerInvoiceList(props.customerID)
        .then((data) => {
            console.log("res - Customer all Invoices", data);
        setInvList(data);
            const selectedInvoices = data.filter((invoice) => invoice.numInvoiceTotalRemaining > 0).map(invoice => ({
              "numInvoiceID": invoice.numInvoiceID,
              "numCustomerID": null,
              "dtBegin_date_of_service": null,
              "dtEnd_date_of_service": null,
              "numEmployeeID": null,
              "varEmployeeName": null,
              "numAppointmentID": null
            }));      
              setSelectedInvList(selectedInvoices);
        })
        .catch((error) => {
            console.log("error", error);
        });
    }
    }

    useEffect(()=>{
        if(invList !== undefined){
            if(invList > 0){
                setMsg("No invoices for the selected customer!")
            }else{
                setMsg("No invoices for the selected customer!")
            }
        }else{
            setMsg("loading..")
        }
    },[invList])


    const selectInvoice = (id) => {
        const index = selectedInvList.findIndex(invoice => invoice.numInvoiceID === id);
        if (index !== -1) {
          const updatedInvoices = [...selectedInvList];
          updatedInvoices.splice(index, 1);
          setSelectedInvList(updatedInvoices);
        } else {
          const newInvoice =   {
            "numInvoiceID": id,
            "numCustomerID": null,
            "dtBegin_date_of_service": null,
            "dtEnd_date_of_service": null,
            "numEmployeeID": null,
            "varEmployeeName": null,
            "numAppointmentID": null
          }
          setSelectedInvList([...selectedInvList, newInvoice]);
        }
      };
      function checkInvoiceExists(invoiceID) {
        return selectedInvList.some(invoice => invoice.numInvoiceID === invoiceID);
      }
      const goToPayments = () => {
        if(selectedInvList.length > 0){
            console.log("selected", selectedInvList)
            setIsOpenCustomerInvoicesBulkModal(true)
        }else{
            toast.info("Please select invoices to proceed");
        }
      }
      const clearSelected = () => {
        setSelectedInvList([])
      }
      const selectAllInvoices = () => {
        const selectedInvoices = invList.filter((invoice) => invoice.numInvoiceTotalRemaining > 0).map(invoice => ({
          "numInvoiceID": invoice.numInvoiceID,
          "numCustomerID": null,
          "dtBegin_date_of_service": null,
          "dtEnd_date_of_service": null,
          "numEmployeeID": null,
          "varEmployeeName": null,
          "numAppointmentID": null
        }));
        
        setSelectedInvList(selectedInvoices);
      };

    
      useEffect(() => {
        let totalRemaining = 0;
        let totalPayments = 0;
        let totalCharges = 0;
    
        selectedInvList.forEach((selectedItem) => {
          const numInvoiceID = selectedItem.numInvoiceID;
    
          const correspondingItem = invList.find((item) => item.numInvoiceID === numInvoiceID);
    
          if (correspondingItem) {
            totalRemaining += correspondingItem.numInvoiceTotalRemaining;
            totalPayments += correspondingItem.numInvoiceTotalPayments;
            totalCharges += correspondingItem.numInvoiceTotalCharges;
          }
        });
    
        setSumRemaining(totalRemaining);
        setSumPayments(totalPayments);
        setSumCharges(totalCharges);
      }, [selectedInvList, invList]);

      const cardStyle:any = {
        border: '1px solid #e5e5e5',
        borderRadius: '8px',
        padding: '10px',
        boxShadow: '2px 2px 16px rgba(0, 40, 103, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
      };
      
      const textContainerStyle:any = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      };

      useEffect(()=>{console.log("selectedInvList", selectedInvList)},[selectedInvList])
    return (
        <Modal open={props.isOpenCustomerInvoicesModal} >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '800px' }}>
            <div style={{ margin: '-15px -15px 0 -15px',display: "flex", justifyContent: 'space-between', backgroundColor: '#f3f4f6', padding: '1rem 1rem 1rem 25px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
                <div>
                    <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
                        <div style={{ color: "#002867", fontSize: "24px", fontWeight: "600" }}>Customer Invoices</div>
                    </div>
                </div>
                <div style={{ color: "#F9FAFB", borderRadius: "8px" }}>
                    <div style={{ height: "40px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <CloseButton onClick={() => props.setIsOpenCustomerInvoicesModal(false)} />
                    </div>
                </div>
            </div>

            <div
              style={{
                display: 'flex',
                gap: '20px',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div style={cardStyle}>
                <ReceiptOutlined style={{ marginBottom: '10px', color:"#1976d2" }} />
                <div style={textContainerStyle}>
                  <p style={{ fontWeight: '600', color: 'black', fontSize: '20px', margin: '0' }}>${formatCash(sumCharges)}</p>
                  <p style={{ fontWeight: '600', color: 'black', margin: '0' }}>Total Charges</p>
                </div>
              </div>
              <div style={cardStyle}>
                <PaidOutlined style={{ marginBottom: '10px', color:"#20bd5a" }} />
                <div style={textContainerStyle}>
                  <p style={{ fontWeight: '600', color: '#20bd5a', fontSize: '20px', margin: '0' }}>${formatCash(sumPayments)}</p>
                  <p style={{ fontWeight: '600', color: 'black', margin: '0' }}>Paid Amount</p>
                </div>
              </div>
              <div style={cardStyle}>
                <HourglassBottom style={{ marginBottom: '10px', color:"#ff5252" }} />
                <div style={textContainerStyle}>
                  <p style={{ fontWeight: '600', color: '#ff5252', fontSize: '20px', margin: '0' }}>${formatCash(sumRemaining)}</p>
                  <p style={{ fontWeight: '600', color: 'black', margin: '0' }}>Remaining Balance</p>
                </div>
              </div>
            </div>

            <div style={{ padding: '0 7px', overflowY:"scroll", minHeight:"100px", maxHeight:"500px", width:"100%" }}>
                {
                    (invList === undefined)? <p style={{color:"gray"}}>{msg}</p>
                        :
                    (invList.length > 0)?
                    invList.filter((invoice) => invoice.numInvoiceTotalRemaining > 0).sort((a, b) => b.numInvoiceID - a.numInvoiceID).map((inv, index)=>{
                        return <div key={index} style={{display:"flex", border:"1px solid #d5d5d5", marginBottom:"10px", borderRadius:"8px", padding:"10px", justifyContent:"space-between", boxShadow: '0px 4px 0px #EDF3F9',gap:"5px"}}>
                                    <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                                        <div>
                                            <p style={{fontWeight:"600"}}>INV NO: {inv.numMainInvoiceID}</p>                            
                                            <div style={{display:"flex", gap:"10px", alignItems:"center"}}>
                                                <p style={{fontWeight:"400"}}><span style={{fontWeight:"500"}}>Due Date:</span> {shortDate(inv.dtDueDate)}</p>
                                                <p style={{fontWeight:"400"}}><span style={{fontWeight:"500"}}>Location:</span> {Truncate(inv.varLocationName,25)}</p>
                                            </div>                     
                                        </div>
                                        <div style={{ width: "90%", padding: "4px", borderRadius: "8px", display: "flex", gap: "20px" }}>
                                            <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#f5f5f5", borderRadius: "8px", padding: "5px" }}>
                                                <p style={{ fontSize: "18px", fontWeight: "600" }}>${formatCash(inv.numInvoiceTotalCharges)}</p>
                                                <p style={{ fontWeight: "500" }}>Invoice Total</p>
                                            </div>
                                            <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#e8ffed", borderRadius: "8px", padding: "5px" }}>
                                                <p style={{ fontSize: "18px", fontWeight: "600" }}>${formatCash(inv.numInvoiceTotalPayments)}</p>
                                                <p style={{ fontWeight: "500" }}>Paid Total</p>
                                            </div>
                                            <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#fff4f4", borderRadius: "8px", padding: "5px" }}>
                                                <p style={{ fontSize: "18px", fontWeight: "600" }}>${formatCash(inv.numInvoiceTotalRemaining)}</p>
                                                <p style={{ fontWeight: "500" }}>Total Remaining</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{display:"flex", flexDirection:"column", gap:"24px", alignItems:"center"}}>
                                        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                                            <Chip size='small' customBackgroundColor={(inv.IsCreatedToday)? "#259923" : "#f15e5e"} customBorder={(inv.IsCreatedToday)? "#259923" : "#f15e5e"} customBorderRadius={"16px"} fontColor={"#ffffff"} label={shortDate(inv.dtCreatedDate)}/>
                                            <p style={{fontSize:"12px", color:"gray", whiteSpace:"nowrap"}}>{"("+ daysPassedOrInFuture(inv.dtCreatedDate)+")"}</p>
                                        </div>
                                        <div style={{padding:"5px", border:"1px solid #d5d5d5", width:"fit-content", borderRadius:"8px", cursor:"pointer"}}>
                                            <Checkbox
                                                key={inv.numInvoiceID}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                onClick={()=>{selectInvoice(inv.numInvoiceID)}}
                                                checked={(checkInvoiceExists(inv.numInvoiceID) && (inv.numInvoiceTotalRemaining > 0))? checkInvoiceExists(inv.numInvoiceID) : false }
                                                disabled={(inv.numInvoiceTotalRemaining > 0)? false : true}
                                            />
                                        </div>                   
                                    </div>
                                </div>
                    })    
                        : 
                        <p style={{color:"gray"}}>{msg}</p>
                }

            </div>
            <div
                style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "10px",
                }}
            >
                <Button variant="text" color="default" onClick={() => clearSelected()}>
                Reset
                </Button>
                <Button variant="outlined" color="default" onClick={() => selectAllInvoices()}>
                Select All
                </Button>
                <Button
                variant="contained"
                color="primary"
                onClick={() => goToPayments()}
                >
                Go to payments ({selectedInvList.length})
                </Button>
                <CustomerInvoicesBulk isOpenCustomerInvoicesBulkModal={isOpenCustomerInvoicesBulkModal} setIsOpenCustomerInvoicesBulkModal={(value) => setIsOpenCustomerInvoicesBulkModal(value)} invoiceList={selectedInvList} isSuccess={(e)=>{
                  if(e === true){
                    fetchCustomerInvoices()
                    props.isSuccess(true)
                  }
                }}/>
            </div>
        </div>
    </Modal>
    );
}

export default CustomerInvoiceList;