import { useEffect, useState } from "react"
import { Avatar, Button } from "../../Components"
import axios from "../../Apis/axios"
import PetProfile from "../PetProfile/PetProfile"
import { CircularProgress } from "@mui/material"

const Pets = (props: { loginData }) => {
    useEffect(() => {
        console.log("loginD", props?.loginData)
    }, [])

    const [customerData, setCustomerData] = useState({}) as any
    const [dataLoading, setDataLoading] = useState(false)
    const [selectedPetIndex, setSelectedPetIndex] = useState("") as any

    async function getCustomerInfo(id) {
        setDataLoading(true)

        await axios
            .get(`${process.env.REACT_APP_API_URL}Customer/GetByID/${id}`)
            .then((result) => {
                if (result.status === 200) {
                    console.log("pet", result)
                    setCustomerData(result.data)
                    setDataLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                console.log("Something went wrong!")
                setDataLoading(false)
            })
    }

    useEffect(() => {
        if (props.loginData.customer.numCustomerID !== null) {
            getCustomerInfo(props.loginData.customer.numCustomerID)
        }
    }, [])

    return (
        <div>
            {
                (selectedPetIndex === "")?
                
                <>
                    <p style={{fontSize:"22px", fontWeight:"600", marginLeft:"10px"}}>My Pets</p>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", padding: "10px", width:"99%" }}>
                        {(dataLoading) ?
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '50px 0', gap: '20px' }}>
                                <CircularProgress style={{ fontSize: '50px' }} />
                                <p style={{ fontWeight: '600', fontSize: '18px' }}>Loading Pets...</p>
                            </div> :
                            (customerData?.customer?.customerPet.length < 1) ? <p style={{ color: "gray", textAlign: "center", fontSize: "14px", width: '100%' }}>No pets found</p>
                                :
                                customerData?.customer?.customerPet?.map((pet, index) => {
                                    return (
                                        <div key={index} style={{
                                            flex: "0 0 calc(23.33% - 10px)",
                                            padding: "20px",
                                            background: "#efefef",
                                            borderRadius: "8px"
                                        }}>
                                            <Avatar
                                                img={`${process.env.REACT_APP_MEDIA_URL}directories/Pets/` + pet?.varPetImagePath}
                                                isVip={false}
                                                className={"medium"}
                                                labelType={"largeLabel"}
                                            />
                                            <p style={{ textAlign: "center", fontSize: "16px" }}>{pet?.varPetName}</p>
                                            <p style={{ color: "gray", textAlign: "center", fontSize: "14px" }}>{pet?.varPrimaryBreed}</p>
                                            <div style={{textAlign:"center"}}>
                                                <Button color="success" variant="contained" text="View Profile" onClick={() => { setSelectedPetIndex(index) }} />
                                            </div>
                
                                        </div>
                                    )
                                })
                        }
                    </div>
                </> 
                :
                <div style={{padding:"10px"}}>
                      <Button onClick={() => setSelectedPetIndex("")} color='default' variant='outlined'>Back</Button>
                      <PetProfile details={customerData} index={selectedPetIndex}/>
                </div>
            }
   
        </div>
    )
}

export default Pets
