import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Pagination,
  MenuItem,
  useMediaQuery,
  useTheme,
  Menu,
  Checkbox,
} from '@mui/material';
import moment from 'moment';
import { axiosPrivate } from '../../../Apis/axios';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import { CloseButton, Modal } from '../../../Components';
import AdvancedDropDown from '../../../Components/AdvancedDropDown/AdvancedDropDown';
import styles from './Gallery.module.scss';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

function Gallery(props:{
  loginData
}) {

  const [formData, setFormData] = useState({
    FirstName: null,
    LastName: null,
    PetName: null,
    LocationID: null,
    FromDate: moment().subtract(1, 'months').format("YYYY-MM-DD"),
    ToDate: moment().format("YYYY-MM-DD"),
    PageNumber: 1,
    PageSize: 6,
    EmployeeID: null,
    ServiceID: null,
    PetBreedID: null,
    PetTypeID: null,
  });

  const [petData, setPetData] = useState([]) as any;
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [allLocations, setAllLocations] = useState([]) as any;
  const [petTypes, setPetTypes] = useState<any>([])
  const [petType, setPetType] = useState<any>("")
  const [petSize, setPetSize] = useState<any>(null)
  const [petSizes, setPetSizes] = useState<any>([])
  const [petBreeds, setPetBreeds] = useState<any>([])
  const [petServices, setPetServices] = useState<any>([])
  const [petBreed, setPetBreed] = useState<any>("")
  const [selectedCut, setSelectedCut] = useState<any>("")
  const [locationEmployees, setLocationEmployees] = useState<any>([])
  const [customerPets, setCustomerPets] = useState<any>([])
  const [customerFilteredPets, setCustomerFilteredPets] = useState<any>([])
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenSelectCut, setIsOpenSelectCut] = useState(false);
  const breedRef = useRef<any>()
  const serviceRef = useRef<any>()
  const typeRef = useRef<any>()
  const locationRef = useRef<any>()
  const sizeRef = useRef<any>()
  const employeeRef = useRef<any>()

  const handleInputChange = (e) => {
    const { name, value } = e.target || e;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}Schedule/GetPetDetailsForGallery`, {
        params: formData,
      });
      setPetData(response.data.data);
      console.log(response.data.data)
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  async function getLocations(signal) {
    setIsLoading(true);
    const result = await axiosPrivate.get(`Location/GetAll`, { signal });
    return result.data.location;
  }

  async function getPetTypes(signal) {
    const result = await axiosPrivate.get(`PetType/GetAll`, {
        signal: signal,
    })
    return result.data.petType
}

async function getSizes(id) {
    const result = await axiosPrivate.get(`PetSize/GetAllByPetTypeID/${id}`, {
        headers: { "Content-Type": "application/json" },
    })
    return result.data
}

async function getBreedByPetType(id) {
    const result = await axiosPrivate.get(`PetBreed/GetAllByPetTypeID/${id}`, {
        headers: { "Content-Type": "application/json" },
    })
    return result.data
}

async function getServicesByPetType(id) {
  const result = await axiosPrivate.get(`ServiceItems/GetServiceItemsByPetTypeID/${id}`, {
      headers: { "Content-Type": "application/json" },
  })
  return result.data
}
async function getEmployeeByLocationID(id) {
  const result = await axiosPrivate.get(`Schedule/GetEmployeesByLocationID?LocationID=${id}`, {
      headers: { "Content-Type": "application/json" },
  })
  return result.data
}

async function getCustomerInfo(id) {
  setIsLoading(true)

  await axios
      .get(`${process.env.REACT_APP_API_URL}Customer/GetByID/${id}`)
      .then((result) => {
          if (result.status === 200) {
              setCustomerPets(result?.data?.customer?.customerPet)
              setIsLoading(false)
          }
      })
      .catch((err) => {
          console.log(err)
          console.log("Something went wrong!")
          setIsLoading(false)
      })
}

  useEffect(() => {
    getCustomerInfo(props?.loginData?.customer?.numCustomerID)
    const controller = new AbortController();
    fetchOnLoadData(controller.signal);
    return () => controller.abort();
  }, []);

  const fetchOnLoadData = (signal) => {
    getLocations(signal)
      .then((res) => {
        setAllLocations(res);
      })
      .catch((err) => {
        if (err.message !== "canceled") {
          console.log(err);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

      getPetTypes(signal)
      .then((res) => {
          setPetTypes(res)
      })
      .catch((err) => {
          if (err.message != "canceled") {
              console.log(err)
          }
      })
  };

  
  const getSizeData = (id) => {
    getSizes(id)
        .then((res) => {
            setPetSizes(res?.petSize)
        })
        .catch((err) => {
            console.log(err)
            console.log("Something went wrong!")
        })
}
const getBreedData = (id) => {
    getBreedByPetType(id)
        .then((res) => {
            setPetBreeds(res?.petBreed)
        })
        .catch((err) => {
            console.log(err)
            console.log("Something went wrong!")
        })
}

const getServiceData = (id) => {
  getServicesByPetType(id)
      .then((res) => {
          setPetServices(res?.serviceItemsLibrary)
      })
      .catch((err) => {
          console.log(err)
          console.log("Something went wrong!")
      })
}
const getEmployeeData = (id) => {
  getEmployeeByLocationID(id)
      .then((res) => {
          setLocationEmployees(res)
      })
      .catch((err) => {
          console.log(err)
          console.log("Something went wrong!")
      })
}

  const handleSearch = () => {
    fetchData();
  };

  const handlePageChange = (event, value) => {
    setFormData((prev) => ({ ...prev, PageNumber: value }));
  };


  useEffect(() => {
    fetchData();
  }, [formData.PageNumber]);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  let flexBasis = '25%'; 
  if (isXs) flexBasis = '100%'; 
  else if (isSm) flexBasis = '50%'; 
  else if (isMd) flexBasis = '33.33%';


  const resetFilters = () => {
    setFormData({
        FirstName: null,
        LastName: null,
        PetName: null,
        LocationID: null,
        FromDate: moment().format("YYYY-MM-DD"),
        ToDate: moment().format("YYYY-MM-DD"),
        PageNumber: 1,
        PageSize: 6,
        EmployeeID: null,
        ServiceID: null,
        PetBreedID: null,
        PetTypeID: null,
      })
      setPetType("")
      setPetBreed("")
      setPetSize("")
      breedRef.current?.handleClear()
      serviceRef.current?.handleClear()
      typeRef.current?.handleClear()
      sizeRef.current?.handleClear()
      locationRef.current?.handleClear()
      employeeRef.current?.handleClear()
      handleSearch()
  }

  const selectCut = (index) => {

    if(customerPets.length > 0){
      const filteredData = customerPets.filter(item => item.numPetBreedID === petData[index]?.numBreedID);
      if (filteredData.length === 0) {
        toast.warning("You cannot select this cut because you do not have a pet of the selected breed.")
      }else{
          setCustomerFilteredPets(filteredData)
          setSelectedCut(index)
          setIsOpenSelectCut(true)
        
      }
    }else{
      toast.warning("Please add your pets to continue")
    }

  }

  useEffect(()=>{console.log(customerFilteredPets)},[customerFilteredPets])

  const saveMultiSelection = () => {
    const filteredData = customerFilteredPets.filter(item => item.isSelected === true);
    if(filteredData.length > 0){

            Swal.fire({
            title: 'Confirm Selection',
            text: 'Are you sure you want to select this cut for your pets next groom?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            customClass: {
              container: 'swal-overlay-container',
              popup: 'swal-popup',
            },
            didOpen: () => {
              const swalOverlay:any = document.querySelector('.swal-overlay-container');
              const swalPopup:any = document.querySelector('.swal-popup');
              if (swalOverlay) {
                swalOverlay.style.zIndex = '9999'; 
              }
              if (swalPopup) {
                swalPopup.style.zIndex = '10000'; 
              }
            }
          }).then((result) => {
            if (result.isConfirmed) {

              const petList:any = [];
              filteredData.forEach(pet => {
                  petList.push({ petID: pet.numPetID });
              });
                    let payload = [
                      {
                        "numCustomerID": props?.loginData?.customer?.numCustomerID,
                        "numLocationID": petData[selectedCut]?.numLocationID,
                        "numAppointmentID": petData[selectedCut]?.numAppointmentID,
                        "serviceID": petData[selectedCut]?.numServiceItemID,
                        "numPetTypeID": petData[selectedCut]?.numPetTypeID,
                        "numPetBreedID": petData[selectedCut]?.numBreedID,
                        "refNo": petData[selectedCut]?.ReferenceNumber,
                        "petList":petList
                      }
                    ]
                    axios.post(`${process.env.REACT_APP_API_URL}Schedule/PopulatePreferredCutsFromGallery`, payload)
                      .then(function (response) {
                      console.log("app_success", response);
                      if(response.status === 200){
                          toast.success("Selection saved successfully!");
                        setSelectedCut("")
                        setCustomerFilteredPets([])
                        setIsLoading(false)
                        setIsOpenSelectCut(false)
                      }
                      })
                      .catch(function (error) {
                          console.log(error);
                          setIsLoading(false)
                      });
               
            }else if(result.dismiss){
                setSelectedCut("")
            } 
          });
    }else{
      toast.warning("Select pets to continue!")
    }
  }

  return (
    <div style={{ marginLeft: "10px" }}>
        <div>
        <Modal open={isOpenSelectCut}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <div style={{ backgroundColor: "#fff", width: "calc(100vw - 400px)", height: "100%", borderRadius: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "column", height: "-webkit-fill-available", gap: "20px", padding: "20px" }}>
                      <div style={{display:"flex", justifyContent:"space-between"}}>
                      <p style={{fontSize:"18px", fontWeight:"600"}}>Select pets</p>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                            <CloseButton onClick={() => setIsOpenSelectCut(false)}></CloseButton>
                        </div>
                      </div>
                        <div>
                 
                          <div style={{display:"flex", gap:"10px", flexDirection:"column", minHeight:"100px", maxHeight:"200px", overflowY:"scroll"}}>
                            {
                              customerFilteredPets.map((pet, index)=>{
                                return(
                                  <div style={{display:"flex", gap:"10px"}}>
                                      <Checkbox 
                                          style={{ padding: '0' }}
                                          key={index} 
                                          checked={customerFilteredPets[index]?.isSelected} 
                                          onClick={() => { 
                                            const filteredPets:any = [...customerFilteredPets];
                                            filteredPets[index].isSelected = !filteredPets[index]?.isSelected
                                            setCustomerFilteredPets(filteredPets)
                                           }}
                                      />
                                      <p style={{fontWeight:"600"}}>{pet?.varPetName+" - "}<span style={{color:"gray"}}>{pet?.varPrimaryBreed}</span></p>
                                  </div>
                                )
                              })
                            }
                          </div>
                          <div style={{textAlign:"end"}}>
                            <Button variant='contained' color='primary' onClick={()=>{saveMultiSelection()}}>Save</Button>
                          </div>
                         
                        </div>
    
                    </div>
                </div>
            </div>
        </Modal>

        <div style={{ display: 'flex', gap: '10px', marginTop:"10px" }}>
              <div>
                <TextField
                  label="From Date"
                  name="FromDate"
                  type="date"
                  value={formData.FromDate}
                  onChange={handleInputChange}
                  InputLabelProps={{ shrink: true }}
                  size='small'
                />
              </div>
              <div>
                <TextField
                  label="To Date"
                  name="ToDate"
                  type="date"
                  value={formData.ToDate}
                  onChange={handleInputChange}
                  InputLabelProps={{ shrink: true }}
                  size='small'
                />
              </div>
              <div style={{display:"flex"}}>
                <Button aria-controls="filters-menu" aria-haspopup="true" onClick={handleMenuClick}>
                  More Filters
                </Button>
                <div style={{display:"flex", gap:"10px"}}>
                  <Button variant="contained" size='medium' onClick={handleSearch}>
                    Search
                  </Button>
                  <Button variant="outlined" color='primary' size='medium' style={{marginRight:"10px"}} onClick={resetFilters}>
                      Clear
                  </Button>
                </div>
  
                <Menu
                  id="filters-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem>
                    <div style={{ width: '250px' }}>
                      {allLocations && (
                      <AdvancedDropDown
                        data={allLocations.map((i) => ({ label: i.varLocationName, value: i.numLocationID }))}
                        onChange={(e) => {
                          handleInputChange({ name: 'LocationID', value: e.value });
                          getEmployeeData(e.value);
                        }}
                        ref={locationRef}
                        placeHolder="Location"
                        isDisabled={allLocations.length > 0 ? undefined : true}
                        passByID={true}
                        value={formData.LocationID}
                      />
                      )}
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div style={{ width: '250px' }}>
                      {petTypes && (
                      <AdvancedDropDown
                        data={petTypes.map((i) => ({ label: i.varPetTypeName, value: i.numPetTypeID }))}
                        onChange={(e) => {
                          handleInputChange({ name: 'PetTypeID', value: e.value });
                          setPetType(e.value);
                          getSizeData(e.value);
                          getBreedData(e.value);  
                          getServiceData(e.value);
                          setPetBreeds([]);               
                          setPetSizes([]);
                          setPetServices([]);
                        }}
                        ref={typeRef}
                        placeHolder="Pets Type"
                        isDisabled={petTypes.length > 0 ? undefined : true}
                        passByID={true}
                        value={formData.PetTypeID}
                      />
                      )}
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div style={{ width: '250px' }}>
                      {petServices && (
                      <AdvancedDropDown
                        data={petServices.map((i) => ({ label: i.varServiceItemName, value: i.numServiceItemID }))}
                        onChange={(e) => {
                          handleInputChange({ name: 'ServiceID', value: e.value });
                        }}
                        ref={serviceRef}
                        placeHolder="Service"
                        isDisabled={petServices.length > 0 ? undefined : true}
                        passByID={true}
                        value={formData.ServiceID}
                      />
                      )}
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div style={{ width: '250px' }}>
                      {petBreeds && (
                      <AdvancedDropDown
                        data={petBreeds.map((i) => ({ label: i.varPetBreedName, value: i.numPetBreedID }))}
                        onChange={(e) => {
                          handleInputChange({ name: 'PetBreedID', value: e.value });
                        }}
                        ref={breedRef}
                        placeHolder="Breed"
                        isDisabled={petBreeds.length > 0 ? undefined : true}
                        passByID={true}
                        value={formData.PetBreedID}
                      />
                      )}
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div style={{ width: '250px' }}>
                      {locationEmployees && (
                      <AdvancedDropDown
                        data={locationEmployees.map((i) => ({ label: i.varEmployeeFirstName + " " + i.varEmployeeLastName, value: i.numEmployeeID }))}
                        onChange={(e) => {
                          handleInputChange({ name: 'EmployeeID', value: e.value });
                        }}
                        ref={employeeRef}
                        placeHolder="Employee"
                        isDisabled={locationEmployees.length > 0 ? undefined : true}
                        passByID={true}
                        value={formData.EmployeeID}
                      />
                      )}
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </div>
        </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '20px' }}>
            { (isLoading)? <p style={{color:"gray"}}>Loading...</p> :
            petData === undefined || petData.length < 1 ? (
                <p style={{ color: "gray", fontSize: "13px", marginLeft: "20px", marginTop: "10px" }}>No images found</p>
            ) : (
                petData.map((pet, index) => (
                <div
                    key={pet?.numAppointmentID}
                    className={(selectedCut === index)? styles.cutCard_active : styles.cutCard}
                    onClick={()=>{selectCut(index)}}
                >
                    <ImgComparisonSlider style={{ width: '100%', height: '300px' }}>
                    <img
                        style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                        alt="404"
                        slot="first"
                        src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${pet?.BeforeImgPath}`}
                    />
                    <img
                        style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                        alt="404"
                        slot="second"
                        src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${pet?.AfterImagePath}`}
                    />
                    </ImgComparisonSlider>
                    <div style={{ textAlign: 'center', padding: '5px 0' }}>
                    <p style={{color:"gray"}}>{pet?.ReferenceNumber}</p>
                    </div>
                </div>
                ))
            )}
            </div>

            <Pagination
                count={totalPages}
                page={formData.PageNumber}
                onChange={handlePageChange}
                style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
            />
    </div>
  );
}

export default Gallery;
