import { Avatar as MaterialAvatar} from '@mui/material';
import styles from './avatar.module.scss'

export const Avatar = (props: {
    badge?: {
        size: string,
        text: string,
        color: string,
        children: null
    }
    text?: string,
    size?: string,
    img?: string,
    shape?: "square" | "circular" | "rounded" | undefined,
    border?: {
        size: null,
        color: null
    },
    isVip?: boolean,
    online?: boolean,
    className?;
    labelType?;
    sx?
}) => {
    const text = props.text?.slice(0, 1).toUpperCase();

    return (
        <div style={{ marginBottom: `${(props.isVip) ? '10px' : ''}`}}>
            <div className={styles.avatarContainer}>
                <MaterialAvatar  className={`${styles[props.className]} ${styles.primary}`} variant={props?.shape} src={(props.img)}>                    
                    {text}
                </MaterialAvatar>
                {(props.isVip) ? <div className={`${styles.chipAvatar} ${styles[props.labelType]}`}>VIP</div> : null}
            </div>
        </div>
    );
}


